import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ActionService extends BaseService {
  key = 'actions'
  constructor(public injector:Injector) { super(injector) }
}
