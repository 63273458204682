import {Injectable} from '@angular/core';
import {Injector} from '@angular/core';
import {BaseService} from './base.service';
import * as _ from 'lodash';
import {map, mergeMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {computePageSize, getMode, hasGroupby} from '../app.utils';


@Injectable({
  providedIn: 'root'
})
export class RecordService extends BaseService {
  key = 'records';

  constructor(public injector: Injector) {
    super(injector);
  }

  public getExcelUrl(entityId, queryParams) {
    const url = this.envService.apiEndpoint + this.key + '/?';
    const qp = _.cloneDeep(queryParams);
    qp['entity'] = entityId;
    qp['extension'] = 'xlsx';
    const queryString = Object.keys(qp).map(key => {
      if (Array.isArray(qp[key])) {
        return qp[key].map(val => key + '=' + val).join('&');
      }
      return key + '=' + qp[key];
    }).join('&');
    return url + queryString;
  }

  public retrieveRecordsCount(params: object, propagations: string[] = []): any {
    return super.fieldToService('entity').retrieveFieldManyMetadata(params['entity']).pipe(
      mergeMap((entity: any) => {
        const mode = getMode(params['mode']);
        let qpCount = _.cloneDeep(params);
        if (mode === 'calendar') {
          _.isArray(qpCount[entity.endingDateField.name]) ? qpCount[entity.endingDateField.name].unshift('notnull') : qpCount[entity.endingDateField.name] = 'notnull';
          _.isArray(qpCount[entity.startingDateField.name]) ? qpCount[entity.startingDateField.name].unshift('notnull') : qpCount[entity.startingDateField.name] = 'notnull';
        } else if (mode === 'timeline') {
          qpCount["any"] = [];
          if (entity.startingDateField) qpCount["any"].push(entity.startingDateField.name)
          if (entity.endingDateField) qpCount["any"].push(entity.endingDateField.name)
        }
        try {
          delete qpCount['page'];
          delete qpCount['page_size'];
        } catch (err) {
        }
        return super.retrieveObjects(qpCount, propagations).pipe(map((records$: any) => records$.length));
      })
    );
  }

  public retrieveUnscheduledCount(params: object, propagations: string[] = []): any {
    return super.fieldToService('entity').retrieveFieldManyMetadata(params['entity']).pipe(
      mergeMap((entity: any) => {
        const mode = getMode(params['mode']);
        if (mode !== 'calendar' && mode !== 'timeline'){
          return of(0);
        }
        const qpUnscheduledCount = _.cloneDeep(params);
        const endingDateField = entity.endingDateField;
        const startingDateField = entity.startingDateField
        if (mode === 'calendar') {
          qpUnscheduledCount['or'] = [JSON.stringify({ [startingDateField.name]: null, [endingDateField.name]: null })];
        } else {
          qpUnscheduledCount[startingDateField.name] = null;
          qpUnscheduledCount[endingDateField.name] = null;
        }
        delete qpUnscheduledCount['page'];
        delete qpUnscheduledCount['page_size'];
        return super.retrieveObjects(qpUnscheduledCount, propagations).pipe(map((records$: any) => records$.length));
      })
    );
  }

  public retrieveDashboardRecords(params: object = {}) {
    params['extension'] = 'reporting';
    const url = this.envService.apiEndpoint + this.key + '/?';
    return this.http.get(url, {params});
  }

  public retrieveCardsRecords(params: object, propagations: string[] = [], page_size: number, page: number, activeFields: Function): any {
    params['page'] = page;
    params['page_size'] = page_size;
    return this.retrieveRecordsPropagated(params, propagations, activeFields);
  }

  public retrieveTimelineRecords(params: object, propagations: string[] = [], page_size: number, page: number, activeFields: Function): any {
    return super.fieldToService('entity').retrieveFieldManyMetadata(params['entity']).pipe(mergeMap((entity: any) => {
      if (!entity.startingDateField && !entity.endingDateField) return of();
      params['page'] = page;
      params['page_size'] = page_size;
      params['any'] = []
      if (entity.startingDateField) params["any"].push(entity.startingDateField.name)
      if (entity.endingDateField) params["any"].push(entity.endingDateField.name)
      return this.retrieveRecordsPropagated(params, propagations, activeFields);
    }));
  }

  public retrieveCalenderRecords(params: object, propagations: string[] = [], calendarStart: Date, calendarEnd: Date, activeFields?: Function): any {
    return super.fieldToService('entity').retrieveListingMetadata(params['entity']).pipe(mergeMap((entity: any) => {
      if (!entity.startingDateField || !entity.endingDateField || !calendarStart || !calendarEnd) return of([]);
      _.isArray(params[entity.endingDateField.name]) ? params[entity.endingDateField.name].unshift('notnull') : params[entity.endingDateField.name] = 'notnull';
      _.isArray(params[entity.startingDateField.name]) ? params[entity.startingDateField.name].unshift('notnull') : params[entity.startingDateField.name] = 'notnull';
      return this.retrieveRecordsPropagated(params, propagations, activeFields);
    }));
  }

  public retrieveRecords(params: object, options:any, propagations: string[] = []) {
    let pageSize = 17;
    return super.fieldToService('entity').retrieveDetailMetadata(params['entity'], []).pipe(mergeMap((entity: any) => {
      const mode = getMode(params['mode']);
      switch (mode) {
        case 'cards':
          pageSize = (options.qp_page || 1) * computePageSize(entity.cols, entity.height + 8);
          return this.retrieveCardsRecords(params, propagations, pageSize, options.page, (r) => {
            return !params['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded || hasGroupby(entity, f)).map(f => f.id).join(',');
          });
        case 'grid':
          pageSize = (options.qp_page || 1) * computePageSize(entity.cols, 80);
          return this.retrieveCardsRecords(params, propagations, pageSize, options.page, (r) => {
            return !params['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isActiveTable).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isActiveTable || hasGroupby(entity, f)).map(f => f.id).join(',');
          });
        case 'timeline':
          pageSize = (options.qp_page || 1) * computePageSize(12, 75);
          return this.retrieveTimelineRecords(params, propagations, pageSize, options.page, (r) => {
            return !params['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded || hasGroupby(entity, f)).map(f => f.id).join(',');
          });
        case 'calendar':
          return this.retrieveCalenderRecords(params, propagations, options.calendarStart, options.calendarEnd, () => {
            return entity.ownerField + ',';
          });
        case 'dashboard':
          return this.retrieveDashboardRecords(params);
        default:
          return of();
      }
    }));
  }

  public retrieveUnscheduledRecords(qp: object, entity: any, page: number) {
    const endingDateField = entity.endingDateField;
    const startingDateField = entity.startingDateField;
    if (qp['mode'] === 'calendar') {
      qp['or'] = [JSON.stringify({ [startingDateField.name]: null, [endingDateField.name]: null })];
    } else {
      if (startingDateField) qp[startingDateField.name] = null;
      if (endingDateField) qp[endingDateField.name] = null;
    }
    qp['entity'] = entity.id;
    const computedPageSize = computePageSize(12, 45);
    qp['page_size'] = computedPageSize;
    qp['page'] = page;
    return this.retrieveObjects(qp)
      .pipe(mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$).pipe(mergeMap((records: any) => {
        let records$ = records.map(r => {
          let fieldIds = entity.ownerField + ',';
          return this.retrieveObject(r.id, ['value.' + fieldIds, 'tags']);
        });
        return forkJoin(records$);
      })) : of([]))));

  }
  private retrieveRecordsPropagated(params: object, propagations: string[] = [], activeFields?: Function): any {
    return super.retrieveObjects(params, propagations).pipe(
      mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$ as Array<any>).pipe(mergeMap((records: any) => {
        const records$ = records.map(record => {
          let fieldIds = activeFields(record);
          return this.retrieveObject(record.id, ['value.' + fieldIds, 'tags'].concat(propagations));
        });
        return forkJoin(records$ as Array<any>);
      })) : of([]))));
  }
}

