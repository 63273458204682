import { Injectable,Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DatalakeService  extends BaseService{

  key = 'datalake';
  constructor(public injector: Injector) {
    super(injector);
   }
}
