
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
@Injectable()
export class I18NInterceptor implements HttpInterceptor {

  constructor(private translocoLocaleService: TranslocoLocaleService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({headers: request.headers.set('Accept-Language', this.translocoLocaleService.getLocale())});
    return next.handle(request);
  }
}
