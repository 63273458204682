import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import  {translate ,TranslocoService} from '@jsverse/transloco'


@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  @Input() text
  constructor(private translocoService: TranslocoService) { }

  ngOnInit(): void {
    if (!this.text){
      this.translocoService.selectTranslate('Chargement des données').subscribe(value=>this.text=value + ' ...')
    }
  }

}
