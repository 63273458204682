import {Injectable, NgZone} from '@angular/core';
import {Injector} from '@angular/core';
import {Store} from '@ngxs/store';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import OpenReplay from "@openreplay/tracker";
import * as _ from 'lodash';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import * as AppActions from "../state/app.actions";
import {LocalService} from "./local.service";
import {UserService} from "./user.service";
import {Actions} from '@ngxs/store';
import {environment} from "../../environments/environment";
import {EnvService} from "./env.service";
import {from} from "rxjs";
import {AppInjector} from "../app.module";

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  previousUrl: string = null;
  currentUrl: string = null;

  public openReplayTracker;

  constructor(public injector: Injector,
              private gtmService: GoogleTagManagerService,
              private store: Store,
              private router: Router,
              private actions$: Actions,
              private route: ActivatedRoute,
              private zone: NgZone,
              private localService: LocalService,
              private envService: EnvService
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
  }

  initGA(user) {
    const gtmTag = {
      userId: user.id + '-' + user.fullName,
      event: 'authentication'
    };
    this.pushTag(gtmTag);
  }

  trackEvents(event, auto) {
    function gtmPushDecorator($event, tracker, callback) {
      if (callback) {
        $event.eventCallback = callback;
      }
      tracker($event);
    }
    const category = this.router.url.split('/')[1].split('?')[0];
    const gtmTag = {
      event: 'ko.DynamicEvent',
      eventCategory: category.charAt(0).toUpperCase() + category.slice(1),
      eventAction: undefined,
      eventLabel: undefined,
      eventValue: undefined,
      eventCallback: undefined
    };
    if (!auto) {
      gtmTag.eventAction = event.action;
      gtmTag.eventLabel = event.label;
    } else {
      const recordId = event.action.recordId;
      if (event.action instanceof AppActions.LoginPage.Login) {
        gtmTag.eventAction = 'Log In';
        gtmTag.eventLabel = 'User [' + this.localService.getUser() + '], ' +
            'Space [' + this.localService.getSpace() + ']';
      } else if (event.action instanceof AppActions.Header.Logout) {
        gtmTag.eventAction = 'Log Out';
      } else if (event.action instanceof AppActions.Sider.SwitchSpace) { // TODO
        gtmTag.eventAction = 'Switch Space';
        gtmTag.eventLabel = 'Space [' + event.action.spaceId + ']';
      } else if (event.action instanceof AppActions.Header.UpdateUserPicture) {
        gtmTag.eventAction = 'Update Profile Picture';
        gtmTag.eventLabel = 'User [' + this.localService.getUser() + ']';
      } else if (event.action instanceof AppActions.RecordPage.Init) {
        if (this.previousUrl == null || this.previousUrl?.indexOf('/record/' + event.action.recordId) === -1) {
          gtmTag.eventAction = 'Open Record' + (this.previousUrl?.includes('mode=timeline') ? ' - Timeline' : '') + (this.previousUrl?.includes('mode=calendar') ? ' - Calendar' : '');
          if (event.action.recordId) {
            gtmTag.eventLabel = 'Record [' + event.action.recordId + ']';
          }
        }
      } else if (event.action instanceof AppActions.RecordPage.CreateRecord) {
        gtmTag.eventAction = 'Create Child Record';
        if (event.action.childRecord) {
          gtmTag.eventLabel = 'Child record [' + event.action.mainFieldValue + ']';
        }
      } else if (event.action instanceof AppActions.RecordPage.AddRecord) {
        gtmTag.eventAction = 'Assign Child Record';
        if (event.action.childRecord) {
          gtmTag.eventLabel = 'Child record [' + event.action.childRecord.id + ']';
        }
      } else if (event.action instanceof AppActions.RecordPage.CreateAttachment) {
        gtmTag.eventAction = 'Add Attachment';
        gtmTag.eventLabel = 'Record [' + recordId + ']';
      } else if (event.action instanceof AppActions.RecordPage.DestroyAttachment) {
        gtmTag.eventAction = 'Delete Attachment';
        gtmTag.eventLabel = 'Record [' + recordId + ']';
      } else if (event.action instanceof AppActions.RecordPage.CreateCheckpointAttachment) {
        gtmTag.eventAction = 'Add Checkpoint Attachment';
        gtmTag.eventLabel = 'Record [' + recordId + '], ' +
            'Checkpoint [' + event.action.checkpointId + ']';
      } else if (event.action instanceof AppActions.RecordPage.DestroyCheckpointAttachment) {
        gtmTag.eventAction = 'Destroy Checkpoint Attachment';
        gtmTag.eventLabel = 'Record [' + recordId + '], ' +
            'Checkpoint [' + event.action.checkpointId + ']';
      } else if (event.action instanceof AppActions.RecordPage.CreateComment) {
        gtmTag.eventAction = 'Add Comment';
        gtmTag.eventLabel = 'Record [' + recordId + ']';
      } else if (event.action instanceof AppActions.RecordPage.UpdateComment) {
        gtmTag.eventAction = 'Edit Comment';
        gtmTag.eventLabel = 'Record [' + recordId + '], ' +
            'Comment [' + event.action.commentId + ']';
      } else if (event.action instanceof AppActions.RecordPage.DestroyComment) {
        gtmTag.eventAction = 'Destroy Comment';
        gtmTag.eventLabel = 'Record [' + recordId + '], ' +
            'Comment [' + event.action.commentId + ']';
      } else if (event.action instanceof AppActions.RecordPage.CreateFixingRecord) {
        gtmTag.eventAction = 'Create Fixing Record';
        gtmTag.eventLabel = 'Record [' + recordId + '], ' +
            'Checkpoint [' + event.action.checkpointId + '] ' +
            'Fixing Entity [' + event.action.entityId + ']';
      } else if (event.action instanceof AppActions.RecordPage.DestroyRecord) {
        gtmTag.eventAction = 'Destroy Record';
        gtmTag.eventLabel = 'Record [' + event.action.recordId + ']';
      } else if (event.action instanceof AppActions.RecordPage.ExecuteAction) {
        gtmTag.eventAction = 'Execute Action';
        let recordId = this.currentUrl.split('/')
        if (event.action.inp) {
          gtmTag.eventLabel = 'Record [' + recordId[recordId.length-1] + '], ' +
              'Action [' + event.action.actionId.id + ']';
        }
      } else if (event.action instanceof AppActions.RecordPage.ToggleRecordTag) {
        gtmTag.eventAction = 'Update Tag';
        gtmTag.eventLabel = 'Tag [' + event.action.tag.id + ']';
        // Todo: find a way to distinguish between assigning tag and removing tag
      } else if (event.action instanceof AppActions.RecordPage.UpdateChecklistRecord) {
        gtmTag.eventAction = 'Select Checklist';
        gtmTag.eventLabel = 'Checklist [' + event.action.checklistRecordId + ']';
      } else if (event.action instanceof AppActions.RecordPage.CreateChecklistObject) {
        gtmTag.eventAction = 'Create Checkpoint/Checkpointset';
        gtmTag.eventLabel = 'Record [' + recordId + ']';
      } else if (event.action instanceof AppActions.RecordPage.UpdateChecklistObject) {
        gtmTag.eventAction = 'Update Checkpoint/Checkpointset';
        gtmTag.eventLabel = event.action.type === 'checkpoints' ? 'Checkpoint [' : 'Checkpointset [' + event.action.object.id + ']';
      } else if (event.action instanceof AppActions.RecordPage.UpdateCheckpointComment) {
        gtmTag.eventAction = 'Edit Checkpoint Comment';
        gtmTag.eventLabel = 'Record [' + recordId + '],' +
            'Checkpoint [' + event.action.checkpointId + ']';
      } else if (event.action instanceof AppActions.RecordPage.UpdateCheckpointState) {
        const state = event.action.state;
        gtmTag.eventLabel = 'Record [' + recordId + '],' +
            'Checkpoint [' + event.action.checkpointId + ']';
        if (state === 'Accepted') {
          gtmTag.eventAction = 'Accept Checkpoint';
        } else if (state === 'Rejected') {
          gtmTag.eventAction = 'Reject Checkpoint';
        } else if (state === 'Fixed') {
          gtmTag.eventAction = 'Fix Checkpoint';
        } else if (state === 'PartiallyConform') {
          gtmTag.eventAction = 'Mark Checkpoint as Partially Conform';
        }
      } else if (event.action instanceof AppActions.RecordPage.UpdateField) {
        const field = event.action.field;
        let fieldValue = event.action.fieldValue;
        if (field) {
          if (field.type === 'Image' && fieldValue) {
            fieldValue = 'image-data';
          }
          gtmTag.eventAction = 'Edit Record';
          gtmTag.eventLabel = 'Field [' + event.action.fieldId + '], ' +
              'Value [' + fieldValue + ']';

        }
      } else if (event.action instanceof AppActions.RecordsPage.CreateRecord) {
        gtmTag.eventAction = 'Create Record';
        if (event.action.mainFieldValue) {
          gtmTag.eventLabel = 'Entity [' + this.router.url.split('/')[2] + '], ' + event.action.mainFieldValue;
        }
      } else if (event.action instanceof AppActions.RecordsPage.CreateView) {
        gtmTag.eventAction = 'Create View';
        if (event.action.view) {
          gtmTag.eventLabel = 'View [' + event.action.view.title + ']';
        }
      } else if (event.action instanceof AppActions.RecordsPage.DestroyView) {
        gtmTag.eventAction = 'Destroy View';
        if (event.action.viewId) {
          gtmTag.eventLabel = 'View [' + event.action.viewId + ']';
        }
      } else if (event.action instanceof AppActions.RecordsPage.RetrieveRecords) {
        if (event.action.page > 1) {
          gtmTag.eventAction = 'Request Next Page';
          gtmTag.eventLabel = 'Page [' + event.action.page + ']';
        }
      } else if (event.action instanceof AppActions.RecordsPage.SelectView) {
        gtmTag.eventAction = 'Select View';
        gtmTag.eventLabel = 'View [' + event.action.view.id + ']';
      } else if (event.action instanceof AppActions.RecordsPage.UpdateRecordFields ||
          event.action instanceof AppActions.HomePage.UpdateRecordFields ||
          event.action instanceof AppActions.RecordPage.UpdateRecordFields) {
        // const fields = Object.keys(event.action.fieldValueMapping as Object);
        // if (fields.length){
        //   if (moment(event.action.record.value[fields[0]]).toISOString() !== moment(event.action.fieldValueMapping[fields[0]]).toISOString() &&
        //     moment(event.action.record.value[fields[1]]).toISOString() !== moment(event.action.fieldValueMapping[fields[1]]).toISOString()) {
        //     gtmTag.eventAction = 'Move Record';
        //   } else {
        //     gtmTag.eventAction = 'Update Record Range';
        //   }
        //   gtmTag.eventLabel = 'Record [' + event.action.record.id + ']';
        // }
      } else if (event.action instanceof AppActions.HomePage.RetrieveRecords) {
        if (event.action.page > 1) {
          gtmTag.eventAction = 'Request Next Page - Home';
          gtmTag.eventLabel = 'Page [' + event.action.page + '], ' +
              'View [' + event.action.currentView.id + ']';
        }
      } else if (event.action instanceof AppActions.AdministrationPage.CreateObject) {
        const obj = event.action.object;
        if (obj) {
          gtmTag.eventLabel = 'Entity [' + obj.entity + ']';
          if (obj.email) {
            gtmTag.eventAction = 'Create User';
            gtmTag.eventLabel += ', Space [' + this.localService.getSpace() + ']';
          } else {
            gtmTag.eventAction = 'Create Tag';
          }
        }
      } else if (event.action instanceof AppActions.AdministrationPage.UpdateObject) {
        const obj = event.action.object;
        if (obj) {
          gtmTag.eventLabel = 'Entity [' + obj.entity + ']';
          if (obj.email) {
            gtmTag.eventAction = 'Edit User';
            gtmTag.eventLabel += ', Space [' + this.localService.getSpace() + '], ' +
                'User [' + obj.id + ']';
          } else {
            gtmTag.eventAction = 'Edit Tag';
            gtmTag.eventLabel += ', Tag [' + obj.id + ']';
          }
        }
      } else if (event.action instanceof AppActions.AdministrationPage.DestroyObject) {
        const obj = event.action.object;
        if (obj) {
          if (obj.email) {
            gtmTag.eventAction = 'Destroy User';
            gtmTag.eventLabel = 'User [' + obj.id + ']';
          } else {
            gtmTag.eventAction = 'Destroy Tag';
            gtmTag.eventLabel = 'Tag [' + obj.id + ']';
          }
        }
      } else if (event.action instanceof AppActions.AdministrationPage.RetrieveObjects) {
        if (event.action.page > 1) {
          const model = event.action.model;
          gtmTag.eventLabel = 'Page [' + event.action.page + ']';
          if (model === 'user') {
            gtmTag.eventAction = 'Request Next User Page';
          } else if (model === 'tag') {
            gtmTag.eventAction = 'Request Next Tag Page';
          }
        }
      } else if (event.action instanceof AppActions.ResetPage.Reset) {
        gtmTag.eventAction = 'Send Reset';
        gtmTag.eventLabel = 'Reset [' + event.action.email + ']';
      } else if (event.action instanceof AppActions.SendResetPage.SendReset) {
        gtmTag.eventAction = 'Reset Password';
        gtmTag.eventLabel = 'Reset password [' + event.action.email + ']';
      } else if (event.action instanceof AppActions.NotificationsPage.RetrieveNotifications) {
        if (event.action.page > 1) {
          gtmTag.eventAction = 'Request Next Notification Page';
          gtmTag.eventLabel = 'Page [' + event.action.page + ']';
        }
      } else if (event.action instanceof AppActions.NotificationsPage.UpdateIsSeen) {
        gtmTag.eventAction = 'Mark as ' + (event.action.isSeen ? 'seen' : 'unseen');
        gtmTag.eventLabel = 'Notification [' + event.action.id + ']';
      } else if (event.action instanceof AppActions.PreferencesPage.PostPreference) {
        gtmTag.eventAction = 'Post Preference';
        gtmTag.eventLabel = 'User [' + this.localService.getUser() + '], ' +
            'Preference [' + event.action.id + '], ' +
            'Channel [' + event.action.channel + ']';
      } else if (event.action instanceof AppActions.RecordsPage.UpdateView) {
        gtmTag.eventAction = 'Rename view';
        gtmTag.eventLabel = 'View [' + event.action.viewId + ']';
      }
    }
    if (gtmTag.eventAction) {
      const successCallback = (verbose) => {
        if (verbose) {
          console.log('Tracking Successful (' +
            'Category: [' + gtmTag.eventCategory +
            '], Action: [' + gtmTag.eventAction +
            '], Label: [' + gtmTag.eventLabel + '])');
        }
      }
      gtmPushDecorator(gtmTag,
        (tag) => this.pushTag(gtmTag),
        () => successCallback(false));
    }
  }

  initTracking() {
    this.gtmService.addGtmToDom().catch((e) => {
    });
    const userService = AppInjector.get(UserService);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd),
      take(1)).subscribe((event: NavigationEnd) => {
      userService.connectedUser().subscribe((connectedUser) => {
        const gtmTag = {
          event: 'ko.InitPageView',
          pageValue: event.urlAfterRedirects
        };
        if (connectedUser) {
          if (event.urlAfterRedirects === '/login') {
            this.route.queryParams
              .subscribe(params => {
                gtmTag.pageValue = params.returnUrl ? params.returnUrl : '/home';
              });
          }
        } else {
          gtmTag.pageValue = '/login';
        }
        this.pushTag(gtmTag);
      });
    });
    this.actions$.pipe(filter(event=>event.status==='SUCCESSFUL')).subscribe((res) => {
      this.trackEvents(res, true);
    });
  }

  initOpenReply() {
    this.zone.runOutsideAngular(() => {
      this.openReplayTracker = new OpenReplay({
        projectKey: environment.OPEN_REPLY_PROJECT_ID,
        // __DISABLE_SECURE_MODE: true,
        onStart: ({sessionToken, sessionID}) => {
          const gtmTag = {
            event: 'ko.onStartOpenReplay',
            sessionToken: sessionToken,
            sessionID: sessionID,
          };
          this.pushTag(gtmTag);
        }
      });
    });
  };

  startOpenReplay(user) {
    if (this.openReplayTracker && !this.openReplayTracker?.isActive()) {
      this.zone.runOutsideAngular(() => {
        this.openReplayTracker.start({
            userID: user.email,
            forceNew: true,
            metadata: {
              'space': this.localService.getSpace(),
            }
          }
        ).catch((e) => {
        });
      });
    }
  }

  stopOpenReplay() {
    if (this.openReplayTracker){
      this.openReplayTracker.stop();
    }
  }

  initSentry(user) {
    const gtmTag = {
      event: 'ko.SentryScope',
      user: user,
      userApp: user.space
    };
    this.pushTag(gtmTag);
  }

  sendToSentry(error){
    const gtmTag = {
      event: 'ko.SentryCapture',
      error:error,
    };
    this.pushTag(gtmTag);
  }

  pushTag(gtmTag) {
    from(this.gtmService.pushTag(gtmTag)).subscribe({
      error: () => {
      }
    });
  }
}
