import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CheckpointService extends BaseService {

  key = 'checkpoints';
  constructor(public injector: Injector) { 
    super(injector);
  }
}
