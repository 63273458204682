import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HttpCancelService } from "../services/httpcancel.service";

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.method === "GET") {
      return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
    return next.handle(req);
  }
}