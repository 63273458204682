import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {

  @Input() notification;
  @Output() notificationClick = new EventEmitter()
  @Output() seenUnseenClick = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

}

