import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {translate} from "@jsverse/transloco"
import * as _ from "lodash";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnInit {
  @Input() form;
  @Input() initialValues = {};
  @Input() record = null;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formClose = new EventEmitter<any>();

  public focusedIndexTab: number = 0;

  @ViewChildren('formField') formFields: QueryList<ElementRef>;

  @HostListener('keyup.tab', ['$event'])
  onTabPress(evt){
    this.focusedIndexTab = (this.focusedIndexTab + 1) % this.formFields.toArray().length;
  }


  constructor(public toastr: ToastrService) { }

  ngOnInit(): void {
  }

  onFormModalClose() {
    this.formClose.emit();
  }

  onSubmit($event) {
    let formData = {};
    let formRawData = {};
    let error = false;
    this.formFields.forEach((item: any) => {
      if (item.formField.isMandatory && (item.value === '' || item.value === null || item.value?.length === 0)) {
        this.toastr.error(item.formField.title + ' : '+ translate('Ce champ est obligatoire'));
        error = true;
      }
      formData[item.formField.name] = item.value;
      formRawData[item.formField.name] = item.rawValue;
    });
    if (!error) {
      this.formSubmit.emit({data: JSON.stringify(formData), rawData: formRawData});
    }
  }

  formFieldTrackBy(index, field) {
    return field.id
  }

  hasInitialValues(){
    return _.keys(this.initialValues).length
  }
}
