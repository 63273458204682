import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit {

 @Input() icon;
 @Input() classes;
 isFontAwesome = false;

  constructor() { }

  ngOnInit(): void {
    this.displayIcon();
  }

  displayIcon(){
    if (this.icon !== null) {
      this.isFontAwesome = this.icon.includes('fa')
      if (!this.isFontAwesome) {
        this.classes = this.classes.replace(/fa-[a-z]*/g, '')
      }
    }
  }


}
