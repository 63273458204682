import { Directive, ElementRef, OnInit, Input} from '@angular/core';
import {Actions, Store} from "@ngxs/store";
import {WebSocketService} from 'src/app/services/websocket.service';
import {filter, takeUntil} from 'rxjs/operators';
import {LocalService} from 'src/app/services/local.service';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Directive({
  selector: '[rthighlight]'
})
export class RthighlightDirective implements OnInit {

  destroyed$: Subject<any> = new Subject();
  @Input() recordId;

  constructor(private el: ElementRef,
              public store: Store,
              private actions$: Actions,
              private webSocketService: WebSocketService,
              private localService: LocalService,
              private location: Location,
              private router: Router) { }

  ngOnInit() {
    this.webSocketService.messages.pipe(filter(data => (data !==  null && data.message.type === 'highlight')), takeUntil(this.destroyed$)).subscribe((data) => {
      const notification = data.message.data;
      if (notification.actor && notification.actor !== this.localService.getUser()){

        if (notification.record && notification.record === parseInt(this.recordId) && this.router.url === this.location.path()
          || notification.entity && notification.entity === this.store.snapshot().app.homePage?.entity?.id
          || notification.entity && notification.entity === this.store.snapshot().app.recordsPage?.entity?.id){

          const element = this.el.nativeElement.id.split('-')[0]
          const elementMapping = {
            'card': 'record',
            'field': 'field',
            'comment': 'snapshot',
            'file': 'snapshot'
          }
          const activityMapping = {
            'field': ['UpdateRecordFieldActivity', 'SetRecordFieldActivity', 'SetRecordUserActivity', 'UpdateRecordUserActivity', 'AddRecordUsersActivity', 'RemoveRecordUsersActivity','AddRecordMultiFieldActivity','RemoveRecordMultiFieldActivity'],
            'comment': ['CreateCommentActivity', 'UpdateCommentActivity'],
            'file': ['CreateAttachmentActivity']
          }

          let notifiedElementId = element === "field" 
                                ? [element, 'rthighlight',  this.recordId, notification[elementMapping[element]]].join('-')
                                : [element, 'rthighlight', notification[elementMapping[element]]].join('-');
           
          if (this.el.nativeElement.id == notifiedElementId) {
            if (element == 'card') {
              this.applyHighlights(notification, element);
            } else {
              if (activityMapping[element].includes(notification.subclassName) && this.recordId === notification.record) {
                this.applyHighlights(notification, element);
              }
            }
          }
        }
      }
    });
  }

  applyHighlights(notification, element, attempt=0) {
    const classList = ['ribbon', 'ribbon-top', 'shadow-lg', 'rounded']
    if (element == 'comment') {
      classList.push('px-4')
    } else if (element == 'card') {
      this.el.nativeElement.children[0].classList.add('rounded', 'border-dotted')
    }
    this.el.nativeElement.classList.add(...classList)

    const ribbon = document.createElement('div')
    ribbon.className = 'ribbon-label'
    ribbon.innerHTML = notification.fullName;
    ribbon.style.backgroundColor = notification.color
    this.el.nativeElement.prepend(ribbon)

    setTimeout(() => {this.removeHighlights(ribbon, classList, element)}, 3000)
  }

  removeHighlights(ribbon, classList, element) {
    ribbon.remove();
    this.el.nativeElement.classList.remove(...classList)
    if (element == 'card') {
      this.el.nativeElement.children[0].classList.remove('rounded', 'border-dotted')
    }
  }

}
