import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {ToastrService} from "ngx-toastr";
import {SendResetPage} from "../../state/app.actions";
import {Router} from "@angular/router";
import {translate} from '@jsverse/transloco';

@Component({
  selector: 'app-sendreset',
  templateUrl: './sendreset.component.html',
  styleUrls: ['./sendreset.component.scss']
})
export class SendresetComponent implements OnInit {

  constructor(private store: Store, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
  }

  sendReset(value: any) {
    this.store.dispatch(new SendResetPage.SendReset(value.email))
      .subscribe( () => {
        this.router.navigateByUrl('/login');
        this.toastr.success(translate('E-mail de réinitialisation envoyé'), translate('Envoyé'));
      }, () => {});
  }

}

