import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'splitStr'
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string, [separator]): any {
    return value.split(separator);
  }

}
