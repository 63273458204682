import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { AppState } from './../../state/app.state';
import { PreferencesPage } from './../../state/app.actions';
import { Store, Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { translate} from '@jsverse/transloco';
import {LocalService} from 'src/app/services/local.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreferencesComponent implements OnInit {
  @Select(AppState.preferencesPage) preferencesPage$;
  @Select(AppState.sider) sider$;
  public title
  channelsIcons = [
    {
      channel: 'Nowhere',
      icons: ['fa-ban'],
      title: translate('Ignorer')
    },
    {
      channel: 'Internal',
      icons: ['fa-bell'],
      title: translate('Centre de notifications uniquement')
    },
    {
      channel: 'Everywhere',
      icons: ['fa-bell', 'fa-envelope'],
      title: translate('Centre de notifications + E-mail')
    }
  ];

  constructor(private store: Store, public toastr: ToastrService, public localService: LocalService) { }

  ngOnInit() {
    this.store.dispatch(new PreferencesPage.Init()).subscribe();
  }

  onPreferenceSubmit(preference, channel) {
    if (preference.channel !== channel) {
      this.store.dispatch(new PreferencesPage.PostPreference(
        { "user": preference.user, "subclassName": preference.subclassName, "entity": preference.entity, "field": preference.field, "channel": channel }
      )).subscribe(res => this.toastr.success(translate('Enregistré')));
    }
  }

  onSwitchLanguageClick(lang) {
    if (this.store.snapshot().app?.preferencesPage?.userLanguage != lang) {
        this.store.dispatch(new PreferencesPage.UpdateUserLanguage(lang)).subscribe(res => this.toastr.success(translate('Enregistré')))
    }
  }

  getLanguageName(lang: string) {
    switch(lang) {
      case 'fr':
        return 'Français (France)';
      case 'en':
        return 'English (US)';
      case 'cn':
        return '中文 (中国)';
      default:
        return lang;
    }
}

}
