import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionComponent implements OnInit {

  @Input() record;
  @Input() entity;
  @Input() action;
  @Input() executingActionId;
  @Output() actionExecute = new EventEmitter<any>();

  confirmationModal;
  intermediateModal;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  openIntermediate(intermediateRef) {
    this.intermediateModal = this.modalService.open(intermediateRef, {size: 'lg', backdrop: 'static'});
  }

  openConfirmation(confirmationRef) {
    this.confirmationModal = this.modalService.open(confirmationRef);
  }

  isSpinnerActive() {
    return this.action.isPrimary && !this.action.intermediate && this.executingActionId === this.action.id;
  }

  onActionClick($event, confirmationRef, intermediateRef) {
    if (this.executingActionId === this.action.id){
      return;
    }
    if (this.action.confirmMessage) {
      this.openConfirmation(confirmationRef);
    } else {
      if (this.action.intermediateForm) {
        this.openIntermediate(intermediateRef);
      } else {
        this.actionExecute.emit({
          'inp': {},
          'action': this.action,
          'confirmationModal': this.confirmationModal,
          'intermediateModal': this.intermediateModal
        });
      }
    }
  }

  onConfirmationSubmit(intermediateRef) {
    if (this.action.intermediateForm) {
      this.openIntermediate(intermediateRef);
    } else {
      this.actionExecute.emit({
        'inp': {},
        'action': this.action,
        'confirmationModal': this.confirmationModal,
        'intermediateModal': this.intermediateModal
      });
    }
  }

  onConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onIntermediateSubmit(evt: object) {
    this.actionExecute.emit({
      'inp': evt,
      'action': this.action,
      'confirmationModal': this.confirmationModal,
      'intermediateModal': this.intermediateModal
    });
  }

  onIntermediateClose() {
    this.intermediateModal.dismiss();
  }
}

