import {Component, OnInit, Inject, ApplicationRef, HostListener} from '@angular/core';
import {GtmService} from "./services/gtm.service";
import {EnvService} from "./services/env.service";
import {LocalService} from "./services/local.service";
import {Subject, interval, concat, firstValueFrom} from "rxjs";
import {filter, takeUntil, first, mergeMap} from "rxjs/operators";
import {WebSocketService} from "./services/websocket.service";
import {ToastrService} from "ngx-toastr";
import {Router, NavigationEnd, NavigationStart} from "@angular/router";
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { translate,TranslocoService} from '@jsverse/transloco';
import { setBrowserTabTitle } from "./app.utils";
import {Select, Store} from '@ngxs/store';
import {DOCUMENT} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {AppState} from "./state/app.state";
import {ModalProxyService} from "./services/modal-proxy.service";
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

declare var window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'front';
  window = window;

  private destroyed$: Subject<any> = new Subject();
  private routeScrollPositions: { [url: string]: number } = {};
  private isPopState = false;
  private deferredRestore = false;

  @Select(AppState.recordsPage) recordsPage$;

  @HostListener('window:resize')
  public onResize() {
    this.localService.setIsMobile();
  }

  constructor(public router: Router,
              private gtmService: GtmService,
              private titleService: Title,
              private envService: EnvService,
              private modalProxyService: ModalProxyService,
              private localService: LocalService,
              private toastr: ToastrService,
              private webSocketService: WebSocketService,
              private translocoService: TranslocoService,
              private store: Store,
              private httpClient: HttpClient,
              private swUpdate: SwUpdate,
              private appRef: ApplicationRef,
              @Inject(DOCUMENT) private document: Document,
              modalConfig: NgbModalConfig) {
                modalConfig.animation = false;
  }

  ngOnInit() {
    const env = this.envService.env;
    this.localService.setEnv(env);
    this.localService.setIsMobile();
    this.translocoService.load(this.translocoService.getActiveLang()).subscribe()
    this.translocoService.events$.pipe(filter(event=>event.type==='translationLoadSuccess')).subscribe(()=>{
      moment.locale(this.translocoService.getActiveLang());
      document.documentElement.lang = this.translocoService.getActiveLang();
    })
    this.checkUpdatePWA().then(()=>{});
    switch (env) {
      case 'prod':
      case 'on-premise':
        this.titleService.setTitle('Kostango');
        break;
      default:
        this.titleService.setTitle('🟢 ' + env.toUpperCase());
    }

    if (env === 'prod')
    {
      try {
        this.gtmService.initTracking();
        this.gtmService.initOpenReply();
      } catch (exception) {
      }
    }

    if (env === 'prod' || env == 'staging' || env == 'sim' || env == 'ops' || env == 'test' ) {
      this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDBc1jDG7AgNImk_PdRjhbh3AyoNM2x90o', 'callback').subscribe({ error: () => { } })
    }

    try {
      this.webSocketService.connectUserWS();
    } catch (exception) {
    }
    this.handleAsyncAction();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const urlTree = this.router.parseUrl(this.router.url);
      const primary = urlTree.root.children['primary'];
      const auxiliary = urlTree.root.children['modal'];
      const page = auxiliary ? auxiliary.segments[1].path : primary.segments[0].path;
      setBrowserTabTitle(page);
    });

    this.store.select(state => state.app.sider?.user?.space?.ssoGateway?.favicon).subscribe(favicon => {
      if(favicon){
        this.document.getElementById('appFavicon').setAttribute('href', favicon);
        this.document.getElementById('appFavicon').removeAttribute('sizes')
        this.document.getElementById("appFavicon2").remove();
        this.document.getElementById("appFavicon3").remove()

      }
      else if (!this.document.getElementById("appFavicon2")) {
        const appFavicon2 = '<link rel="apple-touch-icon" id="appFavicon2" sizes="180x180" href="assets/img/apple-touch-icon.png">'
        const appFavicon3 = '<link rel="icon" type="image/png" id="appFavicon3"  sizes="16x16" href="assets/img/favicon-16x16.png">'
        this.document.getElementById('appFavicon').setAttribute('href', 'assets/img/favicon-32x32.png');
        this.document.getElementById('appFavicon').setAttribute('sizes', '32x32');
        this.document.getElementById('appFavicon').insertAdjacentHTML("afterend", appFavicon2 )
        this.document.getElementById('appFavicon').insertAdjacentHTML("afterend", appFavicon3 )
      }
    })
    this.localService.addPopStateListener();
    this.addScrollListeners();
  }

  ngAfterContentChecked() {
    if(this.deferredRestore && document.body.scrollHeight >= this.routeScrollPositions[this.router.url]) {
      this.restoreScroll(this.router.url)
      this.deferredRestore = false;
    }
  }

  private saveScroll(url) {
    this.routeScrollPositions[url] = window.scrollY;
  }

  private restoreScroll(url) {
    setTimeout(() => {
      this.window.scrollTo({left: 0, top: this.routeScrollPositions[url], behavior: 'instant'})
      if(window.scrollY != this.routeScrollPositions[url]) {
        this.deferredRestore = true;
      }
    })
  }

  private addScrollListeners() {

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)
    ).subscribe((event) => {
      if(event instanceof NavigationStart) {
        this.isPopState = event.navigationTrigger == "popstate"
        if(event.navigationTrigger != "popstate") {
          this.saveScroll(this.router.url);
        }
      }
      if(event instanceof NavigationEnd) {
        if(this.isPopState) {
          this.deferredRestore = true
        }
        this.isPopState = false;
      }
    });
  }


  private handleAsyncAction() {
    this.webSocketService.messages.pipe(filter(data => (data !==  null && data.message.type === 'action')),
      takeUntil(this.destroyed$)).subscribe((data) => {
      if (data.message.user !== this.localService.getUser()) {
        return;
      }
      if (data.message.response.error) {
        this.toastr.error(data.message.response.error);
        return;
      }
      const commandResult = data.message.response.commandResult;
      if (commandResult && commandResult.message) {
        this.toastr.success(commandResult.message);
      } else {
        this.toastr.success(translate('Opération effectuée'));
      }
      if (commandResult && commandResult.file) {
        const htmlAnchorElement = document.createElement('a');
        htmlAnchorElement.href = 'data:application/octet-stream;base64,' + commandResult.file;
        htmlAnchorElement.download = commandResult.filename ? commandResult.filename : 'file';
        htmlAnchorElement.click();
        htmlAnchorElement.remove();
      }
      if (commandResult && commandResult.url) {
        this.router.navigate([commandResult.url]);
      }
    });
  }

  public onRouteActivate(component: any) {
    this.modalProxyService.setParentModal(component);
  }

  async checkUpdatePWA() {
    const appIsStable$ = this.appRef.isStable.pipe(
      first(isStable => isStable === true)
    );
    const fiveMinutesInterval$ = interval(300000); // 5 minutes
    const everyFiveMinutesAppIsStable$ = concat(appIsStable$, fiveMinutesInterval$);
    everyFiveMinutesAppIsStable$.pipe(
      filter(() => this.swUpdate.isEnabled),
      mergeMap(async () => {
        await this.swUpdate.checkForUpdate();
        const updateAvailable = await firstValueFrom(
          this.swUpdate.versionUpdates.pipe(
              filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          )
        );
        if (updateAvailable) {
          console.log('New update available: ', updateAvailable);
          await this.swUpdate.activateUpdate();
          document.location.reload();
          try {
            const registration = await navigator.serviceWorker.register('../delete-cache-sw.js');
            console.log('Service Worker registered with scope:', registration.scope);
          } catch (error) {
            console.error('Service Worker registration failed:', error);
          }
        }
      })
    ).subscribe();
  }
}
