import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  AfterViewInit,
  OnDestroy, 
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import {
  isSameDay,
  isSameMonth,
} from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Level} from '../../services/user.service';
import {Actions, ofActionSuccessful, Store} from "@ngxs/store";
import {HomePage, RecordsPage} from "src/app/state/app.actions";
import { translate, TranslocoService } from "@jsverse/transloco";
import Calendar from '@event-calendar/core';
import Event from '@event-calendar/core';
import TimeGrid from '@event-calendar/time-grid';
import DayGrid from '@event-calendar/day-grid';
import Interaction from '@event-calendar/interaction';
import ResourceTimeline from '@event-calendar/resource-timeline';
import List from '@event-calendar/list';
import { CreateComponent } from '../create/create.component';
import { dateFormat, getGroupByField, getGroupByValue, getRecordsGroups } from 'src/app/app.utils';
import { LocalService } from 'src/app/services/local.service';

registerLocaleData(localeFr);

@Component({
  selector: 'calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() records;
  @Input() unscheduledRecords;
  _unscheduledCount;
  @Input('unscheduledCount')
  set in(count) {
    if (count !== null) this._unscheduledCount = count;
  }
  @Input() isUnscheduledReady;
  @Input() isCreatingRecord;
  @Input() entity;
  @Input() scope;
  @Input() showUnscheduled;
  @Input() isListReady;
  @Input() groupBy = false;
  _listMode;
  @Input()
  set listMode(_listMode) {
    this._listMode = _listMode?.toLowerCase() === 'true';
  }

  get listMode() {
    return this._listMode;
  }

  @Output() calendarScopeDateChange = new EventEmitter();
  @Output() calendarScopeChange = new EventEmitter();
  @Output() calendarUnscheduledChange = new EventEmitter();
  @Output() calendarRecordClick = new EventEmitter();
  @Output() calendarRecordDrag = new EventEmitter();
  @Output() calendarUnscheduledNext = new EventEmitter();
  @Output() createEvent = new EventEmitter();
  @Output() resizeEvent = new EventEmitter();

  @ViewChild('unscheduledContainer') unscheduledContainer: ElementRef;
  @ViewChild('calendar') calendarRef: ElementRef;
  @ViewChild('create', {static: false}) create: CreateComponent;
  @ViewChild('createContainer') createContainerRef: ElementRef;
  @ViewChild('changeScopeDropdown') changeScopeDropdownRef: ElementRef;


  calendar: Calendar;
  events: Event[] = [];
  viewTypes = {
    month: 'dayGridMonth',
    week: 'timeGridWeek',
    day: 'timeGridDay',
    monthGroupBy : 'resourceTimelineMonth',
    weekGroupBy: 'resourceTimelineWeek',
    dayGroupBy: 'resourceTimelineDay',
    monthList: 'listMonth',
    weekList: 'listWeek',
    dayList: 'listDay'
  };
  scopes = {
    'day': translate("Jour"),
    'week': translate('Semaine'),
    'month': translate('Mois')
  }
  hideWeekends = false;
  isSelecting = {
    state: false,
    selectedRange: null
  };


  scopeDate: Date = new Date();

  refresh: Subject<any> = new Subject();
  recordsSub: Subscription;
  homeSub: Subscription;
  switchMode: Subscription;
  unscheduled = false;
  unscheduledLoaded = false;
  unscheduledEvents: any[] = [];

  activeDayIsOpen: boolean = false;

  // exclude weekends
  page = 1;
  firstLoad = false;
  isLoading = false;
  canScroll = true;
  unscheduledEventBound = false
  locale=this.translocoService.getActiveLang()
  computedStyle = getComputedStyle(document.body);
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private actions$: Actions,
    private translocoService :TranslocoService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private localService: LocalService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.unscheduled = this.showUnscheduled === 'true' ? true : this.unscheduled;
    if (!this.scope) this.scope = 'month';
    if (this.route.snapshot.queryParams["timeframe"]){
      this.scopeDate = new Date(this.route.snapshot.queryParams["timeframe"])
      //this.activeDayIsOpen = true
    }
    if (this.unscheduled) this.calendarUnscheduledNext.emit();
    this.initFirstLoad();
    this.initEvents();
  }

  ngAfterViewInit(): void {
    this.initCalendar();
    this.onActiveDateChanged();
  }

  ngOnChanges(changes): void { 
    if (changes.isListReady?.currentValue || this.isListReady) {
      this.isLoading = false;
    }
    this.unscheduledRecords = this.unscheduledRecords || [];
    if (changes.scope && this.scope) {
      this.changeScope(this.scope);
    }
    if (changes.records) {
      this.initEvents();
      this.calendar?.unselect();
      if (this.groupBy) this.setupEventsGrouping();
      this.calendar?.refetchEvents();
      this.refreshCalendarButtons();
    }
    if (changes.groupBy) {
      this.groupBy ? this.switchToGroupByView() : this.clearGroupBy();
    }
    if (changes.showUnscheduled) {
      this.unscheduled = this.showUnscheduled === 'true';
    }
    if (changes.unscheduledRecords) {
      this.initUnscheduledEvents();
      this.refreshCalendarButtons();
    }
  }

  ngOnDestroy(): void {
    this.homeSub.unsubscribe();
    this.recordsSub.unsubscribe();
    this.switchMode.unsubscribe();
  }

  initCalendar(): void {
    const calendarRef = this.calendarRef.nativeElement;
    const changeScopeDropdown = this.changeScopeDropdownRef.nativeElement;

    this.calendar = new Calendar({
      target: calendarRef,
      props: {
        plugins: [TimeGrid, DayGrid, Interaction, ResourceTimeline, List],
        options: this.getCalendarOptions()
      }
    });
    const calendarBtnGroup = this.calendarRef.nativeElement.querySelector('.ec-end');
    this.renderer.appendChild(calendarBtnGroup, changeScopeDropdown);
  }

  getCalendarOptions(): any {
    return {
      view: this.getCurrentView(),
      viewDidMount: info => this.updateCurrentScope(info.type),
      height: '750px',
      selectable: this.getSelectable(),
      unselectCancel: '.no-unselect',
      selectBackgroundColor: 'rgba(0, 0, 0, 0.2)',
      dayMaxEvents: true,
      nowIndicator: true,
      pointer: true,
      firstDay: 1,
      eventSources: [{ events: () => this.events }],
      eventClick: info => this.onEventClick(info.event),
      eventResize: info => this.onEventDateChange(info.event),
      eventDrop: info => this.onEventDateChange(info.event),
      eventDidMount: info => info.el.setAttribute('title', info.event.title),
      select: info => this.onSelect(info.start, info.end),
      dayHeaderFormat: info => this.getDayHeaderFormat(info),
      listDayFormat: info => this.getListDayFormat(info),
      listDaySideFormat: info => this.getListDaySideFormat(info),
      titleFormat: (start, end) => this.getTitleFormat(start, end),
      dayPopoverFormat: info => this.getDayPopoverFormat(info),
      noEventsContent: () => this.getNoEventsContent(),
      allDayContent: () => this.getAllDayContent(),
      customButtons: this.getCustomButtons(),
      buttonText: this.getButtonsTextTranslations(),
      headerToolbar: this.getHeaderToolbar(),
      datesSet: info => this.handleDatesSet(info.view.type),
      moreLinkContent: info => this.getMoreLinkTranslations(info),
      theme: theme => this.getCalendarTheme(theme)
    };
  }

  getSelectable() {
    return this.createEvent.observers.length > 0;
  }

  refreshCalendarButtons(): void {
    if (this.calendar) {
      this.calendar.setOption('customButtons', this.getCustomButtons());
      this.calendar.setOption('headerToolbar', this.getHeaderToolbar());
      const listModeBtn = this.calendarRef.nativeElement.querySelector('.ec-listMode');
      listModeBtn.disabled = this.groupBy;
    }
  }

  getCustomButtons(): any {
    return {
      unscheduledEvents: {
        text: translate("records non planifiés", { 
          unscheduledCount: this._unscheduledCount, 
          e: this.entity?.isMasculine ? '' : 'e',
          s: this._unscheduledCount !== 1 ? 's' : ''
        }),
        click: () => this.switchUnscheduled(),
        active: this.unscheduled
      },
      hideWeekends: {
        text: translate("Masquer les week-ends"),
        click: () => this.toggleWeekends()
      },
      listMode: {
        text: translate("Vue en liste"),
        click: () => this.toggleListMode(),
        active: this.listMode
      }
    };
  }

  getHeaderToolbar(): any {
    const unscheduledBtn = this._unscheduledCount ? 'unscheduledEvents' : '';
    
    return {
      start: 'prev,today,next', 
      center: 'title', 
      end: `${unscheduledBtn} hideWeekends listMode`
    };
  }

  getNoEventsContent(): any {
    return translate("Aucun record à afficher", { 
      e: this.entity?.isMasculine ? '' : 'e',
      title: this.entity.title
    });
  }

  getAllDayContent() {
    return translate('Toute la journée');
  }

  getDateFormatted(info, formatOptions) {
    const langMap = {
      'fr': 'fr-FR',
      'en': 'en-EN',
      'cn': 'zh',
    };
  
    const currentLang = langMap[this.localService.getLanguage()];
    return info.toLocaleDateString(currentLang, formatOptions);
  }

  getCurrentScope() {
    if (this.calendar) {
      const currentView = this.calendar.getOption('view');
      return _.findKey(this.viewTypes, value => value === currentView)?.replace(/GroupBy$/, '')?.replace(/List$/, '');
    } else {
      const calendarConfig = JSON.parse(this.route.snapshot.queryParams?.calendarConfig || '{}');
      return calendarConfig.scope || this.scope;
    }
  }

  getTitleFormat(start, end) {
  const currentScope = this.getCurrentScope();
  const dayFormatMap = {
    'month': { year: 'numeric', month: 'long' },
    'week': { day: 'numeric', month: 'short', year: 'numeric' },
    'day': { year: 'numeric', month: 'long', day: 'numeric' },
  };
  const dayFormat = dayFormatMap[currentScope] || null;
  return currentScope === 'week'
    ? `${this.getDateFormatted(start, dayFormat)} - ${this.getDateFormatted(end, dayFormat)}`
    : this.getDateFormatted(start, dayFormat);
  }

  getDayPopoverFormat(info) {
    return this.getDateFormatted(info, { day: 'numeric', month: 'long', year: 'numeric' });
  }

  getDayHeaderFormat(info) {
    const currentScope = this.getCurrentScope();
    const dayFormatMap = {
      'month': { weekday: 'short' },
      'week': { weekday: 'short', day: 'numeric' },
      'day': { weekday: 'long' },
    };

    const dayFormat = dayFormatMap[currentScope] || null;
    return this.getDateFormatted(info, dayFormat);
  }

  getListDayFormat(info) {
    return this.getDateFormatted(info, { weekday: 'long' });
  }

  getListDaySideFormat(info) {
    return this.getDateFormatted(info, { day: 'numeric', month: 'long', year: 'numeric' });
  }

  getCalendarTheme(theme): any {
    return {
      ...theme,
      button: 'btn btn-sm btn-secondary fs-6 text-gray-700',
      active: 'active btn-info',
      title: 'text-gray-800 me-auto ms-4 flex-center',
      toolbar: 'ec-toolbar d-flex justify-content-between align-items-center py-3 px-2'
    };
  }

  getButtonsTextTranslations(): any{
    return {
      dayGridMonth: translate('Mois'), 
      timeGridDay: translate('Jour'), 
      timeGridWeek: translate('Semaine'),
      today: translate("Aujourd'hui"),
      resourceTimelineMonth: translate('Mois'), 
      resourceTimelineDay: translate('Jour'), 
      resourceTimelineWeek: translate('Semaine'),
      listMonth: translate('Mois'), 
      listDay: translate('Jour'), 
      listWeek: translate('Semaine')
    }
  }

  getMoreLinkTranslations(info): string {
    return translate("events de plus", {count: info.num});
  } 

  handleDatesSet(viewType: string): void {
    if (viewType !== this.getCurrentView()) {
      this.updateCurrentScope(viewType);
    }
    this.onActiveDateChanged();
  }

  getCalendarResources(): any[] {
    if (!this.groupBy) return [];
    const groupByFieldName = this.route.snapshot.queryParams?.groupby;
    return getRecordsGroups(this.entity, this.records, groupByFieldName);
}

  getEventGroup(record): any[] {
    if (!this.groupBy) return [];
    const groupByFieldName = this.route.snapshot.queryParams?.groupby;
    const groupByField = getGroupByField(this.entity, groupByFieldName);
    const groupByValue = getGroupByValue(record, groupByField).fieldValue;
    
    return this.getCalendarResources().find(resource => resource.title == groupByValue).id;
  }

  setupEventsGrouping(): void {
    if (this.groupBy && this.calendar) {       
      this.calendar.setOption('resources', this.getCalendarResources());
      this.groupEvents();
    }
  }

  switchToGroupByView(): void {
    if (this.listMode) this.toggleListMode();
    this.calendar?.setOption('view', this.viewTypes[this.scope + 'GroupBy']);
  }

  clearGroupBy(): void {
    this.calendar?.setOption('view', this.viewTypes[this.scope]);
  }

  recordsAsEvents(): Event[] {
    return _.reduce(this.records, (records, r) => {
      if (r.value[this.entity.startingDateField.id] && r.value[this.entity.endingDateField.id]) {
          records.push(this.recordToEvent(r));
      }
      return records;
    }, []);
  }

  recordToEvent(record): Event {
    const isEditable = record.accessLevel >= Level.Write;
    const color = record.color ? this.computedStyle.getPropertyValue('--bs-'+record.color) : this.computedStyle.getPropertyValue('--bs-primary');
    const isUnscheduled = !(record.value[this.entity.startingDateField.id] && record.value[this.entity.endingDateField.id]);
    const isAllDay = [this.viewTypes['month'], this.viewTypes['monthGroupBy']].includes(this.getCurrentView());
    const startDate = new Date(record.value[this.entity.startingDateField.id]);
    const endDate =  new Date(record.value[this.entity.endingDateField.id]);

    return {
      id: record.id,
      allDay: isAllDay,
      start: startDate,
      end: endDate,
      title: record.str,
      editable: isEditable,
      backgroundColor: color,
      extendedProps: {
        record,
        isUnscheduled
      }
    }
  }

  groupEvents(): Event[] {
    if(!this.groupBy) return this.events;
    this.events = _.map(this.events, (event: Event) => {
      const resourceId = this.getEventGroup(event.extendedProps.record);
      return {
        ...event,
        resourceId: resourceId
      }
    });

  }

  checkActiveDay(date: Date): void {
    this.activeDayIsOpen = _.some(this.records, record => 
      moment(date).format("YYYY-MM-DD") === moment(new Date(record.value[this.entity.endingDateField.id])).format("YYYY-MM-DD")
    );
  }

  initEvents() {
    const date = new Date(this.route.snapshot.queryParams["timeframe"])
    this.events = this.recordsAsEvents();
    this.checkActiveDay(date);
    this.refresh.next(null);
  }

  onActiveDateChanged() {
    this.isLoading = true

    if (!this.calendar) return;
  
    const { activeStart, activeEnd } = this.calendar.getView();
    this.calendarScopeDateChange.emit([activeStart, activeEnd]);
  }

  getCurrentView(): string {
    if (this.groupBy) return this.viewTypes[this.scope + 'GroupBy'];
    if (this.listMode) return this.viewTypes[this.scope + 'List']
    return this.viewTypes[this.scope];
  }

  updateCurrentScope(viewType: string): void {
    this.scope = _.findKey(this.viewTypes, value => value === viewType);
    if (this.groupBy) this.scope = this.scope.replace(/GroupBy$/, '');
    if (this.listMode) this.scope = this.scope.replace(/List$/, '')
    this.changeScope(this.scope);
  }

  onEventClick(event: Event): void {
    this.updateQueryParams("timeframe", moment(this.scopeDate).format("YYYY-MM-DD"))
    this.calendarRecordClick.emit({
      recordId:event.extendedProps.record.id, 
      openModal: this.entity.isDetailPageInModal
    });
  }

  onEventDateChange(event: Event): void {
    const updatedEvent = {
      record: {
        id: event.extendedProps.record.id,
      },
      start: event.start,
      end: event.end
    }

    this.resizeEvent.emit(updatedEvent)
  }

  calendarDrop(event) {
    const targetDate = this.calendar.dateFromPoint(event.clientX, event.clientY);
    const startDate = targetDate.date;
    const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); 
    const droppedEvent = event.dropData.event;

    this.calendarRecordDrag.emit({
      newDate: {
        start: startDate,
        end: endDate
      }, 
      record: droppedEvent.extendedProps.record
    });

    const unscheduledIndex = this.unscheduledEvents.indexOf(droppedEvent);
    if (unscheduledIndex > -1) {
      this.loadNextIfMissing();
      this.unscheduledEvents.splice(unscheduledIndex, 1);
      this.events.push(droppedEvent);
    }
    this.refresh.next(null);
  }

  toggleButton(element: Element, isActive: boolean) {
    const switchOnClass = isActive ? ['active', 'btn-info'] : ['btn-secondary'];
    const switchOffClass = isActive ? ['btn-secondary'] : ['active', 'btn-info'];
    _.forEach(switchOnClass, (className: string) => element.classList.add(className));
    _.forEach(switchOffClass, (className: string) => element.classList.remove(className));
  }

  switchUnscheduled() {
    if (this._unscheduledCount > 0) {
      const unscheduledBtn = this.calendarRef.nativeElement.querySelector('.ec-unscheduledEvents');
      this.unscheduled = !this.unscheduled;
      this.toggleButton(unscheduledBtn, this.unscheduled);
  
      this.calendarUnscheduledChange.emit(this.unscheduled);
      if (this.unscheduled && !this.unscheduledLoaded) {
        this.unscheduledLoaded = true;
      }
    }
  }

  toggleWeekends(): void {
    const hideWeekendsBtn = this.calendarRef.nativeElement.querySelector('.ec-hideWeekends');
    this.hideWeekends = !this.hideWeekends;
    this.toggleButton(hideWeekendsBtn, this.hideWeekends);
    
    const hiddenDays = this.hideWeekends  ? [0, 6] : [];
    this.calendar.setOption('hiddenDays', hiddenDays);
  }

  toggleListMode(): void {
    if (this.groupBy) this.clearGroupBy();

    const listModeBtn = this.calendarRef.nativeElement.querySelector('.ec-listMode');
    this._listMode = !this.listMode;
    this.updateCalendarConfigQp('list', this.listMode);
    this.refreshCalendarButtons();
    this.toggleButton(listModeBtn, this.listMode);

    if (this.listMode) {
      this.calendar?.setOption('view', this.viewTypes[this.scope + 'List'])
    } else {
      this.calendar?.setOption('view', this.viewTypes[this.scope]);
    }
  }

  onSelect(startDate: Date, endDate: Date): void {
    this.isSelecting = {
      state: true,
      selectedRange: {
        start: startDate,
        end: endDate
      }
    }
    this.cdr.detectChanges();
    if (this.entity.creationForm) {
      this.create.openCreationModal(this.create.creationFormRef);
    } else if (this.entity.mainField) {
      const previewEvent = this.calendarRef.nativeElement.querySelector('.ec-event.ec-preview');    
      this.renderer.insertBefore(previewEvent, this.createContainerRef.nativeElement, previewEvent.firstChild);
    } else {
      this.handleCreateSubmit();
    }
  }

  handleCreateSubmit(eventData?: any) {
    const mainField = this.entity.mainField;
    const startingDateField = this.entity.startingDateField;
    const endingDateField = this.entity.endingDateField;

    const fieldsValues = {
      ...(this.entity.creationForm ? { form_data: eventData } : { [mainField?.id]: eventData }),
      ...(startingDateField ? { [startingDateField.id]: this.isSelecting.selectedRange.start } : {}),
      ...(endingDateField ? { [endingDateField.id]: this.isSelecting.selectedRange.end } : {})
    };
    
    this.createEvent.emit({ fieldsValues });
    this.createDismiss();  
  }

  createDismiss() {
    this.isSelecting = {
      state: false,
      selectedRange: null
    }   
  }


  loadNextIfMissing() {
    const unscheduledContent = document.querySelector<HTMLElement>('.unscheduled-content');
    if (unscheduledContent.scrollHeight - 50 <= unscheduledContent.offsetHeight) {
      this.onScroll();
    }
  }

  onScroll() {
    if (this._unscheduledCount > this.unscheduledRecords.length) {
      this.calendarUnscheduledNext.emit();
    }
  }

  initFirstLoad() {
    this.recordsSub = this.actions$.pipe(ofActionSuccessful(RecordsPage.ChangeCalendarDates)).subscribe((res) => {
      if (!this.firstLoad && this.store.snapshot().app.recordsPage.isListReady) {
        this.firstLoad = true;
      }
    });
    this.homeSub = this.actions$.pipe(ofActionSuccessful(HomePage.ChangeCalendarDates)).subscribe((res) => {
      if (!this.firstLoad && this.store.snapshot().app.homePage.isListReady) {
        this.firstLoad = true;
      }
    });
    this.switchMode = this.route.queryParams.subscribe((queryParams) => {
        if (queryParams["mode"] != "calendar") {
            this.firstLoad = false;
        }
    })
  }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    if (isSameMonth(date, this.scopeDate)) {
      this.updateQueryParams("timeframe",moment(date).format("YYYY-MM-DD"))
      if (
        (isSameDay(this.scopeDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
        let qp = _.cloneDeep(this.route.snapshot.queryParams);
        qp["timeframe"] = null;
        this.router.navigate([], {
          queryParams: qp,
          queryParamsHandling: 'merge',
        });
      } else {
        this.activeDayIsOpen = true;
      }
      this.scopeDate = date;
    }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: any): void {
    this.calendarRecordDrag.emit({newDate: newStart, record: event.extendedProps.record});
    const unscheduledIndex = this.unscheduledEvents.indexOf(event);
    if (unscheduledIndex > -1) {
      this.loadNextIfMissing();
      this.unscheduledEvents.splice(unscheduledIndex, 1);
      this.events.push(event);
    }
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next(null);
  }

  changeScope(scope: any) {
    this.isLoading = true;
    this.scope = scope;
    this.calendarScopeChange.emit(scope);
    this.calendar?.setOption('view', this.getCurrentView());
    this.onActiveDateChanged();
  }

  initUnscheduledEvents() {
    if (!this.unscheduledContainer?.nativeElement){
      this.unscheduledEventBound = false;
    }
    let date = new Date(this.route.snapshot.queryParams["timeframe"]);
    this.unscheduledEvents = [];
    for (let record of this.unscheduledRecords) {
      this.unscheduledEvents.push(this.recordToEvent(record));
      if (moment(date).format("YYYY-MM-DD") === moment(new Date(record.value[this.entity.endingDateField.id])).format("YYYY-MM-DD")  )
        this.activeDayIsOpen = true;
    }
    this.canScroll = true;
    if (this.unscheduledContainer?.nativeElement && !this.unscheduledEventBound){
      this.unscheduledEventBound = true;
      let unscheduledElement: HTMLElement = this.unscheduledContainer.nativeElement
      let callback = () => {
        if (this.canScroll && unscheduledElement.clientHeight >= unscheduledElement.scrollHeight - unscheduledElement.scrollTop){
          this.canScroll = false;
          this.onScroll()
        }
      }
      unscheduledElement.removeAllListeners();
      unscheduledElement.addEventListener('scroll', callback)
    }
  }

  externalDrop(event: any) {
    if (this.unscheduledEvents.indexOf(event) === -1) {
      this.calendarRecordDrag.emit({newDate: null, record: event.extendedProps.record});
      this.events = this.events.filter((iEvent) => iEvent !== event);
      this.unscheduledEvents.push(event);
    }
  }

  updateCalendarConfigQp(name, values) {
    let qp = {};
    let calendarConfig = this.route.snapshot.queryParams?.calendarConfig;
    calendarConfig = calendarConfig ? JSON.parse(calendarConfig) : {};
    calendarConfig[name] = values.toString();
    qp['calendarConfig'] = JSON.stringify(calendarConfig);
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }

  updateQueryParams(name, values) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    if (!values || !values.length) { values = null; }
    let qp = {};
    qp[name] = values;
    this.router.navigate([], {
      //relativeTo: this.route,
      queryParams: qp,
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      //skipLocationChange: true
      // do not trigger navigation
    });
  }
}
