import { State, Action } from '@ngxs/store';
import { ResetPage } from './app.actions';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';

@State({
    name: 'resetPage'
})
@Injectable()
export class ResetPageState {

    constructor(private userService: UserService) {}

    // Reset

    @Action(ResetPage.Reset)
    reset(ctx, {email, token, password, confirmation, firstName, lastName}) {
      return this.userService.reset(email, token, password, confirmation, firstName, lastName);
    }
}