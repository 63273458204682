import {State, Action} from '@ngxs/store';
import {ReleaseService} from '../services/release.service';
import {finalize,tap} from 'rxjs/operators';
import {mutateState} from '../app.utils';
import { Injectable} from '@angular/core';
import {ReleasesPage} from "./app.actions";



@State({
    name: 'releasesPage',
    defaults: {
        releases: [],
        isReady: false,
    }
})
@Injectable()
export class ReleasesPageState {

    constructor(private releaseService: ReleaseService) {
    }

    @Action(ReleasesPage.Init)
    init(ctx) {
        mutateState(ctx, draft => { draft.isReady = false});
        return this.releaseService.retrieveObjects({page:1, page_size:10}).pipe(
            tap((release) => {
                mutateState(ctx, draft => {
                    draft.releases.push(release);
                    draft.isReady = true;
                });
            }),
        finalize(() => {
            mutateState(ctx, draft => {
                draft.isReady = true;
            });
        })
        );
    }

    @Action(ReleasesPage.RetrieveReleases)
    retrieveReleases(ctx, { page }) {
        return this.releaseService.retrieveObjects({ page: page, page_size:10}).pipe(
            tap((release) => {
                mutateState(ctx, draft => {
                    draft.releases.push(release);
                    draft.isReady = true;
                });
            })
        );
    }

}
