import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {mergeMap} from "rxjs/operators";
import {forkJoin, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ViewService extends BaseService {
  key = 'views'

  constructor(public injector: Injector) {
    super(injector)
  }

  retrieveObjects(params: object, propagations: string[] = []): any {
    return super.retrieveObjects(params, propagations).pipe(mergeMap((observables$: any) => (observables$.length ? forkJoin(...observables$) : of([]))));
  }
}
