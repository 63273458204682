import { Inject, Injectable, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { LocalService } from "./local.service";
import { webSocket, WebSocketSubject } from "rxjs/webSocket"
import {ActivatedRoute} from '@angular/router';
import { BehaviorSubject ,Subject} from "rxjs";
import { Cache } from "../classes/cache";
import { Store } from "@ngxs/store";
import {RecordService} from "./record.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends BaseService {
  private userWebSocket: WebSocketSubject<any>;
  public messages: Subject<any> = new Subject()
  private timeoutUserWS;

  constructor(public injector: Injector,
              private localService: LocalService,
              private store: Store,
              private route: ActivatedRoute,
              @Inject("Cache") private cache: Cache,
             ){
    super(injector);
    window.onbeforeunload = () => {
      this.disconnectUserWS();
    };
  }

  connectUserWS() {
    if (!this.localService.getUser()) return;

    const WS_ENDPOINT = this.envService.wsEndpoint + 'users/' + this.localService.getUser() + '/';

    clearTimeout(this.timeoutUserWS);
    this.userWebSocket = webSocket({
        url: WS_ENDPOINT,
      }
    );

    this.userWebSocket.subscribe((data) => {
      const type = data.message.type;
      if (type === 'action') {
        this.cache.invalidateFromStales(data.message.response);
        this.injector.get(RecordService).retrieveObject(data.message.sender).toPromise().then((record) => {
          this.cache.invalidateFromEdition(record, 'POST', 'edition');
          data.message.sender = record;
          this.messages.next(data);
        });
      } else {
        if (type === 'notification') {
          this.cache.invalidateFromNotifications();
        } else if (type === 'highlight') {
          this.cache.invalidateFromHighlights(data.message.data, this.localService.getUser());
        } else if (type === 'edition') {
          this.cache.invalidateFromStales(data.message.response);
          data.message.sender = JSON.parse(data.message.sender);
          let recordId;
          this.route.paramMap.subscribe(params => {
            recordId = params.get('id');
          });
          const sameUserAndRecord = data.message.user === this.localService.getUser()
            && data.message.sender.id === this.store.selectSnapshot((state) => state.app.recordPage.getRecord(recordId)).record?.id
            && data.message.type === 'edition';
          if (!sameUserAndRecord){
            this.cache.invalidateFromEdition(data.message.sender, data.message.method, data.message.type);
          }
        } else if (type === 'sync') {
          this.cache.invalidateFromStales(data.message.response);
        } else if (type === 'audit') {
          this.cache.invalidateFromEdition(data.message.response, data.message.method, data.message.type);
        }
        this.messages.next(data);
      }
    },
    () => {
      this.timeoutUserWS = setTimeout(() => {
        this.connectUserWS();
      }, 10000) ;
    },
    () => {
      console.log("WS connection closed");
    });
  }

  disconnectUserWS(){
    this.userWebSocket?.complete();
  }
}
