// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-field-hover-read-only:hover {
  background-color: #fff;
}

.field .badge {
  line-height: 1.5;
}

.position-icon {
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background-color: white;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.multiselect-choice:not(:last-child):not(.badge)::after {
  content: ",";
  margin-left: -3px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/field/field.component.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,+CAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,YAAA;EACA,iBAAA;AAAF","sourcesContent":["\n.bg-field-hover-read-only:hover {\n  background-color: #fff;\n}\n\n.field .badge {\n  line-height: 1.5;\n}\n\n.position-icon {\n  border-radius: 2px;\n  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;\n  background-color: white;\n  margin-right: 10px;\n  width: 40px;\n  height: 40px;\n}\n\n.multiselect-choice:not(:last-child):not(.badge)::after {\n  content: \",\";\n  margin-left: -3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
