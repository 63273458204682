import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { Store, Select} from '@ngxs/store';
import {NotificationsPage, ReleasesPage} from "../../state/app.actions";
import {AppState} from "../../state/app.state";


@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleasesComponent implements OnInit {
  @Select(AppState.releasesPage) releasesPage$;
  page = 1
  scrollDisabled = false
  lengthLoaded = 0;
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new ReleasesPage.Init()).subscribe();
  }
  onScroll(){
    this.scrollDisabled = true
    this.page++;
    this.store.dispatch(new ReleasesPage.RetrieveReleases(this.page)).subscribe((res) => {
      let lengthLoaded = res.app.releasesPage.releases.length;
      if (lengthLoaded > this.lengthLoaded){
        this.scrollDisabled = false
        this.lengthLoaded = lengthLoaded;
      }
    });
  }
}
