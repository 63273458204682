// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden, .hidden input {
  width: 0;
  height: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.timepicker {
  border: 1px solid var(--bs-gray-200);
  border-left: 0;
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-gray-800) !important;
}

.datepicker {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.delete {
  position: absolute;
  top: 4px;
  left: 215px;
  width: 40px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/datepicker/datepicker.component.scss"],"names":[],"mappings":"AACI;EACI,QAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;AAAR;;AAIA;EACE,oCAAA;EACA,cAAA;EACA,kCAAA;EACA,8CAAA;EACA,oCAAA;AADF;;AAGA;EACE,0BAAA;EACA,6BAAA;AAAF;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AAAF","sourcesContent":[".hidden {\n    &, input {\n        width: 0;\n        height: 0;\n        margin: 0;\n        border: none;\n        padding: 0;\n    }\n}\n\n.timepicker {\n  border: 1px solid var(--bs-gray-200);\n  border-left: 0;\n  border-radius: 0 0.25rem 0.25rem 0;\n  background-color: var(--bs-body-bg) !important;\n  color: var(--bs-gray-800) !important;\n}\n.datepicker {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.delete{\n  position: absolute;\n  top: 4px;\n  left: 215px;\n  width: 40px;\n  height: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
