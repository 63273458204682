import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {Router, ActivatedRoute} from "@angular/router";
import {take} from 'rxjs/operators';
import { Interface } from 'readline';

interface ModalOutlet {
  modal: any[] | null
}

interface QueryParamType {
  [key: string] : string | undefined
}

@Injectable({
  providedIn: 'root'
})
export class ModalProxyService {

  private parentModal = new BehaviorSubject<any>(null);

  private getParentModal = this.parentModal.asObservable();

  private queryParamsBackup :QueryParamType[] = [];
  private modalOultetBackup: ModalOutlet[] = []

  constructor(private router: Router, private route: ActivatedRoute) {}

  public setParentModal(component: any) {
    this.parentModal.next(component);
  }

  openRecordModal(recordId: any, tab?: any) {
    firstValueFrom(this.getParentModal).then((parent: any) => {
      if (parent) {
        parent.changeDetector.detach();
        // skip modal outlet backup or query params backup when navigating between same record tabs
        if(!tab){this.backupUrl()}
      } else {
        throw 'debug: uncovered case';
      }
      this.navigateToModalRecord(recordId, tab)
    });
  }

  openMultiRecordsModal(entityId: number, recordIds: number[]){
    firstValueFrom(this.getParentModal).then((parent: any) => {
      if (parent) {
        parent.changeDetector.detach();
        this.backupUrl();
      } else {
        throw 'debug: uncovered case';
      }
      this.navigateToModalRecords(entityId, recordIds)
    });
  }

  navigateToModalRecord(recordId, tab?: any){
    const outlets = tab ? { modal: ['modal', 'record', recordId, tab] } : { modal: ['modal', 'record', recordId] };
    this.router.navigate([{ outlets }], {
      queryParams: {
        'record_ids': null,
        'page': null
      },
      queryParamsHandling: 'merge',
      replaceUrl: tab ? true : false
    }).then(() => {});
  }

  navigateToModalRecords(entityId: number, recordIds: number[]){
    const outlets = { modal: ['modal', 'records', entityId] };
    const qp = {"record_ids": recordIds.join(','), 'v': -1};
    this.router.navigate([{ outlets }], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    }).then(() => {});
  }

  backupUrl(){
    this.route.queryParams.pipe(
      take(1)
    ).subscribe(params => {
      const {page, ...rest} = params
      this.queryParamsBackup.push({ ...rest });
    });

    let modalOutletUrl = this.router.url.split("modal:modal/");
    let outlet: ModalOutlet;
    if (modalOutletUrl.length > 1){
      const modalOutletUrlSegments = modalOutletUrl[1].split(")")[0].replace(")", "").split("/");
      outlet = { modal: ['modal', ...modalOutletUrlSegments]}
    }else{
      outlet = {modal: null}
    }
    this.modalOultetBackup.push(outlet)
  }

  popQueryParamsBackup(): QueryParamType{
    const qpBackup = this.queryParamsBackup.pop();
    return qpBackup
  }

  popModalOutletBackup(): ModalOutlet{
    let backup = this.modalOultetBackup.pop();
    if(!backup){
      return {modal: null}
    }
    return backup;
  }

  onDestroyModal() {
    firstValueFrom(this.getParentModal).then((parent: any) => {
      if (parent) {
        parent.changeDetector.detectChanges();
        parent.changeDetector.reattach();
      } else {
        throw 'debug: uncovered case';
      }
    });
  }
}
