// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-state-bg .menu-sub .menu-item .menu-link.active {
  margin: 0.155rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sider/sider.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ","sourcesContent":[".menu-state-bg .menu-sub .menu-item .menu-link.active {\n    margin: 0.155rem 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
