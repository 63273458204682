// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.filter {
  border: 1px solid #ccc;
}

.card.filter .badge {
  font-size: 1.075rem !important;
}

.card.filter .card-header, .card.filter .separator {
  border-bottom: 1px solid #ccc;
}

.card.filter .card-header {
  min-height: auto;
}

.line-through-hover:hover {
  text-decoration: line-through;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter/filter.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,8BAAA;AAEJ;;AAAA;EACI,6BAAA;AAGJ;;AADA;EACI,gBAAA;AAIJ;;AAFA;EACC,6BAAA;AAKD","sourcesContent":[".card.filter {\n    border: 1px solid #ccc;\n}\n.card.filter .badge {\n    font-size: 1.075rem !important;\n}\n.card.filter .card-header,  .card.filter .separator{\n    border-bottom: 1px solid #ccc;\n}\n.card.filter .card-header {\n    min-height: auto;\n}\n.line-through-hover:hover {\n text-decoration: line-through;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
