import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService extends BaseService {
  key = 'preferences';

  constructor(public injector: Injector) {
    super(injector);
  }

}
