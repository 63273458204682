import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocationStrategy} from "@angular/common";

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class LocalService {

  siderVisibility$: BehaviorSubject<any> = new BehaviorSubject(this.getSiderVisibility());
  theme$: BehaviorSubject<any> = new BehaviorSubject(this.getTheme());
  popState$: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private locationStrategy: LocationStrategy) {
  }


  public setUser(userId) {
    localStorage.setItem('user', userId);
  }

  public setSpace(spaceId) {
    localStorage.setItem('space', spaceId);
  }

  public setWorkspace(workspaceName) {
    localStorage.setItem('workspace', workspaceName);
  }

  public setSiderVisibility(visibility) {
    localStorage.setItem('visible', visibility);
    this.siderVisibility$.next(visibility);
  }

  public getUser() {
    return parseInt(localStorage.getItem('user'));
  }

  public getSpace() {
    return parseInt(localStorage.getItem('space'));
  }

  public getWorkspace() {
    return localStorage.getItem('workspace');
  }

  public getSiderVisibility() {
    return localStorage.getItem('visible') !== null ? +localStorage.getItem('visible') : 1;
  }

  public removeUser() {
    return localStorage.removeItem('user');
  }

  public removeSpace() {
    return localStorage.removeItem('space');
  }

  public removeWorkspace() {
    return localStorage.removeItem('workspace');
  }

  public getEnv(){
    return localStorage.getItem('env')
  }

  public setEnv(env) {
    localStorage.setItem('env', env);
  }

  public setLanguage(lang){
    localStorage.setItem('lang', lang);
  }

  public getLanguage(){
    return localStorage.getItem('lang')
  }

  public getActivePageSet() {
    return +localStorage.getItem('activePageSet')
  }

  public setActivePageSet(pageSet: string) {
    localStorage.setItem('activePageSet', pageSet);
  }

  public getTheme() {
    return localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'light'
  }

  public setTheme(theme: string) {
    localStorage.setItem('theme', theme);
    this.theme$.next(theme);
  }

  public addPopStateListener() {
    this.locationStrategy.onPopState(() => {
      this.popState$.next(true);
    })
  }

  public setCollapsedState(CollapsedState){
    localStorage.setItem(`block_${CollapsedState.blockId}_${CollapsedState.recordId}`, CollapsedState.collapsed ? '1' : '0');
  }

  public getCollapsedState(blockId, recordId){
    return localStorage.getItem(`block_${blockId}_${recordId}`) == '1';
  }

  public setIsMobile() {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isBlackBerry = /BlackBerry/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isOpera = /Opera Mini/i.test(navigator.userAgent);
    const isWindows = /IEMobile/i.test(navigator.userAgent) || /WPDesktop/i.test(navigator.userAgent);
    const isSmallScreen = window.matchMedia("only screen and (max-width: 991px)").matches;
    const isLargeScreen = window.matchMedia("only screen and (min-width: 992px)").matches;
    const isMobile = (isAndroid || isBlackBerry || isiOS || isOpera || isWindows || isSmallScreen) && !isLargeScreen;
    localStorage.setItem('is_mobile_platform', isMobile ? '1' : '0');
  }

  public isMobile(){
    return localStorage.getItem('is_mobile_platform') === '1';
  }
}
