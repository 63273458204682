// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-disabled {
  pointer-events: none;
  opacity: 0.6;
  box-shadow: none;
  cursor: default !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/action/action.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,YAAA;EACA,gBAAA;EACA,0BAAA;AACF","sourcesContent":[".btn-disabled {\n  pointer-events: none;\n  opacity: 0.6;\n  box-shadow: none;\n  cursor: default!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
