import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceStr'
})
export class ReplaceStrPipe implements PipeTransform {

  transform(value: string, old: string, current: string): string {
    return value.length <= 4096 ? value.replace(old, current) : value;
  }

}
