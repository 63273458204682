import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitiesComponent implements OnInit, OnChanges {
  @Input() public parentScroll;
  @Input() public activities;
  @Input() public total;

  @Output() public loadMoreBtnClick = new EventEmitter();

  public readonly PAGE_SIZE = 10;
  public fetching = false;
  public ready = false;
  public page = 1;

  constructor() { }

  ngOnInit(): void {
  }

  track(index: number, obj): number {
    return obj.id;
  }

  ngOnChanges(changes: any): void {
    if (!this.ready){
      if (changes.activities && changes.activities.currentValue.length || this.total === 0){
        this.ready = true;
      }
    }
    if (this.fetching){
      this.fetching = false;
    }
  }

  public onLoadMoreClick(){
    this.page++;
    this.fetching = true;
    this.loadMoreBtnClick.emit({pageSize: this.PAGE_SIZE, page: this.page})
  }

  getRemainingCount() {
    return this.total - this.activities.length;
  }

}
