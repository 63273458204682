import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  OnChanges
} from '@angular/core';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {LocalService} from '../../services/local.service'
import {stripBaseUrl} from "../../app.utils";
import {translate} from "@jsverse/transloco"
import {NgbModal, NgbModalRef, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl} from '@angular/forms';
import {SafeHtml, DomSanitizer} from '@angular/platform-browser';
import {Store} from "@ngxs/store";
import {ChoiceComponent} from "../choice/choice.component";
import * as XLSX from 'xlsx';

@Component({
  selector: 'checkpoint',
  templateUrl: './checkpoint.component.html',
  styleUrls: ['./checkpoint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckpointComponent implements OnInit, OnChanges {

  public _checkpoint: any;
  @Input()
  set checkpoint(val) {
    this._checkpoint = _.cloneDeep(val);
  }

  get checkpoint() {
    return this._checkpoint;
  }

  @Input() public extension;
  @Input() public record;
  @Input() public loading;
  @Input() public checkpointTags;
  @Input() public fixingEntities;
  @Input() public isCreatingFixingRecord;
  @Input() public executeMode = true;

  @Output() public checkpointTitleChange = new EventEmitter();
  @Output() public checkpointTypeUpdate = new EventEmitter();
  @Output() public checkpointEvaluate = new EventEmitter();
  @Output() public checkpointStateChange = new EventEmitter();
  @Output() public checkpointCommentChange = new EventEmitter();
  @Output() public checkpointAttachmentAdd = new EventEmitter();
  @Output() public checkpointAttachmentDelete = new EventEmitter();
  @Output() public checkpointFixingRecordCreate = new EventEmitter();
  @Output() public openImportChoicesModal = new EventEmitter();
  @Output() public checkpointTagToggle = new EventEmitter();
  @Output() public checkpointTagCreate = new EventEmitter();
  @Output() public checkpointTagDelete = new EventEmitter();
  @Output() public checkpointChoiceCreate = new EventEmitter();
  @Output() public checkpointChoiceUpdate = new EventEmitter();
  @Output() public checkpointChoiceDelete = new EventEmitter();
  @Output() public checkpointNotesUpdate = new EventEmitter();
  @Output() public checkpointDelete = new EventEmitter();
  @Output() public onClickCheckpointCard = new EventEmitter();
  @Output() public onClickCardsCard = new EventEmitter();

  @ViewChild("fileRef") fileRef: ElementRef;
  @ViewChild("evaluationConfig") evaluationConfig: NgbModalRef;

  aggregations = ['Average', 'Max', 'Min', 'Sum'];
  activeTab = 'choices';
  removeBtnIsHovered = false;
  checkpointMode = 'read';
  checkpointNotes = [];
  aggregation = null;
  state = null;
  type = null;
  valueControl: UntypedFormControl;
  oldCheckpointTitle: string;
  confirmationModal: any;
  evaluationMode: string;
  commentMode: string;
  modal: NgbModalRef;
  evalValue: any;
  expanded = false;
  checkpointsWithChoices: any;
  typeMapping = {
    "Single Select": "Choix simple",
    "Multi Select": "Choix multiple",
    "Number": "Nombre",
    "Text": "Texte",
    "CNC":"CNC"
  }

  @ViewChild('searchRef', {static: true}) searchRef: NgbTypeahead;
  @ViewChildren(ChoiceComponent) choices!: QueryList<ChoiceComponent>;


  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalService: NgbModal,
    private localService: LocalService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    public store: Store
  ) {
  }

  ngOnInit() {
    this.state = this._checkpoint.state;
    this.type = this._checkpoint.type;
    this.evalValue = this.checkpoint.evaluation;
    this.checkpointNotes = this.checkpoint.notes;
    if (this.type == 'Multi Select') {
      this.aggregation = this.checkpoint.notes?.aggregation ? this.checkpoint.notes?.aggregation : null;
      this.evalValue = this.checkpoint.choices.filter(c => this.checkpoint.evaluation?.includes(c.name)).map(c => c.id);
    }
    this.commentMode = this.checkpoint.comment ? 'update' : null;
    this.evaluationMode = this.checkpoint.evaluation ? 'update' : null;
    this.oldCheckpointTitle = String(this.checkpoint.title);
    this.valueControl = new UntypedFormControl('');
    this.changeDetector.detectChanges();
    this.checkpointsWithChoices = _.flatMap(this.record.originalCheckpointsets, child =>
      _.filter(child.checkpoints, checkpoint => checkpoint.id !== this.checkpoint.id &&
        ['Multi Select', 'Single Select'].includes(checkpoint.type)
      )
    );
  }

  ngOnChanges(changes) {
    if (changes['checkpoint']) {
      this.state = this.checkpoint.state;
      this.evalValue = this.checkpoint.evaluation;
    }
    if (changes['record']) {
      this.checkpointsWithChoices = _.flatMap(this.record.originalCheckpointsets, child =>
        _.filter(child.checkpoints, checkpoint => checkpoint.id !== this.checkpoint.id &&
          ['Multi Select', 'Single Select'].includes(checkpoint.type)
        )
      );
    }
  }

  onSubmit() {
    if (this.oldCheckpointTitle.trim() !== this.checkpoint.title.trim()) {
      this.checkpointTitleChange.emit({type: 'checkpoints', object: this.checkpoint, title: this.checkpoint.title});
    } else {
      this.checkpoint.title = this.checkpoint.title.trim();
    }
    this.checkpointMode = 'read';
  }

  onEvaluate(evt: any) {
    if (this.type == 'Single Select') {
      this.evalValue = evt.name;
    } else if (this.type == 'Multi Select') {
      this.evalValue = evt.map((x: any) => x.name);
    }

    if (this.evalValue !== this.checkpoint.evaluation){
      let note = this.getNote();
      this.state = this.getStateFromRanges(note, this.checkpoint)
      this.checkpointEvaluate.emit({recordId: this.record.id, checkpointId: this.checkpoint.id, value: this.evalValue, state: this.state});
    }
    this.evaluationMode = "update";
  }

  onStateChange(state) {
    if (this.type != 'CNC' && state != 'Unavailable' && this.checkpoint.state != 'Unavailable') {
      this.evaluationMode = 'create';
    } else {
      if (this.state && state !== 'Fixed') {
        this.state = null;
      } else {
        this.state = state;
      }
      this.checkpointStateChange.emit({recordId: this.record.id, checkpointId: this.checkpoint.id, state: this.state});
    }
  }

  onCommentChange() {
    if (this.checkpoint.comment){
      this.checkpointCommentChange.emit({
        recordId: this.record.id,
        checkpointId: this.checkpoint.id,
        comment: this.checkpoint.comment
      });
    }
    this.commentMode = 'update';
  }

  onFixingRecordCreate(id) {
    const entity = this.fixingEntities.find(x => x.id === id);
    this.checkpointFixingRecordCreate.emit({
      spaceId: this.localService.getSpace(),
      entityId: entity.id,
      entityFieldId: entity.mainField?.id,
      title: this.checkpoint.title,
      checkpointId: this.checkpoint.id
    });
  }

  onUploadSelect(file) {
    this.checkpointAttachmentAdd.emit({
      recordId: this.record.id,
      checkpoint: this.checkpoint,
      file: file,
      isStandard: !this.executeMode
    });
  }

  onPictureDelete(evt) {
    const checkpoint = evt.checkpoint ? evt.checkpoint : evt.parent;
    const attachment = _.find(checkpoint.checkpointAttachments, p => stripBaseUrl(p.file) === stripBaseUrl(evt.src));
    if (attachment.isStandard && !this.record.originalCheckpointsets.length) {
      this.toastr.error(translate("La suppression des images standards n'est pas autorisée ici"));
    } else {
      const temp = attachment.file.split('/');
      const fileName = temp[temp.length - 1];
      if (window.confirm(translate('Voulez-vous vraiment supprimer le fichier ') + fileName + ' ?')) {
        this.checkpointAttachmentDelete.emit({
          recordId: this.record.id,
          file: evt.src,
          checkpoint: checkpoint,
          isStandard: !this.executeMode
        });
      }
    }
  }

  onUpdateCheckpointType(type) {
    this.type = type;
    this.checkpointTypeUpdate.emit({recordId: this.record.id, checkpoint: this.checkpoint, type: type});
  }

  onCreateCheckpointTag(tag) {
    this.checkpointTagCreate.emit({
      recordId: this.record.id,
      checkpoint: this.checkpoint,
      extension: this.extension,
      tag: tag
    });
  }

  onToggleCheckpointTag(tag) {
    this.checkpointTagToggle.emit({recordId: this.record.id, checkpoint: this.checkpoint, tag: tag})
  }

  onDeleteCheckpointTag(tag) {
    this.checkpointTagDelete.emit({recordId: this.record.id, checkpoint: this.checkpoint, tag: tag})
  }

  onChoiceTitleChange(choice, title) {
    if (choice.title === title) return;
    choice.title = title;
    this.checkpointChoiceUpdate.emit({recordId: this.record.id, checkpoint: this.checkpoint, choice: {id: choice.id, title: title}});
  }

  onChoiceCreateSubmit(title) {
    const lastChoice: any = _.last(this.checkpoint.choices);
    const position = lastChoice ? lastChoice.position + 1 : 0;
    const choice = {
      checkpoint: this.checkpoint.id,
      title: title,
      titleFr: title,
      name: title,
      position
    };
    this.checkpointChoiceCreate.emit({recordId: this.record.id, checkpointId: this.checkpoint.id, choice: choice})
  }

  onChoiceDelete(choice) {
    this.checkpointChoiceDelete.emit({recordId: this.record.id, choice: choice})
  }

  getMaxNoteEvalution(){
    let notes = this.getNotes();
    if (this.type === 'Single Select') {
      if (!notes?.length) return null;
      return Math.max(...notes.map(choice => choice.note ? choice.note : 0));
    } else if (this.type === 'Multi Select') {
      if (!notes?.length) return null;
      if (this.checkpoint.notes?.aggregation === 'Sum') {
        return notes.reduce((a, b) => a + b.note, 0);
      } else {
        return Math.max(...notes.map(choice => choice.note ? choice.note : 0));
      }
    }
    return null
  }

  getMinNoteEvalution() {
    let notes = this.getNotes();
    if (!notes?.length) return null;
    const filteredNotes = notes.filter(choice => !Number.isNaN(choice.note));
    if (filteredNotes.length === 0) return null;
    return Math.min(...filteredNotes.map(choice => choice.note));
  }

  onEvaluationConfigSave(evaluationColor) {
    if (evaluationColor.isRangeConflict()) {
      this.toastr.error(translate('Les intervalles se chevauchent. Veuillez ajuster les bornes.'));
      return;
    }
    let result = {};
    if (['Single Select', 'Multi Select'].includes(this.type)) {
      let notes = this.getNotes();
      result = {
        "notes": notes
      };
      if (this.type === 'Multi Select') {
        result["aggregation"] = this.aggregation;
      }
      result['ranges'] = evaluationColor?.rangesSliderLimits;
    }
    this.checkpointNotesUpdate.emit({recordId: this.record.id, checkpoint: this.checkpoint, notes: result})
    this.checkpointNotes = [];
    this.modal.dismiss();
  }

  onChoiceNoteChange(choice, note) {
    const index = _.findIndex(this.checkpointNotes, {choice: choice.name});
    if (index !== -1) {
      this.checkpointNotes[index].note = note;
    } else if (!_.isEmpty(note)) {
      this.checkpointNotes.push({choice: choice.name, note: parseInt(note)});
    }
  }

  updateAggregation(agg) {
    this.aggregation = agg;
  }

  getChoiceNote(choice) {
    const notes = this.checkpoint.notes?.notes;
    return _.find(notes, (note) => note?.choice === choice.name)?.note;
  }

  getNote() {
    if (this.type === 'Single Select') {
      const selectedNote = this.checkpoint.notes?.notes?.find(w => w.choice === this.evalValue);
      return selectedNote ? selectedNote.note : null;
    } else if (this.type === 'Multi Select') {
      let matchingNotes = this.checkpoint.notes?.notes.filter(w => this.evalValue.includes(w.choice));
      if (matchingNotes.length === 0) {
        matchingNotes = this.checkpoint.notes?.notes.filter(w => this.checkpoint.evaluation?.includes(w.choice));
      }
      if (!matchingNotes.length) return null;
      if (this.checkpoint.notes?.aggregation === 'Min') {
        return Math.min(...matchingNotes.map(w => w.note ? w.note : 0));
      } else if (this.checkpoint.notes?.aggregation === 'Max') {
        return Math.max(...matchingNotes.map(w => w.note ? w.note : 0));
      } else if (this.checkpoint.notes?.aggregation === 'Average') {
        const avgNote =  matchingNotes.reduce((accumulator, currentValue) => accumulator + currentValue.note, 0) / matchingNotes.length;
        return avgNote.toFixed(2);
      } else if (this.checkpoint.notes?.aggregation === 'Sum') {
        return matchingNotes.reduce((a, b) => a + b.note, 0);
      }
    }
    return null;
  }

  getMaxNote() {
    if (this.type === 'Single Select') {
      return Math.max(...this.checkpoint.notes?.notes.map(choice => choice.note ? choice.note : 0));
    } else if (this.type === 'Multi Select') {
      if (!this.checkpoint.notes?.notes.length) return null;
      if (this.checkpoint.notes?.aggregation === 'Max') {
        return Math.max(...this.checkpoint.notes?.notes.map(choice => choice.note ? choice.note : 0));
      } else if (this.checkpoint.notes?.aggregation === 'Min') {
        return Math.max(...this.checkpoint.notes?.notes.map(choice => choice.note ? choice.note : 0));
      } else if (this.checkpoint.notes?.aggregation === 'Average') {
        return Math.max(...this.checkpoint.notes?.notes.map(choice => choice.note ? choice.note : 0));
      } else if (this.checkpoint.notes?.aggregation === 'Sum') {
        return this.checkpoint.notes?.notes.reduce((a, b) => a + b.note, 0);
      }
    }
    return null
  }

  getMinNote() {
    if (!this.checkpoint.notes?.notes?.length) return null;
    const filteredNotes = this.checkpoint.notes.notes.filter(choice => !Number.isNaN(choice.note));
    if (filteredNotes.length === 0) return null;
    return Math.min(...filteredNotes.map(choice => choice.note));
  }
  
  getChoice(name) {
    return this.checkpoint.choices.find(x => x.name === name);
  }

  isChecked(tag: any) {
    return _.filter(this.checkpoint.tags, {"id": tag.id}).length;
  }

  isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|JPG|GIF|PNG|JPEG|WEBP)$/.test(url);
  }

  hasRecordWritePermission() {
    return this.record.accessLevel >= 2;
  }

  formatCommentUrls(comment: string) {
    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    const formattedComment = comment.replace(urlPattern, (url) => {
      return ` <a href="${url}">${url}</a>`;
    });

    return formattedComment;
  }

  formatComment(comment: string): SafeHtml {
    if (comment){
      return this.sanitizer.bypassSecurityTrustHtml(this.formatCommentUrls(this.checkpoint.comment));
    }
  };

  openCheckpointConfigModal(content) {
    this.checkpointNotes = [];
    this.modal = this.modalService.open(content);
  }

  openFile(attachment) {
    if (this.isImage(attachment.file)) {
      document.getElementById('img_' + attachment.id).click()
    } else {
      window.open(attachment.file, '_blank');
    }
  }

  showTitle(attachment) {
    return attachment.file.split('media/')[1];
  }

  showRemoveBtn(id) {
    document.getElementById('file_' + id)?.classList.remove('d-none');
  }

  hideRemoveBtn(id) {
    document.getElementById('file_' + id)?.classList.add('d-none');
  }


  recopyChoices(event){
    this.expanded = !this.expanded;
    if (!event || this.checkpoint.id == event.id){
      return;
    }

    let newChoices = event.choices.filter(item => {
      return !this.checkpoint.choices.some(choice => choice.title === item.title);
    }).map(item => ({
      title: item.title,
      titleFr: item.titleFr,
      name: item.name,
      checkpoint: this.checkpoint.id,
      position: item.position,
    }));
    let notes = {}
    if (this.checkpoint.notes) {
      notes = {
        ...this.checkpoint.notes,
        notes: [
          ...this.checkpoint.notes?.notes,
          ...event.notes?.notes
        ],
        ranges:[
          ...event.notes?.ranges         
        ]
    }
    } else {
      notes = event.notes
    }
    this.checkpointChoiceCreate.emit({recordId: this.record.id, checkpoint: this.checkpoint, choice: newChoices});
    this.checkpointNotesUpdate.emit({recordId: this.record.id, checkpoint: this.checkpoint, notes: notes});
  }


  getStateFromRanges(note: number, checkpoint: any) {
    let ranges = checkpoint.notes?.ranges;
    if (ranges && note || note === 0) {
      for (const item of ranges) {
        if (note >= item.min && note <= item.max) {
          return item.state;
        }
      }
    }
    return null;
  }

  getNotes(){
    let notes = this.choices.map(choice => ({"choice": choice.choice.name, "note": parseInt(choice.note)}));
    this.checkpointNotes.forEach(checkpointNote => {
      const existsInNotes = _.some(notes, note => note.choice === checkpointNote.choice);
      if (!existsInNotes) {
        notes.push({choice: checkpointNote.choice, note: checkpointNote.note});
      }
    });
    return notes;
  }
}
