import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({ 'providedIn': 'root' })
export class EnvService {

  private _env: 'prod' | 'staging' | 'sim' | 'dev' | 'test' | 'on-premise' | 'ops';
  private _apiEndpoint: string;
  private _wsEndpoint: string;
  private _ssoEndpoint: string;

  get env(): string {
    return this._env;
  }

  get apiEndpoint(): string {
    return this._apiEndpoint;
  }

  get wsEndpoint(): string {
    return this._wsEndpoint;
  }

  get ssoEndpoint(): string {
    return this._ssoEndpoint;
  }

  constructor() { }

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnv();
      resolve();
    });
  }

  private setEnv(): void {
    const parts = window && window.location && window.location.href.split("/");
    const scheme = parts[0]
    const host = parts[2]

    // ENDPOINTS
    this._apiEndpoint = scheme + '//' + host + '/api/';
    this._wsEndpoint = scheme.replace('http', 'ws') + '//' + host + '/ws/';
    this._ssoEndpoint = scheme + '//' + host + '/sso/login/';
    if (!environment.production) {
      if (host == '127.0.0.1:4200') {
        this._apiEndpoint = 'http://127.0.0.1:8000/api/';
        this._wsEndpoint = 'ws://127.0.0.1:8001/ws/';
        this._ssoEndpoint = 'http://127.0.0.1:8000/sso/login/';
      }
    }

    // ENVS: to be replaced later by an endpoint call for variables like env, isSentryActive.. etc
    // Security issue: urls shown in clear, hence, all on-premise urls are grouped in one env to hide their urls
    if (host == 'app.kostango.com') {
      this._env = 'prod';
    } else if (host == 'staging.kostango.com') {
      this._env = 'staging'
    } else if (host == 'sim.kostango.com') {
      this._env = 'sim'
    } else if (host == 'ops.kostango.com') {
      this._env = 'ops'
    } else if (host == '127.0.0.1:4200' || host == '127.0.0.1') {
      this._env = 'dev'
    } else if (host == '127.0.0.1:4202') {
      this._env = 'test'
    } else {
      this._env = 'on-premise'
    }
  }
}
