import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SpaceService extends BaseService {
  key = 'spaces'
  constructor(public injector:Injector) {
    super(injector);
  }
}
