import {produce} from 'immer';
import * as _ from 'lodash';
import DOMPurify from 'dompurify';
import {marked} from 'marked'
import * as moment from "moment";
import {firstValueFrom} from 'rxjs';
import {translate} from '@jsverse/transloco';
import {TranslocoLocaleService} from '@jsverse/transloco-locale';
import {Title} from '@angular/platform-browser';
import {AppInjector} from './app.module';
import {EnvService} from "./services/env.service";
import {Store} from '@ngxs/store';
import {delay, filter} from "rxjs/operators";
import {SpecialTitleCasePipe} from './pipes/special-title.pipe'
import {ModalProxyService} from './services/modal-proxy.service';
import {Router} from '@angular/router';


export function isSubstring(str1, str2) {
    if (!str1) {
        return true;
    }
    function removeDiacritics(s) {
        var r = s.toLowerCase();
        /*r = r.replace(new RegExp("\\s", 'g'), "");
        r = r.replace(new RegExp("[àáâãäå]", 'g'), "a");
        r = r.replace(new RegExp("æ", 'g'), "ae");
        r = r.replace(new RegExp("ç", 'g'), "c");
        r = r.replace(new RegExp("[èéêë]", 'g'), "e");
        r = r.replace(new RegExp("[ìíîï]", 'g'), "i");
        r = r.replace(new RegExp("ñ", 'g'), "n");
        r = r.replace(new RegExp("[òóôõö]", 'g'), "o");
        r = r.replace(new RegExp("œ", 'g'), "oe");
        r = r.replace(new RegExp("[ùúûü]", 'g'), "u");
        r = r.replace(new RegExp("[ýÿ]", 'g'), "y");
        r = r.replace(new RegExp("\\W", 'g'), "");*/
        return r;
    };
    var cleanStr1 = removeDiacritics(str1.toLowerCase())
    var cleanStr2 = removeDiacritics(str2.toLowerCase())
    return cleanStr2.indexOf(cleanStr1) != -1;
}


export function getLocation(href) {
    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    }
}

export function parseUrl(url) {
    // preprocessing

    var pathname = getLocation(url).pathname
    pathname = pathname.replace(/\/$/, "");
    var params = getLocation(url).search
    var splitted = pathname.split('/')
    splitted.shift()
    splitted.shift()

    // validation
    if (splitted.length != 1 && splitted.length != 2) {
        throw 'Url parsing error'
    }

    // parsing
    var type = (splitted.length == 1) ? 'List' : 'Detail'
    var id = (splitted.length == 1) ? null : parseInt(splitted[1])
    var model = splitted[0]

    return { type: type, id: id, model: model, params: params }
}


export function getRandomId(){
    return Math.floor(Math.random() * 1000) + 1
}

export function containsFile(obj) {
    for (let key in obj) {
        if (obj[key] instanceof File) {
            return true;
        }
    }
    return false;
}

export function toNgbDate(date: any) {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}

export function toNativeDate(ngbDate) {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function setCookie(name: string, value: string, expireDays: number) {
  let d:Date = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires:string = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export let attachmentIconMapping = {
  'doc': 'fa-file-word',
  'docx': 'fa-file-word',
  'odt': 'fa-file-word',
  'pdf': 'fa-file-pdf',
  'txt': 'fa-file-alt',
  'xls': 'fa-file-spreadsheet',
  'xlsx': 'fa-file-spreadsheet',
  'pps': 'fa-file-powerpoint',
  'ppt': 'fa-file-powerpoint',
  'pptx': 'fa-file-powerpoint',
  'ai': 'fa-file-image',
  'bmp': 'fa-file-image',
  'gif': 'fa-file-image',
  'ico': 'fa-file-image',
  'jpeg': 'fa-file-image',
  'jpg': 'fa-file-image',
  'png': 'fa-file-image',
  'svg': 'fa-file-image'
}

export let imageIconMapping = {
  'ai': 'fa-file-image',
  'bmp': 'fa-file-image',
  'gif': 'fa-file-image',
  'ico': 'fa-file-image',
  'jpeg': 'fa-file-image',
  'jpg': 'fa-file-image',
  'png': 'fa-file-image',
  'svg': 'fa-file-image',
  'webp': 'fa-file-image'
}

export function isImageResource(src: string) {
  return (/(jpg|gif|png|jpeg|webp)$/.test(src?.toLowerCase()))
}

export function transformWriteOnlyFields(model, object) {
  if(model.child && model.child.isWriteOnly) {
    object[model.child.title+'_'] = object[model.child.title];
    object[model.child.title].forEach(el => {
      if(model.child.deleteField && el.id) {
        delete el[model.child.deleteField];
      }
      transformWriteOnlyFields(model.child, el);
    });
  }
}


export const socketCacheInvalidateRules = {
  records: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'parentRecords'}}, {cached: {param: 'id'}}]],
          listAttribute: true
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{}, {cached: {param: 'id'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {body: 'dependentEntities'}}]],
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{}, {cached: {param: 'id'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {body: 'dependentEntities'}}]],
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'checkpointsets'}}, {cached: {param: 'id'}}]],
          isValid: (cache, res) => (res.checkpointsets && res.checkpointsets.length),
          impacts: [
            {
              model: 'checkpoints',
              type: ['detail'],
              listAttribute: true,
              values: [[{ongoing: {body: 'checkpoints'}}, {cached: {param: 'id'}}]],
              impacts: [
                {
                  model: 'checkpointAttachments',
                  type: ['detail'],
                  listAttribute: true,
                  values: [[{ongoing: {body: 'checkpointAttachments'}}, {cached: {param: 'id'}}]]
                }
              ]
            }
          ]
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'originalCheckpointsets'}}, {cached: {param: 'id'}}]],
          isValid: (cache, res) => (res.originalCheckpointsets && res.originalCheckpointsets.length),
          impacts: [
            {
              model: 'checkpoints',
              type: ['detail'],
              listAttribute: true,
              values: [[{ongoing: {body: 'checkpoints'}}, {cached: {param: 'id'}}]],
              impacts: [
                {
                  model: 'checkpointAttachments',
                  type: ['detail'],
                  listAttribute: true,
                  values: [[{ongoing: {body: 'checkpointAttachments'}}, {cached: {param: 'id'}}]]
                }
              ]
            }
          ]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]],
        },
        {
          model: 'activities',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'activities'}}, {cached: {param: 'id'}}]],
          isValid: (cache, res) => (res.activities && res.activities.length)
        },
        {
          model: 'comments',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'comments',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'comments'}}, {cached: {param: 'id'}}]],
          isValid: (cache, res) => (res.comments && res.comments.length)
        },
        {
          model: 'attachments',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'attachments',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'attachments'}}, {cached: {param: 'id'}}]],
          isValid: (cache, res) => (res.attachments && res.attachments.length)
        }
      ]
    },
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'checkpointsets',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{}, {cached: {param: 'id'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {body: 'dependentEntities'}}]],
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  checkpoints : [
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleRecords'}}, {cached: {param: 'id'}}]],
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          values: [[{ongoing: {body: 'checkpointset'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]],
        }
      ]
    },
  ]
};

export const cacheInvalidateRules = {
  tags: [
    {
      methods: ['PUT', 'PATCH', 'POST'],
      impacts: [
        {
          model: 'tags',
          type: ['list'],
          values: [[{ongoing: {body: 'entity'}}, {cached: {queryParam: 'entity'}}]]
        },
        {
          model: 'entities',
          type: ['detail'],
          values: [[{ongoing: {body: 'entity'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'tags',
          type: ['list'],
          values: [[{ongoing: {body: 'entity'}}, {cached: {queryParam: 'entity'}}]]
        },
        {
          model: 'tags',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'entities',
          type: ['detail'],
          values: [[{ongoing: {body: 'entity'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  roles: [
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'roles',
          type: ['list'],
        },
        {
          model: 'roles',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'roles',
          type: ['list']
        }
      ]
    },
  ],

  views: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'views',
          type: ['list']
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'views',
          type: ['list']
        },
        {
          model: 'views',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  comments: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'comments',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        }
      ]
    },
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'comments',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'comments',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'activities',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {body: 'record'}}]]
        }
      ]
    },
  ],

  attachments: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'attachments',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'attachments',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'attachments',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'record'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'activities',
          type: ['detail'],
          values: [[{ongoing: {body: 'record'}}, {cached: {body: 'record'}}]]
        }
      ]
    },
  ],

  checkpointsets: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'checklistRecord'}}, {cached: {param: 'id'}}]]
        },
      ]
    },
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'checkpoints',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'checkpoints'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (req.body.hasOwnProperty('checkpoints_'))
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'checkpointsets',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'checklistRecord'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'checkpoints'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (res.body.checkpoints && res.body.checkpoints.length),
          impacts: [
            {
              model: 'checkpointAttachments',
              type: ['detail'],
              listAttribute: true,
              values: [[{ongoing: {body: 'checkpointAttachments'}}, {cached: {param: 'id'}}]]
            }
          ]
        }
      ]
    },
  ],

  checkpoints: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'checkpointsets',
          type: ['detail'],
          values: [[{ongoing: {body: 'checkpointset'}}, {cached: {param: 'id'}}]]
        },
      ]
    },
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'checkpointsets',
          type: ['detail'],
          values: [[{ongoing: {body: 'checkpointset'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (!req.body.hasOwnProperty('title'))
        }

      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          values: [[{ongoing: {body: 'checkpointset'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  checkpointAttachments: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'checkpoint'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'checkpoint'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'checkpointAttachments',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  records: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'records',
          type: ['list'],
          values: [[{ongoing: {body: 'checklistsEntity'}}, {cached: {queryParam: 'entity'}}],
            [{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]],
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'parentRecords'}}, {cached: {param: 'id'}}]],
          listAttribute: true
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'parentRecords'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'parentRecords'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleRecords'}}, {cached: {param: 'id'}}]],
        },
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {queryParam: 'entity'}}]],
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {body: 'dependentEntities'}}]],
        }
      ]
    },
    {
      methods: ['DELETE'],
      impacts: [
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'checkpointsets'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (res.body.checkpointsets && res.body.checkpointsets.length),
          impacts: [
            {
              model: 'checkpoints',
              type: ['detail'],
              listAttribute: true,
              values: [[{ongoing: {body: 'checkpoints'}}, {cached: {param: 'id'}}]],
              impacts: [
                {
                  model: 'checkpointAttachments',
                  type: ['detail'],
                  listAttribute: true,
                  values: [[{ongoing: {body: 'checkpointAttachments'}}, {cached: {param: 'id'}}]]
                }
              ]
            }
          ]
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'originalCheckpointsets'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (res.body.originalCheckpointsets && res.body.originalCheckpointsets.length),
          impacts: [
            {
              model: 'checkpoints',
              type: ['detail'],
              listAttribute: true,
              values: [[{ongoing: {body: 'checkpoints'}}, {cached: {param: 'id'}}]],
              impacts: [
                {
                  model: 'checkpointAttachments',
                  type: ['detail'],
                  listAttribute: true,
                  values: [[{ongoing: {body: 'checkpointAttachments'}}, {cached: {param: 'id'}}]]
                }
              ]
            }
          ]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {body: 'dependentEntities'}}]],
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]],
        },
        {
          model: 'activities',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'activities'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (res.body.activities && res.body.activities.length)
        },
        {
          model: 'comments',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'comments',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'comments'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (res.body.comments && res.body.comments.length)
        },
        {
          model: 'attachments',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'attachments',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'attachments'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (res.body.attachments && res.body.attachments.length)
        },
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleRecords'}}, {cached: {param: 'id'}}]],
        },
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {queryParam: 'entity'}}]],
        }
      ]
    },
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'checkpointsets',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'checkpointsets',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'originalCheckpointsets'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (req.body.originalCheckpointsets_)
        },
        {
          model: 'records',
          type: ['list'],
          values: [[{ongoing: {queryParam: 'entity'}}, {cached: {queryParam: 'entity'}}]],
          isValid: (cache, req, res) => (req.body.hasOwnProperty('isArchived'))
        },
        {
          model: 'choices',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]],
          isValid: (cache, req, res) => (
            _.some(_.keys(cache.cache), (url) => (
              parseUrl(url).model === 'choices' && (url.includes('record') || url.includes('field'))
            ))
          )
        },
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleRecords'}}, {cached: {param: 'id'}}]],
        },
        {
          model: 'records',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {queryParam: 'entity'}}]],
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'checklistsEntity'}}, {cached: {queryParam: 'entity'}}],
                  [{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]],
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {body: 'dependentEntities'}}]],
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  actions: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'checkpoints',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'checkpoints',
          type: ['detail'],
          values: [[{ongoing: {body: 'fixedCheckpoint'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'widgets',
          type: ['detail'],
          values: [[{}, {cached: {param: 'id'}}]]
        },
        {
          model: 'activities',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleRecords'}}, {cached: {param: 'id'}}]],
          listAttribute: true
        },
        {
          model: 'records',
          type: ['detail'],
          values: [[{ongoing: {body: 'staleEntities'}}, {cached: {queryParam: 'entity'}}]],
          listAttribute: true
        },
        {
          model: 'records',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {}]]
        },
        {
          model: 'attachments',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'record'}}]]
        },
        {
          model: 'attachments',
          type: ['detail'],
          listAttribute: true,
          values: [[{ongoing: {body: 'attachments'}}, {cached: {param: 'id'}}]],
          isValid: (cache, res) => (res.attachments && res.attachments.length)
        }
      ]
    },
  ],

  users: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'users',
          type: ['list']
        },
        {
          model: 'users',
          type: ['detail'],
          multi: true,
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'users',
          type: ['detail'],
          values: [[{ongoing: { body: 'id' }}, {cached: { param: 'id' }}]],
          isValid: (cache, req, res) => (!Array.isArray(res['body']))
        }
      ]
    },
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'users',
          type: ['list']
        },
        {
          model: 'users',
          type: ['detail'],
          values: [],
          isValid: (cache, req, res) => (req.body.hasOwnProperty('space'))
        }
      ]
    }
  ],

  notifications: [
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'notifications',
          type: ['list']
        },
        {
          model: 'notifications',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]],
          isValid: (cache, req, res) => (!Array.isArray(res.body))
        },
        {
          model: 'notifications',
          type: ['detail'],
          multi: true,
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        }
      ]
    },
  ],

  choices: [
    {
      methods: ['POST'],
      impacts: [
        {
          model: 'choices',
          type: ['list'],
          values: [[{ongoing: {body: 'field'}}, {cached: {queryParam: 'field'}}]],
          isValid: (cache, req, res) => (!Array.isArray(res.body))
        }
      ]
    },
    {
      model: 'choices',
      methods: ['DELETE'],
      impacts: [
        {
          model: 'choices',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        },
        {
          model: 'choices',
          type: ['list'],
          values: [[{ongoing: {body: 'field'}}, {cached: {queryParam: 'field'}}]]
        }
      ]
    }
  ],

  fields: [
    {
      methods: ['PUT', 'PATCH'],
      impacts: [
        {
          model: 'choices',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {queryParam: 'field'}}]]
        },
        {
          model: 'choices',
          type: ['detail'],
          values: [[{ongoing: {body: 'id'}}, {cached: {body: 'field'}}]]
        }
      ]
    }
  ],
  widgets: [
    {
      methods: ['POST'],
      impacts: [
        {
          model:'widgets',
          type: ['list'],
          values: [[{ongoing: {body: 'id'}}, {cached: {param: 'id'}}]]
        }
      ]
    }
  ]
};


export const fieldTypeRecord = ['OneToOne', 'InverseOneToOne', 'OneToMany', 'InverseOneToMany', 'ManyToMany', 'InverseManyToMany'];


// state

export function getMode(mode) {
  switch (mode) {
    case 'cards':
      return 'cards';
    case 'grid':
        return 'grid';
    case 'dashboard':
      return 'dashboard';
    case 'calendar':
      return 'calendar';
    case 'timeline':
      return 'timeline';
    default:
      return 'cards';
  }
}

export function mutateState(ctx, func) {
  ctx.setState(
      produce(ctx.getState(), draft => { func(draft); })
  );
}

export function getGroupByField(entity, groupByFieldName) {
  let groupByField = _.find(
    _.flatMap(entity.blocks, b => b.fields),
    field => field.name === groupByFieldName
  );

  if ((entity.sharedSpaces.length > 1 || _.some(entity.blocks, b => _.some(b.fields, field => field.type === 'Spaces')))
    && groupByFieldName === 'space') {
    groupByField = { name: 'space', type: 'Space' };
  }

  return groupByField;
}

export function getGroupByValue(record, groupByField) {
  const translocoLocaleService = AppInjector.get(TranslocoLocaleService);

  switch (groupByField.type) {
    case 'CreatedBy':
    case 'E-Signature':
    case 'User': {
      const userValue = groupByField.type === 'E-Signature' ? _.get(record, `value[${groupByField.id}].user`) : _.get(record, `value[${groupByField.id}]`);
      return {
        fieldValue: userValue ? userValue.fullName : translate('Non spécifié'),
        picture: userValue ? userValue.picture : null
      };
    }
    case 'SingleSelect': {
      return {
        fieldValue: _.get(record, `value[${groupByField.id}].title`, translate('Non spécifié')),
        picture: null
      };
    }
    case 'LastModifiedAt':
    case 'CreatedAt':
    case 'Date': {
      return {
        fieldValue: _.get(record, `value[${groupByField.id}]`)
          ? moment(new Date(_.get(record, `value[${groupByField.id}]`))).locale(translocoLocaleService.getLocale()).format('DD MMMM YYYY')
          : translate('Non spécifié'),
        picture: null
      };
    }
    case 'DateTime': {
      return {
        fieldValue: _.get(record, `value[${groupByField.id}]`)
          ? moment(new Date(_.get(record, `value[${groupByField.id}]`))).locale(translocoLocaleService.getLocale()).format('lll')
          : translate('Non spécifié'),
        picture: null
      };
    }
    case 'Checkbox': {
      return {
        fieldValue: _.get(record, `value[${groupByField.id}]`)
          ? translate('Oui')
          : (_.get(record, `value[${groupByField.id}]`) !== null ? translate('Non') : translate('Non spécifié')),
        picture: null
      };
    }
    case 'Space': {
      return {
        fieldValue: _.get(record, 'space.path')
          ? _.replace(_.get(record, 'space.path'), '>', '•')
          : translate('Non spécifié'),
        picture: null
      };
    }
    default: {
      return {
        fieldValue: _.get(record, `value[${groupByField.id}].str`, translate('Non spécifié')),
        picture: null
      };
    }
  }
}

export function getRecordsGroups(entity, records, groupByFieldName) {
  let result = [];
  const groupByField = getGroupByField(entity, groupByFieldName);
  let previousFieldKey;

  _.forEach(records, record => {
    const { fieldValue, picture } = getGroupByValue(record, groupByField);

    let currentFieldKey;
    if (_.includes(['Date', 'LastModifiedAt', 'CreatedAt'], groupByField.type)) {
      currentFieldKey = _.get(record, `value[${groupByField.id}]`, null)?.substr(0, 10);
    } else if (groupByField.type === 'DateTime') {
      currentFieldKey = _.get(record, `value[${groupByField.id}]`, null);
    } else if (groupByField.type === 'Space') {
      currentFieldKey = _.get(record, 'space.title', null);
    } else if (groupByField.type === 'E-Signature') {
      currentFieldKey = _.get(record, `value[${groupByField.id}].user.id`, null);
    } else {
      currentFieldKey = _.get(record, `value[${groupByField.id}].id`, null);
    }

    if (!previousFieldKey || currentFieldKey !== previousFieldKey) {
      result.push({
        id: result.length + 1,
        title: fieldValue,
        picture: picture,
        link: _.includes(['OneToOne', 'InverseOneToOne', 'OneToMany'], groupByField.type) && currentFieldKey ? `/record/${currentFieldKey}` : null,
      });
    }

    previousFieldKey = currentFieldKey;
  });

  result = _.uniqBy(result, 'title');
  return result;
}

export function groupRecords(entity, records, queryParams) {
  let result = [];
  let groupbyField = [].concat.apply([], entity.blocks.map(b => b.fields))
    .filter(field => field.name === queryParams['groupby'])[0];
  if((entity.sharedSpaces.length > 1 || entity.blocks.flatMap(b => b.fields.filter(field => field.type === 'Spaces')))
    && queryParams['groupby'] === 'space'){
    groupbyField = {name:'space', type:"Space"}
  }
  records = records.filter( record => record.id !== -1 );
  let fieldValue;
  let picture = null;
  let currentFieldKey;
  let previousFieldKey;
  let translocoLocaleService = AppInjector.get(TranslocoLocaleService);
  for (let i = 0; i < records.length; i++) {
    switch (groupbyField.type) {
      case 'CreatedBy':
      case 'E-Signature':
      case 'User': {
        const userValue = groupbyField.type === 'E-Signature' ? records[i].value[groupbyField.id].user : records[i].value[groupbyField.id];
        fieldValue = userValue ? userValue.fullName : translate('Non spécifié');
        picture = userValue ? userValue.picture : null;
        break;
      }
      case 'SingleSelect': {
        fieldValue = records[i].value[groupbyField.id] ? records[i].value[groupbyField.id].title : translate('Non spécifié');
        break;
      }
      case 'LastModifiedAt':
      case 'CreatedAt':
      case 'Date': {
        fieldValue = records[i].value[groupbyField.id] ? moment(new Date(records[i].value[groupbyField.id])).locale(translocoLocaleService.getLocale()).format("DD MMMM YYYY") : translate('Non spécifié');
        break;
      }
      case 'DateTime': {
        fieldValue = records[i].value[groupbyField.id] ? moment(new Date(records[i].value[groupbyField.id])).locale(translocoLocaleService.getLocale()).format("lll") : translate('Non spécifié');
        break;
      }
      case 'Checkbox': {
        fieldValue = records[i].value[groupbyField.id] ? translate('Oui') : (records[i].value[groupbyField.id] !== null ? translate('Non') : translate('Non spécifié'));
        break;
      }
      case 'Space':{
        fieldValue = records[i].space ? records[i].space.path.replaceAll('>','•') : translate('Non spécifié');
        break;
      }
      default: {
        fieldValue = records[i].value[groupbyField.id] ? records[i].value[groupbyField.id].str : translate('Non spécifié');
        break;
      }
    }
    previousFieldKey = currentFieldKey;
    if (["Date", "LastModifiedAt", "CreatedAt"].indexOf(groupbyField.type) !== -1){
      currentFieldKey = records[i].value[groupbyField.id] ? records[i].value[groupbyField.id].substr(0, 10) : null;
    } else if (groupbyField.type === "DateTime"){
      currentFieldKey = records[i].value[groupbyField.id] ? records[i].value[groupbyField.id] : null;
    } else if (groupbyField.type === 'Space'){
      currentFieldKey = records[i].space ? records[i].space.title : null;
    } else if (groupbyField.type === 'E-Signature'){
      currentFieldKey = records[i].value[groupbyField.id].user ? records[i].value[groupbyField.id].user.id : null;
    } else {
      currentFieldKey = records[i].value[groupbyField.id] ? records[i].value[groupbyField.id].id : null;
    }
    if (i === 0 || (i !== 0 && currentFieldKey !== previousFieldKey)) {
      result.push({
        id: -1,
        title: fieldValue,
        picture: picture,
        link: ['OneToOne', 'InverseOneToOne', 'OneToMany'].includes(groupbyField.type) && currentFieldKey ? '/record/' + currentFieldKey : null
      });
    }
    result.push(records[i]);
  }
  return result;
}

export function hasGroupby(entity, field) {
  let fields = _.flatten(_.map(entity.blocks, 'fields'));
  let targetField = _.find(fields, f => f.id == field.id);
  return targetField.hasGroupby;
}

export function markdownToElement(input:string){
  const wrapper = document.createElement('div');
   wrapper.innerHTML = DOMPurify.sanitize(marked(input, {breaks: true, gfm: true}));
   wrapper.querySelectorAll('a').forEach((anchorElement) => {
     anchorElement.target = "_blank";
     anchorElement.rel = "noopener noreferrer";
     anchorElement.setAttribute("onclick", "event.stopPropagation();");
   })
   wrapper.querySelectorAll('p').forEach((pElement) => {
       pElement.classList.add("mb-0")
   })
  return wrapper;
}

function closestDivisorOfNumber(number, target) {
    let closestDivisor = number;
    let minDifference = Math.abs(number - target);
    for (let divisor = target+1; divisor <= number; divisor++) {
        if (number % divisor === 0) {
            const difference = Math.abs(divisor - target);
            if (difference < minDifference) {
                minDifference = difference;
                closestDivisor = divisor;
            }
        }
    }
    return closestDivisor;
}

// multi-columns cards automation
export function computeColumns(cols: number, totalColumns: number = 12): Array<object> {
  const columns = {};
  const breakpoints = ['xs', 'sm', 'md', 'lg'];
  for (let i = 1; i <= totalColumns; i++) {
    columns[i] = breakpoints.map(view => {
      let colSpan;
      let colMd = closestDivisorOfNumber(totalColumns, i);
      let colSm = closestDivisorOfNumber(totalColumns, colMd);
      let colXs = closestDivisorOfNumber(totalColumns, closestDivisorOfNumber(totalColumns, colSm));
      switch(view) {
        case 'xs':
          colSpan = colXs;
          break;
        case 'sm':
          colSpan = colSm;
          break;
        case 'md':
          colSpan = (i == 2 && totalColumns == 10) ? 10 : colMd;
          break;
        case 'lg':
          colSpan = i;
          break;
        default:
          colSpan = Math.ceil(totalColumns / i);
      }
      return { view, col: colSpan };
    });
  }

  let target = columns[cols];
  if (target) return target;
  return columns[totalColumns];
}

export function currentDeviceViewPort(): string {
  const width = window.innerWidth;
  if (width >= 992) return 'lg';
  if (width >= 768) return 'md';
  if (width >= 576) return 'sm';
  return 'xs';
}

export function computeGridColumnsClasses(columns: Array<object>): string {
  let classes = '';
  const uniqColumns = _.uniqBy(columns, function (e) {
    return e['col'];
  });
  for (let columnKey in uniqColumns){
      classes += " g-col-" + uniqColumns[columnKey]['view'] + "-" + uniqColumns[columnKey]['col'];
  }
  return classes.replace('col-xs', 'col').trim();
}

export function computeColumnsClasses(columns: Array<object>): string {
  let classes = '';
  const uniqColumns = _.uniqBy(columns, function (e) {
    return e['col'];
  });
  for (let columnKey in uniqColumns){
      classes += " g-col-" + uniqColumns[columnKey]['view'] + "-" + uniqColumns[columnKey]['col'];
  }
  return classes.replace('col-xs', 'col').trim();
}

export function computePageSize(cols: number, height: number): number {
  const displayHeight = window.innerHeight ? window.innerHeight : window.screen.height;
  const computedCols = computeColumns(cols);
  const currentViewPort = currentDeviceViewPort();
  const currentCols = _.find(computedCols, _.matchesProperty('view', currentViewPort))['col'];
  try {
    return Math.floor((Math.floor(displayHeight / height) * 12 / currentCols) / (12 / currentCols)) * (12 / currentCols) + (12 / currentCols);
  } catch (ex){
    return currentCols * 18;
  }
}

export const checklistObjects = {
  checkpoints: {
    name: 'checkpoints',
    service: 'checkpointService',
    parent: 'checkpointset',
    parentInRecord: 'originalCheckpointsets',
    propagations: ['checkpointAttachments','tags'],
    parentService: 'checkpointsetService'
  },
  checkpointsets: {
    name: 'originalCheckpointsets',
    service: 'checkpointsetService',
    propagations: ['checkpoints.checkpointAttachments','checkpoints.tags'],
    parent: 'checklistRecord',
    parentService: 'recordService'
  }
};

export function stripBaseUrl(url) {
  return url.split('/').slice(3).join('/');
}

export const locationToQueryParams = (location) => {
  let qpobject = {}
  if (location.split("?")[1]){
    location.split("?")[1].split("&").forEach((e) => {
      if (qpobject[e.split("=")[0]]){
        if (typeof qpobject[e.split("=")[0]] === 'string' || qpobject[e.split("=")[0]] instanceof String){
          qpobject[e.split("=")[0]] = [qpobject[e.split("=")[0]]]
        }
        qpobject[e.split("=")[0]].push(e.split("=")[1])
      } else {
        qpobject[e.split("=")[0]] = e.split("=")[1]
      }
    })
  }
  return qpobject;
}

export function setTabTitle(title: string) {
  const setTitle = (t: string) => {
    const prefix = ['prod', 'on-premise'].includes(AppInjector.get(EnvService).env) ? '' : '🟢 ';
    AppInjector.get(Title).setTitle(prefix + t);
  };

  setTitle(title);
}

export function setBrowserTabTitle(page: string) {
  const store = AppInjector.get(Store);
  const smartTitle: SpecialTitleCasePipe = new SpecialTitleCasePipe();
  firstValueFrom(store.select((state) => state.app.recordPage.records).pipe(
    delay(200), filter((records) => _.last(_.values(records))?.record != null))).then((records) => {
    if (page == 'record') {
      records = store.selectSnapshot((state) => state.app.recordPage.records);
      const record = _.last(_.values(records))?.record;
      if (record?.str) {
        const tabTitle = record.str === 'None'
          ? smartTitle.transform(
            record.entity?.page?.pageset?.title
              ? `${record.entity.page.pageset.title} > ${record.entity.title}`
              : record.entity?.title
          )
          : record.str;
        setTabTitle(tabTitle);
      }
    }
  })
  firstValueFrom(store.select(state => state.app.sider?.user).pipe(
    filter((user) => user?.space?.title != null))).then((user)=>{
      const spaceTitle = user.space.title;
      if (spaceTitle) {
        switch (page) {
          case 'administration':
          case 'dashboard':
          case 'home':
          case 'notifications':
          case 'preferences':
            setTabTitle(smartTitle.transform(page) + ' - ' + spaceTitle);
            break;
          case 'releases':
            setTabTitle(translate('Quoi de neuf ?') + ' - ' + spaceTitle);
            break;
          case 'record':
            break;
          case 'records':
            store.select(state => state.app.recordsPage?.entity?.plural).subscribe(tabTitle => {
              if (tabTitle) {
                setTabTitle(smartTitle.transform(tabTitle) + ' - ' + spaceTitle);
              }
            })
            break;
          default:
            setTabTitle(spaceTitle);
        }
      }
  })
}

export function applyApplicationStyle(style){
  for( let selector in style){
    if (style[selector] instanceof Array){
      for(let item of style[selector] ){
        let classes = item.split(' ')
        if (classes[0] === '-'){
          for (let element of classes.slice(1))
            try {
              document.querySelectorAll(selector).forEach( e => {e.classList.remove(element)})
            } catch (error){

            }
        }
        else
        {
          for (let element of classes.slice(1))
            try {
              document.querySelectorAll(selector).forEach( e => {e.classList.add(element)})
            } catch (error){
            }
        }
      }
    }
    else {
      let classes = style[selector].split(' ')
      if (classes[0] === '-'){
        for (let element of classes.slice(1))
          try {
            document.querySelectorAll(selector).forEach( e => {e.classList.remove(element)})
          } catch (error){
          }
      }
      else
      {
        for (let element of classes.slice(1))
          try {
            document.querySelectorAll(selector).forEach( e => {e.classList.add(element)})
          } catch (error){
          }
      }
    }

  }

}

export function capturePhoto(videoElement) {
  const canvas = document.createElement('canvas');
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;

  const context = canvas.getContext('2d');
  context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL('image/png');
}

export function startCamera(videoElement) {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        stream['stop'] = function () {
          stream.getTracks().forEach((track) => {
            if (track.readyState == 'live') {
              track.stop();
            }
          });
        };
        videoElement.srcObject = stream;
        videoElement.play();
      })
      .catch(error => {
      });
  }
}

export function stopCamera(stream) {
  if (stream && stream.hasOwnProperty('stop') ) {
    stream.stop();
  }
}

export function  isWindowsTablet() {
  const isWindows = navigator.userAgent.indexOf('Windows') > -1;
  const maxTouchPoints = navigator.maxTouchPoints ;
  const isTouchable = 'ontouchstart' in window
    || maxTouchPoints > 0
    || window.matchMedia && matchMedia('(any-pointer: coarse)').matches;
  return isWindows && isTouchable;
}

export function isTouchScreen() {
  return 'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    window.matchMedia("(pointer: coarse)").matches
}

export function updateQueryParams(router, queryParams) {
  const qp = {};
  for (const param of queryParams) {
    const { field: { name }, values } = param;
    qp[name] = (values && values.length !== 0) ? (values.length === 1 ? values[0] : values) : null;
  }

  router.navigate([], {
    queryParams: qp,
    queryParamsHandling: 'merge',
    replaceUrl: true,
  });
}

export function deepCompare(obj1, obj2) {
  const obj1_ = _.size(obj1) > _.size(obj2) ? _.cloneDeep(obj1) : _.cloneDeep(obj2);
  const obj2_ = _.size(obj1) > _.size(obj2) ? _.cloneDeep(obj2) : _.cloneDeep(obj1);
  return _.reduce(obj1_, function(result, value, key) {
    return (obj2_ && key in obj2_ && _.isEqual(value, obj2_[key])) ?
      result : result.concat(key);
  }, []);
}

export function dateFormat(date) {
  if (!date) {
    return '';
  }
  const currentYear = new Date().getFullYear();
  if (moment(date).format('YYYY-MM-DD').split('-')[0] === currentYear.toString()){
    return moment(date).format('DD MMM');
  } else {
    return moment(date).format('DD MMM YYYY');
  }
}

export function openDetailPage(evt, actionCallback = null){
  let router = AppInjector.get(Router);
  let modalProxyService = AppInjector.get(ModalProxyService);
  let recordId = evt?.recordId;
  let openModal = evt.openModal;
  let modalForMultipleRecords = evt?.modalForMultipleRecords;
  if (!openModal){
    router.navigate(['/record/' + recordId]).then(() => {
      if (typeof actionCallback === 'function') {
        actionCallback();
      }
    });
  } else {
    if(modalForMultipleRecords){
      const {entityId, recordIds} = evt;
      modalProxyService.openMultiRecordsModal(entityId, recordIds);
    }else{
      modalProxyService.openRecordModal(recordId);
    }

  }
}

export function fakeEveryOne() {
  return {
    id: -1,
    fullName: translate('Tout le monde'),
    picture: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAIAAAAlC+aJAAAAZElEQVR4nO3PMQ0AIQDAQMC/RkYkML8IhssnPQXt3OeOP1s64FUDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oDWgNaA1oD2gfRlQM6G1uSPgAAAABJRU5ErkJggg=='
  }
}
