import { Injectable, ErrorHandler as AngularErrorHandler, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Router} from "@angular/router";
import * as _ from 'lodash';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private isPopStateEvent: boolean = false;

  constructor(private router: Router,private injector: Injector) {
    window.addEventListener('popstate', () => {
      this.isPopStateEvent = true;
    });
  }

  private isRecordRequest(request: HttpRequest<any>): boolean {
    const currentUrl = this.router.url;
    if (!currentUrl.includes('record')) return false;

    const [url, queryParams] = currentUrl.split('?');
    const id = _.last(url.split('/'));
    return (request.url.includes('/api/records/' + id) || request.url.includes('/api/entities' + id));
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const globalErrorHandler = this.injector.get(AngularErrorHandler);
        if (request.method === 'GET' && err.status === 404 && this.isRecordRequest(request)) {
          const urlTree = this.router.parseUrl(this.router.url);
          const auxiliary = urlTree.root.children['modal'];
          if (auxiliary) {
            this.router.navigate([{outlets: {modal: null}}], {
              queryParamsHandling: 'merge',
            });
          }
          if (this.isPopStateEvent && !auxiliary) {
            window.history.back();
          }
          if(!this.isPopStateEvent && !auxiliary) {
            this.router.navigateByUrl('/404', {
              skipLocationChange: true
            });
          }
          this.isPopStateEvent = false;
          globalErrorHandler.handleError(err);
          return throwError(() => err);
        }
        if (err.url.includes('connected_user/') && ([404, 429, 500].includes(err.status))) {
          return throwError(() => err);
        }
        globalErrorHandler.handleError(err);
        return throwError(() => err);
      })
    );
   }
}
