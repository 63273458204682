import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Inject,
  ChangeDetectorRef, ChangeDetectionStrategy,
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import domtoimage from 'dom-to-image';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DoughnutLabel from '../../classes/doughnut-label'
import {saveAs} from 'file-saver';
import {LocalService} from "src/app/services/local.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Chart} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

declare var bootstrap: any;
declare var $: any;

@Component({
  selector: 'widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetComponent implements OnInit, OnChanges {
  @Input() widget;
  @Input() panelIsFullScreen: boolean;
  @Input() dashboardEntityId: number | undefined;
  @Input() record;
  @Input() hasCard = true;
  @Output() filtersChange = new EventEmitter();
  @Output() editWidget = new EventEmitter();
  @Output() public widgetClick = new EventEmitter();

  @ViewChild('widgetRef') widgetRef;
  @ViewChild('canvas') element;
  @ViewChild('gauge') gauge;
  @ViewChild('carouselElement', { static: false }) carouselElement!: ElementRef;

  output = {};
  options = ''
  readFormData = '';
  readFormRawData = {};
  formModal;
  fullScreenModal;
  public barChartPlugins = [ChartDataLabels, DoughnutLabel];
  widgetIsFullScreen = false;

  theme = this.localService.getTheme();
  computedStyle = getComputedStyle(document.body);

  constructor(
    public localService: LocalService,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    Chart.register(annotationPlugin);
    Chart.defaults.font.size = 15;

    this.readFormData = this.widget.selectedReadForm ? _.cloneDeep(this.widget.selectedReadForm) : '';
    this.output['read_form_data'] = this.readFormData;

    //evaluate functions
    let widgetClone = _.cloneDeep(this.widget);
    this.evaluateFunctions(widgetClone.options);
    this.widget = widgetClone;
    // take options from widget.option if not exist in read_function
    function OptionCustomizer(objValue, srcValue) {
      if (_.isObject(objValue)) {
        return _.mergeWith(objValue, srcValue, OptionCustomizer);
      }
      return srcValue;
    }
    // Merge widget options with dynamic options from read function
    const options = _.mergeWith({}, widgetClone.options, widgetClone.data?.options, OptionCustomizer);
    this.options = this.adaptScalesColors(_.merge(this.getDefaultOptions(), options));
    this.localService.theme$.subscribe((val) => {
      this.theme = val;
      this.options = this.adaptScalesColors(_.merge(this.getDefaultOptions(), options));
      this.changeDetector.detectChanges()
    })
  }

  ngAfterViewInit() {
    if (this.carouselElement) {
      const myCarouselElement = this.carouselElement.nativeElement;
      const carousel = new bootstrap.Carousel(myCarouselElement, {
        interval: 8000,
        ride: 'carousel'
      });
    }

    if (this.element) {
      Chart.defaults.font.size = Math.min(Math.max(12, Math.round(this.element.nativeElement.width / 70)), 16)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let widgetClone = _.cloneDeep(this.widget);
    this.evaluateFunctions(widgetClone.options);
    this.widget = widgetClone;
    const options = _.mergeWith({}, widgetClone.options, widgetClone.data?.options);
    this.options = this.adaptScalesColors(_.merge(this.getDefaultOptions(), options));
  }

  colors = ['#4fc9da', '#e8c444', '#b8d935', '#f06445', '#4F55DA', '#0094b6', '#666666', '#d84280', '#795548', '#9370db', '#3e9623', '#40A497', '#7f8c8d', '#f1c40f',
    '#9b59b6', '#c0392b', '#34495e', '#3498db', '#130f40', '#c7ecee', '#7ed6df', '#e84393', '#fab1a0', '#00b894', '#0984e3', '#fd79a8', '#e1b12c', '#3742fa', '#2f3542',
    '#ff6348', '#eccc68', '#7bed9f', '#ffa502', '#70a1ff', '#2ed573', '#3742fa', '#3742fa', '#A3CB38', '#12CBC4', '#6F1E51', '#FDA7DF', '#B53471', '#006266', '#FFC312',
    '#833471', '#833471', '#009432', '#EA2027', '#12CBC4']
  hoverColors = ['#30A6B6', '#CFAB2A', '#9FBE22', '#DB5437', '#383EBC', '#06738b', '#666666', '#9c1a50', '#503830', '#8459da', '#2f8016']

  getDefaultOptions() {
    //TO DO change defaultOptions
    const compThis = this;

    let defaultOptions = {
      backgroundColor: this.colors,
      ...(this.widget.type === 'doughnut' && {borderColor: this.theme == 'light' ? '#fff' : '#1E1E2D'}),
      animation: this.localService.getEnv() != 'test' ? true : false,
      scales: {},
      hover: {
        mode: null
      },
      elements: {
        bar: {
          borderRadius: 10
        }
      },
      responsive: true,
      plugins: {
        datalabels: {
          display: false,
          font: function(context) {
            return {
              size: Math.min(Math.max(Math.round((context.chart.width/context.dataset.data.length)/5),11), 16)
            };
          },
        },
        legend: {
          labels: {
            font: function(context) {
              return {
                size: Math.min(Math.max(12, Math.round(context.chart.width / 65)), 16)
            };
            },
            usePointStyle: true,
          },
        },
        responsive: true,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false,
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            titleFont: {
              size: 15,
              weight: 'bolder',
            },
            bodyFont: {
              size: 15,
              weight: 'bolder',
            },
            borderWidth: 0,
            boxPadding: 3,
            cornerRadius: 5,
            multiKeyBackground: '#00000000',
            boxWidth: 20,
            boxHeight: 20,
            callbacks: {
              labelColor: function (context) {
                if (context.element.options.backgroundColor.charAt(0) == '#') {
                  return {
                    backgroundColor: context.element.options.backgroundColor,
                    borderWidth: 0,
                    borderRadius: 10,
                  }
                } else {
                  return {backgroundColor: '#fff', borderWidth: 0, borderRadius: 10,}
                }
              },
            }
          },
        }
      },
      onClick: function(evt) {
        const actionData = compThis.extractActionDataFromClickedWidget(this, evt);
        if(actionData && actionData['entityId'] && actionData['recordIds']) {
          compThis.onWidgetClick(actionData.entityId, actionData.recordIds);
        }
      },
      onHover: function(evt){
        const actionData = compThis.extractActionDataFromClickedWidget(this, evt);
        if(actionData && actionData['entityId'] && actionData['recordIds']) {
          evt.native.target.style.cursor = "pointer";
        }else{
          evt.native.target.style.cursor = "default";
        }
      }
    };

    if (this.widget.type === "radar") {
        defaultOptions.scales = {
          r:{
          angleLines: {
              color: this.computedStyle.getPropertyValue('--bs-gray-300'),
          },
        }
      }
    }

    return defaultOptions;
  }

  extractActionDataFromClickedWidget(widget, evt): {entityId: number, recordIds: number[]} | undefined {
    let points = widget.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, false);
    if(points.length > 0){
      const firstPoint = points[0]
      const datasetIndex = firstPoint.datasetIndex;
      const dataIndex = firstPoint.index;
      if(this.widget.data?.actions && this.widget.data.actions.length > 0){
        const actionData = this.widget.data.actions[datasetIndex][dataIndex];
        return actionData;
      }
    }
  }


  onWidgetClick(entityId: number, recordIds: number []){
    if(recordIds.length === 1){
      this.widgetClick.emit({recordId: recordIds[0], openModal: true})
    }else{
      this.widgetClick.emit({modalForMultipleRecords: true, entityId, recordIds,  openModal: true})
    }

  }

  onMetricWidgetClick(evt){
    if(this.widget.data?.actions){
      this.onWidgetClick(this.widget.data.actions['entityId'] , this.widget.data.actions['recordIds'])
    }
  }

  onTableWidgetClick(evt){
    const cell = evt.target.closest('td');
    if (!cell) {return;}

    if (cell.dataset['entity'] && cell.dataset['actions']) {
      const recordIds = cell.dataset.actions.split(',');
      const entityId = Number(cell.dataset.entity);
      this.onWidgetClick(entityId, recordIds)
    };
  }

  onTableWidgetHover(evt){
    const cell = evt.target.closest('td');
    if (!cell) {return;}
    if (cell.dataset['entity'] && cell.dataset['actions']) {
      cell.style.cursor = 'pointer';
    };
  }

  evaluateFunctions(obj): void {
    var func;
    for (var k in obj) {
      if (typeof obj[k] == "object" && obj[k] !== null) {
        this.evaluateFunctions(obj[k]);
      } else if (typeof obj[k] == "string" && obj[k].startsWith("function")) {
        let val = obj[k];
        val = "func =" + val;
        // tslint:disable-next-line:no-eval
        eval(val);
        obj[k] = func;
      }
    }
  }

  applyFilter(evt){
    this.output['read_form_data'] = this.readFormData = JSON.stringify(evt.data);
    this.readFormRawData = evt.rawData;
    let event = {
      id: this.widget.id,
      output: this.output
    }
    this.filtersChange.emit(event);
    this.closeModal();
  }

  writeWidget(evt) {
    this.enableWriteFormBtnLoader();
    let event = {
      widget: this,
      widgetId: this.widget.id,
      writeFormData: evt.data,
      readFormData: this.output['read_form_data']
    }
    this.editWidget.emit(event)
  }

  clearFilters() {
    delete this.output['read_form_data'];
    this.readFormData = '';
    this.readFormRawData = {};
    let event = {
      id: this.widget.id,
      output: this.output
    }
    this.filtersChange.emit(event);
  }

  filtersActive(){
    return !!this.readFormData.length
  }

  openModal(widgetFormRef, fullScreen=false) {
    if (fullScreen) {
      this.fullScreenModal = this.modalService.open(widgetFormRef, {size: 'xl', centered: true, animation: true, fullscreen: true})
      this.widgetIsFullScreen = true;
      this.fullScreenModal.dismissed.subscribe(() => {
        this.widgetIsFullScreen = false;
        this.changeDetector.detectChanges();
      });
    } else {
      this.formModal = this.modalService.open(widgetFormRef, {size: 'lg'});
      if (this.panelIsFullScreen) {
        document.getElementById('pageDetail').appendChild(document.querySelector('ngb-modal-backdrop'));
        document.getElementById('pageDetail').appendChild(document.querySelector('ngb-modal-window'));
      }
    }
  }

  closeModal(){
    this.formModal.close();
  }

  enableWriteFormBtnLoader(){
    const btn = document.querySelector("#applyWrite");
    btn.setAttribute("data-kt-indicator", "on");
    btn.setAttribute("disabled","true")
  }

  disableWriteFormBtnLoader(){
    const btn = document.querySelector("#applyWrite");
    btn.setAttribute("data-kt-indicator", "off");
    btn.removeAttribute("disabled")
  }

  adaptScalesColors(options) {
    let optionsClone = _.cloneDeep(options);
    for (const [key, value] of Object.entries(options.scales)) {
      if (value["grid"]?.display != false) {
        optionsClone["scales"][key]["grid"] = {
          ...optionsClone["scales"][key]["grid"],
          color: this.computedStyle.getPropertyValue('--bs-gray-300'),
          borderColor: this.computedStyle.getPropertyValue('--bs-gray-400')
        }
      }
      optionsClone["scales"][key]["ticks"] = {
        ...optionsClone["scales"][key]["ticks"],
        color: this.computedStyle.getPropertyValue('--bs-gray-700'),
      }
    }
    return optionsClone
  }
}
