// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-field {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 500;
  line-height: 1.2;
  color: #211f1c;
  position: relative;
  right: 10px;
  width: calc(100% + 10px);
  padding-left: 10px !important;
  border: none;
  resize: none;
  min-height: auto;
  overflow: hidden;
  display: table;
}

.main-field:focus {
  border: inherit;
  background-color: inherit;
  box-shadow: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/components/field-main/field-main.component.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,wBAAA;EACA,6BAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EACA,yBAAA;EACA,mBAAA;AAEF","sourcesContent":[".main-field {\n  font-size: calc(1.3rem + .6vw);\n  font-weight: 500;\n  line-height: 1.2;\n  color: #211f1c;\n  position: relative;\n  right: 10px;\n  width: calc(100% + 10px);\n  padding-left: 10px !important;\n  border: none;\n  resize: none;\n  min-height: auto;\n  overflow: hidden;\n  display: table;\n}\n.main-field:focus {\n  border: inherit;\n  background-color: inherit;\n  box-shadow: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
