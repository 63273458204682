// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-11 {
  margin-top: 5rem !important;
}

.logo-container {
  text-align: center;
  margin-bottom: 5rem !important;
}

.mr-1 {
  margin-right: 10px;
}

.top-25 {
  top: 25% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AAGA;EACI,kBAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ","sourcesContent":[".mt-11 {\n    margin-top: 5rem !important;\n}\n\n\n.logo-container {\n    text-align: center;\n    margin-bottom: 5rem !important;\n}\n\n.mr-1 {\n    margin-right: 10px;\n}\n\n.top-25 {\n    top: 25% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
