// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.track {
  position: absolute;
  transform: translateY(-50%);
}

.range-container {
  position: relative;
}

.range-track {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
}

.value-display {
  position: absolute;
  top: -30px;
}

.value {
  transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/app/components/evaluation-color/evaluation-color.component.scss"],"names":[],"mappings":"AAAC;EACG,kBAAA;EACA,2BAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;AACJ;;AAEE;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEE;EACE,kBAAA;EACA,UAAA;AACJ;;AAEE;EACE,2BAAA;AACJ","sourcesContent":[" .track {\n    position: absolute;\n    transform: translateY(-50%);\n  }\n  \n  .range-container {\n    position: relative;\n  }\n  \n  .range-track {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n  \n  .handle {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    border-radius: 50%;\n    cursor: pointer;\n  }\n  \n  .value-display {\n    position: absolute;\n    top: -30px; \n  }\n  \n  .value {\n    transform: translateX(-50%);\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
