import { State, Action } from '@ngxs/store';
import { SendResetPage } from './app.actions';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';

@State({
    name: 'sendResetPage'
})
@Injectable()
export class SendResetPageState {

    constructor(private userService: UserService) {}

    // Send Reset
    @Action(SendResetPage.SendReset)
    sendReset(ctx, { email }) {
      return this.userService.sendReset(email);
    }
}