import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { containsFile } from '../app.utils';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public http = null;
  public envService = null;
  key = null;

  constructor(public injector: Injector) {
    this.http = this.injector.get(HttpClient);
    this.envService = this.injector.get(EnvService);
  }

  private isMany(x) {
    return Array.isArray(x);
  }

  private convertIsoDates(obj) {
    function checkAndConvert(object: object) {
      for (const key in object) {
        const isoDateReg = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
        if (typeof obj[key] === 'string' && isoDateReg.test(obj[key])) {
          obj[key] = new Date(obj[key]);
        }
      }
    }

    checkAndConvert(obj);
    if ('value' in obj) {
      checkAndConvert(obj.value);
    }
    return obj;
  }

  protected fieldToService(key) {
    const mapping = {
      entity: 'EntityService',
      actions: 'ActionService',
      users: 'UserService',
      author: 'UserService',
      actor: 'UserService',
      mentions: 'UserService',
      records: 'RecordService',
      record: 'RecordService',
      spaces: 'SpaceService',
      spaceChildren: 'SpaceService',
      accessibleSpaces: 'SpaceService',
      sharedSpaces: 'SpaceService',
      space: 'SpaceService',
      currentRoles: 'RoleService',
      spaceRoles: 'RoleService',
      roles: 'RoleService',
      tags: 'TagService',
      checkpoints: 'CheckpointService',
      comments: 'CommentService',
      datalake: 'DatalakeService',
      attachments: 'AttachmentService',
      checkpointAttachments: 'CheckpointAttachmentsService',
      fixedCheckpoint: 'CheckpointService',
      fixingEntities: 'EntityService',
      fixingRecords: 'RecordService',
      choices: 'ChoiceService',
      activities: 'ActivityService',
      originalCheckpoints: 'CheckpointService',
      checklistsRecords: 'RecordService',
      pageset: 'PagesetService',
      originalCheckpointsets: 'CheckpointsetService',
      checkpointsets: 'CheckpointsetService',
      checkpointset: 'CheckpointsetService',
      workspace: 'WorkspaceService',
      widgets: 'WidgetService',
      ssoGateway:'SsoGatewayService'
    };
    let service = mapping[key];
    return this.injector.get(service);
  }

  private objToFormData(obj) {
    const formData = new FormData();
    for (const k in obj) {
      if (obj[k]) { formData.append(k, obj[k]); }
    }
    return formData;
  }

  private preWrite(obj) {
    obj = _.cloneDeep(obj);
    for (const key in obj) {
      if (!key.endsWith('_') && this.isMany(obj[key]) && obj[key] && obj[key].length && obj[key][0].id) {
        obj[key] = obj[key].map(x => x.id);
      } else if (!this.isMany(obj[key]) && obj[key] && obj[key].id) {
        obj[key] = obj[key].id;
      }
    }
    if (obj.value) {
      for (const key in obj.value) {
        if (key.endsWith('_')) delete obj.value[key]
        if (obj.value[key] && obj.value[key].id) {
          obj.value[key] = obj.value[key].id;
        } else if (obj.value[key] instanceof Array && obj.value[key].length && obj.value[key][0].id) {
          obj.value[key] = obj.value[key].map(x => x.id);
        }
      }
    }
    return obj;
  }

  private postRead(obs$, propagations = []) {
    return obs$.pipe(
      map(obj => {
        if (Array.isArray(obj)) {
          let objects = [];
          for (let o of obj) {
            o = this.transform(o);
            o = this.convertIsoDates(o);
            objects.push(o);
          }
          return objects;
        }
        obj = this.transform(obj);
        obj = this.convertIsoDates(obj);
        return obj;
      }),
      mergeMap(obj => {
        if (Array.isArray(obj)) {
          let objects = [];
          for (let o of obj) {
            o = _.cloneDeep(o);
            const observables$ = propagations.map(propagation => this.propagate(o, propagation));
            objects.push(!observables$.length ? of(o) : forkJoin(observables$).pipe(map(objects => o)));
          }
          return forkJoin(objects);
        }
        obj = _.cloneDeep(obj);
        const observables$ = propagations.map(propagation => this.propagate(obj, propagation));
        return !observables$.length ? of(obj) : forkJoin(observables$).pipe(map(objects => obj));
      }));
  }

  public propagate(obj, propagation) {
    const first = propagation.startsWith('[') ? propagation : propagation.split('.')[0];
    const tail = propagation.startsWith('[') ? [] : [propagation.split('.').splice(1, propagation.split('.').length).join('.')].filter((f) => f !== '');
    // cover OR case for propagation
    if(first.startsWith('[')) {
      let propagations = first.split('[')[1].split(']')[0].split(',');
      const observables$ = propagations.map(propagation =>  this.propagate(obj, propagation));
      return !observables$.length ? of(obj) : forkJoin(observables$).pipe(map(objects => obj));
    }
    if (first === 'value') {
      return this.propagateRecordValue(obj, tail);
    }
    const service = this.fieldToService(first);
    if (this.isMany(obj[first])) {
      const observables$ = obj[first].map(id => service.retrieveObject(id, tail));
      if (!observables$.length) {
        obj[first] = [];
        return of(obj);
      }
      return forkJoin(observables$).pipe(mergeMap(objects => {
        obj[first] = objects;
        return of(obj);
      }));
    } else {
      const forbidden = /{{FORBIDDEN}}$/i;
      if (forbidden.test(obj[first])){
        return of(obj);
      }
      return service.retrieveObject(obj[first], tail).pipe(map(res => {
        obj[first] = res;
        return obj;
      }));
    }
  }

  private propagateRecordValue(record, propagations) {
    let fieldIds = propagations.length ? propagations[0].split(',').filter(id => !!id && id !== 'null').map(id => +id) : [];
    return this.fieldToService('entity').retrieveDetailMetadata(record.entity, []).pipe(mergeMap((entity: any) => {
      const observables$ = [];
      const forbidden = /{{FORBIDDEN}}$/i;
      if (!fieldIds.length) {
        for (const block of entity.blocks) {
          if (!block.isTabbed || block == entity.blocks[0]) {
            for (const field of block.fields) {
              let service = null;
              if (['OneToOne', 'InverseOneToOne', 'OneToMany'].indexOf(field.type) > -1) {  // isMany = false
                if (!(typeof record.value[field.id] === 'string' && forbidden.test(record.value[field.id]))){
                  service = this;
                }
              } else if (['User', 'CreatedBy'].indexOf(field.type) > -1) {
                service = this.fieldToService('users');
              } else if (field.type === 'Users') {
                const observable$ = of(record.value[field.id]).pipe(map((ids: number[]) =>
                  ids.map(id => this.fieldToService('users').retrieveObject(id))),
                  mergeMap((users$: any) => users$.length ? forkJoin(users$).pipe(tap((users: any) =>
                    record.value[field.id] = users)) : of([]).pipe(tap((users: any) => record.value[field.id] = users)))
                );
                observables$.push(observable$);
              }
              else if (field.type === 'MultiSelect') {
                const observable$ = of(record.value[field.id]).pipe(map((ids: number[]) =>
                  ids.map(id => this.fieldToService('choices').retrieveObject(id))),
                  mergeMap((choices$: any) => choices$.length ? forkJoin(choices$).pipe(tap((choices: any) =>
                    record.value[field.id] =choices)) : of([]).pipe(tap((choices: any) => record.value[field.id] =choices)))
                );
                observables$.push(observable$);
              }
              else if (field.type === 'SingleSelect') {
                service = this.fieldToService('choices');
              }
              else if (field.type === 'Space') {
                service = this.fieldToService('spaces');
              }
              else if(field.type === 'Spaces'){
                const observable$ = of(record.value[field.id]).pipe(map((ids: number[]) =>
                    ids.map(id => this.fieldToService('spaces').retrieveObject(id))),
                  mergeMap((spaces: any) => spaces.length ? forkJoin(spaces).pipe(tap((spaces: any) =>
                    record.value[field.id] = spaces)) : of([]).pipe(tap((spaces: any) => record.value[field.id] =spaces)))
                );
                observables$.push(observable$);
              }
              else if(field.type === 'E-Signature') {
                const observable$ = this.fieldToService('users').retrieveObject(record.value[field.id].user).pipe(tap((user: any) =>
                  record.value[field.id].user = user))
                observables$.push(observable$);
              }
              if (service) {
                const observable$ = service.retrieveObject(record.value[field.id]).pipe(tap(relatedRecord =>
                    record.value[field.id] = relatedRecord
                  ));
                  observables$.push(observable$);
              }
            }
          }
        }
      }
      else {
        for (const block of entity.blocks) {
          for (const field of block.fields) {
            if (fieldIds.indexOf(field.id) > -1) {
              let service = null;
              if (['OneToOne', 'InverseOneToOne', 'OneToMany'].indexOf(field.type) > -1) {  // isMany = false
                if (!(typeof record.value[field.id] === 'string' && forbidden.test(record.value[field.id]))){
                  service = this;
                }
              } else if (['ManyToMany', 'InverseManyToMany', 'InverseOneToMany'].indexOf(field.type) > -1) {
                const observable$ = of(record.value[field.id]).pipe(map((ids: number[]) =>
                  ids.map(id => this.retrieveObject(id))),
                  mergeMap((records$: any) => records$.length ? forkJoin(records$).pipe(tap((records: any) =>
                    record.value[field.id] = records)) : of([]).pipe(tap((records: any) => record.value[field.id] = records)))
                );
                observables$.push(observable$);
              } else if (['User', 'CreatedBy'].indexOf(field.type) > -1) {
                service = this.fieldToService('users');
              } else if (field.type == 'Users') {
                const observable$ = of(record.value[field.id]).pipe(map((ids: number[]) =>
                  ids.map(id => this.fieldToService('users').retrieveObject(id))),
                  mergeMap((users$: any) => users$.length ? forkJoin(users$).pipe(tap((users: any) =>
                    record.value[field.id] = users)) : of([]).pipe(tap((users: any) => record.value[field.id] = users)))
                );
                observables$.push(observable$);
              } else if (field.type === 'SingleSelect') {
                service = this.fieldToService('choices')
              } else if (field.type === 'MultiSelect') {
                const observable$ = of(record.value[field.id]).pipe(map((ids: number[]) =>
                  ids.map(id => this.fieldToService('choices').retrieveObject(id))),
                  mergeMap((choices$: any) => choices$.length ? forkJoin(choices$).pipe(tap((choices: any) =>
                    record.value[field.id] =choices)) : of([]).pipe(tap((choices: any) => record.value[field.id] =choices)))
                );
                observables$.push(observable$);
              }
              if (service) {
                const observable$ = service.retrieveObject(record.value[field.id]).pipe(tap(relatedRecord =>
                    record.value[field.id] = relatedRecord
                  ));
                  observables$.push(observable$);
              }
            }
          }
        }
      }
      if (!observables$.length) {
        return of(record);
      }
      return forkJoin(observables$).pipe(map(res => record));
    }));
  }

  private retrieveIds(params: object = {}) {
    const url = this.envService.apiEndpoint + this.key + '/';
    return this.http.get(url, { params })
  }


  protected transform(object) {
    return object;
  }


  createObject(obj: object, propagations: string[] = []) {
    obj = this.preWrite(obj);
    if (containsFile(obj)) {
      obj = this.objToFormData(obj);
    }
    const url = this.envService.apiEndpoint + this.key + '/';
    return this.postRead(this.http.post(url, obj), propagations);
  }

  destroyObject(id: number) {
    const url = this.envService.apiEndpoint + this.key + '/' + id + '/';
    return this.http.delete(url);
  }

  retrieveObject(id, propagations: string[] = [], params: {} = {}) {
    if (!id || id.id) {  // null or already propagated
      return of(id);
    }
    const url = this.envService.apiEndpoint + this.key + '/' + id + '/';
    return this.postRead(this.http.get(url, {params}), propagations);
  }

  retrieveObjects(params: object, propagations: string[] = [], bulk = false) {
    if (bulk) {
      return this.retrieveIds(params)
    }
    return this.retrieveIds(params).pipe(map((ids: number[]) => ids.map(id => this.retrieveObject(id, propagations))));
  }

  updateObject(obj, propagations: string[] = [], partial: boolean = true) {
    let data = this.preWrite(obj);
    if (containsFile(obj)) {
      data = this.objToFormData(obj);
    }
    const httpMethod = partial ? 'patch' : 'put';
    const url = this.envService.apiEndpoint + this.key + '/' + obj.id + '/';
    return this.postRead(this.http[httpMethod](url, data), propagations);
  }

  updateObjects(body: object = {} ,params: object = {}, partial: boolean = true) {
    const url = this.envService.apiEndpoint + this.key + '/';
    const httpMethod = partial ? 'patch' : 'put'
    return this.http[httpMethod](url, {...body}, {params})
  }

}
