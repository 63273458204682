import {Directive, ElementRef, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import Viewer from 'viewerjs/dist/viewer.js';

@Directive({
  selector: '[gallery]'
})
export class GalleryDirective implements OnChanges {

  private length;
  private viewer;
  private _parent;
  private delete;
  private initialIndex;
  private _viewerTitle;

  @Input() showDeletePicture = true;

  @Input() set parent(value) {
    this._parent = value;
  }

  @Input() set viewerTitle(value) {
    this._viewerTitle = value;
  }

  @Input() set gallery(value) {
    this.length = value;
  }

  @Output() public deletePicture = new EventEmitter();

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes): void {
    if (changes.gallery?.previousValue < changes.gallery?.currentValue) {
      this.delete = false;
    }
    setTimeout(() => {
      this.init()
    }, 0)
  }

  init() {
    if (this.viewer) {
      this.viewer.destroy();
    }
    const options = {
      title: !this._viewerTitle ? 0 : () => {
        return this._viewerTitle
      },
      toolbar: {
        zoomIn: 4,
        zoomOut: 4,
        oneToOne: 4,
        reset: 4,
        prev: 4,
        play: {
          show: 4,
          size: 'large',
        },
        next: 4,
        rotateLeft: 4,
        rotateRight: 4,
        flipHorizontal: 4,
        flipVertical: 4,
      }
    };
    if (this.showDeletePicture) {
      options.toolbar['trash'] = (e) => {
        this.delete = true;
        this.initialIndex = this.viewer.index;
        this.deletePicture.emit({src: this.viewer.image.currentSrc, parent: this._parent});
        this.viewer.hide();
      };
    }
    this.viewer = new Viewer(this.el.nativeElement, options);
    if (this.viewer.index > 0) {
      this.viewer.index = this.initialIndex - 1;
    }
    if (this.delete && this.viewer.images.length) {
      this.viewer.show(true);
    }
  }
}
