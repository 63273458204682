import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {translate} from "@jsverse/transloco"
@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationComponent implements OnInit {

  @Input() body;
  @Input() btnIcon;
  @Input() btnClass;
  @Input() btnMessage;

  @Output() confirmationSubmit = new EventEmitter();
  @Output() confirmationClose = new EventEmitter();

  DEFAULT_MESSAGE: string = translate('Oui');
  DEFAULT_CANCEL_MESSAGE: string = translate('Annuler');

  constructor() { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.confirmationSubmit.emit();
  }

  onCancel() {
    this.confirmationClose.emit();
  }
}
