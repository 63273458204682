// #docplaster
import { Injectable, Inject } from '@angular/core';
import {
    HttpEvent, HttpRequest, HttpResponse,
    HttpInterceptor, HttpHandler
} from '@angular/common/http';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import {tap, share, delay, catchError, finalize} from 'rxjs/operators';
import { Cache } from '../classes/cache';
import { parseUrl, cacheInvalidateRules } from '../app.utils';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


let clearImpacted = (cache, req, res) => {
    const parsed = parseUrl(req.urlWithParams);

    if (!cacheInvalidateRules[parsed.model]) return;

    for (let rule of cacheInvalidateRules[parsed.model]) {
        if (rule.methods.includes(req.method)) {
            for (let impact of rule.impacts) {
                if (impact.isValid && !impact.isValid(cache, req, res)) continue;
                if (impact.type.includes('list')) {
                    if (!impact.values) {
                        cache.clearList(impact.model);
                    } else {
                        cache.clearContains(impact.model, impact.values, res.body);
                    }
                }

                if (impact.type.includes('detail')) {
                    if (parsed.model === 'records' && req.method === 'PATCH' && req.url.endsWith('/records/') && req.body.hasOwnProperty('isArchived')) {
                      continue;
                    }
                    if (impact.model.includes('widgets') && parsed.model !== 'records') {
                        let appId = localStorage.getItem("space");
                        let cachedWidgetList = cache.getContains(impact.model, [{queryParam: "space", value: appId}]);

                        if (cachedWidgetList.length){
                            cachedWidgetList.forEach((widget) => {
                                cache.clearContains(impact.model, impact.values, widget.id);
                            })
                        } else {
                            cache.clearList(impact.model);
                        }
                    } else if (impact.multi && Array.isArray(res.body)) {
                        for (let id of res.body) {
                            cache.clearContains(impact.model, impact.values, id);
                        }
                    } else if(impact.listAttribute && (res.body.length || res.body.hasOwnProperty('staleRecords'))) {
                        let ids = res.body[impact.values[0][0].ongoing.body];
                        cache.clearListAttribute(impact, ids);
                    } else {
                        cache.clearContains(impact.model, impact.values, res.body);
                    }
                }

            }
        }
    }
}

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(@Inject('Cache') private cache: Cache, private router: Router, @Inject('Queue') private queue: Cache, private location: Location) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // writing
      if (req.method == 'POST' || req.method == 'DELETE' || req.method == 'PUT' || req.method == 'PATCH') {
        return next.handle(req).pipe(
          tap((event) => {
            if (event instanceof HttpResponse) {
              clearImpacted(this.cache, req, event);
              if (event.body) {
                if (req.method == 'PUT' || req.method == 'PATCH') {
                  this.cache.put(req, event)
                }
                if (req.method == 'POST') {
                  this.cache.putWithUrl(req, event)
                }
              }
            }
          }),
          finalize(() => {
            if (req.url.includes("/login/")){
              this.cache.clearList('connected_user');
            }
          }));
      }
        // reading
        if (req.method == 'GET') {
            // return cached object if exists
            // of(cachedResponse).pipe(delay(0)) means there's a loader when performing a GET on cached entries
            const cachedResponse = this.cache.get(req) || null;
            if (cachedResponse) {
                if (this.router.url.includes("/records/") || this.router.url.includes("/home") ){
                    if (this.location.path().split("/")[1] === "record"){
                        if (this.location.path().split("/")[3]){
                            return of(cachedResponse)
                        }
                    }
                    return of(cachedResponse).pipe(delay(0));
                }
                else if (this.router.url.includes("/record/") && this.router.url !== this.location.path()){
                    if (this.location.path().split("/")[3]){
                        return of(cachedResponse)
                    }
                    return of(cachedResponse).pipe(delay(0));
                }
                return of(cachedResponse)
            }

            const pendingResponse = this.queue.get(req) || null;
            if (pendingResponse) {
                return pendingResponse;
            }

            if (!(req.url.includes('/entities/') && req.urlWithParams.includes('expand=')) &&
              !req.url.includes('/choices/') && !req.url.includes('/users/') && !req.url.includes('/records/')) {
              req = req.clone({
                headers: req.headers.set('Cache-Control', 'no-cache')
                  .set('Pragma', 'no-cache')
              });
            } else {
              req = req.clone({
                headers: req.headers.set('Cache-Control', 'max-age=0')
              });
            }
            const obs = next.handle(req).pipe(tap(event => {
              if (event instanceof HttpResponse) {
                if (req.url.includes('/entities/')) {
                  let url;
                  this.cache.put(req, event);
                  const expands = req.params.get('expand') ? req.params.get('expand').split(',') : '';
                  if (expands.includes('actions')) {
                    for (const action of event.body.actions) {
                      url = req.url.split('api')[0] + 'api/actions/' + action.id + '/';
                      this.cache.putWithUrlStr(url, event.clone({url, body: action}));
                    }
                  }
                  if (expands.includes('extensions')) {
                    for (const extension of event.body.extensions) {
                      url = req.url.split('api')[0] + 'api/extensions/' + extension.id + '/';
                      this.cache.putWithUrlStr(url, event.clone({url, body: extension}));
                    }
                  }
                  if (expands.includes('tags')) {
                    for (const tag of event.body.tags) {
                      url = req.url.split('api')[0] + 'api/tags/' + tag.id + '/';
                      this.cache.putWithUrlStr(url, event.clone({url, body: tag}));
                    }
                  }
                  if (expands.includes('pageset')) {
                    const pageset = event.body.pageset;
                    if (pageset && typeof pageset === 'object') {
                      url = req.url.split('api')[0] + 'api/pagesets/' + pageset.id + '/';
                      this.cache.putWithUrlStr(url, event.clone({url, body: pageset}));
                    }
                  }
                  if (expands.includes('creation_form')) {
                    const creationForm = event.body.creationForm;
                    if (creationForm && typeof creationForm === 'object') {
                      url = req.url.split('api')[0] + 'api/forms/' + creationForm.id + '/';
                      this.cache.putWithUrlStr(url, event.clone({url, body: creationForm}));
                    }
                  }
                }
                this.cache.put(req, event);
                this.queue.clear(req.urlWithParams);
              }
            }), share());
            this.queue.put(req, obs);
            return obs;
        }

        return next.handle(req);

    }
}

