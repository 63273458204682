import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/state/app.state';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultComponent implements OnInit {

  @Input() isModal = false;
  @Select(AppState.sider) sider$;

  constructor(
    public localService: LocalService
  ) { }

  ngOnInit(): void {
    this.localService.setTheme(this.localService.getTheme())
  }

}
