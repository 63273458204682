import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldValueToClass'
})
export class FieldValueToClassPipe implements PipeTransform {

  transform(value: any, dict /*, record, entity */): any {
    for(let key in dict){
      let is_interval = (key[0]=='[');
      if (is_interval) {
        let endpoints = key.substring(1, key.length-1)
       
        let lower_endpoint:any = endpoints.split(':')[0]
        lower_endpoint = parseInt(lower_endpoint)
        
        let upper_endpoint :any = endpoints.split(':')[1]
        upper_endpoint = parseInt(upper_endpoint)
        let in_interval = (parseInt(value)>=lower_endpoint || isNaN(lower_endpoint))  && (parseInt(value)<=upper_endpoint || isNaN(upper_endpoint));
        if (in_interval) {
          return dict[key]
        } 
      } else {
        if (key==value || key=='*') {
          return dict[key]
        }
      }
        
    }
    return ''
  }

}
