import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService {
  key = 'activities';
  constructor(public injector: Injector) { super(injector); }
}
