import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPageTitle'
})
export class GetPageTitlePipe implements PipeTransform {

  transform(page): string {
    if (page.entity){
      return page.entity.plural;
    }
    if (page.record){
     return page.record.entity.hasStr ?  page.record.str : page.record.entity.plural;
    }
  }
}
