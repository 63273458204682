import { State, Selector,createSelector } from '@ngxs/store';
import {Injectable} from "@angular/core";
import { RecordPageState } from './record-page.state';
import { NotificationsPageState } from './notifications-page.state';
import { AdministrationPageState } from './administration-page.state';
import { PreferencesPageState } from './preferences-page.state';
import { RecordsPageState } from './records-page.state';

import {SiderState} from "./sider.state";
import { HomePageState } from './home-page.state';
import { LoginPageState } from './login-page.state';
import { ResetPageState } from './reset-page.state';
import { SendResetPageState } from './send-reset-page.state';
import {ReleasesPageState} from "./releases-page.state";

@State({
    name: 'app',
    children: [RecordsPageState, NotificationsPageState, RecordPageState, AdministrationPageState, PreferencesPageState, HomePageState, LoginPageState, ResetPageState, SendResetPageState, ReleasesPageState,SiderState]
})
@Injectable()
export class AppState {


    @Selector()
    static homePage(state) {
        return state.homePage;
    }
    @Selector()
    static recordPage(params: any) {
        return createSelector([RecordPageState], (state: RecordPageState) => {
          return state['app'].recordPage.getRecord(params);
        });
      }
    @Selector()
    static recordsPage(state) {
        return state.recordsPage;
    }
    @Selector()
    static administrationPage(state){
        return state.administrationPage;
    }
    @Selector()
    static notificationsPage(state) {
        return state.notificationsPage;
    }
    @Selector()
    static preferencesPage(state) {
        return state.preferencesPage;
    }

    @Selector()
    static releasesPage(state) {
        return state.releasesPage;
    }

    @Selector()
    static sider(state) {
        return state.sider;
    }
}
