import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  OnInit, ChangeDetectionStrategy
} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateStruct, NgbInputDatepicker, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import {translate} from "@jsverse/transloco"

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements AfterViewInit, OnChanges, OnInit {

  /*
  inputs:
    value: depends on mode
      - Single: one Date/null
      - Multiple: array of  Dates/null
      - Range: array of two Dates/null
    - isIso: make output ISOStrings
  output:
    dateSelect: same format as input
  */

  @Input() mode: 'Single' | 'Multiple' | 'Range' | 'RangePopup' = 'Single';
  @Input() isIso = false;
  @Input() isMandatory = true;
  @Input() isForm = false;
  @Input() hasTime = false;
  @Input() seconds = false;
  @Input() focus = false;
  @Input() help;
  @Input() name;
  @Output() datepickerDateSelect = new EventEmitter();
  @Output() datepickerDateTimeSelect = new EventEmitter();

  @ViewChild('dpRef') dpRef;

  public dateModel: NgbDateStruct;
  public timeModel: NgbTimeStruct;

  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  private _value;

  readonly D_FORMAT = 'DD/MM/YYYY';
  queryValue: any;
  timePeriods = ["--", "Ce(tte)", "Dernier/Dernière", "Prochain(e)"];
  timeUnits = ["--", "Jour", "Semaine", "Mois", "Trimestre", "Année"];
  timePeriod;
  timeUnit;
  selectedStartTimeOrPeriod = ["--","À partir de"];
  selectedEndTimeOrUnit = ["--","Jusqu'à"];

  get value() {
    return this._value;
  }

  @Input() set value(value) {
    if(value) {
      switch (this.mode) {
        case 'Single':
          this._value = this.hasTime ? this.toNgbDateTime(value) : this.toNgbDate(value);
          break;
        case 'Multiple':
          this._value = value;
          break;
        case 'Range':
        case 'RangePopup':
          const v0 = this.toNgbDate(value[0]);
          const v1 = this.toNgbDate(value[1]);
          if (this.timePeriods.includes(value[0])){
            this.timeUnit = value[1]
            this.timePeriod =  value[0]
            this.updateFilterDate(value[0])
          } else {
            if (this.fromDate !== v0) {
              this.fromDate = v0;
            }
            if (this.toDate !== v1) {
              this.toDate = v1;
            }
          }
          break;
      }
    }
  }

  constructor(private calendar: NgbCalendar, private route: ActivatedRoute) {}
  ngOnInit(){
    const queryParams = this.route.snapshot.queryParams;
    this.queryValue = queryParams[this.name]
    if (this.queryValue && this.timePeriods.includes(this.queryValue[0])){
      this.selectedStartTimeOrPeriod[0] = this.queryValue[0];
      this.selectedEndTimeOrUnit[0] = this.queryValue[1];
      this.selectedStartTimeOrPeriod[1] = this.queryValue[2] ? this.queryValue[2] : this.selectedStartTimeOrPeriod[1];
      this.selectedEndTimeOrUnit[1] = this.queryValue[3] ? this.queryValue[3] : this.selectedEndTimeOrUnit[1];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus && !changes.focus.firstChange && this.focus && this.dpRef && this.isForm) {
      this.dpRef.open();
    }
    if (changes.focus && !changes.focus.firstChange && !this.focus && this.dpRef && this.isForm) {
      this.dpRef.close();
    }
  }

  ngAfterViewInit(): void {
    if (this.focus && this.isForm && this.dpRef) {
      this.dpRef.open();
    }
  }

  private toNgbDate(date: Date | string | null) {
    if (date) {
      date = (date instanceof Date) ? date : new Date(date);
      return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    } else {
      return null;
    }
  }

  private toNgbDateTime(date: Date | string | null){
    if (!date) {
      this.dateModel = null;
      this.timeModel = null;
      return null;
    } else {
      date = (date instanceof Date) ? date : new Date(date);
      this.dateModel = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      this.timeModel = {hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds()};
      return date;
    }
  }

  private toNativeDate(ngbDate: NgbDate) {
    const currentDate = new Date();
    let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    if (this.isIso) { return date.toISOString(); }
    return date;
  }

  private toNativeDateTime() {
    if (this.dateModel && this.timeModel){
      const date = new Date(this.dateModel.year, this.dateModel.month - 1, this.dateModel.day, this.timeModel.hour, this.timeModel.minute, this.timeModel.second);
      return this.isIso ? date.toISOString() : date;
    }
    return null;
  }

  // single
  public onSingleDateSelection(date) {
    if (this.isForm) {
      this.value = new Date(date.year, date.month - 1, date.day);
      this.dpRef.close();
    }
    this.datepickerDateSelect.emit(this.toNativeDate(date));
  }

  public onSingleDateTimeSelection() {
    if (this.timeModel && this.dateModel) {
      this.value = new Date(this.dateModel.year, this.dateModel.month - 1, this.dateModel.day, this.timeModel.hour, this.timeModel.minute, this.timeModel.second);
    }
    this.datepickerDateTimeSelect.emit(this.toNativeDateTime());
  }

  public onRemoveDate(event) {
    this.datepickerDateSelect.emit(null);
  }

  public getFormattedSingleValue(): string | null {
    if (this.hasTime){
      return this.value ? moment(this.toNativeDateTime()).format('L') + ' ' + moment(this.toNativeDateTime()).format('LT') : null;
    }
    return this.value ? moment(this.toNativeDate(this.value)).format('l') : null;
  }

  // multiple
  public isIn(date) {
    if (!this.value) { return false; }
    for (let v of this.value) {
      v = this.toNgbDate(v);
      if (date.year === v.year && date.month === v.month && date.day === v.day) { return true; }
    }
    return false;
  }

  public onMultipleDateSelection(date) {
    if (!this.value) { this.value = []; }
    if (!this.isIn(date)) {
      this.value.push(this.toNativeDate(date));
    } else {
      this.value = this.value.filter(v =>
        (this.toNgbDate(v).year !== date.year || this.toNgbDate(v).month !== date.month || this.toNgbDate(v).day !== date.day));
    }
    this.datepickerDateSelect.emit(this.value);
  }

  public getFormattedMultipleValue(): string | null {
    return this.value ? this.value.map(v => moment(v).format(this.D_FORMAT)).join(', ') : null;
  }

  // range
  public isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public onRangeDateSelection(date: NgbDate) {
    if (this.fromDate && this.toDate) {
      this.fromDate = date;
      this.toDate = null;
    } else if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else if (this.fromDate && !this.toDate) {
      if (this.fromDate == date) {
        this.fromDate = date;
        this.toDate = date;
      }
      else {
        this.toDate = null;
        this.fromDate = date;
      }
    }
    if (this.fromDate && this.toDate) {
      this._value = [new Date(this.fromDate.year, this.fromDate.month-1, this.fromDate.day).toISOString(), new Date(this.toDate.year, this.toDate.month-1, this.toDate.day, 23).toISOString()]
      this.datepickerDateSelect.emit(this.value);
    }
  }

  // highlight today
  isToday(date: NgbDate) {
    return date.equals(this.toNgbDate(new Date()));
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, this.D_FORMAT);
      return { day: mdt.date(), month: mdt.month() + 1, year: mdt.year() };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) { return ''; }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) { return ''; }
    return mdt.format(this.D_FORMAT);
  }

  validateInput(input: string): NgbDate | null {
    const parsed = this.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
  }

  handleRangePopupInput(input: string) {
    if (!input.trim()) {
      this.fromDate = null;
      this.toDate = null;
      this.datepickerDateSelect.emit([]);
      return;
    }
    const dates = input.split('-').map(date => date.trim());

    if (dates.length === 1) {
      this.fromDate = this.validateInput(dates[0]);
      this.toDate = null;
      this.datepickerDateSelect.emit([]);
      return;
    }

    if (dates.length === 2) {
      // set fromDate
      this.fromDate = this.validateInput(dates[0]);
      // set toDate
      const validatedToDateInput = this.validateInput(dates[1]);
      this.toDate = validatedToDateInput?.after(this.fromDate) || validatedToDateInput?.equals(this.fromDate) ? validatedToDateInput : null;
      // if fromDate and toDate are valid then trigger event with date values
      if (this.fromDate && this.toDate) {
        this.datepickerDateSelect.emit([new Date(this.fromDate.year, this.fromDate.month-1, this.fromDate.day).toISOString(), new Date(this.toDate.year, this.toDate.month-1, this.toDate.day, 23).toISOString()]);
      // fromDate OR/AND toDate are not valid
      } else {
        this.fromDate = null;
        this.toDate = null;
        this.datepickerDateSelect.emit([]);
      }
      return;
    }
    this.fromDate = null;
    this.toDate = null;
    this.datepickerDateSelect.emit([]);

  }

  public openDatePicker(event, dpRef) {
    if (!dpRef.isOpen()){
      dpRef.open();
    }
  }

  onNow() {
    this.value = new Date();
    if (this.isForm){
      this.datepickerDateTimeSelect.emit(this.toNativeDateTime());
    }
  }

  selectFilter(filter: string, isStartTimeOrPeriod: boolean): void {
    const selectedArray = isStartTimeOrPeriod ? this.selectedStartTimeOrPeriod : this.selectedEndTimeOrUnit ;
    if (this.timeModel && !filter) {
        selectedArray[1] = `${this.timeModel.hour}:${this.timeModel.minute}`;
    }
    filter = filter ? filter : selectedArray[0];
    selectedArray[0] = filter;
    this.onFilter(filter);
}

  private onFilter(filter: string): void {
    if (filter == '--'){
      this.datepickerDateSelect.emit();
    } else {
      this.updateFilterDate(filter)
      if(this._value){
        this.datepickerDateSelect.emit(this._value);
      }
    }
  }

  updateFilterDate(filter){
    if(this.timeUnits.includes(filter)) {
      this.timeUnit = filter
    }
    this.timePeriods.forEach((val)=>{
      if (filter == val){
        this.timePeriod = val
      }
      if (this.timeUnit){
        switch (this.timeUnit) {
          case 'Jour':
            this.getDay(filter);
            break;
          case 'Semaine':
            this.getWeek(filter);
            break;
          case 'Mois':
            this.getMonth(filter);
            break;
          case 'Trimestre':
            this.getQuarter(filter);
            break;
          case 'Année':
            this.getYear(filter);
            break;
        }
      }
    })
    if (this.timePeriod && this.timeUnit){
      if (this.selectedStartTimeOrPeriod[1] !== "À partir de"  || this.selectedEndTimeOrUnit[1] !== "Jusqu'à") {
        this._value = [this.timePeriod , this.timeUnit,  this.selectedStartTimeOrPeriod[1], this.selectedEndTimeOrUnit[1]];
      } else {
        this._value = [this.timePeriod , this.timeUnit];
      }
    }
  }

  onDateTimeSubmit() {
    if (!this.toNativeDateTime()){
      return;
    }
    this.datepickerDateTimeSelect.emit(this.toNativeDateTime());
  }

  onRemoveDateTime() {
    this.datepickerDateTimeSelect.emit(null);
  }

  getMonth(filter){
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1)
    // Current Month
    if (filter == 'Ce(tte)' && this.timeUnit == 'Mois'){
      this.fromDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.toDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    } else if (filter =='Dernier/Dernière' && this.timeUnit == 'Mois'){
    // Previous Month
    const previousDate = new Date(currentDate);
    previousDate.setMonth(currentDate.getMonth() - 1);
    this.fromDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth()-1, 1);
    this.toDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() , 0);
    } else if (filter == 'Prochain(e)' && this.timeUnit == 'Mois'){
    //Next Month
    const nextDate = new Date(currentDate);
    nextDate.setMonth(currentDate.getMonth() + 1);
    this.fromDate = new NgbDate(nextDate.getFullYear(), nextDate.getMonth(), 1);
    this.toDate = new NgbDate(nextDate.getFullYear(), nextDate.getMonth() + 1, 0);
    }
  }

  getWeek(filter){
    const currentDate = new Date();
    const firstDayOfCurrentWeek = new Date(currentDate);
    const lastDayOfCurrentWeek = new Date(currentDate);
    firstDayOfCurrentWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);
    lastDayOfCurrentWeek.setDate(currentDate.getDate() + (7 - currentDate.getDay()));
    if (filter == 'Ce(tte)' && this.timeUnit == 'Semaine'){
      this.fromDate = new NgbDate(firstDayOfCurrentWeek.getFullYear(), firstDayOfCurrentWeek.getMonth() + 1, firstDayOfCurrentWeek.getDate());
      this.toDate = new NgbDate(lastDayOfCurrentWeek.getFullYear(), lastDayOfCurrentWeek.getMonth() + 1, lastDayOfCurrentWeek.getDate());

    } else if (filter =='Dernier/Dernière' && this.timeUnit == 'Semaine'){
       // Get the first day of the previous week
      const firstDayOfPreviousWeek = new Date(firstDayOfCurrentWeek);
      firstDayOfPreviousWeek.setDate(firstDayOfCurrentWeek.getDate() - 7);
      this.fromDate = new NgbDate(firstDayOfPreviousWeek.getFullYear(), firstDayOfPreviousWeek.getMonth() + 1, firstDayOfPreviousWeek.getDate());
      // Get the last day of the previous week
      const lastDayOfPreviousWeek = new Date(lastDayOfCurrentWeek);
      lastDayOfPreviousWeek.setDate(lastDayOfCurrentWeek.getDate() - 7);

      this.toDate = new NgbDate(lastDayOfPreviousWeek.getFullYear(), lastDayOfPreviousWeek.getMonth() + 1, lastDayOfPreviousWeek.getDate());
    } else if (filter == 'Prochain(e)' && this.timeUnit == 'Semaine'){
    // Get the first day of the next week
    const firstDayOfNextWeek = new Date(firstDayOfCurrentWeek);
    firstDayOfNextWeek.setDate(firstDayOfCurrentWeek.getDate() + 7);
    this.fromDate = new NgbDate(firstDayOfNextWeek.getFullYear(), firstDayOfNextWeek.getMonth() + 1, firstDayOfNextWeek.getDate());

    // Get the last day of the next week
    const lastDayOfNextWeek = new Date(lastDayOfCurrentWeek);
    lastDayOfNextWeek.setDate(lastDayOfCurrentWeek.getDate() + 7);
    this.toDate = new NgbDate(lastDayOfNextWeek.getFullYear(), lastDayOfNextWeek.getMonth() + 1, lastDayOfNextWeek.getDate());

    }
    }

  getDay(filter) {
    const currentDate = new Date();
    if (filter == 'Ce(tte)' && this.timeUnit == 'Jour') {
      this.fromDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
      this.toDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    } else if (filter == 'Dernier/Dernière' && this.timeUnit == 'Jour') {
      const previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - 1);
      this.fromDate = new NgbDate(previousDate.getFullYear(), previousDate.getMonth() + 1, previousDate.getDate());
    } else if (filter == 'Prochain(e)' && this.timeUnit == 'Jour') {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      this.fromDate = new NgbDate(nextDate.getFullYear(), nextDate.getMonth() + 1, nextDate.getDate());
      this.toDate = new NgbDate(nextDate.getFullYear(), nextDate.getMonth() + 1, nextDate.getDate());
    }
  }

  getYear(filter) {
    const currentDate = new Date();
    if (filter == 'Ce(tte)' && this.timeUnit == 'Année') {
      this.fromDate = new NgbDate(currentDate.getFullYear(), 1, 1);
      this.toDate = new NgbDate(currentDate.getFullYear(), 12, 31);
    } else if (filter == 'Dernier/Dernière' && this.timeUnit == 'Année') {
      const previousDate = new Date(currentDate);
      previousDate.setFullYear(currentDate.getFullYear() - 1);
      this.fromDate = new NgbDate(previousDate.getFullYear(), 1, 1);
      this.toDate = new NgbDate(previousDate.getFullYear(), 12, 31);
    } else if (filter == 'Prochain(e)' && this.timeUnit == 'Année') {
      const nextDate = new Date(currentDate);
      nextDate.setFullYear(currentDate.getFullYear() + 1);
      this.fromDate = new NgbDate(nextDate.getFullYear(), 1, 1);
      this.toDate = new NgbDate(nextDate.getFullYear(), 12, 31);
    }
  }

  getQuarter(filter) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    if (filter == 'Ce(tte)' && this.timeUnit == 'Trimestre') {
      const quarterStartDate = new Date(currentDate.getFullYear(), Math.floor((currentMonth - 1) / 3) * 3, 1);
      const quarterEndDate = new Date(currentDate.getFullYear(), Math.floor((currentMonth - 1) / 3) * 3 + 3, 0);

      this.fromDate = new NgbDate(quarterStartDate.getFullYear(), quarterStartDate.getMonth() + 1, quarterStartDate.getDate());
      this.toDate = new NgbDate(quarterEndDate.getFullYear(), quarterEndDate.getMonth() + 1, quarterEndDate.getDate());
    } else if (filter == 'Dernier/Dernière' && this.timeUnit == 'Trimestre') {
      const previousDate = new Date(currentDate);
      previousDate.setMonth(currentDate.getMonth() - 3);
      const previousMonth = previousDate.getMonth() + 1;

      const quarterStartDate = new Date(previousDate.getFullYear(), Math.floor((previousMonth - 1) / 3) * 3, 1);
      const quarterEndDate = new Date(previousDate.getFullYear(), Math.floor((previousMonth - 1) / 3) * 3 + 3, 0);

      this.fromDate = new NgbDate(quarterStartDate.getFullYear(), quarterStartDate.getMonth() + 1, quarterStartDate.getDate());
      this.toDate = new NgbDate(quarterEndDate.getFullYear(), quarterEndDate.getMonth() + 1, quarterEndDate.getDate());
    } else if (filter == 'Prochain(e)' && this.timeUnit == 'Trimestre') {
      const nextDate = new Date(currentDate);
      nextDate.setMonth(currentDate.getMonth() + 3);
      const nextMonth = nextDate.getMonth() + 1;

      const quarterStartDate = new Date(nextDate.getFullYear(), Math.floor((nextMonth - 1) / 3) * 3, 1);
      const quarterEndDate = new Date(nextDate.getFullYear(), Math.floor((nextMonth - 1) / 3) * 3 + 3, 0);

      this.fromDate = new NgbDate(quarterStartDate.getFullYear(), quarterStartDate.getMonth() + 1, quarterStartDate.getDate());
      this.toDate = new NgbDate(quarterEndDate.getFullYear(), quarterEndDate.getMonth() + 1, quarterEndDate.getDate());
    }
  }
  formatTime(value: string): string {
    if (!value) {
      return value;
    }

    const [hours, minutes] = value.split(':').map(num => num.padStart(2, '0'));
    return `${hours}:${minutes}`;
  }
}
