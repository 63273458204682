import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import DOMPurify from 'dompurify';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/i18n/fr-fr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {translate} from "@jsverse/transloco";
import { TranslocoLocaleService } from '@jsverse/transloco-locale';

@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent implements OnDestroy, AfterViewInit, OnChanges {

  @Input() focus = false;
  @Input() isForm = false;
  @Input() value!: string | null;

  @Output() valueChanges = new EventEmitter<string | null>();
  @Output() tabKeyPress = new EventEmitter();

  @ViewChild('editorWrapper', {static: true}) editorWrapper!: ElementRef;

  readonly destroyed = new Subject();

  private editor?: Editor;

  constructor(private readonly ngZone: NgZone,private translocoLocaleService: TranslocoLocaleService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus && !changes.focus.firstChange && this.focus && this.editor) {
      setTimeout(() => {
        this.editor.focus();
        this.editor.moveCursorToEnd();
      });
    }
    if (
      changes.value &&
      // if the editor is not defined yet the value is set to the most recent one during the initialisation of the editor
      this.editor &&
      this.value !== this.editor.getMarkdown()
    ) {
      this.editor.setMarkdown(this.value ?? '');
    }
  }

  ngAfterViewInit() {
    // to make the (computeheavy) initialisation of the editor non blocking
    this.initializeEditor()
    if ((this.focus && this.isForm) || !this.isForm) {
      setTimeout(() => {
        this.editor.focus();
        this.editor.moveCursorToEnd();
      });
    }
  }

  private initializeEditor() {
    // Use another subject between the ValueChangesEmitter and the editors change-Event
    // to debounce the (slow) calls to getMarkdown()
    const valueChanges = new Subject();
    valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.valueChanges.emit(this.editor!.getMarkdown());
      });
    // run outside the zone to improve performance
    this.ngZone.runOutsideAngular(() => {
      // Initialise editor
      this.editor = new Editor({
        el: this.editorWrapper.nativeElement,
        initialValue: this.value ?? '',
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        height: `auto`,
        language: this.translocoLocaleService.getLocale(),
        customHTMLSanitizer: (html: string) =>
          DOMPurify.sanitize(html),
      });

      // custom toolbar icons
      const icons = {
        "tui-heading": "<i style='transform: translateY(-2px)' class='fa fa-heading'></i>",
        "tui-bold": "<i style='transform: translateY(-2px)' class='fa fa-bold'></i>",
        "tui-italic": "<i style='transform: translateY(-2px)' class='fa fa-italic'></i>",
        "tui-strike": "<i style='transform: translateY(-2px)' class='fa fa-strikethrough'></i>",
        "tui-hrline": "<i style='transform: translateY(-2px)' class='fa fa-horizontal-rule'></i>",
        "tui-quote": "<i style='transform: translateY(-2px)' class='fa fa-quote-left'></i>",
        "tui-ul": "<i style='transform: translateY(-2px)' class='fa fa-list-ul'></i>",
        "tui-ol": "<i style='transform: translateY(-2px)' class='fa fa-list-ol'></i>",
        "tui-task": "<i style='transform: translateY(-2px)' class='fa fa-tasks'></i>",
        "tui-table": "<i style='transform: translateY(-2px)' class='fa fa-table'></i>",
        "tui-image": "<i style='transform: translateY(-2px)' class='fa fa-image'></i>",
        "tui-link": "<i style='transform: translateY(-2px)' class='fa fa-link'></i>"
      }

      // get editor toolbar
      let toolbar = this.editor.getUI().getToolbar();

      // set toolbar elements' icons
      toolbar.getItems().forEach(item => {
        let cssClass = item.className.split(' ')[0];
        if (icons[cssClass]) {
          item.el.innerHTML = icons[cssClass];
        }
      });

      // change second button text to 'Visual'
      this.editor.getUI().getModeSwitch().el.getElementsByClassName("te-switch-button")[1].innerHTML = "Visuel";

      this.editor.on('change', (evt) => {
        this.ngZone.run(() => valueChanges.next(""));
      });

      this.editor['eventManager'].listen('keydown', (event) => {
        if (event.data.key === 'Tab') {
          event.data.preventDefault();
          this.tabKeyPress.emit();
        }
      });

      // fix html issues when pasting

      this.editor['eventManager'].listen('pasteBefore', (event) => {
        if (event.clipboardContainer) {
          var html = event.clipboardContainer.innerHTML;
          var doc = new DOMParser().parseFromString(html, 'text/html');
          doc.querySelectorAll('span').forEach((el) => {
            el.outerHTML = el.textContent;
          });
          html = doc.body;
          event.clipboardContainer.innerHTML = html.outerHTML;
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next("");
  }
}
