import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'record-add',
  templateUrl: './record-add.component.html',
  styleUrls: ['./record-add.component.scss']
})
export class RecordAddComponent implements OnInit {

  @Input() entity;
  @Input() field;
  @Input() takenValues = [];

  @Output() addSubmit = new EventEmitter();
  @Output() removeSubmit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }

  autocompleteSelect(value: any) {    
    this.takenValues.includes(value.id) ? this.removeSubmit.emit(value) : this.addSubmit.emit(value);
  }
}
