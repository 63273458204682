import {
  Compiler,
  Component,
  ComponentFactory,
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  ModuleWithComponentFactories,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import {CommonModule, TitleCasePipe} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AppRoutingModule} from "../../app-routing.module";
import {LocalePipe, MomentModule} from "ngx-moment";
import {FilterByPipe, NgPipesModule} from "ngx-pipes";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ChecklistProgressComponent} from "../checklist-progress/checklist-progress.component";
import {AutofocusDirective} from "../../directives/autofocus.directive";
import {ExistPipe} from "../../pipes/exist.pipe";
import {FieldValueToClassPipe} from "../../pipes/field-value-to-class.pipe";
import { SplitStringPipe } from '../../pipes/split-string.pipe';
import {CheckDirective} from "../../directives/check.directive";
import {ExcludePipe} from "../../pipes/exclude.pipe";
import {FamodePipe} from "../../pipes/famode.pipe";
import {SafeHTMLPipe} from "../../pipes/safe-html.pipe";
import {TrackDirective} from "../../directives/track.directive";
import {MarkdownToTextPipe} from "../../pipes/markdown-to-text.pipe";
import {FormatDatePipe} from "../../pipes/format-date.pipe";
import {GaugeComponent} from '../gauge/gauge.component';
import { MapsComponent } from '../maps/maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {FormatDateTimePipe} from "../../pipes/format-datetime.pipe";
import {BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {ReplaceStrPipe} from "../../pipes/replace-str.pipe";
import {TranslocoRootModule } from '../../transloco-root.module';



export interface DynamicCard {
}

@Injectable()
export class DynamicCardFactoryProvider {

  private factoriesCache: { [templateKey: number]: ComponentFactory<DynamicCard> } = {};

  constructor(private compiler: Compiler) {
  }

  createComponentFactory(template: string, key: number): ComponentFactory<DynamicCard> {
    let factory = this.factoriesCache[key];
    if (factory) {
      return factory;
    }
    const metadata = {
      selector: 'dynamic-card',
      template: template,
      styles: ['@keyframes highlight-animation { 0% { background: #ffff99;} 100% {background: white;}}', '.card-highlight { animation: highlight-animation 2s; }']
    }
    const componentType = Component(metadata)(class {});
    const module = DynamicCardFactoryProvider.createDynamicModule(componentType);
    const moduleFactories: ModuleWithComponentFactories<any> = this.compiler.compileModuleAndAllComponentsSync(module);
    factory = moduleFactories.componentFactories.find(f => f.componentType === componentType);
    this.factoriesCache[key] = factory;
    return factory;
  }

  private static createDynamicModule(decoratedComponentType: any) {
    const moduleClass = class ComponentModule {};
    return NgModule({
      imports: [
        CommonModule,
        BrowserModule,
        NgbModule,
        AppRoutingModule,
        MomentModule,
        NgPipesModule,
        BrowserAnimationsModule,
        GoogleMapsModule,
        BaseChartDirective,
        TranslocoRootModule,
        NgCircleProgressModule.forRoot()
      ],
      providers: [
        provideCharts(withDefaultRegisterables()),
      ],
      declarations: [
        decoratedComponentType,
        GaugeComponent,
        ChecklistProgressComponent,
        AutofocusDirective,
        CheckDirective,
        TrackDirective,
        FieldValueToClassPipe,
        MarkdownToTextPipe,
        SplitStringPipe,
        FormatDatePipe,
        FormatDateTimePipe,
        ReplaceStrPipe,
        TitleCasePipe,
        SafeHTMLPipe,
        FilterByPipe,
        ExcludePipe,
        FamodePipe,
        LocalePipe,
        ExistPipe,
        MapsComponent
      ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
    })(moduleClass);
  }
}
