import { Directive, Input, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {UserService} from "../services/user.service";
import {GtmService} from "../services/gtm.service";

@Directive({
  selector: '[track]'
})
export class TrackDirective {
  @Input() track: string;
  @Input() trackLabel: string;
  @Input() customTrack: boolean;

  constructor(public activatedRoute: ActivatedRoute, private userService: UserService, private gtmService:GtmService) { }

  @HostListener('click', ['$event']) onClick($event) {
    if (!this.customTrack) {
      try {
        const event = {
          action: this.track,
          label: this.trackLabel
        };
        this.gtmService.trackEvents(event, false);
      } catch (error) {
      }
    }
  }

  @HostListener('filterChange', ['$event']) onFilterChange(values) {
    if (this.customTrack) {
      try {
        const event = {
          action: this.track,
          label: this.trackLabel + ', Value [' + values + ']'
        };
        this.gtmService.trackEvents(event, false);
      } catch (error) {
      }
    }
  }

  @HostListener('groupbyChange', ['$event']) onGroupbyChange(value) {
    if (this.customTrack) {
      try {
        const event = {
          action: value.length ? this.track : 'Deactivate GroupBy',
          label: 'Filed [' + value + ']'
        };
        this.gtmService.trackEvents(event, false);
      } catch (error) {
      }
    }
  }

  @HostListener('orderingChange', ['$event']) onOrderingChange(value) {
    if (this.customTrack) {
      try {
        const event = {
          action: value.length ? this.track : 'Deactivate Ordering',
          label: 'Filed [' + value + ']'
        };
        this.gtmService.trackEvents(event, false);
      } catch (error) {
      }
    }
  }

  @HostListener('checkpointDelete', ['$event']) onCheckpointDelete() {
    if (this.customTrack) {
      try {
        const event = {
          action: this.track,
          label: this.trackLabel
        };
        this.gtmService.trackEvents(event, false);
      } catch (error) {
      }
    }
  }


}
