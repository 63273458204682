import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() public parentScroll;
  @Input() public recordId;
  @Input() public comments;
  @Input() public user;
  @Input() public total;

  @Output() public onCommentCreate = new EventEmitter();
  @Output() public onCommentUpdate = new EventEmitter();
  @Output() public onCommentDestroy = new EventEmitter();

  @Output() public loadMoreBtnClick = new EventEmitter();


  public readonly PAGE_SIZE = 10;
  public fetching = false;
  public ready = false;
  public page = 1;

  public confirmationModal;
  public commentToDelete;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    if (!this.ready){
      if (changes.comments && changes.comments.currentValue.length || this.total === 0){
        this.ready = true;
      }
    }
    if (this.fetching){
      this.fetching = false;
    }
  }

  track(index: number, obj): number {
    return obj.id;
  }

  public onLoadMoreClick(){
    this.page++;
    this.fetching = true;
    this.loadMoreBtnClick.emit({pageSize: this.PAGE_SIZE, page: this.page})
  }

  onCommentDestroyConfirmationOpen(evt) {
    this.commentToDelete = evt.comment;
    this.confirmationModal = this.modalService.open(evt.confirmationRef);
  }

  commentDestroy() {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }

    this.onCommentDestroy.emit({commentToDelete: this.commentToDelete})
  }

  onCommentDestroyConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  getRemainingCount() {
    return this.total - this.comments.length;
  }

}