import {Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UserService} from "../services/user.service";

@Directive({
  selector: '[check]'
})
export class CheckDirective implements OnChanges {
  @Input() check;
  @Input() checkSubject;

  @Output() checkPermission = new EventEmitter();

  constructor(private element: ElementRef, private userService: UserService) {
  }

  ngOnChanges() {
    this.userService.can(this.check, this.checkSubject).subscribe(userCan => {
      if (!userCan) {
        this.checkPermission.emit(false);
        this.prevent(this.element, this.get_prevention(this.check));
      }
      else {
        this.checkPermission.emit(true);
        this.element.nativeElement.style.display = 'unset';
        this.element.nativeElement.style.pointerEvents = 'auto';
        this.element.nativeElement.disabled = false;
      }
    }, () => {});
  }

  get_prevention(ability: string) {
    switch (ability) {
      case 'UpdateMainField':
      case 'UpdateField':
      case 'UpdateCheckpointUnclickable':
        return 'unclickable';
      default:
        return 'hidden';
    }
  }

  prevent(element: ElementRef<any>, prevention: string) {
    switch (prevention) {
      case 'hidden':
        element.nativeElement.setAttribute('style', 'display:none !important');
        break;
      case 'unclickable':
        element.nativeElement.style.pointerEvents = 'none';
        break;
      case 'disabled':
        element.nativeElement.disabled = true;
        break;
      default:
        throw new Error('Bad prevention key: ' + prevention);
    }
  }

}