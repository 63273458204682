import {
  APP_INITIALIZER,
  Compiler,
  CompilerFactory,
  CompilerOptions,
  COMPILER_OPTIONS,
  LOCALE_ID,
  NgModule,
  StaticProvider,
  ViewEncapsulation,
  Injector,
  ErrorHandler as FrontErrorHandler,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/login/login.component';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxsModule } from '@ngxs/store';
import { NgxMaskDirective, NgxMaskPipe, IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import {NgClickOutsideDirective, NgClickOutsideAttachOutsideDirective, NgClickOutsideDelayOutsideDirective} from 'ng-click-outside2';

import { environment } from 'src/environments/environment';
import { EnvService } from './services/env.service';
import { LoginPageState } from './state/login-page.state';
import { UserService } from './services/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CommonModule,
  registerLocaleData,
  TitleCasePipe,
} from '@angular/common';
import { CsrfInterceptor } from './interceptors/csrf.interceptor';
import { I18NInterceptor } from './interceptors/i18-n.interceptor';
import { HomeComponent } from './pages/home/home.component';
import { DefaultComponent } from './components/default/default.component';
import { EntityService } from './services/entity.service';
import { PageService } from './services/page.service';
import { SpecialTitleCasePipe } from './pipes/special-title.pipe';
import { TrackDirective } from './directives/track.directive';
import { CheckDirective } from './directives/check.directive';
import { FilterByPipe, FlattenPipe, NgPipesModule, PluckPipe } from 'ngx-pipes';
import { FlattenEntitiesPipe } from './pipes/flatten-entities.pipe';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatDateTimePipe } from './pipes/format-datetime.pipe';
import { MomentModule, LocalePipe, DateFormatPipe } from 'ngx-moment';
import { RoleService } from './services/role.service';
import { PagesetService } from './services/pageset.service';
import { WorkspaceService } from './services/workspace.service';
import { RecordService } from './services/record.service';
import { Cache } from './classes/cache';
import { ErrorHandler } from './classes/error-handler';
import { ToastrModule } from 'ngx-toastr';
import { SendResetPageState } from './state/send-reset-page.state';
import { HomePageState } from './state/home-page.state';
import { PreferencesPageState } from './state/preferences-page.state';
import { ReleasesPageState } from './state/releases-page.state';
import { AdministrationPageState } from './state/administration-page.state';
import { RecordPageState } from './state/record-page.state';
import { NotificationsPageState } from './state/notifications-page.state';
import { RecordsPageState } from './state/records-page.state';
import { AppState } from './state/app.state';
import { ResetPageState } from './state/reset-page.state';
import { ActionService } from './services/action.service';
import { WidgetService } from './services/widget.service';
import { AttachmentService } from './services/attachment.service';
import { CheckpointAttachmentsService } from './services/checkpoint-attachments.service';
import { CheckpointService } from './services/checkpoint.service';
import { ChoiceService } from './services/choice.service';
import { CommentService } from './services/comment.service';
import { TagService } from './services/tag.service';
import { WebSocketService } from './services/websocket.service';
import { HttpCancelService } from './services/httpcancel.service';
import { ViewService } from './services/view.service';
import { ActivityService } from './services/activity.service';
import { CheckpointsetService } from './services/checkpointset.service';
import { RecordComponent } from './pages/record/record.component';
import { RecordsComponent } from './pages/records/records.component';
import { CardsComponent } from './components/cards/cards.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { BaseChartDirective, provideCharts, withDefaultRegisterables  } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxFileDropModule } from 'ngx-file-drop';
import { WidgetComponent } from './components/widget/widget.component';
import { DragulaModule } from 'ng2-dragula';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { MarkdownToTextPipe } from './pipes/markdown-to-text.pipe';
import { MarkdownToHtmlPipe } from './pipes/markdown-to-html.pipe';
import { GaugeComponent } from './components/gauge/gauge.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr';
import localeCn from '@angular/common/locales/zh';
import { CardComponent } from './components/card/card.component';
import { DynamicCardFactoryProvider } from './components/card/card.factory';
import { LoaderComponent } from './components/loader/loader.component';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { EmptyComponent } from './components/empty/empty.component';
import { ActionComponent } from './components/action/action.component';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { HttpCancelInterceptor } from './interceptors/cancel.interceptor';
import { ViewComponent } from './components/view/view.component';
import { OrderingComponent } from './components/ordering/ordering.component';
import { GroupbyComponent } from './components/groupby/groupby.component';
import { FilterComponent } from './components/filter/filter.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { PreferencesComponent } from './pages/preferences/preferences.component';
import { FieldValueToClassPipe } from './pipes/field-value-to-class.pipe';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { PivotTableComponent } from './components/pivot-table/pivot-table.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SsoGatewayComponent } from './pages/sso-gateway/sso-gateway.component';
import { ResetComponent } from './pages/reset/reset.component';
import { SendresetComponent } from './pages/sendreset/sendreset.component';
import { NotificationComponent } from './components/notification/notification.component';
import { FlattenNotificationsPipe } from './pipes/flatten-notifications.pipe';
import { UploadComponent } from './components/upload/upload.component';
import { CreateComponent } from './components/create/create.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ChoiceComponent } from './components/choice/choice.component';
import { Error404Component } from './components/error404/error404.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormComponent } from './components/form/form.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { ExcludePipe } from './pipes/exclude.pipe';
import { ExistPipe } from './pipes/exist.pipe';
import { FieldMainComponent } from './components/field-main/field-main.component';
import { FieldComponent } from './components/field/field.component';
import { EditorComponent } from './components/editor/editor.component';
import { FieldViewerComponent } from './components/field-viewer/field-viewer.component';
import { GalleryDirective } from './directives/gallery.directive';
import { FieldManyComponent } from './components/field-many/field-many.component';
import { CheckpointsetComponent } from './components/checkpointset/checkpointset.component';
import { CheckpointComponent } from './components/checkpoint/checkpoint.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { AuditComponent } from './components/audit/audit.component';
import { ChecklistProgressComponent } from './components/checklist-progress/checklist-progress.component';
import { EvaluationColorComponent } from './components/evaluation-color/evaluation-color.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { FileComponent } from './components/file/file.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ActivityComponent } from './components/activity/activity.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CommentComponent } from './components/comment/comment.component';
import { MentionModule } from 'angular-mentions';
import {AutosizeModule} from 'ngx-autosize';
import { FlexnavDirective } from './directives/flexnav.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapsComponent } from './components/maps/maps.component';
import { FiltersComponent } from './components/filters/filters.component';
import {
  TranslocoHttpLoader,
  TranslocoRootModule,
} from './transloco-root.module';
import { TranslocoService } from '@jsverse/transloco';
import { IconComponent } from './components/icon/icon.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RthighlightDirective } from './directives/rthighlight.directive';
import { ReleasesComponent } from './pages/releases/releases.component';
import { ReplaceStrPipe } from './pipes/replace-str.pipe';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { SiderComponent } from './components/sider/sider.component';
import { HeaderComponent } from './components/header/header.component';
import { SpaceService } from './services/space.service';
import { SiderState } from './state/sider.state';
import { SsoGatewayService } from './services/sso-gateway.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { InitThemeDirective } from './directives/init-theme.directive';
import { GetEntityPipe } from './pipes/get-entity.pipe';
import { FlattenBlocksPipe } from './pipes/flatten-blocks.pipe';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { ModalComponent } from './pages/modal/modal.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { GetPageTitlePipe } from "./pipes/get-page-title.pipe";
import { FormatCheckboxPipe } from "./pipes/format-checkbox.pipe";
import { ErrorHandlingModule } from './error-handling.module';
import { NgForTrackByIdDirective } from './directives/ngFor-track-by-id.directive';
import { RecordAddComponent } from './components/record-add/record-add.component';
import { GridComponent } from './components/grid/grid.component';
import { DragAndDropModule } from 'angular-draggable-droppable';


registerLocaleData(localeFr);
registerLocaleData(localeCn, 'cn');

const compilerOptions: CompilerOptions = {
  defaultEncapsulation: ViewEncapsulation.None,
};

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler([compilerOptions]);
}
export let AppInjector: Injector;
export const RUNTIME_COMPILER_PROVIDERS: StaticProvider[] = [
  { provide: COMPILER_OPTIONS, useValue: compilerOptions, multi: true },
  {
    provide: CompilerFactory,
    useClass: JitCompilerFactory,
    deps: [COMPILER_OPTIONS],
  },
  { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
];

export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DefaultComponent,
    SpecialTitleCasePipe,
    TrackDirective,
    CheckDirective,
    NgForTrackByIdDirective,
    FlattenEntitiesPipe,
    FormatDatePipe,
    FormatDateTimePipe,
    ReplaceStrPipe,
    SplitStringPipe,
    RecordComponent,
    RecordsComponent,
    CardsComponent,
    TimelineComponent,
    WidgetComponent,
    GaugeComponent,
    CalendarComponent,
    CardComponent,
    EmptyComponent,
    LoaderComponent,
    ActionComponent,
    ViewComponent,
    OrderingComponent,
    GroupbyComponent,
    FilterComponent,
    DatepickerComponent,
    AdministrationComponent,
    PreferencesComponent,
    FieldValueToClassPipe,
    AutocompleteComponent,
    PivotTableComponent,
    NotificationsComponent,
    SsoGatewayComponent,
    ResetComponent,
    SendresetComponent,
    NotificationComponent,
    FlattenNotificationsPipe,
    UploadComponent,
    CreateComponent,
    ConfirmationComponent,
    ChoiceComponent,
    SafeHTMLPipe,
    MarkdownToTextPipe,
    MarkdownToHtmlPipe,
    Error404Component,
    AutofocusDirective,
    FormComponent,
    FormFieldComponent,
    MarkdownToHtmlPipe,
    ExcludePipe,
    ExistPipe,
    FieldMainComponent,
    FieldComponent,
    EditorComponent,
    FieldViewerComponent,
    GalleryDirective,
    FieldManyComponent,
    CheckpointsetComponent,
    CheckpointComponent,
    ChecklistComponent,
    AuditComponent,
    ChecklistProgressComponent,
    AttachmentsComponent,
    FileComponent,
    ActivitiesComponent,
    ActivityComponent,
    CommentsComponent,
    CommentComponent,
    FlexnavDirective,
    MapsComponent,
    IconComponent,
    ReleasesComponent,
    SiderComponent,
    HeaderComponent,
    RthighlightDirective,
    BreadcrumbComponent,
    InitThemeDirective,
    GetEntityPipe,
    GetPageTitlePipe,
    FlattenBlocksPipe,
    FiltersComponent,
    ModalComponent,
    NotFoundComponent,
    FormatCheckboxPipe,
    RecordAddComponent,
    GridComponent,
    EvaluationColorComponent,
  ],
  imports: [
    NgxsReduxDevtoolsPluginModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientJsonpModule,
    HttpClientModule,
    NgxsModule.forRoot(
      [
        AppState,
        RecordsPageState,
        NotificationsPageState,
        RecordPageState,
        AdministrationPageState,
        PreferencesPageState,
        HomePageState,
        LoginPageState,
        ResetPageState,
        SendResetPageState,
        ReleasesPageState,
        SiderState,
      ],
      { developmentMode: !environment.production }
    ),
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgPipesModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
    BaseChartDirective,
    MomentModule,
    NgSelectModule,
    NgxFileDropModule,
    DragulaModule.forRoot(),
    InfiniteScrollModule,
    MentionModule,
    NgClickOutsideDirective,
    NgClickOutsideAttachOutsideDirective,
    NgClickOutsideDelayOutsideDirective,
    GoogleTagManagerModule.forRoot({
      id: environment.GTM_TRACKING_ID,
    }),
    NgxMaskDirective, NgxMaskPipe,
    NgxFilesizeModule,
    AutosizeModule,
    GoogleMapsModule,
    TranslocoRootModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgCircleProgressModule.forRoot(),
    NgxPanZoomModule,
    ErrorHandlingModule.forRoot(),
    DragAndDropModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true,
    },

    { provide: HTTP_INTERCEPTORS, useClass: I18NInterceptor, multi: true },
    HttpClientJsonpModule,
    HttpClientModule,
    { provide: 'Cache', useClass: Cache },
    { provide: 'Queue', useClass: Cache },
    { provide: 'ActionService', useClass: ActionService },
    { provide: 'WidgetService', useClass: WidgetService },
    { provide: 'SpaceService', useClass: SpaceService },
    { provide: 'AttachmentService', useClass: AttachmentService },
    {
      provide: 'CheckpointAttachmentsService',
      useClass: CheckpointAttachmentsService,
    },
    { provide: 'CheckpointService', useClass: CheckpointService },
    { provide: 'ChoiceService', useClass: ChoiceService },
    { provide: 'CommentService', useClass: CommentService },
    { provide: 'EntityService', useClass: EntityService },
    { provide: 'PageService', useClass: PageService },
    { provide: 'RecordService', useClass: RecordService },
    { provide: 'RoleService', useClass: RoleService },
    { provide: 'TagService', useClass: TagService },
    { provide: 'UserService', useClass: UserService },
    { provide: 'WebSocketService', useClass: WebSocketService },
    { provide: 'PagesetService', useClass: PagesetService },
    { provide: 'HttpCancelService', useClass: HttpCancelService },
    { provide: 'ViewService', useClass: ViewService },
    { provide: 'ActivityService', useClass: ActivityService },
    { provide: 'CheckpointsetService', useClass: CheckpointsetService },
    { provide: 'WorkspaceService', useClass: WorkspaceService },
    { provide: 'SsoGatewayService', useClass: SsoGatewayService },
    {
      provide: LOCALE_ID,
      useFactory: (translocoService: TranslocoService) => {
        return translocoService.getActiveLang();
      },
      deps: [TranslocoService],
    },
    provideEnvironmentNgxMask(maskOptions),
    provideCharts(withDefaultRegisterables()),
    LocalePipe,
    DateFormatPipe,
    TitleCasePipe,
    FilterByPipe,
    PluckPipe,
    FlattenPipe,
    NgxMaskPipe,
    FieldValueToClassPipe,
    FormatDatePipe,
    FormatDateTimePipe,
    ReplaceStrPipe,
    DynamicCardFactoryProvider,
    ...RUNTIME_COMPILER_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
