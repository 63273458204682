import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SsoGatewayService extends BaseService {

  key = 'ssogateways';
  constructor(public injector: Injector) {
    super(injector);
  }
}
