import {Observable, Subject, concat, forkJoin, merge, of} from 'rxjs';
import {RecordPage, RecordsPage, Sider} from '../../state/app.actions';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ChangeDetectionStrategy,
  ElementRef,
  OnDestroy,
  HostListener,
  Inject,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
  TrackByFunction,
  Input,
  Output,
  EventEmitter,
  Renderer2
} from '@angular/core';
import { AppState } from '../../state/app.state';
import { Store, Select, Actions } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute} from '@angular/router';
import {TitleCasePipe, Location, DOCUMENT} from '@angular/common';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';

import {PluckPipe, FlattenPipe, FilterByPipe} from 'ngx-pipes';
import {RecordService} from "../../services/record.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormControl} from "@angular/forms";
import {filter, takeUntil, startWith, distinctUntilChanged, delay, debounceTime, tap, mergeMap, map} from 'rxjs/operators';
import {
  applyApplicationStyle,
  dateFormat,
  deepCompare,
  updateQueryParams,
  openDetailPage,
  fakeEveryOne
} from 'src/app/app.utils';
import { UserService } from 'src/app/services/user.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { LocalService } from 'src/app/services/local.service';
import {translate} from '@jsverse/transloco';
import { TimelineComponent } from 'src/app/components/timeline/timeline.component';
import party from "party-js";
import { ChoiceService } from 'src/app/services/choice.service';
import * as moment from 'moment';
import { ModalProxyService } from 'src/app/services/modal-proxy.service';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
declare var window;
declare var $: any;

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RecordsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Select(AppState.recordsPage) recordsPage$;
  @Select(AppState.sider) sider$;
  @ViewChild('viewInputRef') viewInputRef;
  @ViewChild('DetailPageModal') detailPageModal;
  @ViewChild('dashboard') dashboard;
  @ViewChild('container') container: ElementRef;
  @ViewChild('create') create: ElementRef;
  @ViewChild('moreButton') moreButton: ElementRef;
  @ViewChild("viewsContainer", { static: false }) viewsContainer: ElementRef;
  @ViewChild("viewsBarContainer", {static: false}) viewsBarContainer: ElementRef;
  @ViewChild("actionsContainer", {static: false}) actionsContainer: ElementRef;
  @ViewChild("viewShare", { static: false }) viewShareRef: ElementRef;
  @ViewChildren("views", { read: ElementRef }) views: QueryList<ElementRef>;
  @ViewChild('timeline', {static: false}) timeline: TimelineComponent;
  @ViewChild('calendar', {static: false}) calendar: CalendarComponent;
  @Input() isModal: boolean = false;
  @Output() closeModal = new EventEmitter();
  @HostListener('window:resize')
  public onResize() {
    this.changeDetector.detectChanges();
  }

  modal;
  params;
  viewTitle;
  queryParams;
  viewToDelete;
  csvValueControl;
  confirmationModal;
  executingActionId;

  page= 1;
  isPopState = false;
  unscheduledPage = 1;
  window = window;
  loading = false;
  showFilters = true;
  updatedViewId = null;
  jsonParse = JSON.parse;
  scrollDisabled = false;
  loadedAtLeastOnce = false;
  showFiltersInMobile = false;
  hiddenViews = [];
  activeFilters = {};
  destroyed$: Subject<any> = new Subject();
  space = this.localService.getSpace();
  modeTitle = {"cards": translate("Liste"),"grid": translate("Tableau"), "calendar": translate("Calendrier"), "timeline": translate("Timeline"), "dashboard": translate("Tableau croisé dynamique")};
  modeIcon = {"cards": "fa-bars", "grid": "fa-table", "calendar": "fa-calendar-alt", "timeline": "fa-chart-gantt", "dashboard": "fa-table-pivot"};

  viewCurrentSelectedUsers: any;
  currentSearchTerm: string = "";
  currentPage: number = 1;
  focused = true;
  listeningTypeAheadScroll: boolean = false;
  canSearch: boolean = true;
  focus$ = new Subject<any>();
  typedInput$ = new Subject<any>();
  users$: Observable<any>;
  valueControl: any;
  currentUser;
  userHiddenColSaves: number[];

  constructor(public store: Store, private titlecasePipe: TitleCasePipe, private toastr: ToastrService, public localService: LocalService,
              private router: Router, private route: ActivatedRoute, public recordService: RecordService,
              public modalService: NgbModal, public changeDetector: ChangeDetectorRef, public userService: UserService,
              private filterBy: FilterByPipe, private pluck: PluckPipe, private flatten: FlattenPipe,
              public modalProxyService: ModalProxyService,
              private actions$: Actions, private location: Location, private webSocketService: WebSocketService,
              @Inject(DOCUMENT) private document: Document, public choiceService: ChoiceService) {
    this.localService.popState$.pipe(takeUntil(this.destroyed$)).subscribe((next) => {
      this.isPopState = next;
    })
    if(!this.isPopState && this.route.snapshot.queryParams?.page) {
      this.router.navigate([], {
        queryParams: { page: null },
        queryParamsHandling: 'merge',
        replaceUrl: true
      })
    } else {
      this.page = +this.route.snapshot.queryParams?.page;
      this.localService.popState$.next(false);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const backToTopBtn = document.getElementById('scrollTop');
    if (backToTopBtn){
      if (this.document.documentElement.scrollTop > 20) {
        backToTopBtn.classList.remove('d-none');
      } else {
        backToTopBtn.classList.add('d-none');
      }
    }
  }

  ngOnInit() {
    this.webSocketService.messages.pipe(filter(data => (data !==  null && data.message.type === 'highlight')),
      takeUntil(this.destroyed$)).subscribe((data) => {
      const content = data.message.data;
      if (content.actor && content.actor !== this.userService.localService.getUser() && content.entity && content.entity === this.store.snapshot().app.recordsPage?.entity?.id){
        this.store.dispatch(new RecordsPage.Init({'entity': content.entity, ...this.queryParams}, true));
      }
    });
    this.webSocketService.messages.pipe(filter(data => (data !==  null && ['edition', 'action'].includes(data.message.type))),
      takeUntil(this.destroyed$)).subscribe((data) => {
      const message = data.message;
      const sameUser = message.user === this.userService.localService.getUser();
      if (!sameUser && data.message.type === 'edition'){
        if (message.sender.id && message.sender.entity && message.method === 'DELETE'){
          if (message.sender.entity === this.store.snapshot().app.recordsPage.entity?.id){
            this.store.dispatch(new RecordsPage.Init({'entity': this.store.snapshot().app.recordsPage.entity?.id, ...this.queryParams}, true));
          }
        }
      } else {
        let timelineConfig = this.queryParams['timelineConfig'] ? JSON.parse(this.queryParams['timelineConfig']) : {};
        if (message.sender.entity === this.store.snapshot().app.recordsPage.entity?.id && this.queryParams){
          this.store.dispatch(new RecordsPage.Init({'entity': this.store.snapshot().app.recordsPage.entity?.id, ...this.queryParams}, true));
        }
      }
    });
    const params$ = this.route.params;
    const queryParams$ = this.route.queryParams.pipe(
      distinctUntilChanged((prev, curr) => {
        const { hiddenCols: prevHiddenCols, ...prevFilteredQueryParams } = prev;
        const { hiddenCols: currHiddenCols, ...currFilteredQueryParams } = curr;
        return JSON.stringify(prevFilteredQueryParams) === JSON.stringify(currFilteredQueryParams);
      })
    );
    combineLatest([params$, queryParams$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
      // todo temporary imitation of a strange behavior in creator_front
        if(!this.route.snapshot.queryParams?.page) this.page = 1;
        if (this.page == (+this.route.snapshot.queryParams?.page || 1)) {
          const queryParamsClone = _.cloneDeep(this.queryParams);
          if (this.queryParams && queryParamsClone["timeframe"]) {
            delete queryParamsClone["timeframe"];
          }
          if (this.queryParams && queryParamsClone["dashboardConfig"]) {
            delete queryParamsClone["dashboardConfig"];
          }
          const snapshotQueryParamsClone = _.cloneDeep(this.route.snapshot.queryParams);
          if (snapshotQueryParamsClone["timeframe"]) {
            delete snapshotQueryParamsClone["timeframe"];
          }
          if (snapshotQueryParamsClone["dashboardConfig"]) {
            this.queryParams = this.route.snapshot.queryParams;
            delete snapshotQueryParamsClone["dashboardConfig"];
          }
          if (!_.isEqual(queryParamsClone, snapshotQueryParamsClone)) {
            this.params = this.route.snapshot.params;
            this.queryParams = this.route.snapshot.queryParams;
            if (!(this.queryParams['mode'] === 'dashboard' && this.store.snapshot().app.recordsPage.mode === 'dashboard' && this.params.id === this.store.snapshot().app.recordsPage.entity.id)) {
              const queryParamsDiff = deepCompare(queryParamsClone, snapshotQueryParamsClone);
              let timelineConfigDiff;
              if (queryParamsClone?.timelineConfig && snapshotQueryParamsClone?.timelineConfig) {
                timelineConfigDiff = deepCompare(JSON.parse(queryParamsClone?.timelineConfig), JSON.parse(snapshotQueryParamsClone?.timelineConfig));
              }
              if (!(queryParamsDiff.includes("calendarConfig") || queryParamsDiff.includes("timelineConfig")) || queryParamsDiff.includes("mode")) {
                this.scrollDisabled = true;
                this.store.dispatch(new RecordsPage.Init({'entity': this.params.id, ...this.queryParams, qp_page: this.page})).subscribe(() => {
                  this.calculateHiddenViews();
                  this.loadedAtLeastOnce = true;
                  this.scrollDisabled = false;
                  if (!this.queryParams.hasOwnProperty('v')) {
                    this.router.navigate([], {
                      queryParams: {...this.queryParams, v: "" + this.store.snapshot().app?.recordsPage?.views[0]?.id},
                    });
                  }
                  if (this.store.snapshot().app?.recordsPage?.views?.length){
                    const viewId = this.queryParams['v'] ? this.queryParams['v'] : -1;
                    let activeView = _.find(this.store.snapshot().app?.recordsPage?.views, v => v.id == viewId);
                    if (!activeView) activeView = this.store.snapshot().app?.recordsPage?.views[0];
                    this.store.dispatch(new RecordsPage.SelectView(activeView));
                    this.viewCurrentSelectedUsers = _.concat(activeView.isPublic ? [-1] : [], _.map(activeView.users, 'id'));
                    this.valueControl = new UntypedFormControl("");
                  }
                  this.getActiveFilters();
                  this.changeDetector.detectChanges();
                });
              } else if (this.queryParams.hasOwnProperty('mode') && queryParamsDiff && (queryParamsDiff.includes("calendarConfig") || (queryParamsDiff.includes("timelineConfig") && !timelineConfigDiff?.includes('window') && !timelineConfigDiff?.includes('mode')))) {
                this.page = 1;
                this.onUnscheduledNext();
              }
            } else {
              this.store.dispatch(new RecordsPage.Init({'entity': this.params.id, ...this.queryParams})).subscribe((res) => {
                this.getActiveFilters();
                this.changeDetector.detectChanges();
                this.dashboard.ngOnInit();
              }, () => {
              });
            }
          }
        }
    });
    this.localService.siderVisibility$.pipe(delay(300), distinctUntilChanged()).subscribe(() => {
        this.calculateHiddenViews();
        this.changeDetector.detectChanges();
    })
    this.initUsers();
    this.currentUser = this.userService.localService.getUser();
    this.route.queryParams
    .pipe(takeUntil(this.destroyed$))
    .subscribe(res => {
      if(res.hiddenCols && res.hiddenCols.length > 0){
        this.userHiddenColSaves = Array.isArray(res.hiddenCols)
                              ? res.hiddenCols.map(val => Number(val))
                              :[Number(res.hiddenCols)];
        this.queryParams = {...this.queryParams, hiddenCols: this.userHiddenColSaves};
      }else{
        this.userHiddenColSaves = [];
        const {hiddenCols, ...rest} = this.queryParams;
        this.queryParams = rest;
      }
    })
  }

  ngAfterViewInit(){
    this.recordsPage$.subscribe(state =>{
      if (state.isReady) {
        var style = this.store.snapshot().app?.sider?.user?.space?.style;
        if (style) {
          applyApplicationStyle(style);
        }
      }
    })
    this.sider$.subscribe(state =>{
        if (state.isReady) {
            this.calculateHiddenViews();
        }
      })
    this.views.changes.pipe(startWith([undefined])).subscribe(change => {
       this.calculateHiddenViews();
    })
    $("body").tooltip({ selector: '[data-bs-toggle=tooltip]' }).click(() => {
        $('[data-bs-toggle=tooltip]').tooltip("hide");
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);  // todo,  a fake argument (undefined) is needed to bypass rxjs 7 error
    this.destroyed$.complete();
    this.window.scrollTo({left: 0, top: 0, behavior: 'instant'})
  }

  openDetailPage(evt) {
    this.scrollDisabled = false;
    openDetailPage(evt, () => {
      this.closeDetailPageModal();
    });
  }

  closeDetailPageModal(reason='') {
    this.closeModal.emit(reason);
  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeDetailPageModal("popstate")
    this.scrollDisabled = true;
  }

  onCardsScroll() {
    this.scrollDisabled = true;
    this.loading = true;
    this.store.dispatch(new RecordsPage.RetrieveRecords({'entity': this.params.id, ...this.queryParams}, this.page + 1)).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.router.navigate([], {
        skipLocationChange: !this.scrollDisabled,
        queryParams: { page: this.page+1 },
        queryParamsHandling: 'merge',
        replaceUrl: true
      }).then(() => {
        this.page++;
      });
      this.loading = false;
      if (!res.app.recordsPage.allElementsLoaded) {
        this.scrollDisabled = false;
        this.changeDetector.detectChanges();
      }
    });
  }

  onScrollGrid(){
    this.loading = true;
    this.store.dispatch(new RecordsPage.RetrieveRecords({'entity': this.params.id, ...this.queryParams}, this.page + 1)).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.router.navigate([], {
        queryParams: { page: this.page + 1 },
        queryParamsHandling: 'merge',
        replaceUrl: true
      }).then(() => {
        this.page++;
      });
      this.loading = false;
    });
  }

  onUpdateUserHiddenColumns(columnIds: number[]){
    const entityId = this.store.snapshot().app.recordsPage.entity.id;
    this.router.navigate([], {
      queryParams: { hiddenCols: columnIds },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
    this.store.dispatch(new Sider.SaveUserTableHiddenCols(entityId, columnIds))
  }
  isViewActive(view) {
    return this.queryParams.hasOwnProperty('v') && view.id == this.queryParams['v'];
  }

  onViewSelect(view) {
    this.viewCurrentSelectedUsers = _.concat(view.isPublic ? [-1] : [], _.map(view.users, 'id'));
    this.valueControl = new UntypedFormControl("");
    let value = _.cloneDeep(view.value);
    for (const key in value) {
      if (Array.isArray(value[key]) && value[key].length === 1) {
        // value[key] = value[key][0];
        value = {
          ...value,
          [key]: value[key][0]
        };
      }
    }
    value['v']=view.id;
    this.store.dispatch(new RecordsPage.SelectView(view));
    this.router.navigate([], {
      queryParams: value,
    });

    if (value.dashboardConfig && this.dashboard){
        this.queryParams = value
        this.changeDetector.detectChanges();
        this.dashboard.ngOnInit();
    }
    this.closeSidebar();
  }

  createView(viewTitle, dropdownRef) {
    if (!viewTitle) return;

    // Create a copy of queryParams without the 'page' and 'v' property
    const viewQueryParams = { ...this.queryParams };
    delete viewQueryParams.page;
    delete viewQueryParams.v;

    this.store.dispatch(new RecordsPage.CreateView({
      title: viewTitle,
      space: this.localService.getSpace(),
      value: viewQueryParams
    })).subscribe(res => {
      dropdownRef.close();
      this.onViewSelect(res.app.recordsPage.latestView);
      this.calculateHiddenViews();
    }, () => {});
    this.closeSidebar();
  }

  onViewDestroyConfirmationOpen(confirmationRef, view) {
    this.viewToDelete = view;
    this.confirmationModal = this.modalService.open(confirmationRef);
  }

  onViewDestroyConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onArchiveConfirmationOpen(confirmationRef) {
    this.confirmationModal = this.modalService.open(confirmationRef);
  }

  onArchiveConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onViewDestroy(viewId) {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }

    this.store.dispatch(new RecordsPage.DestroyView(viewId)).subscribe((res) => {
      this.onViewSelect(res.app.recordsPage.latestView);
      this.calculateHiddenViews();
    }, () => {});
    this.closeSidebar();
  }

  onViewUpdate(viewId, viewTitle) {
    this.viewTitle = viewTitle;
    this.updatedViewId = viewId;
    this.viewInputRef.nativeElement.click();
  }

  updateView(viewId, viewTitle, dropdownRef) {
    if (!viewTitle) return;

    this.store.dispatch(new RecordsPage.UpdateView(viewId, {title: viewTitle})).subscribe(res => {
      dropdownRef.close();
    }, () => {});
    this.closeSidebar();
  }

  onOpenChange(isOpen) {
    if (!isOpen) {
      this.updatedViewId = null;
      this.viewTitle = '';
    }
  }

  updateQueryParams(name, values) {
    const filters = [{ field: { name }, values }]
    updateQueryParams(this.router, filters)
    this.closeSidebar();
  }

  toggleFilters() {
    this.showFiltersInMobile = !this.showFiltersInMobile;
  }

  clearFilters() {
    const queryParams = {};
    if (this.queryParams['ordering']) {
      queryParams['ordering'] = this.queryParams['ordering']
    }
    if (this.queryParams['groupby']) {
      queryParams['groupby'] = this.queryParams['groupby']
    }
    if (this.queryParams['mode']) {
      queryParams['mode'] = this.queryParams['mode']
    }
    if (this.queryParams['is_archived']) {
      queryParams['is_archived'] = this.queryParams['is_archived']
    }
    if (this.queryParams['dashboardConfig']) {
      queryParams['dashboardConfig'] = this.queryParams['dashboardConfig']
    }
    if (this.queryParams['calendarConfig']) {
      queryParams['calendarConfig'] = this.queryParams['calendarConfig']
    }
    if (this.queryParams['timelineConfig']) {
      queryParams['timelineConfig'] = this.queryParams['timelineConfig']
    }
    if (this.queryParams['timeframe']) {
      queryParams['timeframe'] = this.queryParams['timeframe']
    }
    if (this.queryParams['v']) {
      queryParams['v'] = this.queryParams['v'];
    }
    this.unscheduledPage = 1;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
    this.closeSidebar();
  }

  onOrderingChange(ordering) {
    this.updateQueryParams('ordering', ordering);
  }

  onGroupbyChange(groupby) {
    this.updateQueryParams('groupby', groupby);
  }


  // TODO Change in ts file: close offcanvas when filter is applied
  onFilterUpdate(values) {
    this.updateQueryParams(values.field.name, values.values);
    this.unscheduledPage = 1;
    this.changeDetector.detectChanges();
  }

  getActiveFilters() {
    this.activeFilters = {};
    const newFilters = deepCompare(this.store.snapshot().app.recordsPage.latestView?.value, _.omit(this.queryParams, ['v']));
    let fields = []
    this.store.snapshot().app.recordsPage.entity?.blocks.forEach(block => {
      block?.fields.forEach(field => {
        if (this.queryParams[field.name] && newFilters.includes(field.name)) {
          fields.push(field);
        }
      })
    });
    if (newFilters.includes('space')) {
      fields.push({name: 'space', type: 'Space', title: translate("Espace"), icon: 'fa-sitemap'})
    }
    if (newFilters.includes('checklist_record')) {
      fields.push({name: 'checklist_record', type: 'Checklist', title: "Checklist", icon: ''})
    }
    if (newFilters.includes('tags')) {
      fields.push({name: 'tags', type: 'Tags', title: translate('étiquettes'), icon: '🏷'})
    }
    fields.forEach(field => {
      const paramsClone = _.cloneDeep(this.queryParams[field.name]);
      let values = Array.isArray(paramsClone) ? paramsClone : [paramsClone];
      this.activeFilters[field.name] = {};
      this.activeFilters[field.name]['field'] = field;
      this.activeFilters[field.name]['icon'] =  field.icon;
      this.activeFilters[field.name]['title'] =  field.title;
      let result = ""
      switch (field.type) {
        case 'Number':
            this.activeFilters[field.name]['values'] = values;
          break;
        case 'SingleSelect':
          this.retrieveObjects(values, field, this.choiceService);
          break;
        case 'MultiSelect':
          this.retrieveObjects(values, field, this.choiceService);
          break;
        case 'CreatedBy':
        case 'User':
        case 'E-Signature':
        case 'Users':
          this.retrieveObjects(values, field, this.userService);
          break;
        case 'OneToOne':
        case 'InverseOneToOne':
        case 'OneToMany':
        case 'InverseOneToMany':
        case 'ManyToMany':
        case 'InverseManyToMany':
        case 'Checklist':
          this.retrieveObjects(values, field, this.recordService);
          break;
        case 'LastModifiedAt':
        case 'CreatedAt':
        case 'Date':
          this.activeFilters[field.name]['values'] = !isNaN(Date.parse(values[0])) ? (dateFormat(values[0]) != dateFormat(values[1]) ?dateFormat(values[0]) + ' - ' + dateFormat(values[1]) : dateFormat(values[0])) : values[0] + ' ' + values[1];
          break;
        case 'Tags':
          this.store.snapshot().app.recordsPage.entity?.tags.map(tag => {
            if (values.includes(String(tag.id))) {
              result += tag.title + ", "
            }
          });
          this.activeFilters[field.name]['values'] = result.slice(0, -2);
          break;
        case 'Space':
        case 'Spaces':
          this.store.snapshot().app.recordsPage.entity?.spaces.map(space => {
            if (values.includes(String(space.id))) {
              result += (space.icon ? space.icon + " " : "") + space.title + ", "
            }
          });
          this.activeFilters[field.name]['values'] = result.slice(0, -2);
          break;
        case 'Checkbox':
          this.activeFilters[field.name]['values'] = values.map(v => v == 'true' ? true : false);
          break;
      }
    })
  }

  onCardsCreateSubmit(evt) {
    const entity = this.store.snapshot().app.recordsPage.entity;
    let fieldsValues;
    if (evt?.hasOwnProperty('fieldsValues')) {
      fieldsValues = evt.fieldsValues
    } else if (entity.creationForm) {
      fieldsValues = evt
    } else if (entity.mainField) {
      fieldsValues = {
        [entity.mainField.id]: evt
      }
    }
    this.store.dispatch(new RecordsPage.CreateRecord(fieldsValues, evt?.indexGroupBy)).subscribe((res) => {
      let displayedValue = entity.mainField ? fieldsValues[entity.mainField?.id] : null
      //Check if main field is User
      if (displayedValue?.fullName) {
        displayedValue = displayedValue.fullName
      }
      if (entity.creationForm){
        this.toastr.success(translate("record ajouté",{e:entity.isMasculine ? '' : 'e',title:this.titlecasePipe.transform(entity.title)}));
        if (!evt.hasOwnProperty('fieldsValues')) {
          this.create['CreateModal'].dismiss();
        } else {
          (this.timeline || this.calendar).create.CreateModal.dismiss();
        }
      }
      else{
        this.toastr.success(displayedValue,translate("record ajouté",{e:entity.isMasculine ? '' : 'e',title:this.titlecasePipe.transform(entity.title)}));

      }
      if (!['calendar', 'timeline', 'grid'].includes(this.store.snapshot().app.recordsPage.mode)) {
        let evt = {recordId: this.store.snapshot().app.recordsPage.lastId, openModal:entity.isDetailPageInModal};
        openDetailPage(evt);
      } else if(this.store.snapshot().app.recordsPage.mode === 'timeline') {
        this.timeline.cancelCreate();
      }
    }, () => {});
  }

  onExcelExport() {
    window.open(this.recordService.getExcelUrl(this.params.id, this.queryParams));
  }

  openImportModal(modalRef) {
    this.modal = this.modalService.open(modalRef);
    this.csvValueControl = new UntypedFormControl('');
  }

  onCsvImportHandler() {
    if (!this.csvValueControl.value) return;
    this.store.dispatch(new RecordsPage.ImportRecords(this.params.id, this.csvValueControl.value.split('\t').join(','))).subscribe((res) => {
      this.store.dispatch(new RecordsPage.Init({'entity': this.params.id, ...this.queryParams}));
      this.modal.dismiss();
      this.toastr.success(translate('données importées'));
    }, () => {});
  }

  switchMode(mode, entity = null) {
    const qp = {};
    qp['page'] = null;
    qp['mode'] = mode;
    if (mode === 'cards') {
      qp['dashboardConfig'] = null;
      qp['timelineConfig'] = null;
      qp['calendarConfig'] = null;
      qp['timeframe'] = null;
      qp['mode'] = null;
    }else if (mode === 'grid'){
      qp['dashboardConfig'] = null;
      qp['timelineConfig'] = null;
      qp['calendarConfig'] = null;
      qp['timeframe'] = null;
    }
    else if (mode === 'dashboard'){
      qp['calendarConfig'] = null;
      qp['timelineConfig'] = null;
      qp['timeframe'] = null;
      qp['ordering'] = null;
      qp['groupby'] = null;
    } else if (mode === 'calendar') {
      qp['calendarConfig'] = JSON.stringify({scope: 'month'});
      qp['dashboardConfig'] = null;
      qp['timelineConfig'] = null;
      qp['ordering'] = null;
      qp['groupby'] = null;
    } else {
      qp['timelineConfig'] = JSON.stringify({scope: 'month'});
      qp['dashboardConfig'] = null;
      qp['calendarConfig'] = null;
      qp['timeframe'] = null;
    }
    if (entity){
      const datefields = this.filterBy.transform(this.filterBy.transform(this.flatten.transform(this.pluck.transform(entity.blocks,"fields")),["hasFilter"],[true]),["type"],["Date"]);
      datefields.forEach((element) => {
        if ((mode === 'calendar' && element.id === entity.endingDateField?.id) || (mode === 'timeline' && [entity.startingDateField?.id,entity.endingDateField?.id].includes(element.id))){
          qp[element.name] = null;
        }
      });
    }
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
    this.closeSidebar();
  }

  onDashboardConfigChange(config) {
    const qp = {};
    qp['dashboardConfig'] = JSON.stringify(config);
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }


  onCalendarScopeDateChange(evt) {
    this.store.dispatch(new RecordsPage.ChangeCalendarDates(evt[0], evt[1], this.params.id, this.queryParams));
    this.changeDetector.detectChanges();
  }

  onCalendarScopeChange(scope) {
    const qp = {};
    let calendarConfig = this.route.snapshot.queryParams?.calendarConfig;
    calendarConfig = calendarConfig ? JSON.parse(calendarConfig) : {};
    calendarConfig['scope'] = scope;
    qp['calendarConfig'] = JSON.stringify(calendarConfig);
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }

  oncalendarUnscheduledChange(unscheduled) {
    let qp = {};
    let calendarConfig = this.route.snapshot.queryParams?.calendarConfig;
    calendarConfig = calendarConfig ? JSON.parse(calendarConfig) : {};
    calendarConfig['unscheduled'] = unscheduled.toString();
    qp['calendarConfig'] = JSON.stringify(calendarConfig);
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }

  ontimelineUnscheduledChange(unscheduled) {
    let qp = {};
    let timelineConfig = this.route.snapshot.queryParams?.timelineConfig;
    timelineConfig = timelineConfig ? JSON.parse(timelineConfig) : {};
    timelineConfig['unscheduled'] = unscheduled.toString();
    qp['timelineConfig'] = JSON.stringify(timelineConfig);
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }

  onRecordDrag(data) {
    this.store.dispatch(new RecordsPage.RecordDrag(data));
  }

  onUnscheduledNext() {
    this.store.dispatch(new RecordsPage.RetrieveUnscheduledRecords(this.queryParams, this.unscheduledPage));
    this.unscheduledPage++;
  }

  isEqual(value, queryParams) {
    for (const key in value) {
      if (Array.isArray(value[key]) && value[key].length === 1) {
        value = {
          ...value,
          [key]: value[key][0]
        };
      }
    }
    return _.isEqual(value, _.omit(queryParams, ['page']));
  }

  onPrint() {
    window.print();
  }

  onGanttDateChange(data) {
    const entity = this.store.snapshot().app.recordsPage.entity;
    let currentRecord = this.store.snapshot().app.recordsPage.records.find((r) => r.id === data.record.id);
    let fieldValueMapping = {};

    let startDate = moment(data.start);
    let endDate = moment(data.end);

    if (entity.startingDateField && !((new Date(currentRecord.value[entity.startingDateField.id])).getTime() == startDate.toDate().getTime())) {
      if (entity.startingDateField?.type == 'Date')
        startDate = startDate.startOf('day')
      fieldValueMapping[entity.startingDateField.id] = startDate.toDate();
    }
    if (entity.endingDateField && !((new Date(currentRecord.value[entity.endingDateField.id])).getTime() == endDate.toDate().getTime())) {
      if (entity.endingDateField?.type == 'Date')
        endDate = endDate.isSame(endDate.clone().startOf('day')) ? endDate.subtract(1, 'day').endOf('day') : endDate.endOf('day');
      fieldValueMapping[entity.endingDateField.id] = endDate.toDate();
    }

    this.store.dispatch(new RecordsPage.UpdateRecordFields(currentRecord, fieldValueMapping)).subscribe(
      () => {},
      (e) => {
        if (e.status === 400) {
          this.store.dispatch(new RecordsPage.Init({'entity': this.params.id, ...this.queryParams})).subscribe((res) => {});
        }
      }
    );
  }


  onGanttScroll() {
    this.loading = true;
    this.store.dispatch(new RecordsPage.RetrieveRecords({'entity': this.params.id, ...this.queryParams}, this.page + 1)).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.router.navigate([], {
        queryParams: { page: this.page+1 },
        queryParamsHandling: 'merge',
        replaceUrl: true
      }).then(() => {
        this.page++;
      });
      this.loading = false;
    });
  }

  onGanttScopeChange(scope) {
    const qp = {};
    qp['timelineConfig'] = JSON.stringify({'scope': scope});
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }

  toggleArchiveMode() {
    if (this.queryParams['is_archived']){
      this.updateQueryParams("is_archived", null)
    }else {
      this.updateQueryParams('is_archived', "true");
    }
  }

  toggleIsArchived() {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }
    const entity = this.store.snapshot().app.recordsPage.entity;
    const recordsPageCount = this.store.snapshot().app.recordsPage.count;
    let archiveMod = true;
    if (this.queryParams['is_archived']){
      archiveMod = false
    }
    this.store.dispatch(new RecordsPage.UpdateArchived({'entity': this.params.id, ...this.queryParams}, archiveMod)).subscribe((res) => {
      if (archiveMod){
        this.toastr.success(translate("Les records ont été correctement archivés",{title:entity.plural, count:recordsPageCount, e:entity.isMasculine ? '' : 'e'}));
      } else {
        this.toastr.success(translate("Les records ont été correctement désarchivés",{title:entity.plural, count:recordsPageCount, e:entity.isMasculine ? '' : 'e'}));
      }
      this.store.dispatch(new RecordsPage.Init({'entity': this.params.id, ...this.queryParams}))
      })
  }

  // TODO change in ts file: close sider on mobile
  closeSidebar(){
    if (this.localService.isMobile()) {
      $('.dismiss-offcanvas').click();
    }
  }

  viewIsHidden(view) {
    return _.find(this.store.snapshot().app.recordsPage.hiddenViews, u => u?.id === view?.id) != undefined
  }

  calculateHiddenViews() {
    if (this.viewsContainer && this.views.length) {
      const viewsContainer = this.viewsContainer.nativeElement;
      viewsContainer.classList.add("overflow-hidden");
      const views = _.cloneDeep(this.views);
      const newButtonWidth = this.viewInputRef.nativeElement.clientWidth;
      this.hiddenViews = [];
      let moreButtonWidth = 0;
      if (this.moreButton) {
        this.moreButton.nativeElement.hidden = false
        moreButtonWidth = this.moreButton.nativeElement.clientWidth;
      }
      views.forEach((view, index) => { view.nativeElement.children[0].hidden = false });
      const activeView = views.filter((e, i)=> e.nativeElement.classList.contains('active'))[0];
      let visibleWidth = newButtonWidth + moreButtonWidth + (activeView?.nativeElement.clientWidth ? activeView?.nativeElement.clientWidth : 0);
      views.forEach((view, index) => {
        if ((visibleWidth + view.nativeElement.children[0].clientWidth) >= this.viewsBarContainer?.nativeElement?.clientWidth-485) {
          if (!view.nativeElement.children[0].classList.contains('active')) {
            visibleWidth += view.nativeElement.children[0].clientWidth;
            view.nativeElement.children[0].hidden = true;
            this.hiddenViews.push(_.find(this.store.snapshot().app.recordsPage.views, u => u?.id === +view.nativeElement.children[0].id.split("-")[1]));
          }
        } else {
          if (!view.nativeElement.children[0].classList.contains('active')) visibleWidth += view.nativeElement.children[0].clientWidth;
        }
      });
      if (this.hiddenViews.length == 0) {
        this.moreButton.nativeElement.hidden = true
      }
      this.store.dispatch(new RecordsPage.UpdateHiddenViews(this.hiddenViews));
      viewsContainer.classList.remove("overflow-hidden");
      this.views = views;
      this.changeDetector.detectChanges();
    }
  }
  onActionExecute(evt) {
    if (evt.confirmationModal) {
      evt.confirmationModal.close();
    }
    this.executingActionId = evt.action.id;
    this.store.dispatch(new RecordsPage.ExecuteAction(this.queryParams,evt.action.id, evt.inp)).subscribe({
      next: () => {
        if (evt.action.isCelebrated==true ){
          party.confetti(document.getElementById("pageListing"), {
            count: party.variation.range(150, 250),
            shapes: ["star", "roundedSquare"],
          });}
        if (evt.intermediateModal) {
          evt.intermediateModal.close();
        }
        const commandResult = this.store.snapshot().app.recordsPage.commandResult;
        if (commandResult && commandResult.message) {
          if (!commandResult.silent) this.toastr.success(commandResult.message);
        } else {
          if (!commandResult || !commandResult.silent) this.toastr.success(translate('Opération effectuée')+' !');
        }
        if (commandResult && commandResult.file) {
          const htmlAnchorElement = document.createElement("a");
          htmlAnchorElement.href = "data:application/octet-stream;base64," + commandResult.file;
          htmlAnchorElement.download = commandResult.filename ? commandResult.filename : "file";
          htmlAnchorElement.click();
          htmlAnchorElement.remove();
        }
        this.executingActionId = null;
        if (commandResult && commandResult.url) {
          if (!commandResult.url.startsWith('/')) {
            window.open(commandResult.url);
          }
        }
      },
      error: (err) => {
        this.executingActionId = null;
      }
    });
  }

  saveCurrentView() {
    let value = _.cloneDeep(_.omit(this.queryParams, ['v']));
    for (const key in value) {
      if (Array.isArray(value[key]) && value[key].length === 1) {
        value = {
          ...value,
          [key]: value[key][0]
        };
      }
    }
    this.store.dispatch(new RecordsPage.UpdateView(this.store.snapshot().app.recordsPage.latestView.id, {value: value}));
  }

  saveAsNewView() {
    const recordsPageState = this.store.snapshot().app.recordsPage;
    const previousViews = recordsPageState.views.map(view => view.title);

    const savedView = { ...recordsPageState.latestView };
    delete savedView['id'];

    const newViewTitle = translate('Nouvelle');
    const isNewView = _.isEqual(previousViews, ['']) || !previousViews.some(view => view.includes(newViewTitle));

    if (isNewView) {
      savedView.title = newViewTitle;
    } else {
      const newViewCount = previousViews.filter(view => view.includes(newViewTitle)).length;
      savedView.title = `${newViewTitle} [${newViewCount}]`;
    }

    const queryParams = _.mapValues(this.queryParams, value => (_.isArray(value) && value.length === 1 ? value[0] : value));

    savedView.value = { ...savedView.value, ...queryParams };

    this.store.dispatch(new RecordsPage.CreateView(savedView)).subscribe(res => {
      this.onViewSelect(res.app.recordsPage.latestView);
      this.calculateHiddenViews();
    }, () => {});
  }

  resetFilters() {
    this.router.navigate([], {
      queryParams: {v: this.store.snapshot().app.recordsPage.latestView.id, ...this.store.snapshot().app.recordsPage.latestView.value},
    });
  }

  isSaveButtonVisible() {
    let queryParamsClone = _.cloneDeep(_.omit(this.queryParams, ['v']))
    if (queryParamsClone?.hiddenCols?.length === 1){
      queryParamsClone.hiddenCols = queryParamsClone.hiddenCols[0]
    }
    return !this.isEqual(this.store.snapshot().app.recordsPage.latestView?.value, queryParamsClone);
  }

  duplicateView(view) {
    let newView={};
    _.forEach(view, (val, key) => {
        newView[key] = val;
    })
    delete newView['id'];
    const re = /[ ]\((\d+)\)|$/;
    const result = newView['title'].match(re)[1];
    newView['title'] = newView['title'] == '' ? (this.store.snapshot().app.recordsPage.entity.isMasculine ? translate('Tous') : translate('Toutes')) : newView['title']
    newView['title'] = newView['title'].replace(re, ` (${ result ? +result + 1 : 1 })`);
    this.store.dispatch(new RecordsPage.CreateView(newView)).subscribe(res => {
        this.onViewSelect(res.app.recordsPage.latestView);
        this.calculateHiddenViews();
      }, () => {});
  }

  fetchUsers$ = (term: any) => {
    if (term !== this.currentSearchTerm) {
      this.currentPage = 1;
    }
    this.currentSearchTerm = term;
    let paginationParams = {page: 1, page_size: 18 * this.currentPage};
    term = term === null && this.focused ? '' : term;
    const params = {...paginationParams, search: term};
    return this.userService.retrieveObjects(params).pipe(
      mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])),
      map((items: any) => {
        return items;
      }))

  }

  initUsers() {
    const debouncedText$ = this.typedInput$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focus$;
    const everyOneFakeUser = fakeEveryOne();
    this.users$ = concat(
      of([]),
      merge(debouncedText$, inputFocus$).pipe(
        tap(() => {this.loading = true; this.changeDetector.detectChanges()}),
        mergeMap(term => {
          return this.fetchUsers$(term).pipe(
            tap((items) => {
              if (!this.listeningTypeAheadScroll) {
                setTimeout(() => {
                  let usersWindow = document.getElementsByTagName("ngb-typeahead-window")[0] || document.getElementsByClassName("ng-dropdown-panel-items")[0];
                  if (usersWindow) {
                    this.loading = false;
                    this.changeDetector.detectChanges();
                    usersWindow.addEventListener("scroll", (event) => {
                      if (this.canSearch && (usersWindow.scrollTop + usersWindow.clientHeight + 2 >= usersWindow.scrollHeight)) {
                        this.listeningTypeAheadScroll = true;
                        this.currentPage++;
                        this.canSearch = false;
                        inputFocus$.next(this.currentSearchTerm);
                      }
                    }, false);
                  } else {
                    this.loading = false;
                  }
                })
              } else {
                this.loading = false;
              }
            })
          )
        }),
        tap((items: any) => {
          items.unshift(everyOneFakeUser);
        })
      )
    );
  }

  focus(event: any) {
    const ngbTypeaheadWindow = document.getElementsByTagName("ngb-typeahead-window")[0];
    if (!ngbTypeaheadWindow && window.matchMedia("only screen and (max-width: 991px)").matches) {
      event.target.blur();
    }
    this.valueControl = new UntypedFormControl(this.currentSearchTerm);
    setTimeout(() => {
      this.focus$.next(event.target?.value);
    });
  }

  focusout(event) {
    this.listeningTypeAheadScroll = false;
    this.loading = false;
    if(event.relatedTarget){
      this.valueControl = new UntypedFormControl('');
    }
  }

  submit(view, changed: any) {
    const hasEveryOne = _.find(changed, { 'id': -1});
    changed = _.reject(_.filter(changed, (user) => this.getUserFromValue(view.users, user, 'id')), {'id': -1});
    if (hasEveryOne){
      this.store.dispatch(new RecordsPage.UpdateView(view.id, {isPublic: true, users: changed}));
    } else {
      this.store.dispatch(new RecordsPage.UpdateView(view.id, {isPublic: false, users: changed}));
    }
  }

  trackByViewId: TrackByFunction<any> = (index, view) => view.id;

  getUserFromValue(view, user, key) {
    if (view.users && user && !user[key]) {
      return _.find(_.concat(
        view.isPublic ? [fakeEveryOne()] : [], view.users), u => u.id === user.id);
    }
    return user;
  }

  getUsers$ = (text$?: any) => {
    if (text$) {
      text$.subscribe((t) => {
        this.typedInput$.next(t);
      });
    }
    return this.users$;
  }

  private retrieveObjects(ids, field, service) {
    let observables$ = ids.map(value => value !== "null" ? service.retrieveObject(value) : of(null))
    forkJoin(observables$).subscribe((objects: any[]) => {
        // this.activeFilters[field.name]['values'] = objects;
        let result = "";
        switch (field.type) {
          case 'SingleSelect':
            objects.map(e => result += (e != null ? e.title : 'N/A') + ', ');
            break;
          case 'MultiSelect':
            objects.map(e => result += (e != null ? e.title : 'N/A') + ', ');
            break;
          case 'CreatedBy':
          case 'User':
          case 'E-Signature':
          case 'Users':
            objects.map(e => result +=  (e != null ? e.fullName : 'N/A') + ', ');
            break;
          case 'OneToOne':
          case 'InverseOneToOne':
          case 'OneToMany':
          case 'InverseOneToMany':
          case 'ManyToMany':
          case 'InverseManyToMany':
          case 'Checklist':
            objects.map(e => result += e.str + ', ');
            break;
        }
        this.activeFilters[field.name]['values'] = result.slice(0, -2);
        this.changeDetector.detectChanges();
    });
  }

}
