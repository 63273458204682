import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'exist',
})
export class ExistPipe implements PipeTransform {
  transform(static_objects, dynamic_objects, path): any {
    return static_objects.filter((static_object)=> {
      let dynamic_object = dynamic_objects.filter((obj)=> { return obj.id==static_object.id; })[0]
      return _.get(dynamic_object, path)
    })
  }
}
