import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subject, filter } from 'rxjs';
import { TranslocoLocaleService } from "@jsverse/transloco-locale";
import { translate } from "@jsverse/transloco";
import DOMPurify from 'dompurify';
import { NgxMaskPipe } from 'ngx-mask';
import * as _ from 'lodash';

import { Store } from '@ngxs/store';
import { RecordsPage } from 'src/app/state/app.actions';
import { FieldComponent } from '../field/field.component';
import { LocalService } from 'src/app/services/local.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { UserService } from 'src/app/services/user.service';
import {attachmentIconMapping, openDetailPage} from 'src/app/app.utils';
import { FieldValueToClassPipe } from 'src/app/pipes/field-value-to-class.pipe';
import { FormatDatePipe } from 'src/app/pipes/format-date.pipe';
import { FormatDateTimePipe } from 'src/app/pipes/format-datetime.pipe';
import { ReplaceStrPipe } from 'src/app/pipes/replace-str.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { markdownToElement } from 'src/app/app.utils';
declare let tui: any;

@Component({
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements OnInit, OnDestroy, AfterViewInit,  OnChanges {
  @Input() records = [];
  @Input() entity = null;
  @Input() isReady;
  @Input() allElementsLoaded;
  @Input() groupBy;
  @Input() hiddenColumns: number[] = [];
  @Input() isLoading;

  @Output() onScrollGrid = new EventEmitter();
  @Output() onUpdateHiddenColumns = new EventEmitter<number[]>();
  @Output() gridRecordLinkClick = new EventEmitter<{recordId: number, openModal: boolean}>();

  @ViewChild("fieldContainer") fieldContainerRef: ElementRef;
  @ViewChild(FieldComponent) fieldEditRef: FieldComponent;
  @ViewChild("grid") gridRef: ElementRef;
  @ViewChild("recordDetailLinkRef") recordDetailLinkRef: ElementRef;

  readonly #takeUntilDestroyed = takeUntilDestroyed();

  public columns: TableCol[];
  public dynamicColumns: TableCol[];
  public isActiveFieldEdit = false;
  public currentRecord;
  public currentField;
  public currentValue;
  public isActiveRecordLink = false;
  public linkedRecord;

  private grid;
  private fields: Map<number, any> = new Map<number, any>();
  private groupingColumn: number;
  private pinnedColumnsCount = 0;
  private currentPage: number;
  private tableChanges$ = new Subject<TableChange>();
  private currentTableChange: TableChange;

  private locale = this.translocoLocaleService.getLocale();

  private fieldTypeTemplateMapping = {
    'OneToMany': this.getRecordFieldTemplate.bind(this),
    'OneToOne': this.getRecordFieldTemplate.bind(this),
    'InverseOneToOne': this.getRecordFieldTemplate.bind(this),
    'Date': this.getDateFieldTemplate.bind(this),
    'CreatedAt': this.getDateFieldTemplate.bind(this),
    'LastModifiedAt': this.getDateFieldTemplate.bind(this),
    'DateTime': this.getDateTimeFieldTemplate.bind(this),
    'SingleSelect': this.getSingleSelectFieldTemplate.bind(this),
    'MultiSelect': this.getMultiSelectFieldTemplate.bind(this),
    'Image': this.getImageFieldTemplate.bind(this),
    'EmptyImage': this.getEmptyImageFieldTemplate.bind(this),
    'Ressource': this.getRessourceFieldTemplate.bind(this),
    'EmptyRessource': this.getEmptyRessourceFieldTemplate.bind(this),
    'User': this.getUserFieldTemplate.bind(this),
    'CreatedBy': this.getUserFieldTemplate.bind(this),
    'E-Signature': this.getESignatureFieldTemplate.bind(this),
    'Users': this.getUsersFieldTemplate.bind(this),
    'Space': this.getSpaceFieldTemplate.bind(this),
    'Spaces': this.getSpacesFieldTemplate.bind(this),
    'ManyToMany': this.getManyFieldTemplate.bind(this),
    'InverseManyToMany': this.getManyFieldTemplate.bind(this),
    'InverseOneToMany': this.getManyFieldTemplate.bind(this),
    'Number': this.getNumberFieldTemplate.bind(this),
    'Location': this.getLocationMapFieldTemplate.bind(this),
    'EmptyLocation': (record, field, value) => `<div><i class="fa-duotone fa-map-location-dot fa-8x text-gray-300"></i></div>`,
    'Checkbox': this.getCheckboxFieldTemplate.bind(this),
    'LongText': this.getLongTextFieldTemplate.bind(this),
    'ShortText': this.getShortTextFieldTemplate.bind(this),
    'Default': (record, field, value) => `<span class="text-muted fs-4 px-2">--</span>`,
  };

  constructor(
      private store: Store,
      private localService: LocalService,
      private webSocketService: WebSocketService,
      private userService: UserService,
      private translocoLocaleService: TranslocoLocaleService,
      private changeDetectionRef: ChangeDetectorRef,
      private maskPipe: NgxMaskPipe,
      private fieldValueToClass: FieldValueToClassPipe,
      private dateFormat : FormatDatePipe,
      private dateTimeFormat: FormatDateTimePipe,
      private replaceStr: ReplaceStrPipe,
  ) {}

  ngOnInit(): void {
    this.tableChanges$.pipe(this.#takeUntilDestroyed).subscribe((change: TableChange) => {
      this.currentTableChange = change;
    })

    this.webSocketService.messages.pipe(filter(data => (data !==  null && data.message.type === 'highlight')))
    .pipe(this.#takeUntilDestroyed).subscribe((data: any) => {
      const content = data.message.data;
      if(content.entity && content.entity === this.entity?.id){
        if(content.subclassName === "CreateRecordActivity"){
          this.tableChanges$.next({entity: content.entity, record: content.record, action: TableActions.ADD_RECORD})
        }else if(content.actor && content.actor !== this.userService.localService.getUser()){
          this.tableChanges$.next({
            entity: content.entity,
            record: content.record,
            highlight: {field: content.field, fullName: content.fullName, color: content.color},
            action: TableActions.EDIT_RECORD
          });
          this.currentRecord = null;
        }
      }
    });

    this.webSocketService.messages.pipe(filter(data => (data !==  null && ['edition', 'action'].includes(data.message.type))),
      this.#takeUntilDestroyed).subscribe((data: any) => {
      const message = data.message;
      if (data.message.type === 'edition'){
        if (message.sender.id && message.sender.entity && message.method === 'DELETE'){
          if (message.sender.entity === this.entity?.id){
            this.tableChanges$.next({entity: message.sender.entity, record: message.sender.id, action: TableActions.REMOVE_RECORD});
          }
        }
      }
    });

    this.entity.blocks.forEach(block => {
      block.fields.filter(f => f.isPinned || f.isActiveTable).forEach(f => { this.fields.set(f.id, f)});
    })
    const fields = Array.from(this.fields.values());
    this.pinnedColumnsCount = fields.reduce((n, f) => n + (f.isPinned), 0);
    if(!_.isArray(this.hiddenColumns)){this.hiddenColumns = [this.hiddenColumns]};
    this.columns = this.fieldsToColumns();
    const mainFieldId = this.entity.mainField?.id ? this.entity.mainField.id : this.entity.mainField;
    this.dynamicColumns = this.columns.filter(col => col.fieldId !== mainFieldId);
    this.groupingColumn = mainFieldId &&  _.find(this.columns, {fieldId: mainFieldId}) ? mainFieldId : this.columns[0]?.fieldId;
  }

  ngAfterViewInit(): void {
    const rows = this.records.map(record => this.recordToRow(record));
    if(this.gridRef){
      if(this.localService.getTheme() === 'dark') this.gridRef.nativeElement.classList.add('dark-mode');
      if(this.columns.length && rows.length){
        this.initTable(this.gridRef.nativeElement, this.columns, rows);
        this.currentPage = 1;
        this.waitForElm('.tui-grid-body-area').then((elem: HTMLElement) => {
          this.grid.refreshLayout()
          elem.addEventListener('scroll', () => {
            if (elem.scrollTop + elem.clientHeight + 2 >= elem.scrollHeight) {
              if(!this.allElementsLoaded){
                this.onScrollGrid.emit();
                this.tableChanges$.next({entity: this.entity.id, action: TableActions.PAGINATION});
                this.currentPage += 1;
              }
            }
          });

          this.grid.on('mouseover', (ev) => {
            const { rowKey } = ev;
            const row = this.grid.getRow(rowKey);
            if(row){
              if(!this.isActiveRecordLink) this.isActiveRecordLink = true
              if(this.linkedRecord?.id !== row.recordId){
                this.linkedRecord = this.records.filter(r => r.id === row.recordId)[0];
                this.changeDetectionRef.detectChanges();
                if(this.recordDetailLinkRef){
                  const position = ev.nativeEvent.srcElement.getBoundingClientRect();
                  const linkTop = ((position.top + position.bottom) / 2)  - this.gridRef.nativeElement.getBoundingClientRect().top 
                  this.recordDetailLinkRef.nativeElement.style.top = linkTop + 'px';
                }
              }
            }
          });

          this.grid.on('mouseout', (ev) => {
            const { rowKey } = ev;
            const row = this.grid.getRow(rowKey);
            if(!row){
              this.isActiveRecordLink = false;
              this.linkedRecord = null;
              this.changeDetectionRef.detectChanges();
            }
          });
        })
      }
    };

    this.localService.siderVisibility$.pipe(this.#takeUntilDestroyed).subscribe(visibility => {
      setTimeout(() => {
        if(this.grid) this.grid.refreshLayout();
      }, 300)
    });

    this.localService.theme$.pipe(this.#takeUntilDestroyed).subscribe((val) => {
      if(this.gridRef){
        val === 'dark' ? this.gridRef.nativeElement.classList.add('dark-mode') : this.gridRef.nativeElement.classList.remove('dark-mode');
      }
    })
    if(this.grid) this.grid.refreshLayout();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes): void {
      if(changes?.records && changes.records.previousValue && this.currentTableChange && this.currentTableChange.entity === this.entity.id){
        const prevRecordIds = changes.records.previousValue.map(r => r.id);
        this.updateTable(prevRecordIds, this.currentTableChange);
        this.currentTableChange = undefined;
      }
  }

  toggleColumn(columnName: number, event: any){
    const column = this.dynamicColumns.find(col => col.name === columnName);
    const isPinned = this.fields.get(columnName).isPinned;
    const isHiddenCol = event.target.checked === false;
    if (column) {
      column.hidden = isHiddenCol;
      if (isHiddenCol) {
        this.grid.hideColumn(columnName);
        if(isPinned){
          this.pinnedColumnsCount -= 1;
          this.grid.setFrozenColumnCount(this.pinnedColumnsCount);
        }
      } else {
        this.grid.showColumn(columnName);
        if(isPinned){
          this.pinnedColumnsCount += 1;
          this.grid.setFrozenColumnCount(this.pinnedColumnsCount);
        }
      }
      const hiddenColumns = this.columns.filter(col => col.hidden).map(col => col.fieldId);
      this.onUpdateHiddenColumns.emit(hiddenColumns);
    }
  }

  fieldsToColumns(): TableCol[]{
    const fields = Array.from(this.fields.values());
    const pinnedCols = fields.filter(f => f.isPinned);
    const nonPinnedCols = fields.filter(f => !f.isPinned);
    return [...pinnedCols, ...nonPinnedCols].map(f => ({
      name: f.id,
      header: f.title ,
      fieldId: f.id,
      hidden: this.hiddenColumns.includes(f.id)
    }));
  }

  recordToRow(record: any | GroupingRecord): TableRow{
    let row = {recordId: record.id};
    if(record.id === -1){
      this.columns.forEach(col => {row[col.name] = {field: null, record: null}});
      row[this.groupingColumn].field = this.fields.get(this.groupingColumn);
      row[this.groupingColumn].record = record;
    }
    else{
      this.columns.forEach(col => {row[col.name] = {field: this.fields.get(col.name), record }});
    }
    return row;
  }

  initTable(el: HTMLElement, cols: TableCol[], rows: TableRow[]): void{
    el.innerHTML = '';
    this.grid = new tui.Grid({
      el: el,
      scrollX: true,
      scrollY: true,
      data: rows,
      columns: cols.map(col =>  { return {
        ...col,
        //whiteSpace: 'wrap',
        minWidth: 300,
        valign: 'center',
        renderer: {
          type: FieldRenderer,
          options: {
            grid: this,
          }
        }
      }}),

      columnOptions: {
        frozenCount: this.localService.isMobile() ? _.max([this.pinnedColumnsCount, 1]) : this.pinnedColumnsCount,
      },
      contextMenu:null,
      bodyHeight: 'fitToParent',
      header: {
        height: 60
      },
      rowHeight: 80,
   });
    tui.Grid.applyTheme('striped', {
      selection: {
        background: '#EFF0FF',
        border: "#EFF0FF"
      },
      cell: {
        normal: {
          background: '#fff',
          border: '#e0e0e0',
          showVerticalBorder: false,
          showHorizontalBorder: false,
        },
        header: {
          showVerticalBorder: false,
          showHorizontalBorder: false,
          height: 70
        },
        selectedHeader: {
          background: '#e0e0e0'
        },
      },
      rowHeader: {
        border: '#ccc',
        showVerticalBorder: false,
        borderRadius: '10px !important'
      },

    });
  }

  waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  onDeactivateField(e?: any){
    if (e && (Object.values(e.srcElement.classList).indexOf('delete') > -1)) return;
    this.isActiveFieldEdit = false;
    this.changeDetectionRef.detectChanges();
  }

  activateField(record, field, clickedElem?: HTMLElement){
      if(this.isActiveFieldEdit) this.onDeactivateField();
      if(this.currentRecord?.id !== record.id) this.currentRecord = record;
      if(this.currentField?.id !== field.id) this.currentField = field;
      this.isActiveFieldEdit = true;
      this.changeDetectionRef.detectChanges();

      if(clickedElem){
        const gridContainerRect: DOMRect = document.getElementById('grid-container').getBoundingClientRect();
        const clickedCellRect: DOMRect = clickedElem.getBoundingClientRect();

        if(field.type === 'LongText'){
          this.fieldContainerRef.nativeElement.style.width = '450px';
        }else{
          this.fieldContainerRef.nativeElement.style.width = clickedElem.offsetWidth + 'px';
        }

        this.fieldContainerRef.nativeElement.style.left = clickedCellRect.left + window.scrollX + 'px';
        this.fieldContainerRef.nativeElement.style.top = clickedCellRect.top + window.scrollY + 'px';
        const fieldContainerRect: DOMRect = this.fieldContainerRef.nativeElement.getBoundingClientRect();

        if (fieldContainerRect.right >= gridContainerRect.right)  {
          const adjustedLeft = fieldContainerRect.left - (fieldContainerRect.right + 30 - clickedCellRect.right);
          this.fieldContainerRef.nativeElement.style.left =  adjustedLeft +'px';
        }
      }

  }

  openEditField(){
    this.fieldContainerRef.nativeElement.querySelector('.field-wrapper').click();
  }

  uploadRessource(){
    if(this.fieldEditRef) this.fieldEditRef.uploadFile();
  }

  onUpdateFieldSubmit(record: any, field:any, value:any): void{
    const fieldCell = document.getElementById(`table-field-${record.id}-${field.id}`);
    let newFieldTemplate: string;
    const oldFieldTemplate = fieldCell.innerHTML;
    if(['MultiSelect', 'Users'].includes(field.type)){
      value = this.fieldEditRef.value;
      newFieldTemplate = this.getFieldRenderingTemplate(record, field, this.fieldEditRef.value);
    }else{
      newFieldTemplate = this.getFieldRenderingTemplate(record, field, value);
    }

    const subject = this.store.dispatch(new RecordsPage.UpdateRecordFields(record, {[field.id]: value}));

    fieldCell.innerHTML = newFieldTemplate;
    const recordClone = _.cloneDeep(record);
    recordClone.value[field.id] = value;
    this.currentRecord = recordClone;

    this.tableChanges$.next({entity: this.entity.id, record: record.id, action: TableActions.EDIT_RECORD})
    subject.pipe(this.#takeUntilDestroyed).subscribe(
      () => {
      },
      (e) => {
        fieldCell.innerHTML = oldFieldTemplate;
        this.currentRecord = record;
      });
    this.onDeactivateField();
  }

  private updateTable(prevRecordIds: number[], updateData: TableChange): void{
      switch(updateData.action){
        case  TableActions.ADD_RECORD : {
          const newRecordIndex = this.records.findIndex(r => r.id === updateData.record);
          if(newRecordIndex !== -1){
            this.addNewRecord(newRecordIndex);
            // adding new record should preserve the page size
            if(prevRecordIds.length === this.records.length){
              const currentRecordIds = this.records.map(r => r.id).filter(r => r !== -1);
              const deletedRecordId = _.difference(prevRecordIds.filter(r => r !== -1), currentRecordIds)[0];
              this.removeRecord(deletedRecordId);
            }
          }
          break;
        }
        case  TableActions.REMOVE_RECORD : {
          this.removeRecord(updateData.record);
          break;
        }
        case  TableActions.EDIT_RECORD : {
          // updating a record could add it to or romove it from current view as well as changing the group or order
          const prevIndex = prevRecordIds.findIndex(record => record === updateData.record);
          const currIndex = this.records.findIndex(record => record.id === updateData.record);

          if(prevIndex !== -1 && currIndex !== -1){
            const rowKey = this.getRecordRowKey(updateData.record);
            const newRow = this.recordToRow(this.records[currIndex]);
            const prevRow = this.grid.getRow(rowKey);
            let waitTime = 0;

            if(updateData.highlight){
              const cellElement = document.getElementById(`table-field-${updateData.record}-${updateData.highlight.field}`);
              if(cellElement){
                this.applyHighlights(
                  {fullName: updateData.highlight.fullName , color: updateData.highlight.color},
                  cellElement.parentElement
                );
                waitTime = 1001;
              }
            }
            setTimeout(() => {
              if(this.groupBy){
                this.removeRecord(updateData.record);
                this.addNewRecord(currIndex);
              }else{
                this.grid.setRow(rowKey, {...newRow, _attributes: prevRow._attributes});
                if(prevIndex !== currIndex){
                  this.grid.moveRow(rowKey, currIndex)
                }
              }
            }, waitTime);

          }else if(prevIndex !== -1){
            this.removeRecord(updateData.record);
          }else if(currIndex !== -1){
            this.addNewRecord(currIndex);
          }
          break;
        }
        case  TableActions.PAGINATION : {
          let nextPageRecords = this.records.slice(prevRecordIds.length);
          const newRows = nextPageRecords.map(record => this.recordToRow(record));
          this.grid.appendRows(newRows);
          break;
        }
      }
  }

  private addNewRecord(newRecordIndex: number): void{
    if(this.groupBy && this.checkNewGroupWasAdded(newRecordIndex)){
        const recordGroupIndex = newRecordIndex -1;
        const newGroupRow = this.recordToRow(this.records[recordGroupIndex]);
        this.grid.appendRow(newGroupRow);
        const newGroupRowKey = this.grid.getRowAt(this.grid.getData().length -1).rowKey
        this.grid.moveRow(newGroupRowKey, recordGroupIndex)
    }
    const newRow = this.recordToRow(this.records[newRecordIndex]);
    this.grid.appendRow(newRow);
    const rowKey = this.grid.getRowAt(this.grid.getData().length -1).rowKey;
    this.grid.moveRow(rowKey, newRecordIndex);
  }

  private removeRecord(recordId: number): void{
    const rowKey = this.getRecordRowKey(recordId);
    this.grid.removeRow(rowKey);

    if(this.groupBy){ // clean empty group
      const data = this.grid.getData();
      for(const [index, row] of data.entries()){
        if((row.recordId === -1 && index === data.length -1) || (row.recordId === -1 &&  data[index + 1].recordId === -1)){
          this.grid.removeRow(this.grid.getRowAt(index).rowKey);
          break;
        }
      }
    }
  }

  checkNewGroupWasAdded(newRecordIndex: number): boolean{
    if(this.records[newRecordIndex - 1].id === -1){
      if(newRecordIndex === this.records.length -1){
        return true;
      }
      return this.records[newRecordIndex + 1].id === -1;
    }
    return false;
  }

  getRecordRowKey(recordId: number): number{
    for(let row of this.grid.getData()){
      if(row.recordId === recordId){return row.rowKey}
    }
    return -1;
  }

  applyHighlights(notification: {fullName: string, color: string}, element: HTMLElement) {
    const classList = ['ribbon', 'ribbon-top', 'rounded', 'highlighted']
    element.classList.add(...classList);

    const ribbon = document.createElement('div')
    ribbon.className = 'ribbon-label';
    ribbon.innerHTML = notification.fullName;
    ribbon.style.backgroundColor = notification.color;
    element.prepend(ribbon);

    setTimeout(() => {this.removeHighlights(ribbon, classList, element)}, 3000)
  }

  removeHighlights(ribbon: HTMLElement, classList: string[], element: HTMLElement) {
    ribbon.remove();
    element.classList.remove(...classList);
  }

  onRecordLinkClick(evt){
    evt.preventDefault();
    if (!this.entity.isDetailPageInModal) return;
    this.gridRecordLinkClick.emit({
      recordId: this.linkedRecord.id,
      openModal: this.entity.isDetailPageInModal
    });
  }
 
  getFieldRenderingTemplate(record, field, value) {
    let key = this.isEmpty(value) ? `Empty${field.type}` : field.type;
    key = key in this.fieldTypeTemplateMapping ? key : 'Default';
    return this.fieldTypeTemplateMapping[key](record, field, value);
  }

  private isEmpty(value) {
      return value == null || value === '' || value.length === 0 || (value.user === null);
  }

  private getEmptyRessourceFieldTemplate(recordId: number, field) {
      const inptId = `resource-${recordId}-${field.id}`;
      const element =
          `<input id="${inptId}" class="upload-file upload-resource"
                  style="display: none;" type="file" placeholder="${field.help ? field.help : ''}"
              />
          <div class="card border-0 bg-transparent w-100 text-center">
              <div class="card-body p-0">
                  <i class="fa-duotone fa-file fa-5x text-gray-300" style="width: 100px;"></i>
              </div>
          </div>`;

      return element;
  }

  private getEmptyImageFieldTemplate(recordId, field) {
      const inptId = `image-${recordId}-${field.id}`;
      const element =
          `
          <input id="${inptId}" class="upload-file upload-image"
                  style="display: none;" type="file" placeholder="${field.help ? field.help : ''}"
              />
          <div class="card border-0 bg-transparent w-100 text-center">
              <div class="card-body  p-0">
                  <i class="fa-duotone fa-image fa-5x text-gray-300" style="width: 100px;"></i>
              </div>
          </div>
          `;

      return element;
  }

  private getLocationFieldTemplate() {
      return `<div><i class="fa-duotone fa-map-location-dot fa-8x text-gray-300"></i></div>`
  }

  private getRecordFieldTemplate(record, field, value) {
      let span = '';
      if(value?.id){
          let style = record.fields.filter(f => f.id === field.id)[0]?.style
          style = style ? style : 'text-info';
          span = `
              <span style="pointer-events: auto;" class="table-clicked-element text-link fw-bold fs-5 truncate ${style}">
              ${value.str}
              </span>
          `;
      };
      const forbidden = value == 'FORBIDDEN' ? '<i class="fa-user-lock fal mt-1"></i>' : '';
      const element = `<div class="truncate">${span}${forbidden}</div>`;
      return element;
  }

  private getDateFieldTemplate(record, field, value) {
      let style = record.fields.filter(f => f.id === field.id)[0]?.style;
      style = style ? style  : 'text-gray-800';
      const dateFormate = this.dateFormat.transform(new Date(value).toLocaleString());
      const element = `<div class="fs-5 truncate ${style}">${dateFormate}</div>`;
      return element;
  }

  private getDateTimeFieldTemplate(record, field, value) {
      let style = record.fields.filter(f => f.id === field.id)[0]?.style;
      style = style ? style  : 'text-gray-800';
      const dateFormate = this.dateTimeFormat.transform(new Date(value).toLocaleString());
      const element = `<div class="fs-5 truncate ${style}">${dateFormate}</div>`;
      return element;
  }

  private getSingleSelectFieldTemplate(record, field, value) {
      let valueClassMapping = this.fieldValueToClass.transform(value.name, field.valueClassMapping);
      let dynamicClass = valueClassMapping ? valueClassMapping : 'd-inline-block';
      if (!valueClassMapping) {
          dynamicClass += 'text-gray-800';
      }
      const element = `<span class="fs-5 truncate ${dynamicClass}">${value.title}</span>`;
      return element;
  }

  private getMultiSelectFieldTemplate(record, field, value) {
      const choices = value.map(choice => {
          const div = document.createElement('div');
          const classMapping = this.fieldValueToClass.transform(choice.name, field.valueClassMapping)
          const choiceElm = `<div class="fs-5 me-2 my-2 multiselect-choice d-inline-block ${classMapping}" title="${choice.title}">
                                  ${choice.title}
                              </div>`
          return choiceElm;
      });
      const element = `<div>${choices}</div>`;
      return element;
  }

  private getImageFieldTemplate(record, field, value) {
      const transformedSrc = this.replaceStr.transform(value, '.web', '');
      let imgClass = record.fields.filter(f => f.id === field.id)[0]?.style;
      imgClass = imgClass ? imgClass : 'rounded';
      const element = `
                    <div class="d-flex justify-content-center w-100">
                      <picture>
                          <source srcset="${value}" type="image/webp">
                          <source srcset="${transformedSrc}" type="image/jpeg">
                          <img src="${transformedSrc}" class="${imgClass}">
                      </picture>
                    </div>
                      `

      return element;
  }

  private getRessourceFieldTemplate(recrod, field, value) {
      let attachmentIconClass = this.getAttachmentClass(value, attachmentIconMapping);
      const deleteBtn = !field.isMandatory && !field.isReadOnly
                      ? `<button type="button" title="${translate('Supprimer')}"
                          class="btn btn-sm btn-outline-light btn-active-danger btn-icon px-0 me-2 delete-btn">
                          <i aria-hidden="true" class="fal fa-trash-alt fs-6"></i>
                      </button>`
                      : '';
      const updateBtn = !field.isReadOnly
                        ? `<button type="button" title="${translate('Changer le fichier')}"
                                  class="btn btn-sm btn-outline-light btn-active-info btn-icon px-0 update-btn" >
                              <input class="upload-file" type="file"
                                  placeholder="${field.help ? field.help : ''}"  style="display: none;">
                              <i aria-hidden="true" class="fal fa-upload fs-6"></i>
                          </button>`
                        : '';
      const element = `
              <div class="card border-0 bg-transparent w-100">
                  <div class="card-body text-center p-0">
                      <div class="d-flex flex-row justify-content-center align-items-end gap-1" >
                        <i class="${attachmentIconClass.replace('m-auto', '')}"></i>
                        <div class="d-flex mt-0">
                          ${deleteBtn}
                          ${updateBtn}
                        </div>
                      </div>
                      <span class="fs-6 fw-bold text-gray-800 pt-2" title="${value.name}">${value.name}</span>
                  </div>
              </div>`;
      return element;

  }

  private getAttachmentClass(value, attachmentIconMapping) {
      const fileExtension = value.name.split('.').pop();
      const hasExtension = Object.keys(attachmentIconMapping).includes(fileExtension);
      const iconClass = hasExtension ? attachmentIconMapping[fileExtension] : 'fa-file';

      return `m-auto fa-3x fa-duotone ${iconClass}`;
  }

  private getUserFieldTemplate(record, field, value) {
      const userAvatar = typeof(value) === 'object'
          ?  `<div class="symbol symbol-lg-40px symbol-20px symbol-circle cursor-pointer me-2" title="${value.fullName}"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
                  data-kt-menu-overflow="true">
                  <img src="${value.picture}" alt="avatar" class="img-fit">
              </div>`
          : '';

      const element = `<div class="d-flex align-items-center">
                          ${userAvatar}
                          <span class="text-gray-800 fs-5">${value.fullName}</span>
                      </div>`
      return element;
  }

  private getESignatureFieldTemplate(record, field, value) {
    if(value.user){
      const signaturAvatar =  typeof(value) === 'object'
          ? ` <div class="symbol symbol-lg-40px symbol-20px symbol-circle cursor-pointer me-2" title="${value.user.fullName}"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start"
                  data-kt-menu-overflow="true">
                  <img src="${value.user.picture}" alt="avatar" class="img-fit">
              </div>`
          : '';
      const element = `
              <div class="d-flex align-items-center">
                  ${signaturAvatar}
                  <div class="d-flex flex-column">
                      <span class="text-gray-800 fs-2x border-bottom border-bottom-1 border-gray-800" style="font-family: Robertson;"
                              title="${value.user.fullName}">${ value.user.fullName }
                      </span>
                      <span class="text-gray-600 fs-6 fst-italic">${ this.dateFormat.transform(value.date)}</span>
                  </div>
              </div>`;
      return element;
    }
    return '';
  }

  private getUsersFieldTemplate(record, field, value) {
      let users = value.map(user => (
            `<div class="symbol symbol-circle symbol-lg-40px symbol-20px" title="${ user.fullName }">
                      <img src="${user.picture}" alt="avatar" class="img-fit">
                  </div>`
      ));
      users = users.length === 0 ? '' : users.join('')
      const element = `
          <div class="symbol-group symbol-hover">
              ${users}
          </div>`;
      return element;
  }

  private getSpaceFieldTemplate(record, field, value) {
      const icon = value?.icon ? `<icon icon="${value?.icon}" class="me-2 fa-duotone"></icon>` : '';
      const element = `<div class="fs-5 badge badge-secondary me-2 my-1 p-3 d-inline-block" title="${value.title}">
                          ${icon}
                      </div>`
      return element;
  }
  private getSpacesFieldTemplate(record, field, value){
      let spaces = value.map(space => this.getSpaceFieldTemplate(record, field, space));
      spaces = spaces.length === 0 ? '' : spaces.join('')
      return spaces;
  }

  private getManyFieldTemplate(record, field, value) {
      if(!record.value[field.id + '_'].length){
          return '0';
      }
      const style = record.fields.filter(f => f.id === field.id)[0]?.style;
      const length = value.length ? value.length : 0;
      return `<span class="truncate d-inline-flex align-items-center ${style}">${length}</span>`;
  }

  private getNumberFieldTemplate(record, field, value) {
      const formattedNumber = new Intl.NumberFormat(this.locale, {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 0,
          maximumFractionDigits: 10
      }).format(value);

      const style = record.fields.filter(f => f.id === field.id)[0]?.style;
      return `<span class="fs-5 truncate d-inline-flex align-items-center ${style}">
                  ${formattedNumber} ${field.unit ? field.unit : ''}
              </span>`;
  }

  private getLocationMapFieldTemplate(record, field, value) {
      return `<div class="overflow-hidden rounded h-110px">
                  <maps location="${value}"></maps>
              </div>`;
  }

  private getCheckboxFieldTemplate(record, field, value) {
      const chedkedAttr = value === true ? 'checked' : '';
      const element = `<div class="form-check form-check-custom form-check-solid form-check-lg">
                          <input type="checkbox" class="form-check-input" id="checkbox-${record.id}-${field.id}" style="opacity: 1;"
                              ${chedkedAttr} disabled>
                      </div>`;
      return element;
  }

  private getLongTextFieldTemplate(record, field, value){
    let style = record.fields.filter(f => f.id === field.id)[0]?.style;
    style  = style ? style : '';
    const markDown = markdownToElement(value);
    const domPurifiedValue = DOMPurify.sanitize(markDown.innerHTML);
    return `<div style="${style}">${domPurifiedValue}</div>`;
  }

  private getShortTextFieldTemplate(record, field, value){
    let style = record.fields.filter(f => f.id === field.id)[0]?.style;
    style  = style ? style : '';
    const maskedValue = field.mask ? this.maskPipe.transform(value, field.mask) : value;
    return `<div style="${style}" class="text-truncate" title="${maskedValue}">${maskedValue}</div>`;
  }
}

export interface TableChange {
  action: TableAction,
  record?: number,
  entity: number,
  highlight?: {field: number, fullName: string, color: string},
}

type TableAction = "EDIT_RECORD" | "ADD_RECORD" | "REMOVE_RECORD" | "PAGINATION";

export enum TableActions {
  EDIT_RECORD = "EDIT_RECORD",
  ADD_RECORD = "ADD_RECORD",
  REMOVE_RECORD = "REMOVE_RECORD",
  PAGINATION = "PAGINATION",
}

type GroupingRecord = {
  id: number,
  title: string | null,
  picture: string | null,
  link: string | null
}
type TableRow = {recordId: number} & {[key: number] : {field: number, record: any | GroupingRecord}}
type TableCol = { name: number, header : string, fieldId: number, hidden: boolean }

class FieldRenderer {
  el: HTMLElement;
  record;
  field;
  grid;

  constructor(props) {
    this.record = props.value.record;
    this.field = props.value.field;
    this.grid = props.columnInfo.renderer.options.grid;
    this.initElement()
  }

  initElement(){
    const el = document.createElement('div');
    el.classList.add('table-field')
    this.el = el;
    if(this.record){
      if(this.record.id === -1){
        if(this.field) this.renderGroupingCell(this.record)
      }else{
        el.setAttribute('id', `table-field-${this.record.id}-${this.field.id}`);
        this.renderField();
      }
    }
  }

  getElement(){
    return this.el;
  }

  renderGroupingCell(record: GroupingRecord): void{
    const klasses = "symbol me-2 symbol-40px symbol-circle user-select-none";
    this.el.classList.add("card-parent");

    const card = document.createElement('div');
    card.classList.add(...klasses.split(" "));
    const pictureElm = `<img alt="avatar" src="${record.picture}">`;
    const titleElm = `<span class="text-info fs-5 fw-bold user-select-none" style="margin-left: 10px">${record?.title}</span>`;
    if(record.picture){
      card.insertAdjacentHTML('beforeend', pictureElm);
    }
    card.insertAdjacentHTML('beforeend', titleElm);
    this.el.appendChild(card)
  }

  renderField(){
    if(this.record){
      const value = this.grid.currentRecord?.id === this.record.id
                  ? this.grid.currentRecord.value[this.field.id]
                  : this.record.value[this.field.id];

      this.el.innerHTML = this.grid.getFieldRenderingTemplate(this.record, this.field, value);

      if(!this.field.isReadOnly && !['ManyToMany', 'InverseManyToMany', 'InverseOneToMany','InverseOneToOne'].includes(this.field.type)){
        this.el.style.cursor = 'pointer';
        if(this.field.type === 'Ressource' && value){
          const deleteRessourceBtn = this.el.querySelector('.delete-btn');
          deleteRessourceBtn.addEventListener('click', (event) => {
            event.stopPropagation();
            this.grid.onUpdateFieldSubmit(this.record, this.field, null);
          })
          const updateRessourceBtn = this.el.querySelector('.update-btn');
          updateRessourceBtn.addEventListener('click', (event) => {
            event.stopPropagation();
            this.grid.activateField(this.record, this.field)
            setTimeout(() => {this.grid.uploadRessource()}, 1);
          });
        }
          if (['OneToMany', 'OneToOne','InverseOneToOne'].includes(this.field.type)){

            this.el.getElementsByClassName('table-clicked-element')[0]?.addEventListener('click', event => {
              event.preventDefault()
              event.stopPropagation()
              openDetailPage({recordId: value.id, openModal: true});
            })
          }

        this.el.addEventListener('click', event => {
          event.preventDefault();
          let target = event.target as HTMLElement;
          target = target.closest('td');
          this.grid.activateField(this.record, this.field, target);
          setTimeout(() => {this.grid.openEditField();}, 1);
        })
      }

    }else{
      throw new Error(`This record with [id=${this.record.id}] doesn't exists.`);
    }
  }
}
