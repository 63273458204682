import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Injector } from '@angular/core';
import { getCookie } from '../app.utils';


@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  	let headers = req.headers
  	let cookie = getCookie('csrftoken')
  	if (cookie) {
  		headers = req.headers.set('X-CSRFToken', cookie);
  	}
  	const clonedRequest = req.clone({ headers: headers,
  									   withCredentials: true });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }


}