import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Store} from "@ngxs/store";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {ResetPage} from "../../state/app.actions";
import {translate} from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {SendResetPage} from "../../state/app.actions";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  @ViewChild('invalidResetLink') invalidResetLink!: TemplateRef<any>;
  
  constructor(private store: Store, private toastr: ToastrService, private router: Router, private route: ActivatedRoute, 
    public modalService: NgbModal
  ) {}

  email: string = '';
  token: string = '';
  missing = 0;


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['email']) {
        this.email = params['email'];
      }
      if (params['token']) {
        this.token = params['token'];
      }
      if (params['missing']) {
        this.missing = params['missing'];
      }
      }
    );
  }

  reset(value) {
    this.store.dispatch(
      new ResetPage.Reset(
        this.email,
        this.token,
        value.password,
        value.confirmation,
        value.firstName,
        value.lastName)
    ).subscribe(() => {
      this.router.navigate(['/login']);
      this.toastr.success(translate('Mot de passe modifié'), translate('Parfait'));
    }, 
    (error) => {
      if (error?.error?.Detail[0] === 'Lien de réinitialisation invalide') {
        this.modalService.open(this.invalidResetLink, {size: 'lg', centered: true})
      }
    });
  }

  resendResetEmail() {
    this.store.dispatch(new SendResetPage.SendReset(this.email))
    .subscribe( () => {
      this.router.navigateByUrl('/login');
      this.toastr.success(translate('E-mail de réinitialisation envoyé'), translate('Envoyé'));
    }, () => {});

    this.modalService.dismissAll();
  }

}
