import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Level } from 'src/app/services/user.service';
import { Timeline, TimelineOptions, TimelineTimeAxisScaleType } from 'vis-timeline';
import * as _ from 'lodash';
import { DataSet } from 'vis-data';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldValueToClassPipe } from '../../pipes/field-value-to-class.pipe';
import * as moment from 'moment';
import { translate, TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { CreateComponent } from '../create/create.component';
import { Store } from '@ngxs/store';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
const CLICK_DURATION: number = 500;

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() groupBy = false;
  @Input() records = [];
  @Input() recordsPage;
  @Input() entity;
  @Input() allElementsLoaded;
  @Input() isUnscheduledReady;
  @Input() unscheduledRecords;
  @Input() field = null;
  @Input() record = null;
  @Input() ready;
  @Input() isCreatingRecord;
  _unscheduledCount;
  @Input('unscheduledCount')
  set in(count) {
    if (count !== null) this._unscheduledCount = count;
  }
  @Input() hasUnscheduled;
  @Input() canPreview = true;
  @Input() showUnscheduled;
  @Output() dateChanged = new EventEmitter();
  @Output() taskClicked = new EventEmitter();
  @Output() scrolled = new EventEmitter();
  @Output() timelineRecordDrag = new EventEmitter();
  @Output() timelineUnscheduledChange = new EventEmitter();
  @Output() timelineUnscheduledNext = new EventEmitter();
  @Output() createSubmit = new EventEmitter();
  @ViewChild('timelineRef') timelineRef: ElementRef;
  @ViewChild('dropDownRef') dropDownRef: NgbDropdown;
  @ViewChild('timelinePopoverRef') timelinePopoverRef: ElementRef;
  @ViewChild('create', {static: false}) create: CreateComponent;
  unscheduled = false;
  popOverElement;
  popOverTitle = '';
  popOverUser = '';
  popOverStartDate = '';
  popOverEndingDate = '';
  popOverDuration = 0;
  clickStartDate: Date;
  groupByGroups = [];
  showNested = {};
  groupByField;
  currentGroupByValue;
  groupByRecord;
  dropGroup;
  pageY;
  time;
  dates = [];
  loading = false;
  canScroll = true;
  isDropped = false;
  canUnscheduledScroll = true;
  currentlyDragging = null;
  currentWindow = null;
  unscheduledRecordDragging = null;
  recordDateRange = null;
  isCreating = false;
  isEventAdded = false;
  scrollPos = 0;
  scopes = {
    "day": {
      name: translate("Jour"),
      zoomFactor: 9,
      defaultPreviewDuration: 3,
      scopeRangeValue: 1,
    },
    "week": {
      name: translate("Semaine"),
      zoomFactor: 35,
      defaultPreviewDuration: 7,
      scopeRangeValue: 7,
    },
    "month": {
      name: translate("Mois"),
      zoomFactor: 170,
      defaultPreviewDuration: 31,
      scopeRangeValue: 30,
    },
    "quarter": {
      name: translate("Trimestre"),
      zoomFactor: 50,
      defaultPreviewDuration: 15,
      scopeRangeValue: 90,
    },
    "year": {
      name: translate("Année"),
      zoomFactor: 700,
      defaultPreviewDuration: 135,
      scopeRangeValue: 365,
    }
};
  selectedScope: string = 'month';
  private timeline: Timeline;

  private groups = new DataSet();
  private tasks = new DataSet();
  public locale = this.translocoLocaleService.getLocale();

  private hasProgress = false;

  constructor(
    public store: Store,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private translocoLocaleService: TranslocoLocaleService,
    private route: ActivatedRoute,
    private zone: NgZone
  ) { }

  mouseMove(event) {
    event.preventDefault();
    let createGroupbyDate = $(".create-groupby-date");
    let group;
    if ($(event.target).attr('class') == 'vis-group') {
      group = $(event.target);
    } else if ($(event.target).attr('class') == "vis-drag-center") {
      group = $(event.target).parent().parent();
    }
    if (group && group.find("div[class*='preview_']").length != 0 && !this.unscheduledRecordDragging) {
      createGroupbyDate.css("visibility", "visible");
      createGroupbyDate.css("opacity", "80%");
      let previewCreate = group.find(".preview");
      let newButton = group.find(".new_");
      previewCreate.css("visibility", "visible")
      previewCreate.css("opacity", "15%")
      newButton.css("opacity", "0");
    } else {
      createGroupbyDate.css("visibility", "hidden");
      createGroupbyDate.css("opacity", "0");
      let previewCreate = $(".preview");
      previewCreate.css("visibility", "hidden");
      previewCreate.css("opacity", "0")
      let newButton = $(".new_");
      newButton.css("opacity", "100%");
    }
  }

  timelineScroll() {
    const timelineContainer = $('.vis-timeline');
    if (timelineContainer && timelineContainer.length > 0) this.isEventAdded = true;
    timelineContainer.on("scroll", () => {
      this.scrollPos = timelineContainer.scrollTop()
      if (
        timelineContainer.scrollTop() +
        timelineContainer.height() + 45 >=
        timelineContainer[0].scrollHeight + 1 &&
        this.canScroll &&
        !this.loading
      ) {
        this.loading = true;
        this.scrolled.emit();
      }
    })
  }

  ngOnDestroy(): void {
    document.onvisibilitychange = null;
  }

  ngAfterViewInit(): void {
    this.unscheduled = this.showUnscheduled === 'true' && this._unscheduledCount > 0;
    if (this.unscheduled) this.timelineUnscheduledNext.emit();
    this.loading = true;

    if (this.entity.progressField) {
      this.hasProgress = true;
    }

    let container = this.timelineRef.nativeElement;
    let createPreview = {
      id: 0,
      start: new Date(2022, 10, 15),
      end: new Date(2024, 10, 18),
      className: "btn text-start p-0 preview-task ",
      progress: 100,
      group: 0,
      missingStart: false,
      missingEnd: false,
      canShowProgress: this.hasProgress,
      isPreview: true,
    };
    let createGroup = {
      id: 0,
      content: "",
    }
    if (!this.groupBy && this.canPreview) {
      this.tasks.add(createPreview);
      this.groups.add(createGroup);
    }
    this.prepareTasks(this.records);

    let template = (item, element, data) => {
      let marginLeft = 'unset';
      let pointerEvents = 'all';
      if (document.getElementById('vis-item-' + item.id)) {
        let parent;
        if (data.canShowProgress) {
          parent = document.getElementById('vis-item-' + item.id).parentElement
            .parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement;
        } else {
          parent = document.getElementById('vis-item-' + item.id).parentElement
            .parentElement.parentElement.parentElement;
        }
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };
        let startDate = !data.missingStart ? new Date(data.start) : null;
        let endDate = !data.missingEnd ? new Date(data.end) : null;
        //TO DO add round to duration (arrondir les jours)
        let duration = '';
        if (endDate && startDate) {
          duration = Math.round(
            (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
          ).toString();
        }

        parent.title =
          (data.owner?.fullName ? data.owner?.fullName + ', ' : '') +
          (this.groupByField?.isOneLinerGroupby ? data.str + ', ' : '') +
          //@ts-ignore
          (startDate ? startDate.toLocaleString(this.locale, options) + (endDate ? ', ' : '') : '') +
          //@ts-ignore
          (endDate ? endDate.toLocaleString(this.locale, options) : '') +
          (duration ? ', ' : '') +
          (duration ? duration + translate('jours') : duration);
        marginLeft = document.getElementById('vis-item-' + item.id).style
          .marginLeft;
        pointerEvents = document.getElementById('vis-item-' + item.id).style
          .pointerEvents;
      }
      let truncate = this.groupByField?.isOneLinerGroupby ? ' truncate' : '';
      let template = document.createElement('div');
      let html =
        "<div class='ms-1 text-gray-800' id='vis-item-" +
        data.id +
        "' style='pointer-events: " +
        pointerEvents +
        " ;display:flex; align-items: center; height: 25px'>" +
        "<div class='symbol symbol-30px symbol-circle'>" +
        ( !data?.isPreview ? "<img src='" +
        data.owner?.picture +
        "'></img>" + '</div>' +
        "<span class='vis-item-content-span text-gray-900" +
        truncate +
        "' style='margin-left: .5em'>" +
        data.str +
        '</span>' : "" ) +
        '</div>';
      template.innerHTML = data.canShowProgress
        ? this.generateProgressItemHtml(item, data, pointerEvents, truncate, this.entity.isDailyMode)
        : html;
      return template;
    };

    let onMoving = (item, callback) => {
      if (item.id == 0 || String(item.id).includes("preview")) return;
      let element = document.getElementById('vis-item-' + item.id);
      if (element) {
        let image = element.getElementsByClassName('symbol-30px')[0];
        let span = element.getElementsByClassName('vis-item-content-span')[0];

        if (
          image?.getBoundingClientRect().width +
          span?.getBoundingClientRect().width +
          40 >
          element.parentElement.getBoundingClientRect().width
        ) {
          element.style.marginLeft = 'calc(100% + 20px)';
          element.style.pointerEvents = 'none';
        } else {
          element.style.marginLeft = 'unset';
          element.style.pointerEvents = 'all';
        }
      }
      let record = this.records.find((r) => r.id == item.id);
      const recordStart = record.value[this.entity.startingDateField?.id];
      const recordEnd = record.value[this.entity.endingDateField?.id];
      // updates missing date UI when filled
      if (
        item.end &&
        item.missingEnd &&
        new Date(recordStart).getTime() == item.start.getTime()
      ) {
        item.className = this.removeGradientClass(
          item.className,
          'start-opacity-gradient'
        );
        item.missingEnd = false;
      }
      if (
        item.start &&
        item.missingStart &&
        new Date(recordEnd).getTime() == item.end.getTime()
      ) {
        item.className = this.removeGradientClass(
          item.className,
          'end-opacity-gradient'
        );
        item.missingStart = false;
      }

      this.currentlyDragging = item;
      callback(item);
    };

    let groupTemplate = (item, element, data) => {
      let template = document.createElement('div');
      let html = '';
      if (item.content) {
        html +=
          `<div id="parent_${item.id}" class="d-flex flex-center font-weight-normal text-dark mt-0 mw-200px truncate">`;
        if (item.picture) {
          html += '<div class="symbol me-2 symbol-30px symbol-circle">';
          html += '<img alt="avatar" src="' + item.picture + '">';
          html += '</div>';
        }
        if (item.link) {
          html +=
            '<span class="text-gray-800 text-wrap fs-5 fw-bold">' +
            item.title +
            '</span> <a target="_blank" href="' +
            item.link +
            '"  class="btn btn-icon btn-light ms-1 btn-sm"><i class="fa-solid fa-arrow-up-right-from-square"></i></a>';
        } else {
          const valueClassMapping = item.field
            ? FieldValueToClassPipe.prototype.transform(
              item.title,
              item.field.valueClassMapping
            )
            : '';
          html +=
            '<span class="text-gray-800 fs-5 fw-bold ' +
            valueClassMapping +
            '">' +
            item.title +
            '</span>';
        }
        html += '</div>';
      } else {
        html += '<div style="height: 41px"></div>';
      }
      template.innerHTML = html;
      return template;
    };
    // Configuration for the Timeline
    let options: TimelineOptions = {
      align: 'left',
      autoResize: true,
      stack: false,
      locale: this.translocoService.getActiveLang(),
      zoomKey: 'ctrlKey',
      selectable: true,
      showWeekScale: true,
      format: {
        minorLabels: {
          day: 'ddd DD',
          week: this.translocoService.getActiveLang() === 'fr' ? '[S]WW' : 'wo',
        },
      },
      editable: {
        updateTime:
          !this.entity.startingDateField?.isReadOnly &&
          !this.entity.endingDateField?.isReadOnly,
        updateGroup: false,
      },
      onMoving: onMoving,
      minHeight:this.unscheduledRecords.length > 0? window.innerHeight - 750 + 'px' :window.innerHeight - 300 + 'px',
      margin: {
        item: {
          vertical: 6
        }
      },
      orientation: 'top',
      groupHeightMode: 'fixed',
      groupTemplate: groupTemplate,
      horizontalScroll: false,
      verticalScroll: false,
      zoomable: true,
      template: template,
      onInitialDrawComplete: () => {
        // this.timeline.focus(this.records[0]?.id);
        this.timeline.zoomOut(0.5);
        this.restoreTimelineConfig();
        if (this.entity.isDailyMode) {
          // set the initial zoom on 7 days (current week)
          const startDate = new Date();
          const endDate = new Date();
          startDate.setDate(startDate.getDate() - 3);
          endDate.setDate(endDate.getDate() + 4)
          this.timeline.setWindow(startDate, endDate);
        }
        setTimeout(() => {
          this.timelineRef.nativeElement.style.transform = 'unset';
          this.timelineRef.nativeElement.style.position = 'relative';
        }, 100);
        setTimeout(() => {
          if (this.canPreview) {
            this.renderGroupByAddButtons();
          }
          if (!this.groupBy && this.canPreview) {
            this.renderNewTaskButton();
          }
        }, 300)
      },
    };

    if (this.entity.isDailyMode) {
      // If the entity has daily mode enabled, create options for daily mode
      let dailyModeOptions: TimelineOptions = {
        timeAxis: {
          scale: 'day', // Display time axis in days
          step: 1 // Step between time axis labels in days
        },
        zoomMin: 1000 * 60 * 60 * 24, // Minimum zoom level is 1 day (in milliseconds)
        zoomMax: 1000 * 60 * 60 * 24 * 30 * 12, // Maximum zoom level is 12 * 30 days (1 Year in milliseconds)
      }

      // Merge the daily mode options into the existing options object
      options = Object.assign(options, dailyModeOptions)
    }

    // Create a Timeline

    // @ts-ignore
    this.timeline = new Timeline(container, this.tasks, this.groups, options);
    this.currentWindow = this.timeline.getWindow()
    // group from collapsing after opening group record link
    this.timeline['itemSet'].groupHammer.off('tap');
    let timeline = this.timeline;
    this.timeline['itemSet'].groupHammer.on('tap', function (event) {
      let target = event.target;
      if (
        target.classList.value == 'fa-solid fa-arrow-up-right-from-square' ||
        (target.classList.value == 'btn btn-icon btn-light ms-1 btn-sm' &&
          target.firstChild.classList.value ==
          'fa-solid fa-arrow-up-right-from-square')
      ) {
      } else {
        timeline['itemSet']._onGroupClick(event);
      }
    });
    //<editor-fold desc="listeners">
    this.timeline.on('mouseDown', (props) => {
      if (props.item != null || props.group != null) {
        this.clickStartDate = new Date();
      }
    });
    this.timeline.on('changed', (props) => {
      if (!this.isEventAdded) this.timelineScroll();
    });
    this.timeline.on('mouseUp', (props) => {
      if (this.currentlyDragging) {
        this.dateChanged.emit({
          record: {
            id: this.currentlyDragging.id,
          },
          start: this.currentlyDragging.start,
          end: this.currentlyDragging.end,
        });
        this.currentlyDragging = null;
      } else if (props.item != null && this.clickStartDate) {
        if (
          new Date().valueOf() - this.clickStartDate.valueOf() <
          CLICK_DURATION
        ) {
          if (props.item != 0 && !String(props.item).includes("preview_")) {
            this.taskClicked.emit({openModal: this.entity.isDetailPageInModal, recordId: props.item});
          }
        }
      }
      if (this.clickStartDate) {
        if (new Date().valueOf() - this.clickStartDate.valueOf() < CLICK_DURATION) {
          if (!this.isCreating && (props.group == 0 || String(props.group).includes("create_"))) {
            let previewTask = String(props.group).includes("create_") ? $(".preview_"+String(props.group.split("_")[1])) : $(".preview-task");
            if (this.groupBy) {
              this.currentGroupByValue = _.find(this.groups.get(), o => o.id == props.group)["groupByValue"];
              const groupByRecord = _.find(this.groups.get(), o => o.id == _.find(this.groups.get(), o => o.id == props.group)["nestedInGroup"])
              this.groupByRecord = {
                id: -1,
                title: groupByRecord["title"],
                picture: groupByRecord["picture"],
                link: groupByRecord["link"]
              }
            }
            this.isCreating = true
            if (this.entity.creationForm) {
              this.create.openCreationModal(this.create.creationFormRef);
              previewTask.css('visibility', 'visible');
            } else {
              let createTimeline = $('.create-timeline');
              createTimeline.width(previewTask[0]?.offsetWidth);
              createTimeline.offset({ top: previewTask?.offset()?.top, left: previewTask?.offset()?.left })
              previewTask.css('visibility', 'hidden');
              createTimeline.css('visibility', 'visible');
            }
            let creationForm = $('.input-group input:first');
            creationForm.focus();
          }
        }
      }
    });

    this.timeline.on('dragover', () => {
      console.log('dragging');
    });

    this.timeline.on('mouseMove', (event) => {
      let groupByContainer = $('.vis-label.vis-nesting-group:eq(0)')
      let offset = this.groupBy ? groupByContainer[0]?.offsetWidth : 0;

      if (this.unscheduledRecordDragging != null) {
        // preview for planing

        this.zone.runOutsideAngular(() => {
          let planningGroupHTML = `
          <div class="vis-group vis-group_${event.group}" style="height: 70px;">
          <div class="vis-item vis-range btn text-start p-0 preview-planning  vis-selected vis-editable"
              style="transform: translateX(189.474px);  top: 5px;">
              <div class="vis-item-overflow">
                  <div class="vis-item-content" style="transform: translateX(0px);">
                      <div>
                          <div class="btn btn-shadow p-0 d-flex justify-content-between">
                              <div class="btn text-start p-0 w-100 " style="width: 0px;">
                                  <div class="text-start p-0 btn btn-undefined progress-div h-100"
                                      style="width: 100%;position: absolute;top: 0;z-index: -99999; " role="progressbar"
                                      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          `
          let planningGroup_ = $(`.vis-group_${event.group}`)
          if (event.group != null && planningGroup_.length == 0) {
            let prevPlanningGroups_ = $(`div[class*='vis-group_'`)
            prevPlanningGroups_.remove();
            let idx = _.indexOf(_.map(this.groups.get(), "id"), event.group);
            let currentGroup = $(`.vis-foreground .vis-group:eq(${this.groupBy ? idx+1 : idx})`)
            currentGroup.after(planningGroupHTML)
          }

          let previewTask = $(".preview-planning");
          previewTask.css('width', this.getDefaultPreviewDurationPx());
          let offset = this.groupBy ? groupByContainer[0]?.offsetWidth : 0;
          let windowWidth = $('.vis-group:eq(0)')[0].offsetWidth - offset
          previewTask.offset({ left: $('.vis-timeline')?.offset()?.left + offset + (offset && 6.5) + ((event.snappedTime - this.currentWindow.start) * (windowWidth - 6.5) / (this.currentWindow.end - this.currentWindow.start)) });
          let previewDate = $(".preview-planning-date");
          previewDate.children('span.start').text(event.snappedTime.toDate().toLocaleDateString(this.locale, { day:"numeric", month:"short"})
            );
          previewDate.children('span.end').text(moment(event.snappedTime).add(this.scopes[this.selectedScope].defaultPreviewDuration, "days").toDate().toLocaleDateString(this.locale, { day:"numeric", month:"short"}));
          previewDate.width(previewTask[0]?.offsetWidth - 20);
          previewDate.css('left', previewTask?.position()?.left + offset);
        })
        let currGroup = $(`div[class*='vis-group_'`)[0]?.classList[1].split('_')[1];
        let next_idx = _.indexOf(_.map(this.groups.get(), "id"), +currGroup);
        this.dropGroup = this.groups.get()[next_idx+1]?.id;
      } else if (event.group == 0 && !this.isCreating) {
        // preview for creation
        let updatedPreview = {
          id: 0,
          start: event.snappedTime,
          end: moment(event.snappedTime).add(this.getDefaultPreviewDuration(), "days").toDate(),
        };
        this.tasks.update(updatedPreview);
        this.zone.runOutsideAngular(() => {
          let previewTask = $(".preview-task");
          let previewDate = $(".preview-date");
          previewDate.children('span.start').text(event.snappedTime.toDate().toLocaleDateString(this.locale, { day:"numeric", month:"short"})
          );
          previewDate.children('span.end').text(moment(event.snappedTime).add(this.getDefaultPreviewDuration(), "days").toDate().toLocaleDateString(this.locale, { day:"numeric", month:"short"}));
          previewDate.width(previewTask[0]?.offsetWidth - 20);
          previewDate.css('left', previewTask?.position()?.left);
        })
      } else if (String(event.group).includes("create_")) {
        // create on group by
        let parentGroupId = event.group.split("_")[1];
        let createPreview = {
          id: "preview_"+parentGroupId,
          start: event.snappedTime,
          end: moment(event.snappedTime).add(this.getDefaultPreviewDuration(), "days").toDate(),
          className: "btn text-start p-0 preview " + "preview_" + parentGroupId,
          progress: 100,
          group: "create_"+parentGroupId,
          missingStart: false,
          missingEnd: false,
          canShowProgress: null,
          isPreview: true,
        };
        this.tasks.update(createPreview);
        this.zone.runOutsideAngular(() => {
          let previewCreate = $('.preview_'+parentGroupId)
          previewCreate.css("visibility", "visible")
          previewCreate.css("opacity", "15%");
          let previewDate = $(".create-groupby-date");
          previewDate.children('span.start').text(event.snappedTime.toDate().toLocaleDateString(this.locale, { day:"numeric", month:"short"})
          );
          previewDate.children('span.end').text(moment(event.snappedTime).add(this.getDefaultPreviewDuration(), "days").toDate().toLocaleDateString(this.locale, { day:"numeric", month:"short"}));
          previewDate.width(previewCreate[0]?.offsetWidth - 20);
          previewDate.css('left', previewCreate?.position()?.left + offset);
        });
      }
      this.time = event.time;
      if (!this.isCreating) {
        if (this.unscheduledRecordDragging != null) {
          let days = this.getDefaultPreviewDurationPx() / (($('.vis-group:eq(0)')[0].offsetWidth - offset) / ((this.currentWindow?.end - this.currentWindow?.start) / (1000 * 3600 * 24)))
          this.recordDateRange = {start: event.snappedTime.toDate(), end: moment(event.snappedTime).add(days, "days").toDate()}
        } else {
          this.recordDateRange = {start: event.snappedTime.toDate(), end: moment(event.snappedTime).add(this.getDefaultPreviewDuration(), "days").toDate()}
        }
      }
    });

    this.timeline.on('itemover', (event) => {
      if (
        document
          .getElementById('vis-item-' + event.item)
          ?.parentNode.querySelector(':hover') ===
        document.getElementById('vis-item-' + event.item)
      ) {
        return;
      }
      setTimeout(() => {
        this.timeline.setSelection(event.item);
        /*
        let timelineElement = document.getElementById("vis-item-" + event.item).parentElement.parentElement
        if (timelineElement){
          const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
          const visCenter = this.timelineRef.nativeElement.getElementsByClassName("vis-center")[0]
          this.popOverContent(event.item)
          this.timelinePopoverRef.nativeElement.style.display = "block"
          const popOverRect = this.timelinePopoverRef.nativeElement.getBoundingClientRect()
          const elementRect = timelineElement.getBoundingClientRect()
          const visRect = visCenter.getBoundingClientRect()
          const popOverXpos = clamp(elementRect.x, visRect.x, visRect.x + visRect.width - popOverRect.width)
          const popOverYpos = (((visRect.y + visRect.height) - (elementRect.y + elementRect.height)
                              > popOverRect.height) && (window.innerHeight > elementRect.y + elementRect.height + popOverRect.height)) ?
                              elementRect.y + elementRect.height + window.scrollY + 5 : elementRect.y - popOverRect.height + window.scrollY - 5;
          this.timelinePopoverRef.nativeElement.style.top = popOverYpos + "px"
          this.timelinePopoverRef.nativeElement.style.left = popOverXpos + "px"
        }
        */
      });
    });
    this.timeline.on('itemout', (event) => {
      this.timelinePopoverRef.nativeElement.style.display = 'none';
    });

    this.timeline.on('rangechanged', (prop) => {
      this.rangeChangeOrChanged();
      this.adaptScope(prop);
    });

    this.timeline.on('rangechange', (properties) => {
      this.adaptTimeAxisScale(properties);
      // this.rangeChangeOrChanged();
      this.currentWindow = this.timeline.getWindow()
      this.changeDetector.detectChanges()
    });
    window.addEventListener('scroll', () => {
      if (
        window.innerHeight + window.pageYOffset + 1 >=
        document.body.scrollHeight &&
        this.canScroll &&
        !this.loading
      ) {
        this.loading = true;
        this.scrolled.emit();
      }
    });

    this.timeline.on('click', (event) => {
      if (this.dropDownRef.isOpen()) this.dropDownRef.close();
      if (this.groups.get(event.group)['treeLevel'] === 1) {
        setTimeout(() => {
          this.showNested[this.groups.get(event.group)['id']] = this.groups.get(
            event.group
          )['showNested'];
          if (this.canPreview) {
            this.renderGroupByAddButtons();
          }
        }, 100);
      }
    });
    this.applySeperator();
    this.loading = false;
    //</editor-fold>
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._unscheduledCount > this.unscheduledRecords?.length) {
      this.canUnscheduledScroll = true;
    }
    if (this.unscheduled) this.UnscheduledScroll();
    this.unscheduledRecords = this.unscheduledRecords || [];
    if (changes.records) {
      let previousRecordsValue = changes.records.previousValue;
      let currentRecordsValue = changes.records.currentValue;
      if (
        previousRecordsValue?.length > 0 &&
        _.isEqual(previousRecordsValue, currentRecordsValue && !this.isDropped)
      ) {
        this.canScroll = false;
        return;
      }
      this.isDropped = false;
      if (
        previousRecordsValue?.length > 0 &&
        previousRecordsValue?.length != currentRecordsValue?.length
      ) {
        this.tasks.clear();
        this.groups.clear();
        if (!this.groupBy && this.canPreview) {
          let createPreview = {
            id: 0,
            start: this.currentWindow.start,
            end: this.currentWindow.end,
            className: "btn text-start p-0 preview-task ",
            progress: 100,
            group: 0,
            missingStart: false,
            missingEnd: false,
            canShowProgress: false,
            isPreview: true,
          };
          let createGroup = {
            id: 0,
            content: "",
          }
          this.tasks.add(createPreview);
          this.groups.add(createGroup);
          this.renderNewTaskButton();
        }
        this.prepareTasks(this.records);
      }
      if(previousRecordsValue?.length == currentRecordsValue?.length && !_.isEqual(previousRecordsValue, currentRecordsValue)) {
        this.tasks.clear();
        this.groups.clear();
        if (!this.groupBy && this.canPreview) {
          let createPreview = {
            id: 0,
            start: this.currentWindow.start,
            end: this.currentWindow.end,
            className: "btn text-start p-0 preview-task ",
            progress: 100,
            group: 0,
            missingStart: false,
            missingEnd: false,
            canShowProgress: false,
            isPreview: true,
          };
          let createGroup = {
            id: 0,
            content: "",
          }
          this.tasks.add(createPreview);
          this.groups.add(createGroup);
          this.renderNewTaskButton();
        }
        this.prepareTasks(currentRecordsValue);
      }
      setTimeout(() => {
        this.loading = false;
        if (this.canPreview) {
          this.renderGroupByAddButtons();
        }
        this.changeDetector.detectChanges();
      }, 100);
    }
  }

  restoreTimelineConfig() {
    let timelineConfig = this.route.snapshot.queryParams?.timelineConfig;
    timelineConfig = timelineConfig ? JSON.parse(timelineConfig) : {};
    if (timelineConfig["window"]) {
      this.timeline.setWindow(timelineConfig["window"].start, timelineConfig["window"].end,
                              undefined,
                              () => {
                                this.onScopeDateChanged('today');
                              });
    } else {
      this.changeScope((timelineConfig["scope"] && this.scopes.hasOwnProperty(timelineConfig["scope"])) ? timelineConfig["scope"] : "month")
      this.onScopeDateChanged('today');
    }
  }

  removeGradientClass(str, gradientClass) {
    return str.replace(gradientClass, '');
  }

  prepareTasks(records) {
    let groupedElements = {};
    const groupByFieldName = this.field ? this.store.snapshot().app.recordPage.getRecord(this.record.id).filters[this.field.id]['groupby'] : this.route.snapshot.queryParams?.groupby;
    const groupByField = _.find(
      _.flatten(_.map(this.entity.blocks, 'fields')),
      (f) => f.name == groupByFieldName
    );
    const groups = this.groups;
    const showNested = this.showNested;
    const canPreview = this.canPreview;
    const currentWindow = this.currentWindow;
    const groupByGroups = (this.groupByGroups = []);
    let previewTasks = [];
    this.groupByField = groupByField;
    if (this.groupBy) {
      let lastParent;
      _.chain(records)
        .groupBy(function (item) {
          if (item.id === -1) {
            if (item.link == null) {
              lastParent = item.title;
            } else {
              lastParent = item.link;
            }
          }
          return lastParent;
        })
        .map(function (g) {
          const parent = _.first(g);
          const children = _.map(_.chain(g).slice(1).value(), 'id');
          let groupByValue;
          records.forEach((record) => {
            if (record.id == children[0]) {
              if(groupByFieldName == "space") {
                groupByValue = record.space.id;
              } else {
                groupByValue = groupByField ? record.value[groupByField.id] : null;
              }
            }
          });
          let title = parent.link ? parent.link : parent.title;
          const parentGroupId = title.split('').reduce(function (a, b) {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
          }, 0);
          const group = {
            id: parentGroupId,
            content: parent.title,
            treeLevel: 1,
            title: parent.title,
            link: parent.link,
            picture: parent.picture,
            field: groupByField && groupByField.type === "SingleSelect" ? groupByField : null,
          }
          let createPreview = {
            id: "preview_"+parentGroupId,
            start: currentWindow ? currentWindow.start : new Date(2022, 1, 1),
            end: currentWindow ? currentWindow.end : new Date(2024, 1, 1),
            className: "btn text-start p-0 preview " + "preview_" + parentGroupId,
            progress: 100,
            group: "create_"+parentGroupId,
            missingStart: false,
            missingEnd: false,
            canShowProgress: null,
            isPreview: true,
          };
          if (canPreview) {
            previewTasks.push(createPreview)
          }
          let createGroup;
            if (canPreview) {
              createGroup = {
                id: "create_"+parentGroupId,
                content: "",
                groupByValue: groupByValue,
                treeLevel: 2,
                nestedInGroup: parentGroupId
              }
              groups.update(createGroup)
            }
          if (groupByField?.isOneLinerGroupby) {
            const oneLinerGroup = {
              id: 'one_liner_' + parentGroupId,
              content: '',
              treeLevel: 2,
            };
            groups.update(oneLinerGroup);
            groupedElements[parentGroupId] = children;
            group['nestedGroups'] = (canPreview) ? [createGroup.id, oneLinerGroup.id] : children;
            group['showNested'] =
            showNested && showNested[parentGroupId] != undefined
              ? showNested[parentGroupId]
              : true;
          } else {
            _.forEach(children, (t) => {
              const group = {
                id: t,
                content: '',
                treeLevel: 2,
              };
              groups.update(group);
            });
            group['nestedGroups'] = (canPreview) ? [createGroup.id, ...children] : children;
            group['showNested'] =
              showNested && showNested[parentGroupId] != undefined
                ? showNested[parentGroupId]
                : true;
          }

          groups.update(group);
          groupByGroups.push(group);
          return parent;
        })
        .value();
    } else {
      _.forEach(this.records, (t) => {
        const group = {
          id: t.id,
          content: '',
        };
        this.groups.update(group);
      });
    }
    this.tasks.add(previewTasks);
    const tasks = _.map(
      this.groupBy
        ? _.filter(
          this.records,
          (r) => r.id > -1 && !this.tasks.getIds().includes(r.id)
        )
        : _.filter(this.records, (r) => !this.tasks.getIds().includes(r.id)),
      (r) => this.recordToTask(r, groupedElements)
    );
    this.tasks.add(tasks);
    const timelineContainer = $('.vis-timeline');
    timelineContainer.scrollTop(this.scrollPos);
  }

  recordToTask(record, groupedElements) {
    let task;

    // The average duration of a task when the startingDate or endingDate is not specified.
    const defaultTaskDuration: number = this.entity.isDailyMode ? 3 : 40;

    const startingValue = record.value[this.entity.startingDateField?.id];
    const endingValue = record.value[this.entity.endingDateField?.id];
    let startDate = startingValue
      ? moment(startingValue)
      : moment(endingValue).subtract(defaultTaskDuration, 'days');
    let endDate = endingValue
      ? moment(endingValue)
      : moment(startingValue).add(defaultTaskDuration, 'days');
    const progressValue = this.hasProgress
      ? record.value[this.entity.progressField]
      : null;
    const canShowProgress =
      this.hasProgress && startingValue && endingValue && progressValue !== undefined && progressValue !== null;

    let gradientClass = '';
    if (!record.value[this.entity.endingDateField?.id]) {
      gradientClass = 'start-opacity-gradient';
      if (!this.entity.endingDateField) gradientClass += ' not-drag-right'
    } else if (!record.value[this.entity.startingDateField?.id]) {
      gradientClass = 'end-opacity-gradient';
      if (!this.entity.startingDateField) gradientClass += ' not-drag-left'
    }
    let className = '';
    if (!canShowProgress) {
      className =
        `btn text-start p-0 item_${record.id} ` +
        (record.color ? 'btn-' + record.color : 'btn-info') +
        ' ' +
        gradientClass;
    } else {
      className =
        `btn text-start p-0 item_${record.id} ` +
        (record.color
          ? 'progress-div-' + record.color
          : 'progress-div-primary') +
        ' ' +
        gradientClass;
    }
    if (this.entity.endingDateField?.type == 'Date') {
      endDate = endDate.isSame(endDate.clone().startOf('day')) ? endDate.subtract(1, 'day').endOf('day') : endDate.endOf('day');
    }
    if (this.entity.startingDateField?.type == 'Date') {
      startDate = startDate.startOf('day');
    }
    let group;
    if (this.groupByField && this.groupByField?.isOneLinerGroupby) {
      group = 'one_liner_' + Number(Object.keys(groupedElements).find(k => groupedElements[k].includes(record.id)))
    } else {
      group = record.id;
    }
    task = {
      id: record.id,
      str: record.str,
      start: !isNaN(Number(startDate))
        ? startDate
        : this.dates.find((o) => o.id === record.id)
          ? this.dates.find((o) => o.id === record.id).date
          : startDate,
      end: !isNaN(Number(endDate))
        ? endDate
        : this.dates.find((o) => o.id === record.id)
          ? moment(this.dates.find((o) => o.id === record.id).date)
            .add(defaultTaskDuration, 'days')
          : endDate,
      className: className,
      progress: progressValue,
      group: group,
      style:'height:45px',
      owner: {
        picture: 'assets/temporary/avatars/blank.png',
        fullName: null,
      },
      missingStart: !!!startingValue,
      missingEnd: !!!endingValue,
      canShowProgress: canShowProgress,
      recordColor: record.color,
    };

    if (this.entity.ownerField && record.value[this.entity.ownerField]) {
      task.owner.picture = record.value[this.entity.ownerField].picture;
      task.owner.fullName = record.value[this.entity.ownerField].fullName;
    }
    if (record.accessLevel < Level.Write) {
      task['editable'] = false;
    }
    return task;
  }

  popOverContent(id) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const record = this.records.filter((record) => record.id === id)[0];
    this.popOverTitle = record.str;
    this.popOverUser = record.value[this.entity.ownerField].fullName;
    let startDate = new Date(record.value[this.entity.startingDateField?.id]);
    let endDate = new Date(record.value[this.entity.endingDateField?.id]);
    //@ts-ignore
    this.popOverStartDate = startDate.toLocaleDateString(this.locale, options);
    //@ts-ignore
    this.popOverEndingDate = endDate.toLocaleDateString(this.locale, options);
    this.popOverDuration =
      (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    this.changeDetector.detectChanges();
  }

  rangeChangeOrChanged() {
    this.zone.runOutsideAngular(() => {
      this.applySeperator();
      let timelineElements = document.getElementsByClassName('vis-item-content');
      for (let i = 0; i < timelineElements.length; i++) {
        let child;
        let progressItem = false;
        if (
          this.hasProgress &&
          timelineElements[
            i
          ].firstElementChild.firstElementChild.classList.contains('btn')
        ) {
          child = timelineElements[i].firstElementChild;
          progressItem = true;
        } else {
          child = timelineElements[i].firstElementChild;
        }
        let image = child.getElementsByClassName('symbol-30px')[0];
        let span = child.getElementsByClassName('vis-item-content-span')[0];
        if (
          image?.getBoundingClientRect().width +
          span?.getBoundingClientRect().width +
          40 >
          child.getBoundingClientRect().width
        ) {
          if (progressItem) {
            //@ts-ignore
            child.firstElementChild.firstElementChild.firstElementChild.style.marginLeft = `calc(${child.getBoundingClientRect().width
              }px + 5px)`;
            //@ts-ignore
            child.firstElementChild.firstElementChild.firstElementChild.style.pointerEvents =
              'none';
          } else {
            //@ts-ignore
            child.firstElementChild.style.marginLeft = 'calc(100% + 20px)';
            //@ts-ignore
            child.firstElementChild.style.pointerEvents = 'none';
          }
        } else {
          if (progressItem) {
            //@ts-ignore
            child.firstElementChild.firstElementChild.firstElementChild.style.marginLeft =
              'unset';
            //@ts-ignore
            child.firstElementChild.firstElementChild.firstElementChild.style.pointerEvents =
              'all';
          } else {
            //@ts-ignore
            child.firstElementChild.style.marginLeft = 'unset';
            //@ts-ignore
            child.firstElementChild.style.pointerEvents = 'all';
          }
        }
      }
    })
  }

  adaptTimeAxisScale(properties: any) {
    if (!this.entity.isDailyMode)
      return

    let { start, end } = properties; // Start and end time of the visible range

    // Calculate the time range in milliseconds
    let timeRange = end - start;

    // Determine the appropriate scale based on the time range
    let newScale: TimelineTimeAxisScaleType;
    if (timeRange > 1000 * 60 * 60 * 24 * 40) {
      //@ts-ignore
      newScale = 'week';
    }  else {
      newScale = 'day';
    }

    // Update the timeAxis scale options
    this.timeline.setOptions({
      timeAxis: {
        scale: newScale,
        step: 1
      }
    });
  }

  getDefaultPreviewDuration() {
    return Math.round(((this.currentWindow.end - this.currentWindow.start) / (1000 * 3600 * 24)) * (!this.unscheduled ? 0.21 : 0.3))
  }

  getDefaultPreviewDurationPx() {
    let groupByContainer = $('.vis-label.vis-nesting-group:eq(0)')
    let offset = this.groupBy ? groupByContainer[0]?.offsetWidth : 0;
    return ($('.vis-group:eq(0)')[0].offsetWidth - offset) * (!this.unscheduled ? 0.21 : 0.3);
  }

  adaptScope(properties: any) {
    this.zone.runOutsideAngular(() => {
      let { start, end } = properties; // Start and end time of the visible range
      let timeRange = (end - start) / (1000 * 3600 * 24)
      for(let scope in this.scopes) {
        if (_.inRange(timeRange, (this.scopes[scope].zoomFactor*2) + 1) || (scope == "year" && timeRange > (this.scopes[scope].zoomFactor*2) + 1)) {
          this.selectedScope = scope;
          break;
        }
      }
      this.zone.run(() => {
        this.updateQueryParams(start, end);
      })
    })
  }

  updateQueryParams(start, end) {
    let qp = {};
    let timelineConfig = this.route.snapshot.queryParams?.timelineConfig;
    timelineConfig = timelineConfig ? JSON.parse(timelineConfig) : {};
    timelineConfig['scope'] = this.selectedScope;
    timelineConfig['window'] = {start, end};
    qp['timelineConfig'] = JSON.stringify(timelineConfig);
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
      replaceUrl: true
    })
  }

  UnscheduledScroll() {
    const unscheduledContainer = $('.unscheduled-container');
    unscheduledContainer.on('scroll', () => {
      if (
        unscheduledContainer.scrollTop() +
        unscheduledContainer.height() +
        300 >=
        unscheduledContainer[0].scrollHeight + 1 &&
        this.canUnscheduledScroll
      ) {
        this.canUnscheduledScroll = false;
        if (this._unscheduledCount > this.unscheduledRecords.length) {
          this.timelineUnscheduledNext.emit();
        }
      }
    });
  }
  UnscheduledLoadNext() {
    const unscheduledContainer = $('.unscheduled-container');
    if (unscheduledContainer.scrollTop() - 50 <= unscheduledContainer.outerHeight()) {
      if (this._unscheduledCount > this.unscheduledRecords.length) {
        this.timelineUnscheduledNext.emit();
      }
    }
  }
  switchUnscheduled() {
    if(this._unscheduledCount> 0) {
      this.unscheduled = (!this.unscheduled);
      this.timelineUnscheduledChange.emit(this.unscheduled);
      this.timelineUnscheduledNext.emit();
    }
  }

  dragOver() {
    this.zone.runOutsideAngular(() => {
      let previewDate = $(".preview-planning-date");
      let previewTask = $(".preview-planning");
      previewDate.css("visibility", "visible");
      previewTask.css("visibility", "visible");
    })
  }

  dragLeave() {
    this.zone.runOutsideAngular(() => {
      let previewDate = $(".preview-planning-date");
      let previewTask = $(".preview-planning");
      previewDate.css("visibility", "hidden");
      previewTask.css("visibility", "hidden");
      let prevPlanningGroups_ = $(`div[class*='vis-group_'`)
      prevPlanningGroups_.remove();
    })
  }

  timelineDrop(event) {
    let dropGroup = this.dropGroup;
    this.isDropped = true;
    let groupByValue, id, firstChild;
    let previewDate = $(".preview-planning-date");
    let previewTask = $(".preview-planning");
    previewDate.css("visibility", "hidden");
    previewTask.css("visibility", "hidden");
    let prevPlanningGroups_ = $(`div[class*='vis-group_'`)
    prevPlanningGroups_.remove();

    //get drop group
    // Search for wantedGroup: the first matching group that meets the specified conditions.
    const wantedGroup = this.groupByGroups.find((group, idx) =>
      idx < this.groupByGroups.length - 1 &&
      group.id === dropGroup &&
      this.records[idx + 1].id !== -1
    );

    if (wantedGroup) {
      // A matching group was found then determine the First Child.
      // `id` is the first child's position.
      id = this.groupByGroups.indexOf(wantedGroup) + 1;
      firstChild = this.records[id].id;
    } else {
      const recordIndex = this.records.findIndex(record => record.id === dropGroup);
      if (recordIndex !== -1) {
        id = recordIndex;
      }
    }

    this.dates.push({ id: event.id, date: this.time });
    $('.vis-panel.vis-background.vis-vertical').css(
      'background-color',
      'var(--bs-app-light-sidebar-bg-color)'
    );
    if (this.groupBy) {
      this.records.forEach((record) => {
        if (record.id == this.dropGroup || record.id == firstChild) {
          groupByValue = record.value[this.groupByField.id];
        }
      });
      this.timelineRecordDrag.emit({
        newDate: this.recordDateRange,
        record: event,
        timeline: true,
        groupByValue: groupByValue,
        groupByField: this.groupByField.id,
        index: id,
      });
    } else {
      this.timelineRecordDrag.emit({
        newDate: this.recordDateRange,
        record: event,
        drop: true,
        timeline: true,
        index: id,
      });
      this.renderNewTaskButton();
    }

    this.UnscheduledLoadNext();
    //if it's the last unscheduledReocrd
    if (this._unscheduledCount == 1) {
      this.unscheduled = false;
    }
  }

  onMouseWheel(event) {
    event.preventDefault();
    if (event.deltaY > 0) {
      this.timeline.zoomOut(0.75);
    }
    if (event.deltaY < 0) {
      this.timeline.zoomIn(0.75);
    }
  }

  generateProgressItemHtml(item, data, pointerEvents, truncate, isDailyMode): string {
    return `
  <div class="btn btn-shadow p-0 d-flex justify-content-between">
  <div class="btn text-start p-0 w-100 " style="width: 0px;">

      ${!data?.isPreview ? `<div style="z-index:99999" class="ms-1 p-2 d-flex align-items-center h-100">
      <div class="text-gray-800" id="vis-item-${item.id}"
          style="pointer-events: ${pointerEvents};display: flex;align-items: center;">
          <div class="symbol symbol-30px symbol-circle"><img src="${data.owner?.picture
          }"></div>
        <span class="vis-item-content-span text-gray-900 ${truncate}" style="margin-left: .5em">${data.str
    }  </span>
        <span class="ms-1 me-4 mt-3 mb-3 fw-bold d-flex rounded-pill bg-body px-3 py-0 text-gray-800 ">${data.canShowProgress ? data.progress + '%' : ''
    }</span></div>
    </div>
    ` : ""}

      <div class="text-start p-0 btn btn-${data.recordColor} progress-div h-100"
           style="width: ${data.progress
      }%;position: absolute;top: 0;z-index: -99999; ${(data.progress == 100 && isDailyMode ) ? 'border-radius: 0.5rem;' : ''
      }" role="progressbar" aria-valuenow="${data.progress}" aria-valuemin="0"
           aria-valuemax="100">

      </div>
  </div>

  </div>
`;
  }

  // Add seperators for one-liner records
  applySeperator() {
    if (this.groupByField && this.groupByField.isOneLinerGroupby && !this.canPreview) {
      $('div.vis-group').addClass('one-liner-seperator');
    }
  }

  onScopeDateChanged(scope) {
    let curretTime = new Date();
    switch (scope) {
      case "today":
        this.timeline.moveTo(curretTime);
        break;
      case "next":
        this.timeline.setWindow(moment(this.currentWindow?.start).add(this.scopes[this.selectedScope].scopeRangeValue, "days").toDate(), moment(this.currentWindow?.end).add(this.scopes[this.selectedScope].scopeRangeValue, "days").toDate())
        break;
      case "previous":
        this.timeline.setWindow(moment(this.currentWindow?.start).subtract(this.scopes[this.selectedScope].scopeRangeValue, "days").toDate(), moment(this.currentWindow?.end).subtract(this.scopes[this.selectedScope].scopeRangeValue, "days").toDate())
        break;
    }
  }

  changeScope(scope) {
    this.selectedScope = scope;
    let midDate = new Date((this.currentWindow?.start.getTime() + this.currentWindow?.end.getTime()) / 2);
    this.timeline.setWindow(moment(midDate).subtract(this.scopes[this.selectedScope].zoomFactor, "days").toDate(),
                            moment(midDate).add(this.scopes[this.selectedScope].zoomFactor, "days").toDate(),
                            undefined,
                            () => {
                              this.onScopeDateChanged('today');
                            });
  }

  unscheduledDragStart(record) {
    this.unscheduledRecordDragging = record;
  }

  unscheduledDragEnd() {
    this.unscheduledRecordDragging = null;
  }

  formatCurrentWindow() {
    if (this.currentWindow?.start.getFullYear() != this.currentWindow?.end.getFullYear()) {
      return {
        start: this.currentWindow?.start.toLocaleDateString(this.locale,  { year:"numeric", month:"short"}),
        end: this.currentWindow?.end.toLocaleDateString(this.locale,  { year:"numeric", month:"short"})
      }
    } else {
      return {
        start: this.currentWindow?.start.toLocaleDateString(this.locale,  { day:"numeric", month:"short"}),
        end: this.currentWindow?.end.toLocaleDateString(this.locale,  { day:"numeric", month:"short"})
      }
    }
  }

  handleCreateSubmit(event) {
    const mainField = this.entity.mainField;
    const startingDateField = this.entity.startingDateField;
    const endingDateField = this.entity.endingDateField;
    if (this.groupBy) {
      const indexGroupBy = _.findIndex(this.records, (r: any) => r.title == this.groupByRecord.title && r.link == this.groupByRecord.link);
      const groupByFieldName = this.field ? this.store.snapshot().app.recordPage.getRecord(this.record.id).filters[this.field.id]['groupby'] : this.route.snapshot.queryParams?.groupby;
      this.createSubmit.emit({
        fieldsValues: {
          [this.entity.creationForm ? 'form_data' : mainField.id]: event,
          ...(startingDateField && {[startingDateField.id]: this.recordDateRange.start}),
          ...(endingDateField && {[endingDateField.id]: this.recordDateRange.end}),
          [this.groupByField ? this.groupByField.id : groupByFieldName]: this.currentGroupByValue
        },
        indexGroupBy: indexGroupBy
      });
    } else {
      this.createSubmit.emit({
        fieldsValues: {
          [this.entity.creationForm ? 'form_data' : mainField.id]: event,
          ...(startingDateField && {[startingDateField.id]: this.recordDateRange.start}),
          ...(endingDateField && {[endingDateField.id]: this.recordDateRange.end})
        }
      });
    }
    this.isCreating = false;
  }

  cancelCreate() {
    let create = $('.create-timeline');
    let previewTask = $('.preview-task');
    create.css("visibility", "hidden");
    previewTask.css('visibility', 'hidden');
    $('.input-group input:first').val('');
    this.isCreating = false;
  }

  renderNewTaskButton() {
    this.zone.runOutsideAngular(() => {
      let firstGroup = $('.vis-foreground .vis-group:eq(0)');
      const buttonHTML = `
        <button class="btn btn-sm bg-transparent ms-auto px-4 fs-5 text-gray-600 new-timeline" style="position: absolute;top: 0;bottom: 0;left: 140px;z-index: 999999;">
          <i class="fa-regular fa-plus fs-3 text-gray-600 mb-1 me-1"></i>${translate('Ajouter')}
        </button>
      `
      firstGroup.append(buttonHTML);
    })
  }

  renderGroupByAddButtons() {
    this.zone.runOutsideAngular(() => {
      const buttonHTML = `
        <button class="btn btn-sm bg-transparent ms-auto px-0 fs-6 text-gray-600 new_" style="position: absolute;top: 0;bottom: 0;left: 35px;z-index: 999999;">
          <i class="fa-regular fa-plus fs-5 text-gray-600 mb-1 me-1"></i>${translate('Ajouter')}
        </button>
      `
      const groupLabels = $('.vis-label.vis-nesting-group.vis-nested-group.vis-group-level-1.expanded');
      _.forEach(groupLabels, (group, idx) => {
        const index = $(".vis-labelset > div").index(group);
        let correspondingVisGroup = $(`.vis-foreground .vis-group:eq(${index + 1})`);
        if (!correspondingVisGroup.has('.new_').length) {
          correspondingVisGroup.append(buttonHTML);
        }
      })
    })
  }
}
