// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ng-value-container {
  padding: 5px !important;
}

::ng-deep .ng-dropdown-panel {
  max-width: 300px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/checkpoint/checkpoint.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACI,2BAAA;AAEJ","sourcesContent":["::ng-deep .ng-value-container {\n    padding: 5px !important;\n}\n::ng-deep .ng-dropdown-panel {\n    max-width: 300px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
