import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit, ChangeDetectionStrategy
} from "@angular/core";
import { Store } from '@ngxs/store';
import {UntypedFormControl} from "@angular/forms";
import {concat, forkJoin, merge, Observable, of, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, tap} from "rxjs/operators";
import * as _ from "lodash";
import {RecordService} from "src/app/services/record.service";
import {ChoiceService} from "src/app/services/choice.service";
import {UserService} from "src/app/services/user.service";
import {LocalService} from "src/app/services/local.service";
import {EntityService} from "src/app/services/entity.service";
import {SpaceService} from "../../services/space.service";
import {translate} from "@jsverse/transloco"
import {NgSelectComponent} from "@ng-select/ng-select";
declare var $: any;

@Component({
  selector: "autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ['autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() field;
  @Input() isForm = false;
  @Input() isFilter = false;
  @Input() focused = false;
  @Input() takenValues = [];
  @Input() record = null;
  @Input() value = null;
  @Input() checkpoint = null;
  @Input() showSelectedValue = false;
  @Input() placeholder = null;
  @Input() solid = true;
  @Output() autocompleteSelectItem = new EventEmitter();
  @ViewChild("searchRef") searchRef: ElementRef;
  @ViewChild("select") select: NgSelectComponent;
  @ViewChild("instance", {static: true}) instance;
  focus$ = new Subject<any>();
  click$ = new Subject<any>();
  typedInput$ = new Subject<any>();
  items$: Observable<any>;

  valueControl: any;
  elementToAdd: any = [];
  currentSelectedItems: any;
  currentSearchTerm: string = "";
  currentPage: number = 1;
  loading: boolean = false;
  canSearch: boolean = false;
  isChanged: boolean = false;
  listeningTypeAheadScroll: boolean = false;
  currentUser;

  constructor(
    public recordService: RecordService,
    public choiceService: ChoiceService,
    public userService: UserService,
    public spaceService: SpaceService,
    public localService: LocalService,
    public entityService: EntityService,
    private ref: ChangeDetectorRef,
    public store: Store,
  ) {
  }

  ngOnInit() {
    if (this.field) {
      this.field = _.cloneDeep(this.field);

      this.currentUser = this.store.snapshot().app.sider.user;
      if (this.value && this.isForm) {
        if (this.field.type == 'Record') this.valueControl = new UntypedFormControl(this.value.str);
        if (this.field.type == 'User') this.valueControl = new UntypedFormControl(this.value.fullName);
        if (this.field.type == 'SingleSelect') this.valueControl = new UntypedFormControl(this.value.title);
        if (this.field.type == 'Space') this.valueControl = new UntypedFormControl(this.value.title);
      } else {
        this.valueControl = new UntypedFormControl("");
      }

      if (this.field.type === 'MultiSelect') {
        if (!this.record && !this.isForm) {
          let field = _.cloneDeep(this.field);
          field.type = 'SingleSelect';
          this.field = field;
        }
        if (this.value != null) {
          this.currentSelectedItems = this.value.map(choice => choice.id)
        }
      }

      if (this.field.type === 'Users') {
        if (!this.record && !this.isForm) {
          // used on filters , so the field should behave just like the user type
          let field = _.cloneDeep(this.field);
          field.type = 'User';
          this.field = field;
        } else {
          this.currentSelectedItems = this.value.map(user => user.id);
        }
      }

      if (this.field.type === 'Spaces') {
        if (!this.record && !this.isForm) {
          // used on filters , so the field should behave just like the user type
          let field = _.cloneDeep(this.field);
          field.type = 'Space';
          this.field = field;
        } else {
          this.currentSelectedItems = this.value.map(space => space.id);
        }
      }

      if (this.value && this.isForm) {
        this.autocompleteSelectItem.emit(this.value);
      }
    }
    else if (this.checkpoint) {
      this.field = {
        type: this.checkpoint.type == 'Multi Select' ? 'MultiSelect' : 'SingleSelect',
        checkpoint: this.checkpoint
      }
      this.valueControl = new UntypedFormControl("");
    }
    this.initItems();

  }

  ngAfterViewInit(): void {
    if(this.isFilter &&  this.select && !this.select?.isOpen){
      this.select.open();
    }
    if (this.select && this.focused) {
      setTimeout(() => {
          this.select.focus();
      });
    }

  }

  initItems() {
    const debouncedText$ = this.typedInput$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    this.items$ = concat(
      of([]),
      merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        tap(() => {this.loading = true; this.ref.detectChanges()}),
        mergeMap(term => {
          return this.fetchItems$(term).pipe(
            tap((items) => {
              if (!this.listeningTypeAheadScroll) {
                this.loading = false;
                setTimeout(() => {
                  let itemsWindow = document.getElementsByTagName("ngb-typeahead-window")[0] || document.getElementsByClassName("ng-dropdown-panel-items")[0];
                  if (itemsWindow) {
                    this.ref.detectChanges();
                    itemsWindow.addEventListener("scroll", (event) => {
                      if (this.canSearch && (itemsWindow.scrollTop + itemsWindow.clientHeight + 2 >= itemsWindow.scrollHeight)) {
                        this.listeningTypeAheadScroll = true;
                        this.currentPage++;
                        this.canSearch = false;
                        inputFocus$.next(this.currentSearchTerm)
                      }
                    }, false);
                  } else {
                    this.loading = false;
                  }
                })
              } else {
                this.loading = false;
              }
            })
          )
        })
      )
    );
  }

  getItems$ = (text$?: any) => {
    if (text$) {
      text$.subscribe((t) => {
        this.typedInput$.next(t);
      })
    }
    return this.items$;
  }

  fetchItems$ = (term: any) => {
    if (term !== this.currentSearchTerm) {
      this.currentPage = 1;
    }
    this.currentSearchTerm = term;
    const autocompleteParams: { [key: string]: any } = {};

    if (!this.isFilter && (this.field.hasAutocompleteFilter || this.field.hasAutocompleteOrderby)) {
        autocompleteParams['field'] = this.field.id;
        if (this.record) {
            autocompleteParams['record'] = this.record.id;
        }
        if (this.isForm) {
            autocompleteParams['form'] = this.field.form;
        }
    }

    const paginationParams = { page: 1, page_size: 18 * this.currentPage };

    const commonParams = { ...paginationParams, ...autocompleteParams, search: term };

    if (['User', 'CreatedBy', 'Users', 'E-Signature'].includes(this.field.type)) {
      term = term === null && this.focused ? '' : term;
      const params = this.record && !this.field.allowDeniedUsersSelection
        ? { ...commonParams, record: this.record.id }
        : { ...commonParams, ...(this.isFilter ? { entity: this.field.entity } : {}) };

      return this.userService.retrieveObjects(params).pipe(
        mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])),
        map((items: any) => {
          if (!this.field.isMandatory && this.isFilter) {
            items.unshift({ id: 'null', fullName: '--' });
          }
          return this.filteredItems(items, true, false);
        })
      );
    }

    if (this.field.type === 'Space') {
      term = term === null ? '' : term;
      return this.spaceService.retrieveObjects(commonParams).pipe(
        mergeMap((observables$: any) => observables$.length ? forkJoin(observables$) : of([])),
        map((items: any) => this.filteredItems(items, true, true))
      );
    }

    if (this.field.type === 'SingleSelect') {
      term = this.field.hasTooManyChoices && term === null && this.focused ? '' : term || '';
      const query = this.checkpoint
        ? { ...commonParams, checkpoint: this.checkpoint.id }
        : { ...commonParams, choiceset: this.field.choiceset };

      return this.choiceService.retrieveObjects(query).pipe(
        mergeMap((observables$: any) => observables$.length ? forkJoin(observables$) : of([])),
        map((items: any) => {
          if (this.isFilter && !this.field.isMandatory) {
            items.unshift({ id: 'null', title: '--' });
          }
          return this.filteredItems(items, true, true);
        })
      );
    }

    if (this.field.type === 'MultiSelect') {
      const params = this.record
        ? { ...commonParams, choiceset: this.field.choiceset }
        : this.checkpoint
          ? { ...paginationParams, search: term, checkpoint: this.checkpoint.id }
          : { ...commonParams, choiceset: this.field.choiceset, ...(this.isFilter ? { entity: this.field.entity } : {}) };

      return this.choiceService.retrieveObjects(params).pipe(
        mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])),
        tap((items: any) => this.filteredItems(items))
      );
    }

    if (this.field.type === 'Spaces') {
      const params = { ...commonParams, space: this.record.space };
      return this.spaceService.retrieveObjects(params).pipe(
        mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])),
        tap((items: any) => this.filteredItems(items))
      );
    }

    if (['OneToOne', 'InverseOneToOne', 'OneToMany', 'InverseOneToMany', 'ManyToMany', 'InverseManyToMany'].includes(this.field.type)) {
      const params = { ...commonParams, entity: this.field.mirrorEntities };
      return this.recordService.retrieveObjects(params).pipe(
        mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])),
        map((items: any) => this.filteredItems(items, true))
      );
    }

    if (this.field.type === 'Record') {
      const params = { ...commonParams, entity: this.field.entities };
      return this.recordService.retrieveObjects(params).pipe(
        mergeMap((observables$: any) => observables$.length ? forkJoin(observables$) : of([])),
        tap((items: any) => this.filteredItems(items))
      );
    }

    if (this.field.type === 'Checklist') {
      return this.entityService.retrieveObjects({
        space: this.localService.getSpace(),
        extension: 'Checklist'
      }, []).pipe(
        mergeMap((observables$: any) => observables$.length ? forkJoin(observables$).pipe(
          mergeMap((entities: any) => this.recordService.retrieveObjects({
            ...paginationParams,
            search: term,
            entity: entities[0].id
          }).pipe(
            mergeMap((observables$: any) => observables$.length ? forkJoin(observables$) : of([])),
            tap((items: any) => this.filteredItems(items))
          ))
        ) : of([]))
      );
    }
  }

  add() {
    if (this.isChanged) {
      this.autocompleteSelectItem.emit(this.elementToAdd);
    }
  }

  submit(added: any) {
    if (this.field.type === 'Spaces') {
      added = added.map((space) => this.getItemFromValue(space, 'title'));
      this.elementToAdd = added;
      this.isChanged = true;
    } else if (this.field.type === 'Users' || ((this.field.type == 'User' || this.field.type == 'CreatedBy' ||  this.field.type == 'E-Signature') && this.isFilter)) {
      added = added.map(user => this.getItemFromValue(user, 'fullName'));
      this.elementToAdd = added;
      this.isChanged = true;
    } else if (this.field.type === 'MultiSelect' || (this.field.type == 'SingleSelect' && this.isFilter)) {
      added = added.map(choice => this.getItemFromValue(choice, 'title'));
      this.elementToAdd = added;
      this.isChanged = true;
    } else {
      // this.value = added.id;
      this.valueControl = new UntypedFormControl("");
      if (this.showSelectedValue) {
        if (this.field.type == 'Record') this.valueControl = new UntypedFormControl(added.str);
        if (this.field.type == 'User' && !this.isFilter) this.valueControl = new UntypedFormControl(added.fullName);
        if (this.field.type == 'SingleSelect' && !this.isFilter) this.valueControl = new UntypedFormControl(added.title);
      } else {
        this.valueControl = new UntypedFormControl("");
      }
      this.searchRef.nativeElement.blur();
      this.autocompleteSelectItem.emit(added);
    }
    if(this.isFilter) {
      this.autocompleteSelectItem.emit(added);
    }
  }

  focus(event: any) {
    if (this.localService.isMobile() && !this.canSearch && !this.checkpoint && this.currentPage === 1 ) {
      event.target.blur();
    }
    if (this.showSelectedValue) this.valueControl = new UntypedFormControl(this.value?this.currentSearchTerm:'');
    setTimeout(() => {
      this.focus$.next(event.target.value);
    });
  }

  focusout(event) {
    this.listeningTypeAheadScroll = false;
    this.loading = false;
    if(event.relatedTarget){
      this.valueControl = new UntypedFormControl('')
    }
  }

  getItemFromValue(item, key) {
    // Get object from record value if items visible
    if (item && !item[key]) {
      return _.find(this.value, u => u.id === item.id);
    }
    return item;
  }

  trackByFn(item: any) {
    return item.id;
  }

  private filteredItems(items: any, checkMandatory = false, nullable = false) {
    let filtered = [];
    for (let item of items) {
      let find = false;
      for (let v of this.takenValues) {
        if (v !== null && item !== null && item.id === v.id) {
          find = true;
        } else if (nullable && item === null && v === null) {
          find = true;
        }
      }
      if ((this.field.type == 'User' || this.field.type == 'Users' || this.field.type == 'E-Signature') && item.id === this.currentUser.id){
        find = true
      }
      if (this.currentSearchTerm == '' && (this.field.type == 'User' || this.field.type == 'Users' || this.field.type == 'E-Signature') && !filtered.includes(this.currentUser)){
        filtered.unshift(this.currentUser)
      }
      if (!find) {
        if(item.id =='null') {
          filtered.unshift(item);
        }else {
          filtered.push(item);
        }
      }
    }
    if (!this.isFilter && checkMandatory && this.field.isMandatory !== undefined && !this.field.isMandatory && !this.takenValues.includes(null) && !this.showSelectedValue && ["OneToOne", "InverseOneToOne", "OneToMany", "User", "SingleSelect"].includes(this.field.type)) {
        filtered.unshift(null);
    }
    if(this.field.canAdd && ['InverseOneToOne', 'InverseOneToMany', 'InverseManyToMany', 'Record','ManyToMany'].includes(this.field.type)) {
        const takenValuesSet = new Set(this.takenValues);
        filtered = _.sortBy(filtered, [
          (item) => !takenValuesSet.has(item.id), // Prioritize taken values (false comes before true)
          (item) => item                      // Sort alphabetically
        ]);

      }

    if (18 * this.currentPage === items.length) {
      this.canSearch = true
    }
    return filtered;
  }

  isSelected(record: any): boolean {
    return this.takenValues.includes(record.id);
  }

  onScrollToEnd() {
    if (this.canSearch) {
      this.listeningTypeAheadScroll = true;
      this.currentPage++;
      this.canSearch = false;
      this.loading = true;
      this.focus$.next(this.currentSearchTerm)
    }
  }
}
