import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService extends BaseService {

  key = 'workspaces';
  constructor(public injector: Injector) {
    super(injector);
  }
}
