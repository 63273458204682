import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import {translate} from "@jsverse/transloco"
@Component({
  selector: 'ordering',
  templateUrl: './ordering.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderingComponent implements OnInit {
  @Input() fields = []
  @Input() value;
  @Input() isMobile: Boolean = false;
  @Input() isRecord: Boolean = false;
  @Output() orderingChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  getOrderingName(value){
    if (!value) {
      return translate('Trier')
    }
    let name = value.startsWith('-')?value.substring(1):value
    let nameFields = this.fields.filter(f=> f.name==name)
    if (nameFields.length){
      return nameFields[0].title
    } else {
      return ''
    }

  }

}
