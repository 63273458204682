// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host:not(:last-child) .timeline-line {
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/activity/activity.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;AACJ","sourcesContent":[":host:not(:last-child) .timeline-line{\n    bottom: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
