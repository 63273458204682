import {Directive ,Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { LocalService } from 'src/app/services/local.service';

declare var $: any;

@Directive({
  selector: '[initTheme]'
})
export class InitThemeDirective {

  constructor(@Inject(DOCUMENT) private document: Document, public localService: LocalService) { }

  ngOnInit() {
    this.localService.siderVisibility$.subscribe((val) => {
        const visibility = String(!!val)
        this.document.body.setAttribute("data-kt-app-sidebar-enabled", visibility)
        this.document.body.setAttribute("data-kt-app-sidebar-fixed", visibility)
        this.document.body.setAttribute("data-kt-app-sidebar-push-header", visibility)
    })
    this.localService.theme$.subscribe((val) => {
        $('html').attr("data-bs-theme", val);
        $('body').attr("data-kt-app-layout", `${val}-sidebar`);
    })
  }
}
