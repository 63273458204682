import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {Store} from "@ngxs/store";
import {LocalService} from "../services/local.service";
import {UserService} from "../services/user.service";
import {map} from "rxjs/operators";
import {getCookie} from "../app.utils";

@Injectable({
  providedIn: 'root'
})

export class AuthenticationGuard  {

  constructor(public router: Router, private store: Store, private localService: LocalService, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let connectedUser$ = this.userService.connectedUser();
    return connectedUser$.pipe(map((connectedUser) => {
      if (connectedUser) {
        return true;
      } else {
        const workspace = this.localService.getWorkspace();
        if (!workspace) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        } else {
          this.router.navigate(['/' + workspace], { queryParams: { returnUrl: state.url } });
        }
        return false;
      }
    }));
  }
}
