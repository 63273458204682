import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { attachmentIconMapping, imageIconMapping, isImageResource } from '../../app.utils';
import {translate} from "@jsverse/transloco"

@Component({
  selector: 'file',
  templateUrl: './file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent implements OnInit {
  @Input() mode;
  @Input() attachment;

  @Output() fileUploadSelect = new EventEmitter();
  @Output() fileDelete = new EventEmitter();

  @ViewChild("fileRef") fileRef: ElementRef;


  attachmentIconMapping = attachmentIconMapping;
  imageIconMapping = imageIconMapping;

  constructor() {
  }

  ngOnInit() {
  }

  openFile() {
    if (this.fileRef) {
      this.fileRef.nativeElement.click();
    }
    else {
      window.open(this.attachment.file, '_blank');
    }
  }

  endsWithImage(src: string) {
    return isImageResource(src);
  }

  onFileUploadSelect(file) {
    this.fileUploadSelect.emit(file);
  }

  onFileDelete(id) {
      event.stopPropagation();
      if (window.confirm(translate('Voulez-vous vraiment supprimer le fichier ') + this.attachment.name + ' ?')){
        this.fileDelete.emit(id);
      }
  }

}
