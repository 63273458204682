import { State, Action } from '@ngxs/store';
import { AdministrationPage } from './app.actions';
import { LocalService } from '../services/local.service';
import { transformWriteOnlyFields } from '../app.utils';
import { of, forkJoin, throwError } from 'rxjs';
import * as _ from 'lodash';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { EntityService } from '../services/entity.service';
import { mutateState } from '../app.utils';
import { Inject, Injectable } from '@angular/core';
import { ViewService } from '../services/view.service';
import { ActionService } from '../services/action.service';
import { RecordService } from '../services/record.service';
import { TagService } from '../services/tag.service';
import { UserService } from '../services/user.service';
import { RoleService } from '../services/role.service';
import { ActivityService } from '../services/activity.service';
import { AttachmentService } from '../services/attachment.service';
import { CommentService } from '../services/comment.service';
import { ChoiceService } from '../services/choice.service';
import {ChoicesetService} from "../services/choiceset.service";
import { DatalakeService } from '../services/datalake.service';
import {translate } from '@jsverse/transloco';
import { Cache } from '../classes/cache';
import {SpaceService} from "../services/space.service";

@State({
    name: 'administrationPage',
    defaults: {
        currentModel: null,
        currentEntity: null,
        objects: [],
        sections: [],
        hiddenSections: [],
        isReady: false,
        isSectionReady: false,
        entitiesModels:  [],
        permissionsModels:[],
        datalakeModels:[],
        filesCount: null
    }
})
@Injectable()
export class AdministrationPageState {

  constructor(private viewService: ViewService,
              private actionService: ActionService,
              private recordService: RecordService,
              private entityService: EntityService,
              private tagService: TagService,
              private userService: UserService,
              private localService: LocalService,
              private roleService: RoleService,
              private attachmentService: AttachmentService,
              private commentService: CommentService,
              private datalakeService: DatalakeService,
              private activityService: ActivityService,
              private choiceService: ChoiceService,
              private choicesetService: ChoicesetService,
              private spaceService: SpaceService,
              @Inject('Cache') private cache: Cache) {
  }


    @Action(AdministrationPage.Init)
    changeModelEntity(ctx, {entityId, model, page, search}) {
      let  entitiesModels: any = [
            {
              plural: translate('champs'),
              title: translate('champ'),
              isMasculine: true,
              modelName: 'field',
              icon: 'shapes',
              isReadOnly: true,
              children: {
                name: 'choices',
                service: 'choiceService',
                fetchCondition: {
                  key: 'type',
                  value: ['SingleSelect','MultiSelect']
                }
              },
              objects: (entity) => _.flatten(_.map(entity.blocks, (b)=> b.fields))
            },
            {
              plural:translate('actions'),
              title: translate('action'),
              isMasculine: false,
              modelName: 'action',
              icon: 'play',
              isReadOnly: true,
              propagations: [],
              objects: (entity) => entity.actions ? entity.actions : []
            },
            {
              plural:translate ('signaux'),
              title: translate('signal'),
              isMasculine: true,
              modelName: 'signal',
              icon: 'bolt',
              isReadOnly: true,
              propagations: [],
              objects: (entity) => entity.signals ? entity.signals : []
            },
            {
              plural: translate('routines'),
              title: translate('routine'),
              isMasculine: false,
              modelName: 'job',
              icon: 'alarm-clock',
              isReadOnly: true,
              propagations: [],
              objects: (entity) => entity.jobs ? entity.jobs : []
            },
            {
              plural: translate('étiquettes'),
              title: translate('étiquette'),
              isMasculine: false,
              modelName: 'tag',
              icon: 'tags',
              isReadOnly: false,
              propagations: [],
              isSortable: true,
              colors: ['success', 'warning', 'primary', 'info', 'lime', 'danger', 'indigo', 'gray', 'aqua', 'magenta', 'midnight', 'pine', 'brown','pomegranate'],
              modalSize: 'md',
              objects: (entity) => entity.tags ? entity.tags : []
            },
            {
              plural:translate ('extensions'),
              title: translate('extension'),
              isMasculine: false,
              modelName: 'extension',
              icon: 'plug',
              isReadOnly: true,
              propagations: [],
              mapping: {
                Attachments: {
                  text: translate('Fichiers'),
                  icon: 'paperclip'
                },
                Discussion: {
                  text: translate('Discussion'),
                  icon: 'comments'
                },
                Checklist: {
                  text: translate('Points'),
                  icon: 'clipboard-list-check'
                },
                History: {
                  text: translate('Historique'),
                  icon: 'history'
                },
                Tags: {
                  text: translate('Étiquettes'),
                  icon: 'tags'
                },
                Audit: {
                  text: translate('Audit'),
                  icon: 'clipboard-list-check'
                }
              },
              objects: (entity) => _.flatten(_.map(entity.blocks, (b)=> b.extensions))
            }
          ];

      let permissionsModels: any = [
            {
              plural: translate('utilisateurs'),
              title: translate('utilisateur'),
              isMasculine: true,
              modelName: 'user',
              icon: 'users',
              isReadOnly: false,
              propagations: ['spaceRoles'],
              parent: {
                title: 'role',
                service: 'role'
              },
              client: {
                title: 'space',
                service:'space'
              },
              multi: true,
              //modalSize: 'sm',
              withImport: true,
              withExport: true
            },
            {
              plural: translate('rôles'),
              title: translate('rôle'),
              isMasculine: true,
              modelName: 'role',
              icon: 'user-lock',
              isReadOnly: true,
            },
            {
              plural: translate('espaces'),
              title: translate('espace'),
              isMasculine: true,
              modelName: 'space',
              icon: 'sitemap',
              isReadOnly: true

            }
          ];
      let datalakeModels: any = [
        {
            plural: translate('datalake'),
            title: translate('datalake'),
            isMasculine: false,
            modelName: 'datalake',
            icon: 'paperclip',
            isReadOnly: false,
        }
      ];

      mutateState(ctx, draft => {
        draft.isReady = false;
        draft.isSectionReady = draft.currentEntity === entityId;
        draft.entitiesModels=entitiesModels;
        draft.permissionsModels=permissionsModels
        draft.datalakeModels=datalakeModels
      });
      let entities$, models$, obs$, obsNames$, parent$, filesCount$, client$;
      let newEntitiesModels, newPermissionsModels,newDatalakeModels;
      const space = this.localService.getSpace();
      let currentModel = _.cloneDeep(_.find(entitiesModels, e => e.modelName === model));
      entities$ = this.entityService.retrieveObjects({space}).pipe(
        mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
      );
      if (entityId > 0) {
        parent$ = of([]);
        client$ = of([]);
        obs$ = of([]);
        obsNames$ = of([]);
        filesCount$ = of([]);
        models$ = this.entityService.retrieveAdministrationMetadata(entityId).pipe(mergeMap((entity: any) => {
          const objects = currentModel.objects(entity);
          if (currentModel.modelName === 'field') {
            const fields = _.cloneDeep(_.filter(objects, f => (f.type === 'SingleSelect' || f.type === 'MultiSelect')));
            const choices$ = _.map(fields, f => this.choiceService.retrieveObjects({choiceset: f.choiceset}));
            return fields.length ? forkJoin(choices$).pipe(
              mergeMap((choices: any) => {
                const counts = {};
                _.forEach(fields, function (value: any, key) {
                  counts[value.id] = choices[key].length;
                });
                _.map(entity.blocks, (fs) => _.map(fs.fields, (f: any) => {
                  f.choicesLength = counts[f.id] ? counts[f.id] : 0;
                }))
                return of(entity);
              })): of(entity);
          }
          return of(entity);
        }))
      } else if (entityId == -1) {
        parent$ = of([]);
        client$ = of([]);
        obs$ = of([]);
        obsNames$ = of([]);
        let params = {space, page, search: search};
        models$ = this.datalakeService.retrieveObjects(params, ).pipe(
          mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
        );
        filesCount$ = this.datalakeService.retrieveObjects({space}).pipe(
            mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
          );
      } else {
        let obs = [];
        let obsNames = [];
        permissionsModels.forEach(permiModel => {
          let service = this[permiModel.modelName + 'Service'];
          obsNames.push(of(permiModel.modelName));
          obs.push(service.retrieveObjects({entity: entityId}, []));
          if (model === permiModel.modelName) {
            currentModel = _.cloneDeep(permiModel)
          }
        });
        if (currentModel.parent) {
          let parentService = this[currentModel.parent.service + 'Service'];
          parent$ = parentService.retrieveObjects({entity: entityId, space}, []).pipe(
            mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
          )
        } else {
          parent$ = of([]);
        }
        if (currentModel.client) {
          let clientService = this[currentModel.client.service + 'Service'];
          client$ = clientService.retrieveObjects({entity: entityId, space}, []).pipe(
            mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
          )
        } else {
          client$ = of([]);
        }
        obs$ = forkJoin(obs);
        obsNames$ = forkJoin(obsNames);
        filesCount$ = of([]);
        let service = this[model + 'Service'];
        let params = {space, page, search: search};
        models$ = service.retrieveObjects(params, currentModel.propagations).pipe(
          mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
        );
      }
      return forkJoin([entities$, models$, obs$, obsNames$, parent$, filesCount$, client$]).pipe(
        tap(([entities, models, obs, obsNames, parent, filesCount, client]: any) => {
          let counts = {};
          if (entityId > 0 ) {
            newEntitiesModels = entitiesModels.map(entityModel => {
              let entityModelCloned = _.cloneDeep(entityModel);
              if (entityModelCloned.modelName === 'tag' && !_.find(models.extensions, e => e.type === 'Tags')) {
                return;
              }
              if (entityModelCloned.modelName === model) {
                currentModel = entityModelCloned;
              }
              entityModelCloned.count = entityModelCloned.objects(models).length;
              return entityModelCloned;
            }).filter(entityModel => !!entityModel);
          } else if (entityId == -1 ) {
            newDatalakeModels = datalakeModels.map(datalakeModels => {
                let datalakeModelsCloned = _.cloneDeep(datalakeModels);
                if (datalakeModelsCloned .modelName === model) {
                  currentModel = datalakeModelsCloned ;
                }
                datalakeModelsCloned.count = counts[datalakeModelsCloned.modelName];
                return datalakeModelsCloned ;
              });
          } else {
            for (let i = 0; i < obs.length; i++) {
              counts[obsNames[i]] = obs[i].length;
            }
            newPermissionsModels = permissionsModels.map(permissionModels => {
              let permissionModelsCloned = _.cloneDeep(permissionModels);
              if (permissionModelsCloned.modelName === model) {
                currentModel = permissionModelsCloned;
              }
              permissionModelsCloned.count = counts[permissionModelsCloned.modelName];
              return permissionModelsCloned;
            });
          }
          let sections = _.map(entities, e => {
            return {
              entity: e,
              models: entityId === e.id ? newEntitiesModels : [],
              isActive: entityId === e.id
            }
          });
          sections.push({
            entity: {
              id: 0,
              label: 'Permissions',
              plural: 'Permissions',
              icon: 'lock-alt'
            },
            models: !entityId ? newPermissionsModels : [],
            isActive: !entityId
          });
          sections.push({
            entity: {
              id: -1,
              label: translate('Lac de données'),
              plural: translate('Lac de données'),
              icon: 'fa-paperclip'
            },
            models: entityId == -1 ? newDatalakeModels: [],
            isActive: !entityId
          });
          if (parent.length) {
            currentModel.parent.values = parent;
          }
          if (client.length) {
            currentModel.client.values = client;
          }
          mutateState(ctx, draft => {
            draft.currentEntity = entityId;
            draft.sections = sections;
            draft.currentModel = currentModel;
            draft.objects = (entityId > 0) ? currentModel.objects(models) : models;
            draft.isReady = true;
            draft.isSectionReady = true;
            draft.filesCount = filesCount.length
          })
        }))
    }

    @Action(AdministrationPage.SearchObjects)
    searchObjects(ctx, { entityId, model, page, search, propagations}) {
        let space = this.localService.getSpace();
        let service = this[model+'Service'];
        let params = entityId ? {entity: entityId, page} : { space, page, search: search};
        let  models$ = service.retrieveObjects(params, propagations)
                    .pipe(mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([]))))
        return models$.subscribe((models) => {
            mutateState(ctx, draft => {
                draft.objects = models;
            })
        })
    }

    // Admin retrieve objects
    @Action(AdministrationPage.RetrieveObjects)
    retrieveObjects(ctx, { entityId, model, page, search }) {
        let currentModel = ctx.getState().currentModel;
        let service = this[model+'Service'];

        let space = this.localService.getSpace();
        let params = entityId ? {entity: entityId, page} : {space, page, search: search};

        let models$ = !currentModel.children?
                 service.retrieveObjects(params, currentModel.propagations)
                .pipe(mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([]))))
                :
                service.retrieveObjects(params, currentModel.propagations)
                .pipe(mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$).pipe(
                    mergeMap((objects: any) => {
                        let childrenService = this[currentModel.children.service];
                        let children$ = [];
                        for (let obj of objects) {
                            if (obj[currentModel.children.fetchCondition['key']] === currentModel.children.fetchCondition['value']) {
                                children$.push(childrenService.retrieveObjects({[currentModel.modelName]: obj.id}).pipe(
                                    mergeMap((observables$: any) => {
                                        obj[currentModel.children.name] = observables$;
                                        return of(obj);
                                    })
                                ))
                            } else {
                                children$.push(of(obj));
                            }
                        }
                        return forkJoin(children$);
                    })
                ) : of([]))))

        return models$.pipe(
            tap((models: any) => {
                // mutate state
                mutateState(ctx, draft => {
                    draft.objects.push(...models);
                });
            })
        )

    }

    // Admin update object
    @Action(AdministrationPage.UpdateObject, {cancelUncompleted: true})
    updateObject(ctx, { object, page }) {
        let {currentModel} = ctx.getState();
        let service = this[currentModel.modelName + 'Service'];
        object = _.cloneDeep(object);

        if(currentModel.isSortable && Array.isArray(object)){
            let sortableObjects = [];
            object.forEach(obj => {
                sortableObjects.push(service.updateObject(obj, currentModel.propagations, true));
            });

            let sortableObjects$ = forkJoin(sortableObjects);
            return sortableObjects$.pipe(
                tap(res => {
                    mutateState(ctx, draft => {
                        draft.objects = res;
                    })
                }),
                catchError(err => {
                    mutateState(ctx, draft => {
                        draft.objects = _.cloneDeep(draft.objects);
                    });
                    return throwError(err);
                })
            )
        }
        transformWriteOnlyFields(currentModel, object);

        // update field and retrieve its children (choices)
        if (currentModel.modelName == 'field') {
            return this.choicesetService.updateObject(object, currentModel.propagations, true).pipe(
                mergeMap((obj: any) => {
                    let childrenService = this[currentModel.children.service];
                    let children$;
                    if (obj[currentModel.children.fetchCondition['key']] === currentModel.children.fetchCondition['value']) {
                        children$ = childrenService.retrieveObjects({[currentModel.modelName]: obj.id, page: 1, page_size: 18 * page}).pipe(
                            mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([]))),
                            mergeMap((children: any) => {
                                obj[currentModel.children.name] = children;
                                return of(obj);
                            }))
                    } else {
                        children$ = of(obj);
                    }
                    return children$;
                }),
                tap((res: any) => {
                    mutateState(ctx, draft => {
                        draft.objects = draft.objects.map(obj => {
                            if(obj.id === res.id){
                                if (obj.choicesLength){
                                    res.choicesLength = obj.choicesLength;
                                }
                                return res;
                            }
                            if (obj.choiceset === res.id) {
                              obj.choices = object.choices_;
                            }
                            return obj;
                        })
                    });
                })
            );
        }

        return service.updateObject(object, currentModel.propagations, true).pipe(
            tap((res: any) => {
                if (currentModel.modelName === 'user' && object.hasOwnProperty('isActive')) {
                    mutateState(ctx, draft => {
                        draft.objects = draft.objects.filter(obj => obj.id !== object.id);
                        draft.currentModel.count--;
                        draft.sections.forEach(section => {
                            if(section.isActive){
                                section.models.forEach(model => {
                                    if(model.modelName === currentModel.modelName){
                                        model.count--;
                                    }
                                })
                            }
                        });
                    })
                }
                else {
                    mutateState(ctx, draft => {
                        draft.objects = draft.objects.map(obj => {
                            if(obj.id === res.id){
                                return res;
                            }
                            return obj;
                        })
                    });
                }
            })
        );
    }

    // Admin create object
    @Action(AdministrationPage.CreateObject)
    createObject(ctx, { object }) {
        let {currentEntity, currentModel} = ctx.getState();
        let service = this[currentModel.modelName + 'Service'];

        // if currentEntity != 0 then we should give the object the id of entity
        if (currentEntity){
            object.entity = currentEntity;
        }

        let currentSpace = this.localService.getSpace();

        if(currentModel.multi && object[currentModel.modelName+'s']) {
            let objs = object[currentModel.modelName+'s'].trim().split('\n')
            let data = [];
            for(let obj of objs) {
                if (obj) {
                    let fields = obj.trim().split(',')
                    if (fields.length !== 3) {
                        return throwError(new Error(translate('Format indésirable')));
                    }

                    data.push({
                        space: object.spaces[0],
                        spaces: object.spaces,
                        role: object.role,
                        roles: object.roles,
                        email: fields[0],
                        firstName: fields[1],
                        lastName: fields[2]
                    });
                }
            }
            object = data;
        }
        else {
            object.space = currentSpace;
            transformWriteOnlyFields(currentModel, object);
        }

        return service.createObject(object, currentModel.propagations).pipe(
            mergeMap((res: any) => {
                if(currentModel.multi) {
                    let space = this.localService.getSpace();
                    return service.retrieveObjects({space}, currentModel.propagations).pipe(
                        mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([])))
                    )
                }
                else {
                    return of(res);
                }
            }),
            tap((res: any) => {

                mutateState(ctx, draft => {
                    if (currentModel.multi) {
                        draft.objects = res;
                        draft.currentModel.count += res.length;
                    }
                    else {
                        draft.objects.push(res);
                        draft.currentModel.count++;
                    }
                    draft.currentModel.count++;
                    draft.sections.forEach(section => {
                        if(section.isActive){
                            section.models.forEach(model => {
                                if(model.modelName === currentModel.modelName){
                                    if (currentModel.multi) {
                                        model.count = res.length;
                                    }
                                    else {
                                        model.count++;
                                    }
                                }
                            })
                        }
                    });
                });
            })
        );
    }

    // Admin delete object
    @Action(AdministrationPage.DestroyObject)
    destroyObject(ctx, {id}){
        let {currentModel} = ctx.getState();

        let service = this[currentModel.modelName + 'Service'];

        return service.destroyObject(id).pipe(
            tap((res: any)=> {
                mutateState(ctx, draft => {
                    draft.objects = draft.objects.filter(obj => obj.id !== id);
                    draft.currentModel.count--;
                    draft.sections.forEach(section => {
                        if(section.isActive){
                            section.models.forEach(model => {
                                if(model.modelName === currentModel.modelName){
                                    model.count--;
                                }
                            })
                        }
                    });
                })
            })
        )
    }

    @Action(AdministrationPage.CreateChoice)
    createChoice(ctx, { choice }) {
        return this.choiceService.createObject(choice).pipe(
            tap((createdChoice: any) => {
                this.cache.clearContains('choices', [[{}, {cached: {queryParam: 'choiceset'}}]], createdChoice.choiceset)
                mutateState(ctx, draft => {
                    // add choice to the field
                    _.forEach(draft.objects, obj => {
                      if (obj.canEditChoices && obj.choiceset === createdChoice.choiceset) {
                          obj.choices.unshift(createdChoice);
                          if (obj.choicesLength) {
                              obj.choicesLength += 1;
                          }
                      }
                  });
                });
            })
        );
    }

    @Action(AdministrationPage.CreateChoices)
    createChoices(ctx, { field, choices }) {
        return this.choiceService.createObject(choices).pipe(
            tap((choices: any) => {
                if (choices.length) {
                    // clear entry: /choices/?choiceset=id
                    this.cache.clearContains('choices', [[{}, {cached: {queryParam: 'choiceset'}}]], field.choiceset)
                    mutateState(ctx, draft => {
                        // add choice to the choiceset
                        draft.objects = draft.objects.map(obj => {
                            if (obj.choiceset === choices[0].choiceset) {
                                obj.choices = obj.choices.concat(choices);
                            }
                            return obj;
                        })
                    });
                }
            })
        );
    }

    @Action(AdministrationPage.UpdateChoice)
    updateChoice(ctx, { choice }) {
        return this.choiceService.updateObject(choice).pipe(
            tap((choice: any) => {
                mutateState(ctx, draft => {
                    draft.objects = draft.objects.map(obj => {
                        if (obj.choiceset === choice.choiceset) {
                            obj.choices = obj.choices.map(c => c.id === choice.id ? choice : c);
                        }
                        return obj;
                    })
                });
            })
        );
    }

    @Action(AdministrationPage.DestroyChoice)
    destroyChoice(ctx, { id }) {
        const state = ctx.getState();
        const choiceField = state.objects.find(f => (f.choices && f.choices.filter(c => c.id === id).length > 0))

        return this.choiceService.destroyObject(id).pipe(
            tap(res => {
                this.cache.clearContains('choices', [[{}, {cached: {queryParam: 'choiceset'}}]], choiceField.choiceset)
                mutateState(ctx, draft => {
                    draft.objects = draft.objects.map(obj => {
                        if (obj.choices) {
                            obj.choices = obj.choices.filter(c => c.id !== id);
                            if (obj.choicesLength){
                                obj.choicesLength -= 1
                            }
                        }
                        return obj;
                    });
                });
            })
        );
    }

    @Action(AdministrationPage.RetrieveFieldChoices)
    retrieveFieldChoices(ctx, { field, page }) {
        // invalidate cache
        let cachedResults = this.cache.getContains('choices', [{queryParam: 'choiceset', value: field.choiceset}]);
        if (cachedResults.length) {
          for (let cachedResult of cachedResults) {
            for (let choiceId of cachedResult['body']) {
                this.cache.clearContains('choices', [[{}, {cached: {param: 'id'}}]], choiceId);
            }
          }
        }
        return this.choiceService.retrieveObjects({choiceset: field.choiceset, page: page}).pipe(
            mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$) : of([]))),
            tap((choices: any) => {
                mutateState(ctx, draft => {
                    draft.objects = draft.objects.map(obj => {
                        if (obj.id === field.id) {
                            if (obj.choices && obj.choices.length > 0 && page === 1 && !obj.choicesLength){
                                obj.choicesLength = obj.choices.length
                            }
                            if (obj.choices && obj.choices[0] && obj.choices[0].id && page > 1){
                                obj.choices = obj.choices.concat(choices.filter(choice =>
                                  obj.choices.every(existingChoice => existingChoice.id !== choice.id)
                                ));
                            } else {
                                obj.choices = choices;
                            }
                        }
                        return obj;
                    });
                });
            })
        );
    }

    @Action(AdministrationPage.CreateDatalakeFile)
    CreateDatalakeFile(ctx, { file }) {
        mutateState(ctx, draft => {
            draft.objects.unshift({id: -1});
          });
        const newAttachment = {
            file,
            space: this.localService.getSpace(),
          };
        return this.datalakeService.createObject(newAttachment).pipe(
            tap(res=> {
                mutateState(ctx, draft => {
                    const index = _.findIndex(draft.objects, {id: -1});
                    draft.objects.splice(index, 1, res);
                  });
            })
        );
    }

    @Action(AdministrationPage.UpdateHiddenSections)
    UpdateHiddenSections(ctx, { hiddenSections }) {
      mutateState(ctx, draft => {
        draft.hiddenSections = hiddenSections;
      });
    }
}
