import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Input, ChangeDetectionStrategy} from '@angular/core';
import {capturePhoto, startCamera, isWindowsTablet, stopCamera} from "../../app.utils";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class UploadComponent implements OnInit {
  @ViewChild('fileRef') fileRef:ElementRef;
  @ViewChild('ModalRef') ModalRef: ElementRef;
  @Output() uploadSelect = new EventEmitter()
  @Input() class = ''
  @Input() multiple = false;
  @Input() fileType = null;
  videoElement: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  fileChange(event) {
    if (!event.target.files.length) return;
    let index = 0
    while (event.target.files[index]){
      let file = event.target.files[index];
      this.uploadSelect.emit({ file: file});
      index++;
    }
    event.target.value = null
  }

  onFileDropHandler(event) {
    if (this.multiple){
      for (const file of event) {
        file.fileEntry.file(element => {
          this.uploadSelect.emit({file: element});
        });
      }
    } else {
      let fileEntry = event[0].fileEntry;

      fileEntry.file(file => {
        this.uploadSelect.emit({file: file});
      });
    }
  }

  capturePhoto(modal) {
    const byteCharacters = atob(capturePhoto(this.videoElement).split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });

    // Créer un objet File à partir du blob
    const file = new File([blob], 'photo.png', { type: 'image/png' });
    this.uploadSelect.emit({ file: file});
    modal.close();
  }

  chooseFile() {
    if (isWindowsTablet()){
      this.openModal();
    } else {
      this.fileRef.nativeElement.click();
    }
  }

  uploadPicture(modal){
    this.fileRef.nativeElement.click();
    modal.close();
  }

  openModal(){
    const modelRef = this.modalService.open(this.ModalRef, {size: 'lg', centered: true});
    this.videoElement = document.getElementById('video');
    startCamera(this.videoElement);
    modelRef.closed.subscribe(() => {
      stopCamera(this.videoElement?.srcObject);
    });
    modelRef.dismissed.subscribe(() => {
      stopCamera(this.videoElement?.srcObject);
    });
  }
}
