import { Pipe, PipeTransform } from '@angular/core';
import {DateFormatPipe} from "ngx-moment";
import * as moment from "moment";
import {translate} from "@jsverse/transloco";

@Pipe({
  name: 'dateFormat'
})
export class FormatDatePipe extends DateFormatPipe implements PipeTransform {

  transform(value: Date | moment.Moment | string | number, ...args: any[]): string {
    if (!value) {
      return '';
    }
    const currentYear = new Date().getFullYear();
    if (typeof value === 'string' && !args.length){
      if (moment(value).format('YYYY-MM-DD').split('-')[0] === currentYear.toString()){
        return super.transform(value, 'DD MMM');
      } else {
        return super.transform(value, 'DD MMM YYYY');
      }
    }
    return super.transform(value, args[0]);
  }
}
