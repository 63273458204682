import {Component, OnInit, EventEmitter, Input, Output, ChangeDetectionStrategy} from '@angular/core';
import {translate} from "@jsverse/transloco"

@Component({
  selector: 'groupby',
  templateUrl: './groupby.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupbyComponent implements OnInit {
  @Input() fields = [];
  @Input() value;
  @Input() hasSharedSpaces;
  @Input() isMobile: Boolean = false;
  @Input() isRecord: Boolean = false;
  @Output() groupbyChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  getGroupbyName(value) {
    if (!value) {
      return translate('Regrouper')
    }
    let name = value
    let nameFields = this.fields.filter(f => f.name == name)
    if (nameFields.length) {
      return nameFields[0].title
    }
    else if (this.hasSharedSpaces){
      return  translate('Espace')
    }
    else {
      return ''
    }

  }

}
