// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled {
  opacity: 0.5;
  pointer-events: none;
}

::ng-deep .offcanvas-backdrop {
  z-index: 9998 !important; /* Ensure this is higher than other elements */
}

::ng-deep .offcanvas {
  z-index: 9999 !important; /* Ensure this is higher than other elements */
}`, "",{"version":3,"sources":["webpack://./src/app/components/field-many/field-many.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,oBAAA;AACJ;;AACA;EAEI,wBAAA,EAAA,8CAAA;AACJ;;AACA;EAEI,wBAAA,EAAA,8CAAA;AACJ","sourcesContent":[".disabled {\n    opacity: 0.5;\n    pointer-events: none;\n}\n::ng-deep  .offcanvas-backdrop {\n\n    z-index: 9998 !important; /* Ensure this is higher than other elements */\n}\n::ng-deep  .offcanvas {\n\n    z-index: 9999 !important; /* Ensure this is higher than other elements */\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
