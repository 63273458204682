import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DragulaService} from "ng2-dragula";

@Component({
  selector: 'checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistComponent implements OnInit {

  @Input() public record;
  @Input() public extension;
  @Input() public checkpointTags;
  @Input() public isCreating;
  @Input() public loading;

  @Output() public onCreateSubmit = new EventEmitter();
  @Output() public onChecklistObjectTitleChange = new EventEmitter();
  @Output() public onUpdateCheckpointType  = new EventEmitter();
  @Output() public onAddCheckpointAttachment = new EventEmitter();
  @Output() public onDeleteCheckpointAttachment = new EventEmitter();
  @Output() public onCreateCheckpointChoice = new EventEmitter();
  @Output() public onUpdateCheckpointChoice = new EventEmitter();
  @Output() public onDeleteCheckpointChoice = new EventEmitter();
  @Output() public onToggleCheckpointTag = new EventEmitter();
  @Output() public onCreateCheckpointTag = new EventEmitter();
  @Output() public onDeleteCheckpointTag = new EventEmitter();
  @Output() public onUpdateCheckpointNotes = new EventEmitter();
  @Output() public onDeleteCheckpoint = new EventEmitter();
  @Output() public onDeleteCheckpointset = new EventEmitter();
  @Output() public openImportCheckpointsModal = new EventEmitter();
  @Output() public OnOpenImportChoicesModal = new EventEmitter();
  @Output() public openCreateCheckpointsetModal = new EventEmitter();
  @Output() public dragulaChecklistMove = new EventEmitter();


  checkpointsStr;
  checkpointsetStr;
  confirmationModal;
  checkpointToDelete;
  checkpointsetToDelete;
  scrollInterval = null;

  disableDragAndDropOnSync(element) {
    // disable drag-drop buttons when synchronizing elements
    const elementsWithClass = this.elementRef.nativeElement.querySelectorAll(element);
    elementsWithClass.forEach(element => {
      element.parentElement.style.pointerEvents = 'none';
    });
  };
  constructor(private modalService: NgbModal, private dragulaService: DragulaService,
    private elementRef: ElementRef) {
    dragulaService.destroy('Checkpointsets');
    dragulaService.destroy('Checkpoints');


    dragulaService.createGroup("Checkpointsets", {
      moves: (el, container, handle) => {
        return handle.className.includes('dragula-checkpointsets-handle');
      },
      revertOnSpill: true,
    });

    dragulaService.createGroup("Checkpoints", {
      moves: (el, container, handle) => {
        return handle.className.includes('dragula-checkpoints-handle');
        },
        revertOnSpill: true,
    });

    dragulaService.dropModel('Checkpointsets').subscribe( obj => {
      const checkpointsets = obj.targetModel.map((cps, index) => ({ id: cps.id, position: index + 1, checklistRecord: cps.checklistRecord }));

      this.disableDragAndDropOnSync('a.dragula-checkpointsets-handle');

      this.dragulaChecklistMove.emit({type: 'checkpointsets', value: checkpointsets})
    });

    dragulaService.dropModel('Checkpoints').subscribe( obj => {
      // target checkpointset
      const checkpointset = obj.targetModel.find(e => e.id === -1);
      let checkpoints;
      // get source checkpointset id
      let staleObjects = [obj.item.checkpointset];
      // if target has no children and we added a new one
      if (checkpointset) {
        checkpoints = obj.targetModel.filter(cp => cp.id !== -1).map((cp, index) => ({ id: cp.id, position: index, checkpointset: checkpointset.checkpointsetId }));
      }
      // if target has children, then we should update the checkpointset of the extra child
      else {
        const targetCheckpointsetId = obj.targetModel.find(e => e.id !== obj.item.id).checkpointset;
        staleObjects = staleObjects.filter(staleObj => staleObj !== targetCheckpointsetId);
        checkpoints = obj.targetModel.map((cp, index) => {
          if (cp.id === obj.item.id) {
            return { id: cp.id, position: index, checkpointset: targetCheckpointsetId };
          }
          return { id: cp.id, position: index, checkpointset: cp.checkpointset };
        });
      }

      this.disableDragAndDropOnSync('a.dragula-checkpoints-handle');
      this.dragulaChecklistMove.emit({type: 'checkpoints', value: {objects: checkpoints, staleObjects}})
    })
  }

  ngOnInit() {
    this.dragulaService.drag('Checkpoints').subscribe(({name, el, source}) => this.onDrag({name, el, source}));
    this.dragulaService.drag('Checkpointsets').subscribe(({name, el, source}) => this.onDrag({name, el, source}));
    // detach the mouse move event when the drag ends
    this.dragulaService.dragend('Checkpoints').subscribe(_ => {
      document.onmousemove = null;
      this.stopScrolling();
    });
    this.dragulaService.dragend('Checkpointsets').subscribe(_ => {
      document.onmousemove = null;
      this.stopScrolling();
    });
  }

  private startScrollingUp() {
    if (this.scrollInterval) return; // don't set it multiple times
    this.scrollInterval = window.setInterval(() => window.scrollBy({ top: -50 }), 80);
  }

  private startScrollingDown() {
    if (this.scrollInterval) return;
    this.scrollInterval = window.setInterval(() => window.scrollBy({ top: 50 }), 80);
  }

  private stopScrolling() {
    window.clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  }

  private onDrag({ name, el, source }) {
  // add an event listener
  document.onmousemove = e => {
    let event = e || window.event;
    let mouseY = event['pageY'];
    let scrollTop = document.documentElement?.scrollTop || document.body.scrollTop;
    let scrollBottom = scrollTop + window.innerHeight;
    let elementHeight = el.clientHeight;

    // and check if the event is on top or bottom of the page
    if (mouseY - elementHeight / 2 < scrollTop) {
      this.startScrollingUp();
    } else if (mouseY + elementHeight / 2 > scrollBottom) {
      this.startScrollingDown();
    } else {
      this.stopScrolling();
    }
  };
}


  // used for the dragula [dragulaModel]
  getCheckpoints(evt) {
    const checkpointsetId = evt.checkpointsetId;
    const checkpoints = evt.checkpoints;
    return checkpoints.length ? checkpoints : [{id: -1, checkpointsetId}];
  }

  onCreateCheckpointset(modal){
    modal.close(this.checkpointsetStr)
    this.checkpointsetStr = ''
  }
  onCreateCheckpoints(modal){
    modal.close(this.checkpointsStr)
    this.checkpointsStr = ''
  }

  onCheckpointDestroyConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onCheckpointsetDestroyConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onCheckpointsetDestroyConfirmationOpen(evt) {
    const confirmationRef = evt.confirmationRef;
    this.checkpointsetToDelete = evt.checkpointset;
    this.confirmationModal = this.modalService.open(confirmationRef);
  }

  onCheckpointDestroyConfirmationOpen(evt) {
    const confirmationRef = evt.confirmationRef;
    this.checkpointToDelete = evt.checkpoint;
    this.confirmationModal = this.modalService.open(confirmationRef);
  }

  checkpointDestroy() {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }
    this.onDeleteCheckpoint.emit(this.checkpointToDelete);
  }

  checkpointsetDestroy() {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }
    this.onDeleteCheckpointset.emit(this.checkpointsetToDelete);
  }
}
