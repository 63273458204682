// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 990px) {
  ::ng-deep .record-page-modal .modal-dialog {
    min-width: 85vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/modal/modal.component.scss"],"names":[],"mappings":"AAAA;EACI;IACI,eAAA;EACN;AACF","sourcesContent":["@media screen and (max-width: 990px) {\n    ::ng-deep .record-page-modal .modal-dialog {\n        min-width: 85vw;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
