import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {mergeMap} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import {UserService} from "../../services/user.service";
import * as _ from 'lodash';
import {marked} from 'marked';
import DOMPurify from 'dompurify';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements OnInit, OnChanges {
  @Input() mode;
  @Input() comment;
  @Input() user = null;
  @Input() recordId;
  @Output() commentSubmit = new EventEmitter();
  @Output() commentDelete = new EventEmitter();
  mentionConfig = {disableSearch: true, allowSpace: true, labelKey: 'fullName', mentionSelect: this.onMentionSelection,
                  parentRef: this};
  valueControl;
  users;
  mentionsMapping = {};
  commentDisplay;
  constructor(public userService: UserService) { }

  ngOnInit() {
  }

  ngOnChanges(){
    if (typeof(this.comment) == 'object') {
      let comment = _.cloneDeep(this.comment);
      let commentText = comment.text;
      let commentDisplay = DOMPurify.sanitize(marked(comment.text, {breaks: true, gfm: true}));
      this.comment.mentions.forEach((mention) => {
        this.mentionsMapping[mention.id] = mention.fullName;
        const globalRegex = new RegExp('@' + mention.id, 'g');
        commentText = commentText.replace(globalRegex, '@' + mention.fullName);
        //TDO mention template redesigned
        commentDisplay = commentDisplay.replace(globalRegex,
          `<span class="badge rounded-pill badge-light-info fw-semibold out fs-6">
              <img width="25px" class="rounded me-2" src="${mention.picture}">
              ${mention.fullName}
            </span>`
        );
      });
      comment.text = commentText;
      this.comment = comment;
      this.commentDisplay = commentDisplay;
    }
    this.valueControl = new UntypedFormControl(typeof(this.comment) == 'object' ? this.comment.text : '');
  }

  onSubmitHandler() {
    if (!this.valueControl.value) return;
    this.mode = this.mode == "update" ? 'read' : 'createCollapsed';
    let value = this.valueControl.value;
    Object.keys(this.mentionsMapping).forEach((key) => {
      const globalRegex = new RegExp('@' + this.mentionsMapping[key], 'g');
      value = value.replace(globalRegex, '@' + key);
    });
    this.commentSubmit.emit(value);
    this.valueControl.setValue('');
  }

  onCancel() {
    this.mode === 'update' ? this.mode = 'read' : this.mode = 'createCollapsed';
    this.valueControl = new UntypedFormControl(this.comment ? this.comment.text : '');
  }

  onMentionSelection(selected) {
    this['parentRef'].mentionsMapping[selected.id] = selected.fullName;
    return '@' + selected.fullName;
  }

  onDeleteHandler() {
    this.commentDelete.emit(this.comment);
  }

  searchUsers(term) {
    this.users = this.userService.retrieveObjects({page: 1, search: term, record: this.recordId})
      .pipe(mergeMap((observables: any) => observables.length ? forkJoin(observables) : of([])));
  }

}
