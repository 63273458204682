import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'flattenNotifications'
})
export class FlattenNotificationsPipe implements PipeTransform {
    transform(grouped_notifications: any){
        let flat_notifications = [];
        let groups = [];
        _.cloneDeep(grouped_notifications).forEach(notification => {
            if (groups.filter((element) => element.groupkey === notification.day).length === 0){
                if (flat_notifications[flat_notifications.length - 1]){
                    flat_notifications[flat_notifications.length - 1].first_in_day = true
                }
                groups.push({groupkey: notification.day})
                flat_notifications.push({groupkey: notification.day})
            }
            flat_notifications.push(notification);
        });
        return flat_notifications;
    }
}