// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record .card {
  cursor: pointer;
}

a.record {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/card.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AACA;EACI,eAAA;AAEJ","sourcesContent":[".record .card{\n    cursor: pointer;\n}\na.record{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
