import {Directive, ElementRef, Input, QueryList, OnChanges, SimpleChanges} from '@angular/core';
import {startWith} from 'rxjs/operators';


@Directive({
    selector: '[flexnav]'
})

export class FlexnavDirective implements OnChanges {

    @Input() flexnavElements: QueryList<ElementRef>; ;
    @Input() flexnavIcons: QueryList<ElementRef>;

    constructor(private navbar: ElementRef) { }

    ngOnChanges(changes: SimpleChanges) {
        if(this.flexnavIcons) {
            this.flexnavIcons.changes.pipe(startWith([undefined])).subscribe(change => {
              this.hideScrollbar();
            })
        }
    }

    getNavbarWidth() {
        var sum = 0;
        this.flexnavElements.forEach((element) => {
          sum += element.nativeElement.clientWidth;
        });
        return sum;
    }

    hideScrollbar() {
        if (this.navbar?.nativeElement.clientWidth > 0) {
          var nav = this.navbar.nativeElement;
          var pageSetIsActive = false;
    
          //percentages change according to pagesets being active or not
          this.flexnavElements.forEach((element) =>{
            if (element.nativeElement.classList.contains('gl-dropdown')) {
              if (element.nativeElement.children[0].classList.contains('active')) {
                pageSetIsActive = true;
                return;
              }
            }
          })
    
          // first step: hide icons
          if (this.getNavbarWidth() > nav.offsetWidth) {
            this.flexnavIcons.forEach((icon) => {
              icon.nativeElement.hidden = true;
            });
          } else {
            nav.style.visibility = 'visible';
            return;
          }

          //second setp: remove margins
          if (this.getNavbarWidth() > nav.offsetWidth) {
            this.flexnavElements.forEach((element) => {
              element.nativeElement.classList.remove('pe-5');
            })
          } else {
            nav.style.visibility = 'visible';
            return;
          }
    
          //third step: remove more margin
          if (this.getNavbarWidth() > nav.clientWidth) {
            this.flexnavElements.forEach((element) => {
              element.nativeElement.children[0].classList.add('mx-2');
            })
          } else {
            nav.style.visibility = 'visible';
            return;
          }
    
          //4th step: truncate entity's title
          if (this.getNavbarWidth() > nav.clientWidth) {
    
            // some elements are hidden by check directive
            var visibleElements = this.flexnavElements.filter(function (e) {
              return e.nativeElement.children[0].style.display != 'none';
            })
    
            //percentages change if pageset is active
            var widthPageSetNotActive = (100 / (visibleElements.length))
            var widthPageSetActive = (100 / (visibleElements.length + 1) )
            
            this.flexnavElements.forEach((element) => {
              if (element.nativeElement.children[0].style.display != 'none') {
                if (element.nativeElement.classList.contains('gl-dropdown')) {
    
                  if (element.nativeElement.children[0].classList.contains('active')) {
                    element.nativeElement.style.maxWidth = (widthPageSetActive * 2).toString() + '%';
                  } else if (pageSetIsActive){
                    element.nativeElement.style.maxWidth = widthPageSetActive.toString() + '%';
                  } else {
                    element.nativeElement.style.maxWidth = widthPageSetNotActive.toString() + '%';
                  }
      
                  element.nativeElement.children[0].style.maxWidth = '90%'
                  var divs = element.nativeElement.children[0].getElementsByTagName('div');
      
                  for (var i = 0; i < divs.length; i++) {
                    divs[i].classList.add('text-overflow');
                    var spans = divs[i].getElementsByTagName('span');
                    spans[0].classList.add('text-overflow');
                  } 
                } else {
                  if (pageSetIsActive) {
                    element.nativeElement.style.maxWidth = widthPageSetActive.toString() + '%'
                  } else {
                    element.nativeElement.style.maxWidth = widthPageSetNotActive.toString() + '%'
                  }
                  element.nativeElement.children[0].classList.add('text-overflow')
                  element.nativeElement.children[0].getElementsByTagName('span')[0].classList.add('text-overflow');
                }
              }
            })
          } else {
            nav.style.visibility = 'visible';
            return;
          }

          //5th step: decrease font size
          if (this.getNavbarWidth() > nav.clientWidth) {
            this.flexnavElements.forEach((element) => {
              element.nativeElement.classList.remove('fs-5');
              element.nativeElement.classList.add('fs-6');
            })
          } else {
            nav.style.visibility = 'visible';
            return;
          }

          nav.style.visibility = 'visible';
        }
      }

}