import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ChoiceService extends BaseService {
  key = 'choices'
  constructor(public injector:Injector) { 
    super(injector);
  }
}
