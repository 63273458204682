import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getEntity'
})
export class GetEntityPipe implements PipeTransform {

  transform(page): unknown {
    return page.entity ? page.entity : page.record.entity;
  }

}
