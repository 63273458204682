import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import { fakeEveryOne } from 'src/app/app.utils';

@Component({
  selector: 'view',
  templateUrl: './view.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewComponent implements OnInit {
  @Input() view;
  @Input() isActive = false;
  @Input() isReady = true;
  @Input() isFirst = false;
  @Input() isLast = false;
  @Input() isHidden = false;
  @Input() isEntityMasculine = false;
  @Input() icon = false;
  @Input() users;
  @Input() viewCurrentSelectedUsers;
  @Input() user;
  @Input() typedInput;
  @Output() viewDestroy = new EventEmitter();
  @Output() viewSelect = new EventEmitter();
  @Output() viewUpdate = new EventEmitter();
  @Output() viewDuplicate = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onFocusout = new EventEmitter();
  @Output() onChange = new EventEmitter();

  @ViewChild("shareViewSelect") shareViewSelect;
  isViewSelectActive = false;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() { }

  viewOpenChange(isOpen: boolean) {
    if (!isOpen){
      this.isViewSelectActive = false;
    }
  }

  onViewShare() {
    this.isViewSelectActive = true;
    this.changeDetector.detectChanges();
    this.shareViewSelect.open();
    this.shareViewSelect.focus();
  }

  trackByFn(item: any) {
    return item.id;
  }

  getUserFromValue(view, user, key) {
    if (view.users && user && !user[key]) {
      return _.find(_.concat(
        view.isPublic ? [fakeEveryOne()] : [], view.users), u => u.id === user.id);
    }
    return user;
  }

}

