// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item {
  padding: 0;
}

.dropdown-item.active, .dropdown-item:hover {
  color: unset;
  text-decoration: none;
  background-color: unset;
}

.dropdown-item.active .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}`, "",{"version":3,"sources":["webpack://./src/app/components/create/create.component.scss"],"names":[],"mappings":"AACA;EACI,UAAA;AAAJ;;AAGA;EACI,YAAA;EACA,qBAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,uDAAA;AAAJ","sourcesContent":["\n.dropdown-item {\n    padding: 0;\n  }\n\n.dropdown-item.active, .dropdown-item:hover {\n    color: unset;\n    text-decoration: none;\n    background-color: unset;\n  }\n\n  .dropdown-item.active .menu-link{\n    transition: color 0.2s ease, background-color 0.2s ease;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
