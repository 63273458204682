import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PageService extends BaseService {

  key = 'pages'
  constructor(public injector: Injector) { super(injector); }
}