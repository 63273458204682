import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'famode'
})
export class FamodePipe implements PipeTransform {

  transform(value, newMode) {
    value = value.replace('fal', newMode);
    value = value.replace('fad', newMode);
    return value
  }

}
