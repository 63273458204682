import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  key = 'comments'
  constructor(public injector:Injector) { super(injector) }
}
