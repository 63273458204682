import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService {
  
  key = 'roles';
  constructor(public injector: Injector) {
    super(injector);
   }
}
