// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle::after {
  display: none;
}

.page.record .tabs .nav-tabs {
  border-bottom-width: 0 !important;
  box-shadow: inset 0 -3px 0 0 var(--bs-gray-300);
}
.page.record .tabs .nav-tabs .nav-item .nav-link.active,
.page.record .tabs .nav-tabs.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-width: 4px !important;
}
.page.record .nav-line-tabs .nav-item .nav-link {
  padding: 0.75rem 0;
}

:host ::ng-deep ordering .dropdown a {
  margin-left: 0 !important;
}

:host ::ng-deep groupby .dropdown a {
  margin-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/record/record.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEE;EACE,iCAAA;EACA,+CAAA;AACJ;AAEE;;EAEE,mCAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;;AAKA;EACE,yBAAA;AAFF;;AAMA;EACE,yBAAA;AAHF","sourcesContent":[".dropdown-toggle::after {\n  display: none;\n}\n.page.record {\n  .tabs .nav-tabs {\n    border-bottom-width: 0 !important;\n    box-shadow: inset 0 -3px 0 0 var(--bs-gray-300);\n  }\n\n  .tabs .nav-tabs .nav-item .nav-link.active,\n  .tabs .nav-tabs.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {\n    border-bottom-width: 4px !important;\n  }\n\n  .nav-line-tabs .nav-item .nav-link {\n    padding: 0.75rem 0;\n  }\n}\n\n:host ::ng-deep ordering .dropdown a {\n  margin-left: 0 !important;\n}\n\n\n:host ::ng-deep groupby .dropdown a {\n  margin-left: 0 !important;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
