import { State, Action } from '@ngxs/store';
import { LoginPage } from './app.actions';
import { LocalService } from '../services/local.service';
import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';

@State({
    name: 'loginPage'
})
@Injectable()
export class LoginPageState {

    constructor(private userService: UserService, private localService: LocalService) {}

     // Login

     @Action(LoginPage.Login)
     login(ctx, { email, password, keepMeConnected }) {
       return this.userService.login(email, password, keepMeConnected).pipe(
         mergeMap((obj: any) => {
           return this.userService.retrieveObject(obj.id);
         }), tap((obj: any) => {
           this.localService.setUser(obj.id);
           this.localService.setSpace(obj.space);
         })
       );
     }
}
