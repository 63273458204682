import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends BaseService {

  key = 'attachments';
  constructor(public injector: Injector) {
    super(injector);
   }
}
