// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 992px) {
  .app-container {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI;IACI,gCAAA;IACA,iCAAA;EACN;AACF","sourcesContent":["@media (min-width: 992px) {\n    .app-container {\n        padding-left: 1.25rem !important;\n        padding-right: 1.25rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
