import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';
import {computeColumns, computeColumnsClasses} from "../../app.utils";

@Component({
  selector: 'cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsComponent implements OnInit{
  @Input() records = [];
  @Input() entity = null;
  @Input() isReady;
  @Input() canCreate = false;
  @Input() field = null;
  @Input() groupBy = false;
  @Input() isCollapsed = false
  @Input() scrollDisabled;
  @Input() allElementsLoaded;
  @Input() isCreatingRecord;
  @Input() parentRecord = null;
  @Input() modalWindow = null;
  @Output() cardsScroll = new EventEmitter();
  @Output() cardsCreateSubmit = new EventEmitter();
  @Output() cardsAddSubmit = new EventEmitter();
  @Output() cardsExpand = new EventEmitter();
  @Output() cardsCardClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCreateSubmit(evt) {
    this.cardsCreateSubmit.emit(evt);
  }

  onAddSubmit(evt) {
    this.cardsAddSubmit.emit(evt);
  }

  public track(index: number, record): number {
    return record.id;
  }

  computedColumns() : string {
    const cols = computeColumns(this.entity.cols);
    return computeColumnsClasses(cols);
  }

  shouldCheckParentRecord(){
    return this.parentRecord && this.parentRecord.accessLevel <= this.entity.accessLevel;
  }
}
