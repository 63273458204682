// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
  height: inherit !important;
  width: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gauge/gauge.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,yBAAA;AACJ","sourcesContent":["canvas {\n    height: inherit !important;\n    width: inherit !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
