import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityComponent implements OnInit {

  @Input() activity;
  moment = moment;

  constructor() { }

  ngOnInit() {
  }

}
