import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit {
  @Input() entity;
  @Input() mode;
  @Input() params

  @Output() filterUpdate = new EventEmitter<any>();
  constructor( private router: Router) { }

  ngOnInit(): void {
  }

  getValues(field) {
    let result = [];
    if (this.params[field.name]) {
      const paramsClone = _.cloneDeep(this.params[field.name]);
      result = Array.isArray(paramsClone) ? paramsClone : [paramsClone];
    }
    return result;
  }

  onFilterChange(field, values){
    this.filterUpdate.emit({field,values})
  }
}
