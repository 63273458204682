import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {concatMap} from "rxjs/operators";
import {concat, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReleaseService extends BaseService {
  key = 'releases';

  constructor(public injector: Injector) {
    super(injector);
  }
  retrieveObjects(params: object, propagations: string[] = []): any {
    return super.retrieveObjects(params, propagations).pipe(
        concatMap((observables$: any) => (observables$.length ? concat(...observables$) : of()))
    );
  }

}
