const plugin = {
  id: 'doughnutlabel',
  afterDatasetDraw: (chart, args, options) => {
    const config = chart.config._config;
    const computedStyle = getComputedStyle(document.body);
    
    const doughnutLabel = config.options?.plugins?.doughnutlabel;
    if (doughnutLabel && config.type === 'doughnut') {
      const label = doughnutLabel?.label || '';

      // Get the center coordinates of the chart
      const title = config.options?.plugins?.title 
      const offset = (title && title.display) ? 0.18 : 0.04;

      const centerX = chart.width / 2;
      const centerY = (chart.height + chart.height * offset)/ 2;

      // Calculate the font size based on the smaller dimension of the chart
      const labelFontSize = Math.min(chart.width, chart.height) * 0.3;
      
      chart.ctx.textBaseline = 'middle';
      chart.ctx.textAlign = 'center';
      chart.ctx.font = labelFontSize + 'px Arial';
      chart.ctx.fillStyle = computedStyle.getPropertyValue('--bs-gray-800');
      chart.ctx.fillText(label, centerX, centerY);
    }
  }
};

export default plugin;
