import { Pipe, PipeTransform } from '@angular/core';
import {DateFormatPipe} from "ngx-moment";
import * as moment from "moment";
import {translate} from "@jsverse/transloco";

@Pipe({
  name: 'datetimeFormat'
})
export class FormatDateTimePipe implements PipeTransform {

  transform(value: Date | moment.Moment | string | number, ...args: any[]): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string' && !args.length){
        return moment(value).format('lll');
    }
    return moment(value).format(args[0]);
  }
}
