import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, viewChild} from '@angular/core';
import {UserService} from "../../services/user.service";
import {Store} from "@ngxs/store";
import {ActivatedRoute, Router} from "@angular/router";
import {LoginPage} from "../../state/app.actions";
import {getCookie} from "../../app.utils";
import {LocalService} from "../../services/local.service";
import { WebSocketService } from '../../services/websocket.service';
import {GtmService} from "../../services/gtm.service";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {catchError, firstValueFrom, skip, Subject, tap, throwError} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {NgModel} from "@angular/forms";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";


declare var bootstrap: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  readonly #takeUntilDestroyed = takeUntilDestroyed();

  @ViewChild('passwordInput', { static: false }) passwordInput: NgbPopover;

  public onEmailModelChange = new Subject<string>();

  password = '';
  email = '';
  currentEmail = '';
  prevEmail = '';
  isEmailChecked = false;
  isLoading = false;
  isValid = false;
  isRateLimited = false;
  returnUrl: string;
  keepMeConnected = getCookie('keep_me_connected') ? getCookie('keep_me_connected') === 'True'  : true;

  constructor(
    private userService: UserService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private localService: LocalService,
    private gtmService: GtmService,
    private webSocketService: WebSocketService
  ) {
    this.onEmailModelChange.pipe(this.#takeUntilDestroyed).pipe(
      skip(1)).pipe(
      distinctUntilChanged()).pipe(
      debounceTime(500)).subscribe(() => {
      this.currentEmail = this.email;
    });
  }


  ngOnInit() {
    this.userService.connectedUser().subscribe((connectedUser) => {
      if (connectedUser) this.router.navigateByUrl('/home');
      this.route.queryParams
        .subscribe(params => {
          this.returnUrl = params['returnUrl'] || '/home';
        });
    });
    this.localService.removeWorkspace();
  }

  login(value: any) {
    this.isLoading = true;
    this.store.dispatch(new LoginPage.Login(value.email, value.password, value.keepMeConnected)).subscribe((obj) => {
      this.router.navigateByUrl(this.returnUrl);
      this.webSocketService.connectUserWS();
      this.userService.retrieveObject(this.userService.localService.getUser(), ['space']).subscribe((user) => {
        this.gtmService.initGA(user);
      });
    },

    (error) => {
      this.isLoading = false;
      if (error?.status === 403) {
        this.showPopover();
        this.isValid = false;
        this.password = '';
      }else if (error?.status === 429){
        this.isValid = false;
        this.password = '';
        this.isRateLimited = false;
      }else{
        this.isValid = false;
        this.password = '';
      }
    });
  }

  checkEmail(event) {
    this.isLoading = true;

    if (!this.isEmailChecked) {
      event?.preventDefault(); // Prevent form submission
    }
    firstValueFrom(this.userService.checkUserSso(this.email)).then(
      (data: any) => {
        setTimeout(() => {
          if (data.hasSso && data.ssoUrl !== '') {
            this.router.navigateByUrl('/' + data.ssoUrl);
          }
          this.isLoading = false;
          if (!this.isLoading) {
            this.prevEmail = this.currentEmail;
            this.isEmailChecked = true;
          }
        }, 2000);
      }).catch(
      (error: any) => {
        setTimeout(() => {
          this.isLoading = false;
          this.isEmailChecked = false;
          this.isValid = false;

          if (error.status === 429) {
            this.isRateLimited = true;
          }
        }, 2000);
        return throwError(error);
      })
  }

  resetEmailChecked() {
    if (this.currentEmail != this.prevEmail) {
      this.isLoading = false;
      this.isEmailChecked = false;
      this.isValid = true;
      this.isRateLimited = false;
    }
  }

  showPopover(): void {
    this.passwordInput?.open();
  }

  focusInput(): void {
    this.isValid = true;
    this.passwordInput?.close();
    this.isRateLimited = false;
  }

  hidePopover(): void {
    this.isValid = true;
    this.isRateLimited = false;
  }
 }
