import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import {Sider} from "../../state/app.actions";
import { Store } from '@ngxs/store';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {

  @Input() entity;
  @Input() isReady;
  @Input() count;
  @Input() currentCount;
  @Input() user;




  constructor(
      public store: Store,
  ) { }

  ngOnInit(): void {
  }
  switchSpace(spaceId) {
    this.store.dispatch(new Sider.SwitchSpace(spaceId,true));
  }

}
