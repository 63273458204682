import { NgModule, ModuleWithProviders, ErrorHandler as AngularErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from './classes/error-handler'; // Adjust the import path as needed
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor'; // Adjust the import path as needed

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class ErrorHandlingModule {
  static forRoot(): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule: ErrorHandlingModule,
      providers: [
        { provide: AngularErrorHandler, useClass: ErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
      ]
    };
  }
}
