import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DoughnutLabel from '../../classes/doughnut-label'
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'checklist-progress',
  templateUrl: './checklist-progress.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistProgressComponent implements OnInit {

  @Input() record;
  @Input() isEdit = false;
  @Input() style = '';
  @Input() isChart = false;
  options;
  data;
  public barChartPlugins = [ChartDataLabels, DoughnutLabel];

  theme = this.localService.getTheme();

  constructor(public localService: LocalService, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.options = this.defaultOptions;
    this.localService.theme$.subscribe((val) => {
        this.theme = val;
        this.data = this.checkpointsData();
        this.changeDetector.detectChanges();
    })
  }

  totalCheckPointsCount() {
    return this.record.fixedCheckPointsCount + this.record.emptyCheckPointsCount + this.record.acceptedCheckPointsCount + this.record.rejectedCheckPointsCount + this.record.unavailableCheckPointsCount + this.record.partiallyConformCheckPointsCount + this.record.evaluatedCheckPointsCount;
  }

  rejectedCheckPointsProgressWidth() {
    return ((this.record.rejectedCheckPointsCount / this.totalCheckPointsCount()) * 100)
  }

  acceptedCheckPointsProgressWidth() {
    return ((this.record.acceptedCheckPointsCount / this.totalCheckPointsCount()) * 100)
  }

  fixedCheckPointsProgressWidth() {
    return ((this.record.fixedCheckPointsCount / this.totalCheckPointsCount()) * 100)
  }

  unavailableCheckPointsProgressWidth() {
    return ((this.record.unavailableCheckPointsCount / this.totalCheckPointsCount()) * 100)
  }

  partiallyConformCheckPointsProgressWidth() {
    return ((this.record.partiallyConformCheckPointsCount / this.totalCheckPointsCount()) * 100)
  }

  evaluatedCheckPointsProgressWidth() {
    return ((this.record.evaluatedCheckPointsCount / this.totalCheckPointsCount()) * 100)
  }

  checkpointsData() {

    const colors = {
        'light': ['#3e9623', '#4F55DA', '#e67e22', '#F06545', '#4FC9DA', '#cdd0d9', '#efeff3'],
        'dark': ['#3e9623', '#4F55DA', '#e67e22', '#F06545', '#4FC9DA', '#cdd0d9', '#efeff3'],
    }

    return {
        labels: ['', '', '', '', '', ''],
        datasets: [{
          label: "checkpoints",
          data: [this.record.acceptedCheckPointsCount, this.record.fixedCheckPointsCount, this.record.partiallyConformCheckPointsCount, this.record.rejectedCheckPointsCount,this.record.evaluatedCheckPointsCount, this.record.unavailableCheckPointsCount, this.record.emptyCheckPointsCount],
          backgroundColor: colors[this.theme],
          hoverBackgroundColor: colors[this.theme],
          hoverBorderColor: colors[this.theme],
          borderColor: this.theme == 'light' ? '#fff' : '#1E1E2D',
          datalabels: {
            color: (ctx) => {
              if (ctx.dataset.data[ctx.dataIndex] == 0 || ['#efeff3', '#2B2B40'].includes(ctx.dataset.backgroundColor[ctx.dataIndex])) {
                return "transparent";
              } else {
                return "#fff"
              }
            }
          }
        }]
    }
  }

  defaultOptions = {
    chart: {
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: '68%',
    title: {
      display: false
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    tooltips: {
      enabled: true,
      intersect: false,
      mode: 'nearest',
      bodySpacing: 5,
      yPadding: 10,
      xPadding: 10,
      caretPadding: 0,
      displayColors: false,
      backgroundColor: '#20D489',
      titleFontColor: '#ffffff',
      cornerRadius: 4,
      footerSpacing: 0,
      titleSpacing: 0
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        color: "#fff",
        font: {
          weight: 'bold',
        }
      },
    }

  };
}
