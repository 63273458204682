import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  provideTransloco
} from '@jsverse/transloco';
import { Injectable, NgModule, Injector } from '@angular/core';
import { environment } from '../environments/environment';
import { EnvService } from './services/env.service';
import { TranslocoLocaleModule,provideTranslocoLocale } from '@jsverse/transloco-locale';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  public http = null;
  public envService = null;

  constructor(public injector: Injector) {
    this.http = this.injector.get(HttpClient);
    this.envService = this.injector.get(EnvService);
  }

  getTranslation(lang: string) {
    const parts = window && window.location && window.location.href.split("/");
    const scheme = parts[0]
    const host = parts[2]
    return this.http.get(`${scheme}//${host}/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [ TranslocoModule ],

  providers: [
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        fr: 'fr-FR',
        cn: 'cn-CN'
      }
    }),
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr', 'cn'],
        defaultLang: (localStorage.getItem('lang')? localStorage.getItem('lang'):'fr'),
        fallbackLang: "fr",
        reRenderOnLangChange: true,
        prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    }),

  ]
})
export class TranslocoRootModule {}
