import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {translate} from '@jsverse/transloco';
import {Router} from "@angular/router";

@Component({
  selector: 'audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditComponent implements OnInit {

  @Input() public record;
  @Input() public extension;
  @Input() public checkpointTags = [];
  @Input() public checkpointsets = [];
  @Input() public checkpoints = {};
  @Input() public fixingEntities;
  @Input() public isCreatingFixing;
  @Input() public isUpdatingChecklist;
  @Input() public checklistsRecords;

  @Output() public onChecklistRecordSelect = new EventEmitter();
  @Output() public onEvaluateCheckpoint = new EventEmitter<any>();
  @Output() public onUpdateCheckpointState = new EventEmitter<any>();
  @Output() public onUpdateCheckpointComment = new EventEmitter<any>();
  @Output() public onAddCheckpointAttachment = new EventEmitter<any>();
  @Output() public onDeleteCheckpointAttachment = new EventEmitter<any>();
  @Output() public onCreateCheckpointFixingRecord = new EventEmitter<any>();
  @Output() public onClickCheckpointset = new EventEmitter();
  @Output() public onClickCardsCard = new EventEmitter();
  @Output() public closeDetailPageModal = new EventEmitter();

  public activeCheckpointStates = ['Fixed', 'Accepted', 'PartiallyConform', 'Rejected', 'Evaluated', 'Unavailable', null]
  public checkpointStates = [{
    value: 'Accepted',
    color: 'lime',
    title: translate('Afficher/Masquer les points acceptés')
  }, {
    value: 'Fixed',
    color: 'info',
    title: translate('Afficher/Masquer les points corrigés')
  }, {
    value: 'PartiallyConform',
    color: 'carrot',
    title: translate('Afficher/Masquer les points partiellement conformes')
  }, {
    value: 'Rejected',
    color: 'danger',
    title: translate('Afficher/Masquer les points rejetés')
  }, {
    value: 'Evaluated',
    color: 'primary',
    title: translate('Afficher/Masquer les points evalués')
  },
    {
      value: 'Unavailable',
      color: 'gray-600',
      title: translate('Afficher/Masquer les points N/A')
    }, {
      value: null,
      color: 'gray-900',
      title: translate('Afficher/Masquer les points non évalués')
    }];

  constructor(private router: Router, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    
  }

  toggleActiveCheckpointStates(state) {
    const index = this.activeCheckpointStates.indexOf(state);
    const updatedStates = [...this.activeCheckpointStates];

    if (index === -1) {
      updatedStates.push(state);
    } else {
      updatedStates.splice(index, 1);
    }

    this.activeCheckpointStates = updatedStates;
    this.cdRef.markForCheck()
  }


  navigateToChecklist() {
    this.router.navigate(['/records', this.checklistsRecords[0].entity.id]).then(() => {
      this.closeDetailPageModal.emit();
    });
  }

}
