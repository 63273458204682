import { State, Action, Store } from '@ngxs/store';
import {Sider, Header, RecordsPage, PreferencesPage} from './app.actions';
import { mergeMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { LocalService } from '../services/local.service';
import { PageService } from '../services/page.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { WidgetService } from '../services/widget.service';
import { mutateState } from '../app.utils';
import { NotificationService } from '../services/notification.service';
import { Inject, Injectable } from '@angular/core';
import { Cache } from '../classes/cache';
import * as _ from 'lodash';
import { StateResetAll } from 'ngxs-reset-plugin';
import { WebSocketService } from '../services/websocket.service';
import {TranslocoService} from '@jsverse/transloco'
@State({
    name:'sider',
    defaults: {
        pages: [],
        spaces: [],
        primaryPages: [],
        nonPrimaryPages: [],
        user: null,
        unseenNotificationsCount: 0,
        isReady: false
    }
})

@Injectable()
export class SiderState
{
    constructor(private localService: LocalService,
                private pageService: PageService,
                private userService: UserService,
                private router: Router,
                private widgetService: WidgetService,
                private notificationService: NotificationService,
                @Inject('Cache') private cache: Cache,
                private store: Store,
                private webSocketService: WebSocketService,
                private translocoService: TranslocoService) { }

    @Action(Sider.Init, {cancelUncompleted: true})
    init(ctx, {params, queryParams}) {
        return this.userService.retrieveObject(this.localService.getUser(),['accessibleSpaces', 'space.ssoGateway', 'currentRoles']).pipe(
            mergeMap((user: any) => {
                if (user.space.id !== Number(this.localService.getSpace())) {
                    this.localService.setSpace(user.space.id);
                }
                if (!user.space.availableLanguages.includes(this.localService.getLanguage())) {
                    this.store.dispatch(new PreferencesPage.UpdateUserLanguage(user.space.availableLanguages[0]));
                }
                const pages$ = this.pageService.retrieveObjects({space: this.localService.getSpace()}, ['pageset', 'entity', 'record.entity']).pipe(
                    mergeMap((observables$: any) => (observables$.length ? forkJoin(observables$ as Array<any>) : of([]))));
                return forkJoin([pages$, of(user)])
            }),
            mergeMap(([pages, user]: any) => {
                mutateState(ctx, draft => {
                    draft.user = user;
                    if (!_.isEqual(draft.spaces, user.accessibleSpaces)){
                        draft.spaces = user.accessibleSpaces
                    }
                    if (!_.isEqual(draft.pages, pages)) {
                        draft.pages = pages;
                        draft.primaryPages = pages.filter(page => page.isPrimary);
                        draft.nonPrimaryPages = pages.filter(page => !page.isPrimary);
                    }
                    draft.isReady = (params?.isReady != null) ? params.isReady : true;
                });
                this.store.dispatch(new Header.GetUnseen());
                return of(user);
            }),
            mergeMap((user: any) => {
                if (this.router.isActive('/records', false)) {
                    try {
                        if (!_.isEqual(user.saves.records[params.id], queryParams)) {
                            let saves = _.cloneDeep(user.saves);
                            saves.records[params.id] = queryParams;
                            return this.userService.updateObject({
                                id: this.localService.getUser(),
                                saves: saves
                            }, ['accessibleSpaces', 'space.ssoGateway', 'currentRoles']);
                        } else {
                            return of(user);
                        }
                    } catch (err) {
                        return this.userService.updateObject({
                            id: this.localService.getUser(),
                            saves: {home: {}, records: {}, record: {}}
                        }, ['accessibleSpaces', 'space.ssoGateway', 'currentRoles']);
                    }
                } else {
                    return of(user);
                }
            }),
            tap((user: any) =>  {
              const updatedUser = _.cloneDeep(ctx.getState().user);
              updatedUser.saves = user.saves;
              mutateState(ctx, draft => {
                  draft.user = updatedUser;
              });
            }));
    }
    @Action(Sider.SaveUserTableHiddenCols)
    saveUserTableHiddenCols(ctx,{ entityId, columnIds}){
        let saves = _.cloneDeep(ctx.getState().user.saves);
        saves.records[entityId] = {...saves.records[entityId], "hiddenColumns": columnIds}
        return this.userService.updateObject({
            id: this.localService.getUser(),
            saves: saves
        }, ['accessibleSpaces', 'space.ssoGateway', 'currentRoles'])
        .pipe(
            tap((user: any) =>  {
            const updatedUser = _.cloneDeep(ctx.getState().user);
            updatedUser.saves = user.saves;
            mutateState(ctx, draft => {
                draft.user = updatedUser;
            });
            })
        );
    }

    @Action(Header.GetUnseen)
    getUnseen(ctx) {
        return this.notificationService.count({user: this.localService.getUser(), is_seen: false}).pipe(
            tap((count: any) => {
                mutateState(ctx, draft => { draft.unseenNotificationsCount = count });
            })
        );
    }

    @Action(Header.UpdateIsSeen)
    updateIsSeen(ctx, { isSeen }) {
        mutateState(ctx, draft => {
            if (isSeen) {
                draft.unseenNotificationsCount = draft.unseenNotificationsCount - 1;
            } else {
                draft.unseenNotificationsCount = draft.unseenNotificationsCount + 1;
            }
        });
    }

    @Action(Header.UpdateNotificationsIsSeen)
    updateNotificationsIseen(ctx) {
        mutateState(ctx, draft => {
            draft.unseenNotificationsCount = 0
        })
    }

    @Action(Header.AddNotification)
    addNotification(ctx, { count }) {
        mutateState(ctx, draft => {
            draft.unseenNotificationsCount += count;
        });
    }

    @Action(Header.UpdateUserPicture)
    updateUserPicture(ctx, { pic }) {
        mutateState(ctx, draft => {
            draft.user.picture = pic;
        })
    }
    @Action(Sider.SwitchSpace)
    switchSpace(ctx, { spaceId,redirect }) {
        if (spaceId !== this.localService.getSpace()) {
            mutateState(ctx, draft => {
                draft.isReady = false;
                draft.pages = [];
                draft.spaces = [];
                draft.primaryPages = [];
                draft.nonPrimaryPages = [];
            });
            this.webSocketService.disconnectUserWS();
            this.localService.setSpace(spaceId);
            this.localService.setActivePageSet('');
            this.cache.clearAll();
            return this.userService.updateObject({
                id: this.localService.getUser(),
                space: spaceId
            }).pipe(
                mergeMap(() => this.store.dispatch(new StateResetAll())),
                tap(() => {
                    this.store.dispatch(new Sider.Init({ isReady: !redirect })).subscribe(() => {
                        if (redirect)
                        {
                            let entityId = this.store.snapshot().app.recordsPage?.entity?.id
                            let pages = this.store.snapshot().app.sider.primaryPages.filter(p => p.entity)
                            if (entityId && pages.find(p => p.entity.id == entityId)){
                                this.store.dispatch(new RecordsPage.Init({entity:entityId})).subscribe(() => {
                                    mutateState(ctx, draft => {
                                        draft.isReady = true;
                                      });
                                });
                                this.router.navigate(['/records/'+entityId]);
                            }else if(pages.length>0){
                                this.store.dispatch(new RecordsPage.Init({entity:pages[0].entity.id,space:this.localService.getSpace()})).subscribe(() => {
                                    mutateState(ctx, draft => {
                                        draft.isReady = true;
                                      });
                                });
                                this.router.navigate(['/records/'+pages[0].entity.id]);
                            }else {
                                mutateState(ctx, draft => {
                                    draft.isReady = true;
                                });
                                this.router.navigate(['/home']);
                            }
                        }
                        this.webSocketService.connectUserWS()
                    });
                }));
        }
    }

    @Action(Header.Logout)
    logout(ctx, { }) {
        return this.userService.logout().pipe(tap(() => {
            mutateState(ctx, draft => {
                draft.pages = [];
                draft.primaryPages = [];
                draft.nonPrimaryPages = [];
                draft.user = null;
                draft.spaces = [];
            });
            this.localService.removeUser();
            this.localService.removeSpace();
            this.cache.clearAll();
            this.store.dispatch(
                new StateResetAll()
            );
        }));
    }

}
