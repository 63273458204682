import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AppState } from './../../state/app.state';
import { NotificationsPage } from './../../state/app.actions';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import * as moment from 'moment'
import {LocalService} from "../../services/local.service";
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnDestroy {

  @Select(AppState.notificationsPage) notificationsPage$;
  @Select(AppState.sider) sider$;
  window = window
  page = 1
  scrollDisabled = false
  lengthLoaded = 0;
  destroyed$: Subject<any> = new Subject();
  user = this.localService.getUser();


  constructor(private store: Store, private router: Router, public localService: LocalService) { }

  ngOnInit() {
    this.store.dispatch(new NotificationsPage.Init()).pipe(takeUntil(this.destroyed$)).subscribe()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(undefined);
    this.destroyed$.complete();
  }

  onNotificationClick(notification, navigate = true) {
    if (navigate) {
      this.router.navigate(['/record/' + notification.record]);
    }
    if (!notification.isSeen) {
      this.store.dispatch(new NotificationsPage.UpdateIsSeen(notification.id, true)).subscribe((res) => { }, () => { });
    }
  }

  onSeenUnseenClick(notification) {
    if (!notification.isSeen) {
      this.store.dispatch(new NotificationsPage.UpdateIsSeen(notification.id, true)).subscribe((res) => { }, () => { });
    } else {
      this.store.dispatch(new NotificationsPage.UpdateIsSeen(notification.id, false)).subscribe((res) => { }, () => { });
    }
  }

  allMarkAsSeen(notifications) {
    this.store.dispatch(new NotificationsPage.UpdateNotificationsIsSeen())
    /*
    for (let n of notifications) {
      this.onNotificationClick(n, false)
    }
    */
  }
  onScroll(){
    this.scrollDisabled = true
    this.page++;
    this.store.dispatch(new NotificationsPage.RetrieveNotifications(this.page)).subscribe((res) => {
      let lengthLoaded = res.app.notificationsPage.notifications.length;
      if (lengthLoaded > this.lengthLoaded){
        this.scrollDisabled = false
        this.lengthLoaded = lengthLoaded;
      }
    });
  }
  public track(index: number, notification): number {
    return notification.id;
  }

}
